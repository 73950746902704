import { Form, Input, Radio } from "antd";
import React from "react";
import { respiratoryPatternOptions } from "../../Options/RespiratoryTherapistOptions";

const RespiratoryPattern = () => {
  return (
    <>
      <Form.Item
        label="Repiratory Pattern"
        name={["respiratory_details", "pattern"]}
      >
        <Radio.Group options={respiratoryPatternOptions} />
      </Form.Item>
      <Form.Item name={["respiratory_details", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default RespiratoryPattern;
