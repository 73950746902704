import React from "react";
import { Form, Input, Radio } from "antd";
import {
  abdomenAppearanceOptions,
  bowelMovementOptions,
} from "../../../../Options/systemicExamOptions";
import { presentAbsentOptions } from "../../../../Options/commonOptions";

const AbdomenForm = () => {
  return (
    <>
      <Form.Item label="Apppearance" name="appearance">
        <Radio.Group options={abdomenAppearanceOptions} />
      </Form.Item>
      <Form.Item label="Bowel Sounds" name="bowel_sounds">
        <Radio.Group options={presentAbsentOptions} />
      </Form.Item>
      <Form.Item label="Bowel Movement" name="bowel_movement">
        <Radio.Group options={bowelMovementOptions} />
      </Form.Item>
      <Form.Item name="last_bowel_movement">
        <Input type="text" placeholder="Last Bowel Movement" />
      </Form.Item>
    </>
  );
};

export default AbdomenForm;
