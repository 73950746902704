import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SpeechReviewContext } from "./SpeechSwallowReviewFormProvider";
import {
  getLatestSpeechReviewAPI,
  getSpeechInspectionAPI,
  getSpeechReviewAPI,
  speechReviewAPI,
  updateSpeechReviewAPI,
} from "../../../../services/speech.services";
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Modal,
  Row,
  Tabs,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import SpeechSwallowInitialFunctionalDiagnosis from "../../InspectionForms/FunctionalDiagnosis/SpeechSwallowInitialFunctionalDiagnosis";
import SpeechSwallowScalesReview from "../../InspectionSummaries/Scales/SpeechSwallowScalesReview";

const SpeechSwallowReviewForm = ({
  editId,
  visible,
  setVisible,
  refreshPage,
  iAssessment,
  addMode,
  setAddMode,
  setActiveSegment,
  reviewData,
  setDailyReviewRefresh,
  dailyRefresh,
  segmentData,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [diagnosisForm] = Form.useForm();
  const [scalesForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const { formData, setFormData } = useContext(SpeechReviewContext);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);
  const newReview = reviewData?.length === 0;
  const reviewId = reviewData?.[reviewData.length - 1]?.id;
  const newReviewId = addMode === false && reviewId ? reviewId : null;
  console.log("Segment Data ", segmentData);
  const reviewDate =
    segmentData?.find(
      (option) => option.value === editId && option.value !== "others"
    )?.label ||
    new Date().toLocaleDateString("en-US", { month: "short", day: "2-digit" });

  const handleSubmit = async (values) => {
    try {
      if (editId || newReviewId) {
        await updateSpeechReviewAPI(editId || newReviewId, values);
        setDailyReviewRefresh();
      } else {
        await speechReviewAPI(values);
        refreshPage();
        setAddMode(false);
        setDailyReviewRefresh();
      }

      message.success("Form submitted successfully");

      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onDiagnosisSubmit = (values) => {
    handleSubmit({
      ...formData,
      functional_diagnosis: values,
      admission_id: params?.patient,
    });
  };
  const onScalesSubmit = (values) => {
    handleSubmit({
      ...formData,
      scales: values,
      admission_id: params?.patient,
    });
  };

  const openEdit = async () => {
    if (editId && newReview === false) {
      try {
        const response = await getSpeechReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else if (newReview === false) {
      try {
        const response = await getLatestSpeechReviewAPI(params?.patient);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else {
      try {
        const response = await getSpeechInspectionAPI(iAssessment);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return diagnosisForm.submit();
      case "2":
        return scalesForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      switch (activeTab) {
        case "1":
          diagnosisForm.validateFields().then((values) => {
            setIsDirty(false);
            onDiagnosisSubmit(values);
            setActiveTab(key);
          });
          break;
        case "2":
          scalesForm.validateFields().then((values) => {
            setIsDirty(false);
            onScalesSubmit(values);
            setActiveTab(key);
          });
          break;
        default:
          setActiveTab(key);
      }
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <div>
      <Drawer
        open={visible}
        width="100%"
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
                ({reviewDate})
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  Modal.confirm({
                    title: "Confirmation",
                    content: "Are you sure you want to close?",
                    onOk: () => {
                      setVisible(false);
                    },
                  });
                }}
              />
            </Col>
          </Row>
        }
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                getFormKey();
                const nextTab = (parseInt(activeTab) + 1).toString();
                if (activeTab === "2") {
                  setVisible(false);
                } else {
                  setActiveTab(nextTab);
                }
              }}
              type="primary"
            >
              {activeTab === "2" ? "Done" : " Save & Proceed"}
            </Button>
            {"  "}
            {activeTab !== "2" && (
              <Button
                onClick={() => {
                  getFormKey();
                  setVisible(false);
                }}
                type="primary"
              >
                Save & Close
              </Button>
            )}
          </div>
        }
        onClose={() => {
          setVisible(false);
          setActiveSegment(reviewData[0]?.id);
        }}
        closeIcon={null}
        keyboard={false}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Functional Diagnosis" key="1">
            <Form
              id="review-functional-diagnosis"
              form={diagnosisForm}
              layout="vertical"
              onFinish={onDiagnosisSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <SpeechSwallowInitialFunctionalDiagnosis
                form={diagnosisForm}
                context={SpeechReviewContext}
              />
            </Form>
          </TabPane>
          <TabPane tab="Scales" key="2">
            <Form
              id="review-scales-form"
              form={scalesForm}
              layout="vertical"
              onFinish={onScalesSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <SpeechSwallowScalesReview form={scalesForm} />
            </Form>
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

export default SpeechSwallowReviewForm;
