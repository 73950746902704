import React, { useContext, useState, useEffect, useMemo } from "react";
import { Form, Input, Typography, Checkbox } from "antd";
import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import {
  moreOstomiesOptions,
  ostomiesOptions,
} from "../../../../Options/genExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
const { Text } = Typography;
const Ostomies = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [showOthers, setShowOthers] = useState(
    initialValues?.general_examination?.ost_others === true ||
      initialValues?.examination?.ost_others === true
  );
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    setShowOthers(
      initialValues?.general_examination?.ost_others === true ||
        initialValues?.examination?.ost_others === true
    );
  }, [initialValues]);

  useEffect(() => {
    const initialCheckboxes = ostomiesOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.general_examination?.[checkbox.value] ||
        initialValues?.examination?.[checkbox.value] ||
        false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Text>Ostomies</Text>
      <CheckListFBYRadioItems
        checkBoxOptions={ostomiesOptions}
        radioOptions={moreOstomiesOptions}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
      <Form.Item valuePropName="checked" name="ost_others">
        <Checkbox onChange={(e) => setShowOthers(e.target.checked)}>
          Others
        </Checkbox>
      </Form.Item>
      {showOthers && (
        <Form.Item name="ostomies_others">
          <Input placeholder="Please specify others" />
        </Form.Item>
      )}
    </>
  );
};

export default Ostomies;
