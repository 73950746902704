import axiosInstance from "../config/axios.config";

export const createFacilityApi = (payload) => {
  return axiosInstance.post(`/facility/create/`, payload);
};
export const getFacilityListApi = () => {
  return axiosInstance.get(`/facility/list/`);
};
export const getFacilityDetailApi = (id) => {
  return axiosInstance.get(`/facility/detail/${id}/`);
};
export const updateFacilityApi = (id, payload) => {
  return axiosInstance.patch(`/facility/update/${id}/`, payload);
};
