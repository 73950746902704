import React, { useContext, useEffect, useState } from "react";
import {
  beckAnxietyOptions,
  beckAnxietyScaleOptions,
} from "../../Options/psychologistOptions";
import { Form, Radio, List, Typography } from "antd";

const BeckAnxiety = ({ context }) => {
  const { formData } = useContext(context);
  const [selectedValues, setSelectedValues] = useState({});

  const handleRadioChange = (name, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateAggregate = () => {
    return Object.values(selectedValues).reduce((sum, value) => sum + value, 0);
  };

  const getAnxietyLevelText = (aggregate) => {
    if (aggregate < 22) return "Low Anxiety";
    if (aggregate < 36) return "Moderate Priority";
    return "Potentially concerning levels of anxiety";
  };

  const aggregate = calculateAggregate();
  const anxietyLevelText = getAnxietyLevelText(aggregate);

  useEffect(() => {
    if (formData?.scales?.beck_anxiety) {
      Object.entries(formData?.scales?.beck_anxiety).forEach(([key, value]) => {
        handleRadioChange(key, value);
      });
    }
  }, [formData]);

  return (
    <>
      <Typography.Title level={5}>Beck Anxiety Inventory</Typography.Title>
      {beckAnxietyOptions.map((option) => (
        <Form.Item
          key={option.value}
          label={option.label}
          name={["beck_anxiety", option.value]}
        >
          <Radio.Group
            options={beckAnxietyScaleOptions}
            onChange={(e) => handleRadioChange(option.value, e.target.value)}
          />
        </Form.Item>
      ))}

      <List bordered>
        <List.Item>
          <Typography.Text strong>
            Aggregate Score: {aggregate}{" "}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text strong>Anxiety Level: </Typography.Text>
          {anxietyLevelText}
        </List.Item>
      </List>
    </>
  );
};

export default BeckAnxiety;
