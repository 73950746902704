import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Col,
  Row,
  Modal,
  InputNumber,
  message,
  Input,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { CloseOutlined } from "@ant-design/icons";
import {
  addVitalsMonitoringApi,
  updateNurseVitalsMonitoringApi,
} from "../../../../services/nurse.services";
import useQuery from "../../../../hooks/useQuery";

const GrbsModal = ({ open, setOpen, editData, refresh }) => {
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue(editData);
    }
  }, []);
  const onFinish = (values) => {
    setLoading(true);
    if (editData?.id) {
      updateNurseVitalsMonitoringApi(editData?.id, values)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
          setLoading(false);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
          setLoading(false);
        });
    } else {
      addVitalsMonitoringApi(params?.patient, { ...values, is_grbs: true })
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
          setLoading(false);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editData?.id ? "Edit Vitals" : "Add Vitals"}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Col>
          </Row>
        }
        closeIcon={null}
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
      >
        <Form layout="vertical" onFinish={onFinish} form={form} ref={formRef}>
          <Row>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form.Item
                label="GRBS"
                name="grbs"
                rules={[
                  {
                    type: "number",
                    min: 60,
                    message: `Out of Range (60-500)`,
                  },
                  {
                    type: "number",
                    max: 500,
                    message: `Out of Range (60-500)`,
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form.Item label="Insulin" name="insulin">
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form.Item label="GRBS" name="remarks">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default GrbsModal;
