import { Descriptions } from "antd";
import React from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";
import { symptomsOptions } from "../../Options/psychologistOptions";

const PsychologistSymptomsSummary = ({ formData }) => {
  const constructSymptomsSummaryItems = () => {
    const symptomsSummaryItems = [];
    if (formData?.symptoms) {
      symptomsSummaryItems.push({
        key: "1",
        label: "Symptoms",
        children: (
          <>
            {getLabelTagsArray(symptomsOptions, formData?.symptoms?.symptoms)}
            {getLabelTagInput("Others", formData?.symptoms?.others_description)}
          </>
        ),
      });
    }
    return symptomsSummaryItems;
  };
  const symptomsSummaryItems = constructSymptomsSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={"Symptoms / Impressions"}
        items={symptomsSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PsychologistSymptomsSummary;
