import React, { useState } from "react";
import { Table, Tag, Col, Pagination } from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditAppointmentModal from "./EditAppointmentModal";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import { capitalizeFirstLetter } from "../../../helpers/auth/helper";
import dayjs from "dayjs";

const AppointmentsTable = ({ data, refresh, page, setPage }) => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const columns = [
    {
      title: "Name Of Patient",
      dataIndex: "patient_first_name",
      key: "patient_first_name",
      render: (value, record) =>
        `${capitalizeFirstLetter(value)} ${capitalizeFirstLetter(
          record.patient_last_name
        )}`,
    },

    {
      title: "Date",
      dataIndex: "appointment_date",
      key: "appointment_date",
      render: (value) => dayjs(value).format("DD MMM"),
    },
    {
      title: "Slot",
      dataIndex: "appointment_time",
      key: "appointment_time",
      render: (value) => value.split(":").slice(0, 2).join(":"),
    },
    {
      title: "Practitioner Name",
      dataIndex: "doctor_name",
      key: "doctor_name",
      render: (value) =>
        `${capitalizeFirstLetter(value.split(" ")[0])} ${capitalizeFirstLetter(
          value.split(" ")[1]
        )}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <Tag
          color={value === "reported" ? "orange" : "green"}
          className={tagStyles.mdRndTag}
        >
          {capitalizeFirstLetter(value)}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            setOpen(true);
            setEditData(record);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <Table
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: 0 }}
      />
      <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
        <Pagination
          current={page}
          pageSize={50}
          total={data?.total_pages}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </Col>
      {open && (
        <EditAppointmentModal
          open={open}
          setOpen={setOpen}
          editData={editData}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default AppointmentsTable;
