import { ConfigProvider } from "antd";
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { isAuthenticated } from "../helpers/utility";
import { customLightTheme } from "../theme/customTheme";

const PrivateRoute = () => {
  const { userDetails } = useContext(UserContext);

  function reduceOpacity(hex, opacity) {
    if (userDetails?.activeOrg?.color) {
      hex = hex?.replace("#", "");
      var r = parseInt(hex?.substring(0, 2), 16);
      var g = parseInt(hex?.substring(2, 4), 16);
      var b = parseInt(hex?.substring(4, 6), 16);
      return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
    }
  }

  const customTheme = {
    ...customLightTheme,
    token: {
      ...customLightTheme.token,
      colorPrimary: userDetails?.activeOrg?.color || "#7F56D9",
    },
    components: {
      ...customLightTheme.components,
      Button: {
        ...customLightTheme.components.Button,
        colorPrimaryHover:
          reduceOpacity(userDetails?.activeOrg?.color, 0.9) || "#7F56D9",
        colorPrimaryActive: userDetails?.activeOrg?.color || "#7F56D9",
      },
      Input: {
        ...customLightTheme.components.Input,
        colorPrimaryHover: userDetails?.activeOrg?.color || "#7F56D9",
      },
    },
  };
  return isAuthenticated && isAuthenticated() ? (
    <ConfigProvider theme={customTheme}>
      <Outlet />
    </ConfigProvider>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
