export const getInitialValuesForChild = (
  requiredFields = [],
  formValues = {}
) => {
  const filteredData = {};

  Object?.keys(formValues)?.forEach((key) => {
    if (
      requiredFields.some((requiredField) => key?.includes(requiredField.value))
    ) {
      filteredData[key] = formValues[key];
    }
  });
  return filteredData;
};
