import React from "react";
import { Form, Radio } from "antd";
import { hydrationOptions } from "../../../../Options/genExamOptions";

const Hydration = () => {
  return (
    <>
      <Form.Item label="Hydration" name="hydration" rules={[]}>
        <Radio.Group options={hydrationOptions} />
      </Form.Item>
    </>
  );
};

export default Hydration;
