import { Card, Descriptions } from "antd";
import React from "react";

const SummaryMedicines = ({ data }) => {
  return (
    <Card title="Current Medicines" style={{ marginTop: "10px" }}>
      <Descriptions>
        {data?.map((medicines) => (
          <Descriptions.Item>
            <span style={{ backgroundColor: "#FFFFED" }}>
              {medicines?.name}
            </span>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
};

export default SummaryMedicines;
