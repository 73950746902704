import axiosInstance from "../config/axios.config";

export const createMedicationApi = (payload) => {
  return axiosInstance.post(`/careservices/medication/`, payload);
};
export const updateMedicationApi = (med_id, payload) => {
  return axiosInstance.put(`/careservices/medication/${med_id}/`, payload);
};
export const getMedicationApi = (params) => {
  return axiosInstance.get(`/careservices/medication/?${params}`);
};
export const searchMedicationApi = (params) => {
  return axiosInstance.get(`/careservices/search/medication/?${params}`);
};
export const getGlobalMedicationApi = (params) => {
  return axiosInstance.get(`/careservices/get-global-medicines/?${params}`);
};
