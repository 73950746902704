import React, { useState, useCallback } from "react";
import { AutoComplete, Form, Input, Row, Col, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "../../../../helpers/utility";
import { getOrgServicesSearchApi } from "../../../../services/common.services";

const setSearchOptions = (data) => {
  return data.map((options) => ({
    value: options?.name,
    key: options?.record_id,
    price: options?.cash_price,
    name: options?.name,
    label: (
      <Row>
        <Col span={10}>
          <span>{options?.name}</span>
        </Col>
      </Row>
    ),
  }));
};

const ServiceSearch = ({
  form,
  name,
  setTotalPrice,
  type,
  setFinalDiscountedPrice,
  id,
}) => {
  const [data, setData] = useState([]);
  const [searchedText, setSearchedText] = useState(null);
  const [previousPrice, setPreviousPrice] = useState(0); // Track the previous price

  const handleSearch = (searchedValue) => {
    getOrgServicesSearchApi(searchedValue.toLowerCase())
      .then((res) => {
        setData(setSearchOptions(res?.data));
      })
      .catch((err) => console.log(err));
  };

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    const currentPrice = option.price;

    // Subtract the previous price from the total
    setTotalPrice((prevTotal) => prevTotal - previousPrice + currentPrice);
    setFinalDiscountedPrice &&
      setFinalDiscountedPrice(
        (prevTotal) => prevTotal - previousPrice + currentPrice
      );

    // Update the form with the selected service
    type
      ? form.setFieldsValue({
          service_packages: {
            [name]: {
              price: option.price,
              quantity: 1,
              service: option.key,
              discount: 0,
            },
          },
        })
      : form.setFieldsValue({
          services: {
            [name]: {
              price: option.price,
              quantity: 1,
              service: option.key,
              totalservice_price: option.price,
            },
          },
        });

    // Store the current price as the new previous price
    setPreviousPrice(currentPrice);
  };

  return (
    <Form.Item
      name={[name, "service_name"]}
      label="Search Service"
      rules={[{ required: !id && true, message: "Please choose service" }]}
    >
      <AutoComplete
        disabled={id}
        key={name}
        options={data}
        className="search"
        onSearch={optimizedSearch}
        onSelect={handleSelect}
        onChange={(value) => {
          setSearchedText(value);
          optimizedSearch(value);
        }}
        value={searchedText}
        style={{ marginBottom: "6px" }}
      >
        <Input
          disabled={id}
          size="large"
          prefix={<SearchOutlined />}
          placeholder="Search services"
          style={{ width: "300px" }}
        />
      </AutoComplete>
    </Form.Item>
  );
};

export default ServiceSearch;
