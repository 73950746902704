import {
  Button,
  Form,
  InputNumber,
  Radio,
  message,
  Input,
  Typography,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getPeakFlowMeterAPI,
  peakFlowMeterAPI,
  updatePeakFlowMeterAPI,
} from "../../../../../services/physio.services";

const PeakFlowMeterTest = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});
  const genderOptions = [
    { label: "Child", value: "child" },
    { label: "Adult Male", value: "male adult" },
    { label: "Adult Female", value: "female adult" },
  ];

  const handleChange = (key, value) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
      predicted_PEFR: predictPefr(),
    };
    try {
      if (scalesId?.pefr) {
        await updatePeakFlowMeterAPI(scalesId?.pefr, finalData);
      } else {
        const response = await peakFlowMeterAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, pefr: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.pefr) {
      getPeakFlowMeterAPI(admission, scalesId?.pefr)
        .then((response) => {
          const formData = response.data;
          form.setFieldsValue(formData);
          setStates({
            gender: formData?.gender,
            height: formData?.height,
            age: formData?.age,
          });
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const predictPefr = () => {
    const { gender, age, height } = states;
    if (gender && age && height) {
      switch (gender) {
        case "male adult":
          return Math.round(((height / 100) * 5.48 + 1.58 - age * 0.041) * 60);
        case "female adult":
          return Math.round(((height / 100) * 3.72 + 2.24 - age * 0.03) * 60);
        case "child":
          return Math.round((height - 100) * 5 + 100);
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="cardless-scale">
        <Card style={{ width: "600px" }}>
          <Form id="peak-flow-meter" onFinish={handleSubmit} form={form}>
            <Form.Item label="Gender" name="gender">
              <Radio.Group
                options={genderOptions}
                onChange={(e) => handleChange("gender", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Age" name="age">
              <InputNumber
                max={150}
                min={0}
                suffix="yrs"
                onChange={(value) => handleChange("age", value)}
              />
            </Form.Item>
            <Form.Item label="Height" name="height">
              <InputNumber
                min={0}
                suffix="cms"
                onChange={(value) => handleChange("height", value)}
              />
            </Form.Item>
            <Typography>Predicted PEFR: {predictPefr()}</Typography>
            {/* <Form.Item name="predicted_PEFR" initialValue={predictPefr()} hidden>
          <Input value={predictPefr()} hidden />
        </Form.Item> */}
            <Form.Item label="Tested PEFR" name="test_PEFR">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Comments" name="comments">
              <Input defaultValue=" " />
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default PeakFlowMeterTest;
