import React from "react";
import {
  Form,
  Switch,
  TimePicker,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Select,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
const { RangePicker } = TimePicker;

const slotDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "45 minutes", value: 45 },
  { label: "60 minutes", value: 60 },
];

const MySlots = ({ timeSlots, addTimeSlot, form }) => {
  const days = Object.keys(timeSlots);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item
          label="Slot Duration"
          name="slot_duration"
          rules={[{ required: true, message: "Please select a slot duration" }]}
        >
          <Select options={slotDurations} className={inputStyles.mdSelect} />
        </Form.Item>
      </Col>
      {days.map((day) => (
        <Col key={day} xs={24} sm={12} md={8} xl={8}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues[day]?.availability !== curValues[day]?.availability
            }
          >
            {({ getFieldValue }) => {
              const status = getFieldValue([day, "availability"]);
              return (
                <Card
                  title={
                    <Row>
                      <Col span={12}>
                        <Typography>{day}</Typography>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <Form.Item
                          name={[day, "availability"]}
                          valuePropName="checked"
                          initialValue={day === "Sunday" ? false : true} // Default to false for Sunday
                        >
                          <Switch defaultChecked={day !== "Sunday"} />
                        </Form.Item>
                      </Col>
                    </Row>
                  }
                  bodyStyle={{ display: status ? "block" : "none" }}
                >
                  {timeSlots[day].map((slot, index) => (
                    <Form.Item
                      key={index}
                      name={[day, "availability_timings", index]}
                      rules={[
                        {
                          required: status && true,
                          message: "Please select a time slot",
                        },
                      ]}
                    >
                      <RangePicker use12Hours format="h:mm A" minuteStep={15} />
                    </Form.Item>
                  ))}
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    onClick={() => addTimeSlot(day)}
                  >
                    Add Another
                  </Button>
                </Card>
              );
            }}
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

export default MySlots;
