import React, { useEffect, useState } from "react";
import { Button, Col, Row, Space } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { OpenAIOutlined } from "@ant-design/icons";
import { getpatientMedicationsAPI } from "../../../services/patient.services";
import { useLocation } from "react-router-dom";
import AddMedicineModal from "./AddMedicineModal";
import ScanImageDrawer from "./ScanImage/ScanImageDrawer";
import MedicationHistoryChart from "./MedicationHistoryChart";
import AddMedicineTable from "./AddMedicineTable";

const MedicationHistoryIP = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [open, setOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getpatientMedicationsAPI(`ip_admission=${params?.patient}`)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  return (
    <>
      <>
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right", marginTop: "10px" }}>
            <Space>
              <Button
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => setHistoryModal(true)}
              >
                Medication History
              </Button>
              {/* <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setOpen(true)}
              >
                <OpenAIOutlined /> Scan Images
              </Button> */}
            </Space>
          </Col>
          <Col span={24}>
            <AddMedicineTable
              data={data}
              setData={setData}
              refreshPage={refreshPage}
              loading={loading}
            />
          </Col>
        </Row>
      </>

      {open && (
        <ScanImageDrawer
          open={open}
          setOpen={setOpen}
          refreshPage={refreshPage}
        />
      )}
      {addModal && (
        <AddMedicineModal
          open={addModal}
          setOpen={setAddModal}
          refreshPage={refreshPage}
        />
      )}
      {historyModal && (
        <MedicationHistoryChart
          open={historyModal}
          setOpen={setHistoryModal}
          data={data}
        />
      )}
    </>
  );
};

export default MedicationHistoryIP;
