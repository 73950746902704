import { Checkbox, Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { symptomsOptions } from "../../Options/psychologistOptions";
import { PsychologistInitialContext } from "../../Psychologist/InitialInspection/PsychologistInitialAssessmentProvider";

const Symptoms = ({ form }) => {
  const { formData } = useContext(PsychologistInitialContext);
  const [showOthers, setShowOthers] = useState(false);
  const handleChange = (values) => {
    setShowOthers(values?.includes("others"));
  };
  useEffect(() => {
    form.setFieldsValue(formData?.symptoms);
    setShowOthers(formData?.symptoms?.symptoms?.includes("others"));
  }, [formData]);
  return (
    <>
      <Form.Item name={"symptoms"}>
        <Checkbox.Group options={symptomsOptions} onChange={handleChange} />
      </Form.Item>
      {showOthers && (
        <>
          <Form.Item name={"others_description"}>
            <Input placeholder="Please enter the details" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default Symptoms;
