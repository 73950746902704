import { Form, InputNumber, Table, Typography } from "antd";
import React from "react";

const CervicalSpine = () => {
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Left Active",
      dataIndex: "left_active",
      key: "left_active",
      render: (text, record) => (
        <Form.Item name={["cervical_spine", `left_active_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Left Passive",
      dataIndex: "left_passive",
      key: "left_passive",
      render: (text, record) => (
        <Form.Item name={["cervical_spine", `left_passive_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Active",
      dataIndex: "right_active",
      key: "right_active",
      render: (text, record) => (
        <Form.Item name={["cervical_spine", `right_active_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Passive",
      dataIndex: "right_passive",
      key: "right_passive",
      render: (text, record) => (
        <Form.Item name={["cervical_spine", `right_passive_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      action: "Flexion",
      value: "flexion",
      max: 45,
      min: 0,
    },
    {
      key: "2",
      action: "Extension",
      value: "extension",
      max: 45,
      min: 0,
    },
    {
      key: "3",
      action: "Right Rotation",
      value: "right_rotation",
      max: 60,
      min: 0,
    },
    {
      key: "4",
      action: "Left Rotation",
      value: "left_rotation",
      max: 60,
      min: 0,
    },
    {
      key: "5",
      action: "Right Lateral Flexion",
      value: "right_lateral_flexion",
      max: 45,
      min: 0,
    },
    {
      key: "6",
      action: "Left Lateral Flexion",
      value: "left_lateral_flexion",
      max: 45,
      min: 0,
    },
  ];
  return (
    <>
      <Typography>Cervical Spine</Typography>
      <Table dataSource={data} columns={columns} pagination={false} />
    </>
  );
};

export default CervicalSpine;
