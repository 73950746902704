import React, { useContext } from "react";
import CustomLayout from "../Layout/CustomLayout";
import { Result, Typography } from "antd";
import typoStyles from "../../styles/Custom/Typography.module.css";
import { SmileOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Facilities from "./Facilities/Facilities";
import { capitalizeFirstLetter } from "../../helpers/utility";
import DietMenu from "./DietMenu/DietMenu";
import AccountDetails from "../Account/Details";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import Medication from "./Medication/Medication";
import Users from "./Users/Users";
import Investigations from "./Investigations/Investigations";
import Services from "./Services/Services";
import { UserContext } from "../../context/UserContext";

const Dashboard = () => {
  const query = useParams();
  const { userDetails } = useContext(UserContext);

  const renderContent = () => {
    switch (query?.tab) {
      case "facilities":
        return <Facilities />;
      case "diet-menu":
        return <DietMenu />;
      case "details":
        return <AccountDetails />;
      case "users":
        return <Users />;
      case "investigation":
        return <Investigations />;
      case "medication":
        return <Medication />;
      case "services":
        return <Services />;
      default:
        return (
          <CustomLayout
            header={
              <Typography className={typoStyles.h6}>
                {capitalizeFirstLetter(query?.tab)}
              </Typography>
            }
            sideContent={<SideMenu />}
          >
            <Result
              icon={<SmileOutlined style={{ color: "#101828" }} />}
              title="Coming soon!!"
            />
          </CustomLayout>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default Dashboard;
