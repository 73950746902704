import React from "react";
import { getLabelTagInput, getLabelTagYesNO } from "../constructTextFromValues";
import { Descriptions } from "antd";

const PsychologistPreviousCounsellingSummary = ({ formData }) => {
  const constructCounsellingSummaryItems = () => {
    const counsellingSummaryItems = [];
    if (formData?.previous_counselling?.previous_counselling) {
      counsellingSummaryItems.push({
        key: "1",
        label: "Previous Counselling",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.previous_counselling?.previous_counselling
            )}
            {getLabelTagInput(
              "Details",
              formData?.previous_counselling?.description
            )}
          </>
        ),
      });
    }
    return counsellingSummaryItems;
  };
  const counsellingSummaryItems = constructCounsellingSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={"Previous Counselling / Psychotherapy details"}
        items={counsellingSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PsychologistPreviousCounsellingSummary;
