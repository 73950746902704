import { Descriptions } from "antd";
import React from "react";
import {
  getGussSummary,
  getLabel,
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagsWithDescription,
} from "../constructTextFromValues";
import {
  aphasiaSeverityOptions,
  eatingAssessmentOptions,
  levelFiveOptions,
  levelFourOptions,
  levelOneOptions,
  levelSevenOptions,
  levelSixOptions,
  levelThreeOptions,
  levelTwoOptions,
  naturalSpeechOptions,
  noFunctionalSpeechOptions,
  noSpeechDisorderOptions,
  obviousSpeechDisorderOptions,
  reductionSpeechOptions,
  totalOralIntakeOptions,
  tubeDependentOptions,
} from "../../Options/speechSwallowOptions";

const SpeechSwallowScalesReviewSummary = ({ formData }) => {
  const constructScalesSummaryItems = () => {
    const scalesSummaryItems = [];
    const scales = formData?.scales || {};
    const pushScalesItem = (key, label, children) => {
      scalesSummaryItems.push({ key, label, children });
    };
    if (scales?.eating_assessment) {
      pushScalesItem(
        "1",
        "Eating Assessment Tool",
        <>
          {getLabelTagInput("Score", scales?.eating_assessment?.score)}
          {getLabelScalesTags(
            eatingAssessmentOptions,
            scales?.eating_assessment
          )}
        </>
      );
    }
    if (
      scales?.direct_swallowing_liquid ||
      scales?.direct_swallowing_semisolid ||
      scales?.direct_swallowing_solid ||
      scales?.indirect_swallowing
    ) {
      pushScalesItem(
        "2",
        "GUSS",
        <>
          {getLabelTagInput("Guss Score", scales?.guss_score)}
          {scales?.indirect_swallowing &&
            getLabelTagInput(
              "Indirect Swallowing",
              <>
                {getLabelTagInput("Score", scales?.indirect_swallowing?.score)}
                {getGussSummary(scales?.indirect_swallowing)}
              </>
            )}
          {scales?.direct_swallowing_semisolid &&
            getLabelTagInput(
              "Direct Swallowing Semi-solid",
              <>
                {getLabelTagInput(
                  "Score",
                  scales?.direct_swallowing_semisolid?.score
                )}
                {getGussSummary(scales?.direct_swallowing_semisolid)}
              </>
            )}
          {scales?.direct_swallowing_liquid &&
            getLabelTagInput(
              "Direct Swallowing Liquid",
              <>
                {getLabelTagInput(
                  "Score",
                  scales?.direct_swallowing_liquid?.score
                )}
                {getGussSummary(scales?.direct_swallowing_liquid)}
              </>
            )}
          {scales?.direct_swallowing_solid &&
            getLabelTagInput(
              "Direct Swallowing Solid",
              <>
                {getLabelTagInput(
                  "Score",
                  scales?.direct_swallowing_solid?.score
                )}
                {getGussSummary(scales?.direct_swallowing_solid)}
              </>
            )}
        </>
      );
    }
    if (scales?.masa_details) {
      pushScalesItem(
        "3",
        "MASA",
        <>
          {scales?.masa_details?.general_patient_examination &&
            getLabelTagInput(
              "General Patient Examination",
              <>{getGussSummary(scales?.general_patient_examination_details)}</>
            )}
          {scales?.masa_details?.oral_phase &&
            getLabelTagInput(
              "Oral Phase",
              <>{getGussSummary(scales?.oral_phase_details)}</>
            )}
          {scales?.masa_details?.oral_preparation_main &&
            getLabelTagInput(
              "Oral Preparation",
              <>{getGussSummary(scales?.oral_preparation_main_details)}</>
            )}
          {scales?.masa_details?.pharyngeal_phase &&
            getLabelTagInput(
              "Pharyngeal Phase",
              <>{getGussSummary(scales?.pharyngeal_phase_details)}</>
            )}
        </>
      );
    }
    if (scales?.functional_oral_intake) {
      pushScalesItem(
        "4",
        "Functional Oral Intake Scale",
        <>
          {scales?.functional_oral_intake?.tube_dependent && (
            <>
              {getLabel("Tube Dependent")}
              {getLabelTagsWithDescription(
                tubeDependentOptions,
                scales?.functional_oral_intake
              )}
            </>
          )}
          {scales?.functional_oral_intake?.total_oral_intake && (
            <>
              {getLabel("Total Oral Intake")}
              {getLabelTagsWithDescription(
                totalOralIntakeOptions,
                scales?.functional_oral_intake
              )}
            </>
          )}
          {getLabelTagInput(
            "Comments",
            scales?.functional_oral_intake?.comments
          )}
        </>
      );
    }
    if (scales?.aphasia_severity_rating) {
      pushScalesItem(
        "5",
        "Aphasia Severity Rating",
        <>
          {getLabelTag(aphasiaSeverityOptions, scales?.aphasia_severity_rating)}
        </>
      );
    }
    if (scales?.doss_details) {
      pushScalesItem(
        "6",
        "DOSS",
        <>
          {scales?.doss_details?.full_po_normal_diet && (
            <>
              {getLabel("Full per-oral nutrition (P.O): Normal diet")}
              {scales?.doss_details?.level_7 && (
                <>
                  {getLabelTagInput(
                    "Level 7: Normal in all situations",
                    getLabelTagsArray(
                      levelSevenOptions,
                      scales?.doss_details?.level_7_details
                    )
                  )}
                </>
              )}
              {scales?.doss_details?.level_6 && (
                <>
                  {getLabelTagInput(
                    "Level 6: Within functional limits/modified independence",
                    getLabelTagsArray(
                      levelSixOptions,
                      scales?.doss_details?.level_6_details
                    )
                  )}
                </>
              )}
            </>
          )}
          {scales?.doss_details?.full_po_modified_diet && (
            <>
              {getLabel("Full P.O: Modified diet and/or independence")}
              {scales?.doss_details?.level_5 && (
                <>
                  {getLabelTagInput(
                    "Level 5: Mild dysphagia: Distant supervision, may need one diet consistency restricted",
                    getLabelTagsArray(
                      levelFiveOptions,
                      scales?.doss_details?.level_5_details
                    )
                  )}
                </>
              )}
              {scales?.doss_details?.level_4 && (
                <>
                  {getLabelTagInput(
                    "Level 4. Mild-moderate dysplasia: intermittent supervision/cueing, one or two consistencies restricted",
                    getLabelTagsArray(
                      levelFourOptions,
                      scales?.doss_details?.level_4_details
                    )
                  )}
                </>
              )}
              {scales?.doss_details?.level_3 && (
                <>
                  {getLabelTagInput(
                    "Level 3: Moderate dysphagia: Total assist, supervision, or strategies, two or more diet constituencies restricted",
                    getLabelTagsArray(
                      levelThreeOptions,
                      scales?.doss_details?.level_3_details
                    )
                  )}
                </>
              )}
            </>
          )}
          {scales?.doss_details?.non_oral_nutrition && (
            <>
              {getLabel("Non oral nutrition necessary")}
              {scales?.doss_details?.level_2 && (
                <>
                  {getLabelTagInput(
                    "Level 2 : Moderately severe dysphasia: Maximum assistance or use of strategies with partial P.O. only (tolerates at least one consistency safely with total use of strategies)",
                    getLabelTagsArray(
                      levelTwoOptions,
                      scales?.doss_details?.level_2_details
                    )
                  )}
                </>
              )}
              {scales?.doss_details?.level_1 && (
                <>
                  {getLabelTagInput(
                    "Level 1 : Severe dysphasia : NPO: Unable to tolerate any P.O. safely",
                    getLabelTagsArray(
                      levelOneOptions,
                      scales?.doss_details?.level_1_details
                    )
                  )}
                </>
              )}
            </>
          )}
          {getLabelTagInput("Comments", scales?.doss_details?.comments)}
        </>
      );
    }
    if (scales?.speech_intelligibility) {
      pushScalesItem(
        "7",
        "Speech Intelligibility Scale",
        <>
          {scales?.speech_intelligibility?.no_detectable_speech_disorder && (
            <>
              {getLabelTagInput(
                "No detectable speech disorder",
                getLabelTagsArray(
                  noSpeechDisorderOptions,
                  scales?.speech_intelligibility
                    ?.no_detectable_speech_disorder_details
                )
              )}
            </>
          )}
          {scales?.speech_intelligibility?.obvious_speech_disorder && (
            <>
              {getLabelTagInput(
                "Obvious speech disorder with intelligible speech",
                getLabelTagsArray(
                  obviousSpeechDisorderOptions,
                  scales?.speech_intelligibility
                    ?.obvious_speech_disorder_details
                )
              )}
            </>
          )}
          {scales?.speech_intelligibility?.reduction_in_speech && (
            <>
              {getLabelTagInput(
                "Reduction in speech intelligibility",
                getLabelTagsArray(
                  reductionSpeechOptions,
                  scales?.speech_intelligibility?.reduction_in_speech_details
                )
              )}
            </>
          )}
          {scales?.speech_intelligibility
            ?.natural_speech_augmentative_techniques && (
            <>
              {getLabelTagInput(
                "Natural speech supplemented by augmentative techniques",
                getLabelTagsArray(
                  naturalSpeechOptions,
                  scales?.speech_intelligibility
                    ?.natural_speech_augmentative_techniques_details
                )
              )}
            </>
          )}
          {scales?.speech_intelligibility?.no_functional_speech && (
            <>
              {getLabelTagInput(
                "No functional speech",
                getLabelTagsArray(
                  noFunctionalSpeechOptions,
                  scales?.speech_intelligibility?.no_functional_speech_details
                )
              )}
            </>
          )}
          {getLabelTagInput(
            "Comments",
            scales?.speech_intelligibility?.comments
          )}
        </>
      );
    }
    return scalesSummaryItems;
  };
  const scalesSummaryItems = constructScalesSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Scales"
        items={scalesSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default SpeechSwallowScalesReviewSummary;
