import { Form, Input, Radio } from "antd";
import React from "react";
import { ventilationStatusOptions } from "../../Options/RespiratoryTherapistOptions";

const VentilationStatus = () => {
  return (
    <>
      <Form.Item
        label="Ventialtion Status"
        name={["ventilation_details", "status"]}
      >
        <Radio.Group options={ventilationStatusOptions} />
      </Form.Item>
      <Form.Item name={["ventilation_details", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default VentilationStatus;
