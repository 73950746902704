import { Descriptions } from "antd";
import React from "react";
import { getPatientConditionTags } from "../constructTextFromValues";
import { speechOptions } from "../../Options/speechSwallowOptions";
import { yesNoOptions } from "../../Options/commonOptions";

const SpeechSwallowGeneralExaminationSummary = ({ formData }) => {
  const constructExaminationSummaryItems = () => {
    const examinationSummaryItems = [];
    const pushExaminationItem = (key, label, children) => {
      examinationSummaryItems.push({ key, label, children });
    };
    if (formData?.general_examination) {
      pushExaminationItem(
        "1",
        "General Examination",
        getPatientConditionTags(speechOptions, yesNoOptions, formData)
      );
    }
    return examinationSummaryItems;
  };
  const examinationSummaryItems = constructExaminationSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="General Examination"
        items={examinationSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default SpeechSwallowGeneralExaminationSummary;
