import React, { useContext, useState, useEffect, useMemo } from "react";
import { Form, Radio } from "antd";
import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  pupilOptions,
  pupilRadioOptions,
  pupilSideOptions,
} from "../../../../Options/genExamOptions";

const Pupils = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [abnormalPupil, setAbnormalPupil] = useState(
    initialValues?.general_examination?.pupil_condition === "abnormal"
      ? true
      : false
  );
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    setAbnormalPupil(
      initialValues?.general_examination?.pupil_condition === "abnormal"
        ? true
        : false
    );
  }, [initialValues]);

  useEffect(() => {
    const initialCheckboxes = pupilSideOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.general_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Form.Item label="Pupils" name="pupil_condition">
        <Radio.Group
          options={pupilOptions}
          onChange={(e) => setAbnormalPupil(e.target.value === "abnormal")}
        />
      </Form.Item>

      {abnormalPupil && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={pupilSideOptions}
            radioOptions={pupilRadioOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default Pupils;
