import { Form, Input } from "antd";
import React from "react";

const MaintainsOwn = () => {
  return (
    <>
      <Form.Item label="Maintains Own" name={["air_way", "maintains_own"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default MaintainsOwn;
