import React, { useEffect, useState } from "react";
import { Form, Radio, Slider, Typography, Input, Button, message } from "antd";
import {
  getRpeScaleAPI,
  rpeScaleAPI,
  updateRpeScaleAPI,
} from "../../../../../services/physio.services";
import PainScale from "../GeneralExaminationForm/GeneralExaminationComponents/PainScale";
import { PhysioReviewContext } from "../../../Physiotherapist/PhysiotherapistReview/PhysioReviewFormProvider";
import RPESelection from "./RPEScaleComponents/RPESelection";

const RPEScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateRpeScaleAPI(scalesId?.rpe, finalData);
      } else {
        const response = await rpeScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, rpe: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.rpe) {
      getRpeScaleAPI(admission, scalesId?.rpe)
        .then((response) => {
          form.setFieldsValue(response.data);
          setFormData(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Form id="rpe-scale" onFinish={handleSubmit} form={form}>
          <RPESelection />
          <Form.Item name="comments">
            <Input placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default RPEScale;
