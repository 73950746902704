import React, { useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Table,
  InputNumber,
  Checkbox,
  Select,
  Button,
  Space,
} from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import styles from "../../../../styles/Custom/Dashboard/Dashboard.module.css";

const priceTagList = [
  { name: "With tax", id: true },
  { name: "Without tax", id: false },
];
const FacilityServiceModal = ({
  visible,
  setVisible,
  facilities,
  formValues,
  setFacilityPrice,
  editListData,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  useEffect(() => {
    if (
      editListData &&
      editListData?.facility_services &&
      editListData?.facility_services?.length > 0
    ) {
      form.setFieldsValue({
        ...editListData,
        facilities: editListData?.facility_services,
      });
    }
  }, [editListData]);

  const customInputForm = (index, key, initialValue, taxkey, taxInValue) => {
    return (
      <Space.Compact>
        <Form.Item
          name={["facilities", index, key]}
          rules={[
            {
              message: "Please enter a valid price which is less than 999999/-",
              pattern: new RegExp("^[0-9]{0,6}(\\.[0-9]+)?$"),
            },
          ]}
          initialValue={initialValue}
        >
          <InputNumber
            placeholder="Enter price...."
            style={{ width: "100%" }}
            className={inputStyles.mdNumberInput}
          />
        </Form.Item>
        <Form.Item
          name={["facilities", index, taxkey]}
          style={{ width: "50%" }}
          initialValue={taxInValue}
        >
          <Select className={inputStyles.mdSelect} defaultValue={false}>
            {priceTagList?.map((data) => {
              return (
                <Select.Option key={data?.id} value={data?.id}>
                  {data?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Space.Compact>
    );
  };

  const columns = [
    {
      title: "Facilities",
      dataIndex: "name",
      key: "name",

      render: (value, record, index) => {
        // Check if the record has 'name' directly, otherwise check for the nested 'facility' object
        const facilityName =
          value || (record?.facility && record?.facility?.name) || record?.id;
        return facilityName;
      },
    },
    {
      title: "Is Service available",
      dataIndex: "is_available",
      key: "is_available",

      render: (_, record, index) => (
        <Form.Item
          name={["facilities", index, "is_available"]}
          valuePropName="checked"
          initialValue={true} // Default value for the checkbox
        >
          <Checkbox />
        </Form.Item>
      ),
    },

    {
      title: "Facility Cash Price",
      dataIndex: "cash_price",
      key: "cash_price",

      render: (_, record, index) =>
        customInputForm(
          index,
          "cash_price",
          formValues?.cash_price || editListData?.cash_price,
          "cash_withtax",
          formValues?.cash_withtax || editListData?.cash_withtax
        ),
    },
    {
      title: "Facility Insurance Price",
      dataIndex: "insurance_price",
      key: "insurance_price",

      render: (_, record, index) =>
        customInputForm(
          index,
          "insurance_price",
          formValues?.insurance_price || editListData?.insurance_price,
          "insurance_withtax",
          formValues?.insurance_withtax || editListData?.insurance_withtax
        ),
    },
  ];

  const onFinish = (values) => {
    // const formData = values.facilities.map((facility, index) => ({
    //   ...facility,
    //   facility:
    //     facilities[index]?.id ||
    //     editListData?.facility_investigations[index]?.facility_id,
    //   id: editListData?.facility_investigations[index]?.id,
    // }));
    const formData = values?.facilities?.map((facility, index) => {
      const baseData = { ...facility, facility: facilities[index]?.id };
      if (
        editListData &&
        editListData?.facility_services &&
        editListData?.facility_services?.length > 0
      ) {
        return {
          ...baseData,
          facility: editListData?.facility_services[index]?.facility?.id,
          id: editListData?.facility_services[index]?.id,
        };
      }
      return baseData;
    });

    setFacilityPrice(formData);
    setVisible(false);
  };

  return (
    <>
      <Modal
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={"80%"}
        className={styles.test}
        title=" Facilitywise Availability and Pricing"
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setVisible(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
      >
        <Form
          ref={formRef}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            facilities: facilities?.map(() => ({
              is_available: true,
              cash_withtax: false,
              insurance_withtax: false,
            })),
          }}
        >
          <Table
            columns={columns}
            dataSource={
              editListData &&
              editListData?.facility_services &&
              editListData?.facility_services?.length > 0
                ? editListData?.facility_services
                : facilities
            }
            pagination={false}
          />
        </Form>
      </Modal>
    </>
  );
};

export default FacilityServiceModal;
{
  /* <div
style={{
  display: "flex",
  gap: "12px",
  paddingTop: "12px",
  justifyContent: "flex-end",
}}
>
<Form.Item>
  <Button
    type="primary"
    ghost
    className={btnStyles.mdBtn}
    onClick={() => setVisible(false)}
  >
    Cancel
  </Button>
</Form.Item>
<Form.Item>
  <Button
    htmlType="submit"
    type="primary"
    className={btnStyles.mdBtn}
  >
    Save
  </Button>
</Form.Item>
</div> */
}
