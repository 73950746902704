import React, { useContext, useEffect, useState } from "react";
import { treatmentGivenOptions } from "../../Options/RespiratoryTherapistOptions";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";

const RespiratoryTherapistTreatmentPlan = ({ form, context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState({});
  useEffect(() => {
    form.setFieldsValue(formData?.treatment_plan);
    const initialCheckboxes = treatmentGivenOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = formData?.treatment_plan?.[checkbox.value];

      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <CheckListFBYInput
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
        options={treatmentGivenOptions}
      />
    </>
  );
};

export default RespiratoryTherapistTreatmentPlan;
