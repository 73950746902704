import { Button, Form, message } from "antd";
import { useEffect, useState } from "react";

import useQuery from "../../../../hooks/useQuery";
import { updateOrderSheetApi } from "../../../../services/orderSheet.services";

function ReviewForm({ formItems, formType, formData }) {
  const [form] = Form.useForm();
  const query = useQuery();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (formData) form.setFieldsValue(formData?.review_data);
  }, [formData, form]);

  async function onFinish(values) {
    setLoading(true);
    try {
      const response = await updateOrderSheetApi(
        {
          review_data: values,
          care_type: formType,
          admission_id: query?.patient,
        },
        formData?.id
      );

      message.success(response.data.message);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form form={form} layout="vertical" name={formType} onFinish={onFinish}>
      {formItems}
      <Form.Item style={{ textAlign: "right" }}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ReviewForm;
