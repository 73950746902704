import React, { useEffect, useContext } from "react";
import { Divider } from "antd";
import ChiefComplaints from "./ChiefComplaints/ChiefComplaints";
import PresentIllness from "./PresentIllness/PresentIllness";
import TreatmentHistory from "./TreatmentHistory/TreatmentHistory";
import PersonalHistory from "./PersonalHistory/PersonalHistory";
import FunctionalCapacity from "./FunctionalCapacity/FunctionalCapacity";
import PastSurgicalHistory from "./PastSurgicalHistory/PastSurgicalHistory";
import Informant from "./Informant/Informant";
import "./HistoryForm.css";
import PastMedicalHistory from "./PastMedicalHistory/PastMedicalHistory";

import KnownAllergies from "./KnownAllergies/KnownAllergies";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";

const DoctorHistoryForm = ({ form, context, editId, patientData }) => {
  const { formData } = useContext(context);

  useEffect(() => {
    const SetHistoryData = async () => {
      if (editId) {
        form.setFieldsValue(formData?.history);
      } else {
        try {
          const response = await getDoctorInspectionAPI(
            patientData?.doctor_intial_assessment
          );
          form.setFieldsValue(response?.data?.data?.history);
        } catch (error) {
          console.log(`Error:`, error);
        }
      }
    };
    SetHistoryData();
  }, [editId]);

  return (
    <>
      <Informant context={context} />
      <Divider />
      <ChiefComplaints context={context} />
      <Divider />
      <PresentIllness context={context} />
      <Divider />
      <TreatmentHistory context={context} />
      <Divider />
      <PastMedicalHistory context={context} />
      <Divider />
      <PastSurgicalHistory context={context} />
      <Divider />
      <PersonalHistory />
      <Divider />
      <FunctionalCapacity />
      <Divider />
      <KnownAllergies context={context} />
      <Divider />
    </>
  );
};

export default DoctorHistoryForm;
