import { Form, Input, Radio, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { yesNoOptions } from "../../../../../../constants/defaultKeys";

const IndirectSwallowingTest = ({ handleGussStates, context, gussStates }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState({});

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    setStates({
      vigilance:
        formData?.scales?.indirect_swallowing?.vigilance === "no" ? 1 : 0,
      coughing:
        formData?.scales?.indirect_swallowing?.coughing === "no" ? 1 : 0,
      swallowing_saliva:
        formData?.scales?.indirect_swallowing?.swallowing_saliva === "no"
          ? 1
          : 0,
      swallowing_saliva_drooling:
        formData?.scales?.indirect_swallowing?.swallowing_saliva_drooling ===
        "yes"
          ? 1
          : 0,
      swallowing_saliva_voice_change:
        formData?.scales?.indirect_swallowing
          ?.swallowing_saliva_voice_change === "yes"
          ? 1
          : 0,
    });
  }, [formData]);

  useEffect(() => {
    const sum = Object.values(states).reduce((acc, cur) => acc + cur, 0);
    handleGussStates("indirectSwallowing", sum);
  }, [states]);

  const getScoreMessage = (sum) => {
    return sum < 5
      ? "Stop the test see GUSS-Evaluation"
      : "Continue Direct Swallowing Test (Semi Solid)";
  };

  const calculateScore = () => {
    console.log(Object.values(states).reduce((acc, cur) => acc + cur, 0));
    return Object.values(states).reduce((acc, cur) => acc + cur, 0);
  };

  return (
    <>
      <Typography>IndirectSwallowingTest: {calculateScore()}</Typography>
      <Typography>{getScoreMessage(calculateScore())}</Typography>
      <Form.Item
        name={["indirect_swallowing", "score"]}
        hidden
        initialValue={gussStates?.indirectSwallowing}
      >
        <Input value={gussStates.indirectSwallowing} hidden />
      </Form.Item>
      <div className="subsection-card">
        <Form.Item
          label="Vigilance"
          name={["indirect_swallowing", "vigilance"]}
        >
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange("vigilance", e.target.value === "no" ? 1 : 0)
            }
          />
        </Form.Item>
        <Form.Item label="Coughing" name={["indirect_swallowing", "coughing"]}>
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange("coughing", e.target.value === "no" ? 1 : 0)
            }
          />
        </Form.Item>
        <Form.Item
          label="Swallowing saliva (Swallowing successful)"
          name={["indirect_swallowing", "swallowing_saliva"]}
        >
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange("swallowing_saliva", e.target.value === "no" ? 1 : 0)
            }
          />
        </Form.Item>
        <Form.Item
          label="Swallowing saliva (Drooling)"
          name={["indirect_swallowing", "swallowing_saliva_drooling"]}
        >
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange(
                "swallowing_saliva_drooling",
                e.target.value === "yes" ? 1 : 0
              )
            }
          />
        </Form.Item>
        <Form.Item
          label="Swallowing saliva (Voice change after swallowing)"
          name={["indirect_swallowing", "swallowing_saliva_voice_change"]}
        >
          <Radio.Group
            options={yesNoOptions}
            onChange={(e) =>
              handleChange(
                "swallowing_saliva_voice_change",
                e.target.value === "yes" ? 1 : 0
              )
            }
          />
        </Form.Item>
      </div>
    </>
  );
};

export default IndirectSwallowingTest;
