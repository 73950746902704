import React, { useState } from "react";
import { Form, Button, message } from "antd";
import ScanImage from "../../../PatientsIP/MedicationsIp/ScanImage/ScanImage";
import ScanImageList from "../../../PatientsIP/MedicationsIp/ScanImage/ScanImageList";
import btnStyles from "../../../../styles/Custom/Button.module.css";

const ScanModule = ({ setCurrent }) => {
  const [uploadFile, setUploadFile] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [add, setAdd] = useState(false);
  return (
    <div>
      {add ? (
        <ScanImageList
          uploadFile={uploadFile}
          initial={true}
          setCurrent={setCurrent}
        />
      ) : (
        <Form layout="vertical">
          <ScanImage setUploadFile={setUploadFile} uploadFile={uploadFile} />
          <div
            className="flex-end"
            style={{
              gap: "24px",
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              // textAlign: "right",
            }}
          >
            <Button
              loading={btnLoading}
              className={btnStyles.lgBtn}
              type="primary"
              onClick={() => {
                uploadFile?.length >= 1
                  ? setAdd(true)
                  : message.error("Please upload images to proceed");
              }}
            >
              Next
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default ScanModule;
