import React, { useState } from "react";
import { Table, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import InvestigationForm from "./InvestigationForm";
import { formatToINR, user2Roles } from "../../../helpers/utility";
const { Paragraph, Text } = Typography;

const InvestigationsTable = ({ data, refreshPage, userDetails }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [editListData, setEditListData] = useState(null);
  const columns = [
    {
      title: "Investigation Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (value) =>
    //     value ? (
    //       <Paragraph
    //         ellipsis={{
    //           rows: 2,
    //           expandable: true,
    //           symbol: "more",
    //         }}
    //         style={{ width: 320 }}
    //       >
    //         {value}
    //       </Paragraph>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      title: "Department",
      dataIndex: ["department", "name"],
      key: "department",
      sorter: (a, b) => a?.department?.name?.localeCompare(b?.department?.name),
    },
    {
      title: "Cash Price (Including tax)",
      dataIndex: "cash_price",
      key: "cash_price",
      sorter: (a, b) => a?.cash_price - b?.cash_price,
      render: (value) => (value ? formatToINR(value) : "-"),
    },
    {
      title: "Insurance Price (Including tax)",
      dataIndex: "insurance_price",
      key: "insurance_price",
      sorter: (a, b) => a?.insurance_price - b?.insurance_price,
      render: (value) => (value ? formatToINR(value) : "-"),
    },
    ...(userDetails?.activeOrg?.default_role === "Facility_Admin"
      ? []
      : [
          {
            title: "Action",
            render: (_, record) => (
              <EditOutlined
                onClick={() => {
                  setIsVisible(true);
                  setEditListData(record);
                }}
              />
            ),
          },
        ]),
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        scroll={{ y: "calc(100vh - 290px)" }}
      />
      {isVisible && (
        <InvestigationForm
          open={isVisible}
          setOpen={setIsVisible}
          refreshPage={refreshPage}
          editListData={editListData}
        />
      )}
    </>
  );
};

export default InvestigationsTable;
