import { Form, Input, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { yesNoOptions } from "../../../Options/commonOptions";
import { DailyReviewContext } from "../../../Doctor/DailyReview/DailyReviewFormProvider";

const AmbulationDetails = () => {
  const { formData } = useContext(DailyReviewContext);
  const [needAmbulation, setNeedAmbulation] = useState(
    formData?.systemic_examination?.ambulation === "yes"
  );
  console.log("FormData", formData);
  const handleChange = (e) => {
    console.log("e", e);
    setNeedAmbulation(e.target.value === "yes");
  };
  useEffect(() => {
    setNeedAmbulation(formData?.systemic_examination?.ambulation === "yes");
  }, [formData]);
  return (
    <>
      <Form.Item label="Ambulation" name="ambulation">
        <Radio.Group options={yesNoOptions} onChange={handleChange} />
      </Form.Item>
      {needAmbulation && (
        <Form.Item name="ambulation_desc">
          <Input placeholder="Enter Ambulation Data" />
        </Form.Item>
      )}
    </>
  );
};

export default AmbulationDetails;
