import React from "react";
import { Form, Radio, Typography } from "antd";
import { jvpOptions } from "../../../../Options/systemicExamOptions";
const { Text } = Typography;
const JVP = () => {
  return (
    <>
      <Text>JVP</Text>
      <Form.Item name="jvp">
        <Radio.Group options={jvpOptions} />
      </Form.Item>
    </>
  );
};

export default JVP;
