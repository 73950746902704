import React from "react";
import { Input } from "antd";
import { debounce } from "../../../helpers/utility";
import AllPatientTable from "./AllPatientTable";

const AllPatient = ({ data, refresh, setPage, setSearchQuery, page }) => {
  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };
  return (
    <>
      <Input.Search
        style={{ width: "30%", marginBottom: "10px" }}
        size="large"
        placeholder="Search Patient (Name,Contact)"
        onChange={debounce(onContactSearch, 300)}
      ></Input.Search>
      <AllPatientTable
        data={data}
        refresh={refresh}
        setPage={setPage}
        page={page}
      />
    </>
  );
};

export default AllPatient;
