import React, { useContext } from "react";
import { RespiratoryInitialContext } from "./RespiratoryTherapistInitialAssessmentProvider";
import { Col, Row } from "antd";
import DrClinicalImpressionSummary from "../../InspectionSummaries/ClinicalImpressionSummary/DrClinicalImpressionSummary";
import RespiratoryTreatmentPlanSummary from "../../InspectionSummaries/TreatmentPlan/RespiratoryTreatmentPlanSummary";
import RespiratoryChartAssessmentSummary from "../../InspectionSummaries/ChartAssessment/RespiratoryChartAssessmentSummary";
import PatientAssessmentSummary from "../../InspectionSummaries/PatientAssessment/PatientAssessmentSummary";
import RespiratorySpecialInstructionSummary from "../../InspectionSummaries/Special/RespiratorySpecialInstructionSummary";

const RespiratoryTherapistInspectionSummary = () => {
  const { formData } = useContext(RespiratoryInitialContext);
  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.clinical_diagnosis && (
          <DrClinicalImpressionSummary
            context={RespiratoryInitialContext}
            formData={formData}
          />
        )}
        {formData?.treatment_plan && (
          <RespiratoryTreatmentPlanSummary
            formData={formData}
            context={RespiratoryInitialContext}
          />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.chart_assessment && (
          <RespiratoryChartAssessmentSummary formData={formData} />
        )}

        {formData?.patient_assessment && (
          <PatientAssessmentSummary formData={formData} />
        )}
        {formData?.special_instruction && (
          <RespiratorySpecialInstructionSummary formData={formData} />
        )}
      </Col>
    </Row>
  );
};

export default RespiratoryTherapistInspectionSummary;
