import React, { useContext } from "react";
import { Menu, Row, Col, Space, Tag, Typography, theme, Image } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { getuserRoleTabs } from "./getuserRoleTabs";
import bedImg from "../../assets/images/patientIcons/bed.svg";
import { isDesktop } from "react-device-detect";
import { UserContext } from "../../context/UserContext";
import { useParams } from "react-router-dom";

const { useToken } = theme;
const PatientMenu = ({
  current,
  onClick,
  userRole,
  params,
  navigate,
  patientData,
}) => {
  const query = useParams();
  const { userDetails } = useContext(UserContext);
  const details = patientData?.admission;
  const { token } = useToken();
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    .replaceAll("_", "-");

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <Space>
          <LeftOutlined
            onClick={() =>
              role === "receptionist"
                ? navigate(
                    `/${role}/${
                      query?.tab === "ip" ? "in-patients" : query?.tab
                    }`
                  )
                : navigate(`/${role}/home`)
            }
            style={{ fontSize: "18px" }}
          />
          <Typography
            style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
          >
            {`${details?.patient?.first_name} ${details?.patient?.last_name}`}{" "}
          </Typography>
          <Tag color="orange">
            <Image src={bedImg} preview={false} /> {details?.bed?.bed_no}
          </Tag>
        </Space>
        <br />
        <Space style={{ marginTop: "10px" }}>
          <Tag color="blue">
            {details?.patient?.gender} {details?.patient?.age}
          </Tag>
          <Tag>IP ID: {details?.id}</Tag>
        </Space>
      </Col>
      <Col xs={24} sm={24} md={24} lg={18} xl={18}>
        <Menu
          className="patientMenu"
          style={{ justifyContent: isDesktop && "flex-end" }}
          onClick={onClick}
          selectedKeys={[params?.type || current]}
          mode="horizontal"
          items={getuserRoleTabs(userRole, params?.type, token)}
        />
      </Col>
    </Row>
  );
};

export default PatientMenu;
