import React from "react";
import { Form, Radio, Typography } from "antd";
import {
  alcoholOptions,
  educationalOptions,
  maritalStatusOptions,
  smokingOptions,
} from "../../../Options/historyOptions";

const { Text } = Typography;

const PersonalHistory = () => {
  return (
    <>
      <Text>Personal History</Text>
      <div className="subsection-card">
        <Form.Item label="Marital Status" name="marital_status">
          <Radio.Group options={maritalStatusOptions} />
        </Form.Item>
        <Form.Item label="Education" name="education">
          <Radio.Group options={educationalOptions} />
        </Form.Item>
        <Form.Item label="Smoking" name="smoking">
          <Radio.Group options={smokingOptions} />
        </Form.Item>
        <Form.Item label="Alcohol" name="alcohol">
          <Radio.Group options={alcoholOptions} />
        </Form.Item>
      </div>
    </>
  );
};

export default PersonalHistory;
