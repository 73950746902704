export const deglutitionOptions = [
  { label: "Swallowing not possible", value: "swallowing_not_possible" },
  { label: "Swallowing delayed", value: "swallowing_delayed" },
  { label: "Swallowing successful", value: "swallowing_successful" },
];

export const eatingAssessmentOptions = [
  {
    label: "My swallowing problem has caused me to lose weight",
    value: "weight_loss",
  },
  {
    label:
      "My swallowing problem interferes with my ability to go out for meals",
    value: "interferes_with_meals",
  },
  { label: "Swallowing liquids takes extra effort", value: "liquids_effort" },
  { label: "Swallowing solids takes extra effort", value: "solids_effort" },
  { label: "Swallowing pills takes extra effort", value: "pills_effort" },
  { label: "Swallowing is painful", value: "painful" },
  {
    label: "The pleasure of eating is affected by my swallowing",
    value: "pleasure_affected",
  },
  { label: "When I swallow food sticks in my throat", value: "food_sticks" },
  { label: "I cough when I eat", value: "cough" },
  { label: "Swallowing is stressful", value: "stressful" },
];

export const eatingAssessmentRangeOptions = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

export const oralIntakeOptions = [
  { label: "Tube Dependent", value: "tube_dependent" },
  { label: "Total Oral Intake", value: "total_oral_intake" },
];

export const totalOralIntakeOptions = [
  {
    label: "Total oral intake of a single consistency",
    value: "single_consistency",
  },
  {
    label:
      "Total oral intake of multiple consistencies requiring special preparation",
    value: "multiple_consistencies_special_preparation",
  },
  {
    label:
      "Total oral intake with no special preparation, but must avoid specific foods or liquid items",
    value: "no_special_preparation_avoid_specific",
  },
  { label: "Total oral intake with no restrictions", value: "no_restrictions" },
];

export const tubeDependentOptions = [
  { label: "No oral intake", value: "no_oral_intake" },
  {
    label: "Tube dependent with minimal/inconsistent oral intake",
    value: "tube_dependent_minimal_intake",
  },
  {
    label: "Tube supplements with consistent oral intake",
    value: "tube_supplements_consistent_intake",
  },
];

export const aphasiaSeverityOptions = [
  {
    label:
      "Speech, writing and/or auditory comprehension are not functional. Any attempts to speak or use of fluent utterances are not understandable to the listener OR the individual may not attempt to speak at all.",
    value: "non_functional",
  },
  {
    label:
      "The individual may occasionally produce words or phrases that are meaningful in context, but communication is fragmentary and not possible without significant help from the listener (e.g., guessing, questioning). Conversation is very 'one sided' with the listener bearing almost all of the burden. An extremely limited amount or range of information is exchanged; misunderstandings or failed communications are very frequent.",
    value: "fragmentary_communication",
  },
  {
    label:
      "Basic conversation about most familiar or everyday topics is possible but significant breakdown occurs with more abstract or difficult conversations. The burden of conversation is not fully shared by the individual who experiences several instances of word finding failures, paraphasias, and/or misunderstandings by the listener requiring the need for repair.",
    value: "basic_conversation",
  },
  {
    label:
      "Despite some observable issues related to speech fluency or comprehension, there is no significant limitation. The individual may hesitate to access words or self-correct during the conversation, but is able to share a significant portion of the burden of communication with the listener.",
    value: "some_issues",
  },
  {
    label:
      "Although the individual feels that he/she has a problem with language, this is barely apparent to the listener who may not detect any problem with speaking or understanding.",
    value: "barely_apparent",
  },
];

export const dossOptions = [
  {
    label: "Full per-oral nutrition (P.O): Normal diet",
    value: "full_po_normal_diet",
  },
  {
    label: "Full P.O: Modified diet and/or independence",
    value: "full_po_modified_diet",
  },
  { label: "Non oral nutrition necessary", value: "non_oral_nutrition" },
];

export const normalDietOptions = [
  {
    label: "Level 7: Normal in all situations",
    value: "level_7",
  },
  {
    label: "Level 6: Within functional limits/modified independence",
    value: "level_6",
  },
];

export const levelSevenOptions = [
  { label: "Normal diet", value: "normal_diet" },
  {
    label: "No strategies or extra time needed",
    value: "no_strategies_extra_time",
  },
];

export const levelSixOptions = [
  {
    label: "Normal diet, functional swallow",
    value: "normal_diet_functional_swallow",
  },
  {
    label:
      "The patient may have a mild oral or pharyngeal delay, retention or trace epiglottal undercoating but independently and spontaneously compensates/clears",
    value: "mild_delay_compensates",
  },
  { label: "May need extra time for meal", value: "needs_extra_time_for_meal" },
  {
    label: "Have no aspiration or penetration across consistencies",
    value: "no_aspiration_penetration",
  },
];

export const modifiedDietOptions = [
  {
    label:
      "Level 5: Mild dysphagia: Distant supervision, may need one diet consistency restricted",
    value: "level_5",
  },
  {
    label:
      "Level 4. Mild-moderate dysphagia: intermittent supervision/cueing, one or two consistencies restricted",
    value: "level_4",
  },
  {
    label:
      "Level 3: Moderate dysphagia: Total assist, supervision, or strategies, two or more diet consistencies restricted",
    value: "level_3",
  },
];

export const levelFiveOptions = [
  {
    label:
      "Aspiration of thin liquids only but with strong reflexive cough to clear completely",
    value: "aspiration_thin_liquids_strong_cough",
  },
  {
    label:
      "Airway penetration midway to cords with one or more consistency or to cords with one consistency but clears spontaneously",
    value: "airway_penetration_clears_spontaneously",
  },
  {
    label: "Retention in pharynx that is cleared spontaneously",
    value: "retention_pharynx_cleared_spontaneously",
  },
  {
    label:
      "Mild oral dysphagia with reduced mastication and/or oral retention that is cleared spontaneously",
    value: "mild_oral_dysphagia_cleared_spontaneously",
  },
];

export const levelFourOptions = [
  {
    label: "Retention in pharynx cleared with cue",
    value: "retention_pharynx_cleared_with_cue",
  },
  {
    label: "Retention in the oral cavity that is cleared with cue",
    value: "retention_oral_cavity_cleared_with_cue",
  },
  {
    label: "Aspiration with one consistency, with weak or no reflexive cough",
    value: "aspiration_one_consistency_weak_cough",
  },
  {
    label:
      "Airway penetration to the level of the vocal cords with cough with two consistencies",
    value: "airway_penetration_vocal_cords_cough_two_consistencies",
  },
  {
    label:
      "Airway penetration to the level of the vocal cords without cough with one consistency",
    value: "airway_penetration_vocal_cords_no_cough_one_consistency",
  },
];

export const levelThreeOptions = [
  {
    label: "Moderate retention in pharynx, cleared with cue",
    value: "moderate_retention_pharynx_cleared_with_cue",
  },
  {
    label: "Moderate retention in oral cavity, cleared with cue",
    value: "moderate_retention_oral_cavity_cleared_with_cue",
  },
  {
    label:
      "Airway penetration to the level of the vocal cords without cough with two or more consistencies",
    value: "airway_penetration_vocal_cords_no_cough_two_or_more_consistencies",
  },
  {
    label: "Aspiration with two consistencies, with weak or no reflexive cough",
    value: "aspiration_two_consistencies_weak_cough",
  },
  {
    label:
      "Aspiration with one consistency, no cough and airway penetration to cords, with one, no cough",
    value: "aspiration_one_consistency_no_cough_airway_penetration",
  },
];

export const nonOralOptions = [
  {
    label:
      "Level 2: Moderately severe dysphagia: Maximum assistance or use of strategies with partial per-oral (P.O.) only (tolerates at least one consistency safely with total use of strategies)",
    value: "level_2",
  },
  {
    label:
      "Level 1: Severe dysphagia: NPO (nothing by mouth): Unable to tolerate any P.O. safely",
    value: "level_1",
  },
];

export const levelTwoOptions = [
  {
    label:
      "Severe retention in pharynx, unable to clear or needs multiple cues",
    value: "severe_retention_pharynx_unable_to_clear",
  },
  {
    label:
      "Severe oral stage bolus loss or retention, unable to clear or needs multiple cues",
    value: "severe_oral_stage_bolus_loss_unable_to_clear",
  },
  {
    label:
      "Aspiration with two or more consistencies, no reflexive cough, weak volitional cough",
    value: "aspiration_two_or_more_consistencies_weak_cough",
  },
  {
    label:
      "Aspiration with one or more consistencies, no cough, and airway penetration to cords with one or more consistencies, no cough",
    value: "aspiration_one_or_more_consistencies_no_cough_airway_penetration",
  },
];

export const levelOneOptions = [
  {
    label: "Severe retention in pharynx, unable to clear",
    value: "severe_retention_pharynx_unable_to_clear",
  },
  {
    label: "Severe oral stage bolus loss or retention, unable to clear",
    value: "severe_oral_stage_bolus_loss_unable_to_clear",
  },
  {
    label:
      "Silent aspiration with two or more consistencies, non-functional volitional cough or unable to achieve swallow",
    value: "silent_aspiration_two_or_more_consistencies_non_functional_cough",
  },
  { label: "Unable to achieve swallow", value: "unable_to_achieve_swallow" },
];

export const speechIntelligibilityOptions = [
  {
    label: "No detectable speech disorder",
    value: "no_detectable_speech_disorder",
  },
  {
    label: "Obvious speech disorder with intelligible speech",
    value: "obvious_speech_disorder",
  },
  {
    label: "Reduction in speech intelligibility",
    value: "reduction_in_speech",
  },
  {
    label: "Natural speech supplemented by augmentative techniques",
    value: "natural_speech_augmentative_techniques",
  },
  { label: "No functional speech", value: "no_functional_speech" },
];

export const noSpeechDisorderOptions = [
  {
    label:
      "Individuals who place high demand on communication will report changes in speech performance",
    value: "high_demand_report_changes",
  },
  {
    label: "Complaints include sense of increased effort to speak",
    value: "increased_effort_to_speak",
  },
];

export const obviousSpeechDisorderOptions = [
  {
    label:
      "Changes in voice: reduced loudness, reduced pitch variability and unsteady voice",
    value: "changes_in_voice_reduced_loudness",
  },
  {
    label:
      "Complaints include difficulty speaking in specific communication contexts, such as a noisy environment",
    value: "difficulty_speaking_noisy_environment",
  },
];

export const reductionSpeechOptions = [
  { label: "Frequently asked to repeat", value: "frequently_asked_to_repeat" },
  {
    label: "Imprecise oral articulation",
    value: "imprecise_oral_articulation",
  },
  {
    label: "Limited range of oral movements",
    value: "limited_range_oral_movements",
  },
  {
    label: "Rushes of speech with abnormal placement of pauses",
    value: "rushes_of_speech_abnormal_pauses",
  },
];

export const naturalSpeechOptions = [
  {
    label: "Natural speech alone is no longer functional",
    value: "natural_speech_no_longer_functional",
  },
  {
    label: "Frequent communication breakdowns",
    value: "frequent_communication_breakdowns",
  },
  {
    label: "Severe dysarthria with minimal speech intelligibility",
    value: "severe_dysarthria_minimal_intelligibility",
  },
];

export const noFunctionalSpeechOptions = [
  { label: "Unable to speak at all", value: "unable_to_speak_at_all" },
  {
    label: "Requires augmentative and assistive communication devices",
    value: "requires_augmentative_assistive_devices",
  },
];

export const speechOptions = [
  {
    label: "Patient is alert (can follow command)",
    value: "alert_follow_command",
  },
  {
    label: "Dysarthia (speech slurred or garbled)",
    value: "dysarthia",
  },
  {
    label: "Able to clench teeth",
    value: "clench_teeth",
  },
  {
    label: "Able to close lips",
    value: "close_lips",
  },
  {
    label: "Face is symmetrical with movement",
    value: "face_symmetrical_movement",
  },
  {
    label: "Tongue is midline",
    value: "tongue_midline",
  },
  {
    label: "Uvula is midline",
    value: "uvula_midline",
  },
  {
    label: "S/Z: Phonation Duration",
    value: "sz_phonation_duration",
  },
  {
    label: "Diadochokinetic rate: |p| |t| |k|",
    value: "diadochokinetic_rate",
  },
  {
    label: "Articulation",
    value: "articulation",
  },
  {
    label: "Voice GRBAS scale",
    value: "voice_grbas_scale",
  },
  {
    label: "Aphasia (trouble speaking or understanding words)",
    value: "aphasia",
  },
  {
    label: "Gag reflex is present",
    value: "gag_reflex",
  },
  {
    label: "Has voluntary cough (have patient cough 2 times)",
    value: "voluntary_cough",
  },
  {
    label: "Swallow reflex is present",
    value: "swallow_reflex",
  },
  {
    label: "Able to swallow own secretions",
    value: "swallow_own_secretions",
  },
];

export const masaOptions = [
  {
    label: "General Patient Examination",
    value: "general_patient_examination",
  },
  { label: "Oral Preparation", value: "oral_preparation_main" },
  { label: "Oral Phase", value: "oral_phase" },
  { label: "Pharyngeal Phase", value: "pharyngeal_phase" },
];

export const generalPatientOptions = [
  { label: "Consciousness", value: "consciousness" },
  { label: "Cooperation", value: "cooperation" },
  { label: "Language Function", value: "language_function" },
  { label: "Verbal and Oral Praxis", value: "verbal_oral_praxis" },
  { label: "Articulation", value: "articulation" },
];

export const oralPreparationOptions = [
  { label: "Control of Saliva (e.g., drooling)", value: "control_of_saliva" },
  { label: "Oral Hygiene (e.g., tongue coating)", value: "oral_hygiene" },
  { label: "Lip Seal", value: "lip_seal" },
  {
    label: "Tongue Movement (degree and co-ordination)",
    value: "tongue_movement",
  },
  { label: "Tongue Strength", value: "tongue_strength" },
  { label: "Oral Preparation", value: "oral_preparation" },
  {
    label:
      "Respiration (airway patency, supported or independent ventilation, respiratory rate and rhythm)",
    value: "respiration",
  },
  {
    label: "Respiratory Disease (chest infection, airway obstruction)",
    value: "respiratory_disease",
  },
];

export const oralPhaseOptions = [
  { label: "Gag Reflex", value: "gag_reflex" },
  {
    label: "Palatal Movement and Competence",
    value: "palatal_movement_and_competence",
  },
  {
    label:
      "Oral Transit Time (measured from entry point at lips to swallowing reflex trigger)",
    value: "oral_transit_time",
  },
  {
    label:
      "Bolus Clearance (presence or absence of residue in the mouth after swallowing is completed)",
    value: "bolus_clearance",
  },
];

export const pharyngealOptions = [
  { label: "Pharyngeal Control", value: "pharyngeal_control" },
  {
    label: "Pharyngeal Pooling and Laryngeal Elevation",
    value: "pharyngeal_pooling_and_laryngeal_elevation",
  },
  { label: "Cough: Reflex", value: "cough_reflex" },
  { label: "Cough: Voluntary", value: "cough_voluntary" },
  { label: "Voice Quality", value: "voice_quality" },
  { label: "Tracheostomy", value: "tracheostomy" },
];
