import React, { useContext, useEffect, useMemo, useState } from "react";
import { Radio, Form } from "antd";

import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import {
  cranialNerveSeverityOptions,
  cranialNervesAbnormalOptions,
  cranialNervesOptions,
} from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const CranialNerves = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [showAbnormal, setShowAbnormal] = useState(
    initialValues?.systemic_examination?.cranial_nerves === "abnormal"
      ? true
      : false
  );
  const [checkboxes, setCheckboxes] = useState([]);

  const handleCranialChange = (e) => {
    // console.log(e.target.value, "shashak");
    setShowAbnormal(e.target.value === "abnormal");
  };

  useEffect(() => {
    setShowAbnormal(
      initialValues?.systemic_examination?.cranial_nerves === "abnormal"
        ? true
        : false
    );
    const initialCheckboxes = cranialNervesAbnormalOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Form.Item label="Cranial Nerves" name="cranial_nerves">
        <Radio.Group
          options={cranialNervesOptions}
          onChange={handleCranialChange}
        />
      </Form.Item>
      {showAbnormal && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={cranialNervesAbnormalOptions}
            radioOptions={cranialNerveSeverityOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default CranialNerves;
