import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Col,
  Row,
  Modal,
  InputNumber,
  message,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { vitalValidations } from "../../../../helpers/medicineKeys";
import {
  addVitalsMonitoringApi,
  updateNurseVitalsMonitoringApi,
} from "../../../../services/nurse.services";
import useQuery from "../../../../hooks/useQuery";

const VitalsModal = ({ open, setOpen, editData, refresh }) => {
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue(editData);
    }
  }, []);
  const onFinish = (values) => {
    if (editData?.id) {
      updateNurseVitalsMonitoringApi(editData?.id, values)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    } else {
      addVitalsMonitoringApi(params?.patient, values)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
        });
    }
  };

  const formItems = [
    { name: "Temperature", value: "temperature" },
    { name: "Pulse Rate", value: "pulse" },
    { name: "Respiratory Rate", value: "respiration" },
    { name: "SPO2", value: "oxygen_saturation" },
    { name: "Systolic Pressure", value: "systolic_pressure" },
    { name: "Diastolic Pressure", value: "diastolic_pressure" },
  ];
  return (
    <div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editData?.id ? "Edit Vitals" : "Add Vitals"}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Col>
          </Row>
        }
        closeIcon={null}
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
      >
        <Form layout="vertical" onFinish={onFinish} form={form} ref={formRef}>
          <Row>
            {formItems?.map((item) => (
              <Col xs={24} sm={24} md={12} lg={8}>
                <Form.Item
                  label={item?.name}
                  name={item?.value}
                  rules={[
                    {
                      type: "number",
                      min: vitalValidations[item?.value]?.minimum,
                      message: `Out of Range (${
                        vitalValidations[item?.value]?.minimum
                      }-${vitalValidations[item?.value]?.maximum})`,
                    },
                    {
                      type: "number",
                      max: vitalValidations[item?.value]?.maximum,
                      message: `Out of Range (${
                        vitalValidations[item?.value]?.minimum
                      }-${vitalValidations[item?.value]?.maximum})`,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default VitalsModal;
