import React, { createContext, useState } from "react";

export const DailyReviewContext = createContext({});
const DailyReviewFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <DailyReviewContext.Provider value={{ formData, setFormData }}>
      {children}
    </DailyReviewContext.Provider>
  );
};

export default DailyReviewFormProvider;
