import { Descriptions, Tag } from "antd";
import React from "react";
import {
  getFractureSummary,
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithCheckGroup,
  getLabelTagsWithDescription,
  getLabelTagsWithRadioOptions,
  getLabelTagsWithSelectItems,
  getLabelTagYesNO,
} from "../constructTextFromValues";
import {
  abnormalBreathZoneOptions,
  auscultationOptions,
  breathSoundsOptions,
  coordinationOptions,
  cranialNervesAbnormalOptions,
  cranialNerveSeverityOptions,
  cranialNervesOptions,
  fractureAreasOptions,
  gaitOptions,
  heartSoundsOptions,
  jointPainLocationOptions,
  jointPainSeverityOptions,
  jvpOptions,
  leftRightOptions,
  limbDeformityOptions,
  mentalStatusOptions,
  motorAbnormalRadioItems,
  motorSystemAbnormalOptions,
  motorSystemOptions,
  nursePressureSoreGradeOptions,
  periPulsesVolumeOptions,
  pressureSoreAreaOptions,
  reflexes,
  sensoryExtremitiesOptions,
  sensorySystemAbnormalOptions,
  sensorySystemOptions,
  skullOptions,
  softTissueInjuryAreaOptions,
  spineOptions,
} from "../../Options/systemicExamOptions";
import {
  noneYesOptions,
  normalAbsentOptions,
  notAssessableAndAssessableOptions,
  positiveNegativeOptions,
  regularIrregularOptions,
} from "../../Options/commonOptions";
import {
  bergScaleOptions,
  postureOptions,
  tcmOptions,
} from "../../Options/physioOptions";

const PhysioSystemicExaminationSummary = ({ formData }) => {
  const getReflexesSummary = () => {
    const reflexOptions = reflexes
      .map((reflex) => {
        const leftValue =
          formData?.systemic_examination?.[reflex.value + "_left"];
        const rightValue =
          formData?.systemic_examination?.[reflex.value + "_right"];

        if (leftValue || rightValue) {
          let labelCreated = `${reflex.label} Left: ${
            leftValue || "N/A"
          }, Right: ${rightValue || "N/A"}`;
          return (
            <Tag
              bordered={false}
              color="purple"
              key={reflex.value}
              className="break-line-tag"
            >
              {labelCreated}
            </Tag>
          );
        }
        return null;
      })
      .filter(Boolean); // Remove any null values

    return reflexOptions.length === 0 ? null : reflexOptions;
  };

  const constructskinSummaryItems = () => {
    const skinSummaryItems = [];
    if (formData?.systemic_examination?.pressure_sore_details?.pressure_sores) {
      skinSummaryItems.push({
        key: "1",
        label: "Pressure Sores",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.systemic_examination?.pressure_sore_details
                ?.pressure_sores
            )}
            {getLabelTagsWithRadioOptions(
              pressureSoreAreaOptions,
              nursePressureSoreGradeOptions,
              formData?.systemic_examination?.pressure_sore_details
            )}
          </>
        ),
      });
    }

    if (
      getLabelTagsWithDescription(
        softTissueInjuryAreaOptions,
        formData?.systemic_examination?.soft_tissue_injury
      )
    ) {
      skinSummaryItems.push({
        key: "2",
        label: "Soft Tissue Injury",
        children: getLabelTagsWithDescription(
          softTissueInjuryAreaOptions,
          formData?.systemic_examination?.soft_tissue_injury
        ),
      });
    }
    if (formData?.systemic_examination?.scar) {
      skinSummaryItems.push({
        key: "3",
        label: "Scar",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.scar)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.scar_description
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.surgical_incision) {
      skinSummaryItems.push({
        key: "4",
        label: "Surgical Incision",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.systemic_examination?.surgical_incision
            )}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.surgical_incision_description
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.swellings) {
      skinSummaryItems.push({
        key: "5",
        label: "Swellings",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.swellings)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.swelling_description
            )}
          </>
        ),
      });
    }
    return skinSummaryItems;
  };
  const constructCardioVascularSystemSummaryItems = () => {
    const cardioVascularSystemSummaryItems = [];
    if (
      formData?.systemic_examination?.peripheral_regular ||
      formData?.systemic_examination?.peripheral_volume ||
      formData?.systemic_examination?.peripheral_desc
    ) {
      cardioVascularSystemSummaryItems.push({
        key: "1",
        label: "Peripheral Pulses",
        children: (
          <>
            {getLabelTag(
              regularIrregularOptions,
              formData?.systemic_examination?.peripheral_regular
            )}
            {getLabelTag(
              periPulsesVolumeOptions,
              formData?.systemic_examination?.peripheral_volume
            )}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.peripheral_desc
            )}
          </>
        ),
      });
    }
    if ((jvpOptions, formData?.systemic_examination?.jvp)) {
      cardioVascularSystemSummaryItems.push({
        key: "2",
        label: "JVP",
        children: getLabelTag(jvpOptions, formData?.systemic_examination?.jvp),
      });
    }
    if (
      getLabelTagsWithDescription(
        heartSoundsOptions,
        formData?.systemic_examination
      )
    ) {
      cardioVascularSystemSummaryItems.push({
        key: "3",
        label: "Heart Sounds",
        children: getLabelTagsWithDescription(
          heartSoundsOptions,
          formData?.systemic_examination
        ),
      });
    }
    return cardioVascularSystemSummaryItems;
  };

  const constructRespiratorySystemSummaryItems = () => {
    const respiratorySystemSummaryItems = [];

    if (formData?.systemic_examination?.type_of_breathing) {
      respiratorySystemSummaryItems.push({
        key: "1",
        label: "Type of Breathing",
        children: (
          <>
            <Tag bordered={false} color="purple" key={"type_of_breathing"}>
              {formData?.systemic_examination?.type_of_breathing}
            </Tag>
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.auscultation) {
      respiratorySystemSummaryItems.push({
        key: "2",
        label: "Auscultation",
        children: (
          <>
            {" "}
            {getLabelTag(
              auscultationOptions,
              formData?.systemic_examination?.auscultation
            )}
            {getLabelTagsWithSelectItems(
              abnormalBreathZoneOptions,
              breathSoundsOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    return respiratorySystemSummaryItems;
  };
  const constructMusculoSkeletalSummaryItems = () => {
    const musculoSkeletalSummaryItems = [];
    if (formData?.systemic_examination?.limb_deformity) {
      musculoSkeletalSummaryItems.push({
        key: "1",
        label: "Limb Deformity",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.limb_deformity)}
            {getLabelTagsWithDescription(
              limbDeformityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.fractures) {
      musculoSkeletalSummaryItems.push({
        key: "2",
        label: "Fractures",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.fractures)}
            {getFractureSummary(
              fractureAreasOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.skull) {
      musculoSkeletalSummaryItems.push({
        key: "3",
        label: "Skull",
        children: (
          <>
            {getLabelTag(skullOptions, formData?.systemic_examination?.skull)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.skull_description
            )}
          </>
        ),
      });
    }
    if (
      getLabelTagsWithDescription(spineOptions, formData?.systemic_examination)
    ) {
      musculoSkeletalSummaryItems.push({
        key: "4",
        label: "Spine",
        children: getLabelTagsWithDescription(
          spineOptions,
          formData?.systemic_examination
        ),
      });
    }
    if (formData?.systemic_examination?.joint_pain) {
      musculoSkeletalSummaryItems.push({
        key: "5",
        label: "Joint Pain",
        children: (
          <>
            {getLabelTag(
              noneYesOptions,
              formData?.systemic_examination?.joint_pain
            )}
            {getLabelTagsWithCheckGroup(
              jointPainLocationOptions,
              jointPainSeverityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.contractures) {
      musculoSkeletalSummaryItems.push({
        key: "6",
        label: "Contractures",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.contractures)}
            {/* Elbow: */}
            {getLabelTagInput(
              "Elbow",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_elbow
              )
            )}
            {/* Wrist: */}
            {getLabelTagInput(
              "Wrist",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_wrist
              )
            )}
            {/* Shoulder: */}
            {getLabelTagInput(
              "Shoulder",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_shoulder
              )
            )}
            {/* Hip: */}
            {getLabelTagInput(
              "Hip",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_hip
              )
            )}
            {/* Knee: */}
            {getLabelTagInput(
              "Knee",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_knee
              )
            )}
            {/* Ankle: */}
            {getLabelTagInput(
              "Ankle",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_ankle
              )
            )}
          </>
        ),
      });
    }
    return musculoSkeletalSummaryItems;
  };

  const constructCentralNervousSystemSummaryItems = () => {
    const centralNervousSystemSummaryItems = [];

    if (formData?.systemic_examination?.mental_status) {
      centralNervousSystemSummaryItems.push({
        key: "1",
        label: "Mental Status",
        children: (
          <>
            {getLabelTag(
              mentalStatusOptions,
              formData?.systemic_examination?.mental_status
            )}
            {getLabelTagInput(
              "Details",
              formData?.systemic_examination?.mental_status_details
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.gait) {
      centralNervousSystemSummaryItems.push({
        key: "2",
        label: "Gait",
        children: (
          <>
            {getLabelTag(gaitOptions, formData?.systemic_examination?.gait)}
            <Tag bordered={false} color="purple" key={"gait_details"}>
              {formData?.systemic_examination?.gait_details}
            </Tag>
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.posture) {
      centralNervousSystemSummaryItems.push({
        key: "3",
        label: "Posture",
        children: (
          <>
            {getLabelTag(
              postureOptions,
              formData?.systemic_examination?.posture
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.berg_scale?.status) {
      centralNervousSystemSummaryItems.push({
        key: "4",
        label: "Berg Balance Scale",
        children: (
          <>
            {getLabelTag(
              notAssessableAndAssessableOptions,
              formData?.systemic_examination?.berg_scale?.status
            )}
            {getLabelScalesTags(
              bergScaleOptions,
              formData?.systemic_examination?.berg_scale
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.cranial_nerves) {
      centralNervousSystemSummaryItems.push({
        key: "5",
        label: "Cranial Nerves",
        children: (
          <>
            {getLabelTag(
              cranialNervesOptions,
              formData?.systemic_examination?.cranial_nerves
            )}
            {getLabelTagsWithRadioOptions(
              cranialNervesAbnormalOptions,
              cranialNerveSeverityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.sensory_system) {
      centralNervousSystemSummaryItems.push({
        key: "6",
        label: "Sensory System",
        children: (
          <>
            {getLabelTag(
              sensorySystemOptions,
              formData?.systemic_examination?.sensory_system
            )}
            {/* Sensation: */}
            {getLabelTagInput(
              "Sensation",

              getLabelTagsWithRadioOptions(
                sensorySystemAbnormalOptions,
                sensoryExtremitiesOptions,
                formData?.systemic_examination
              )
            )}

            {/* Tactile Localization: */}
            {/* 2 Point Discrimination: */}
            {getLabelTagInput(
              "Tactile Localization\n2 point Discrimination",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.two_point_discrimination
              )
            )}
            {/* Stereognosis: */}
            {getLabelTagInput(
              "Stereognosis",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.stereognosis
              )
            )}
            {/* Graphesthesia: */}
            {getLabelTagInput(
              "Graphesthesia",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.graphesthesia
              )
            )}
            {/* Romberg's Sign: */}
            {getLabelTagInput(
              "Romberg's Sign",
              getLabelTag(
                positiveNegativeOptions,
                formData?.systemic_examination?.rombergs_sign
              )
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.motor_system) {
      centralNervousSystemSummaryItems.push({
        key: "7",
        label: "Motor System",
        children: (
          <>
            {getLabelTag(
              motorSystemOptions,
              formData?.systemic_examination?.motor_system
            )}
            {getLabelTagsWithRadioOptions(
              motorSystemAbnormalOptions,
              motorAbnormalRadioItems,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (getReflexesSummary()) {
      centralNervousSystemSummaryItems.push({
        key: "8",
        label: "Reflexes",
        children: getReflexesSummary(),
      });
    }
    if (formData?.systemic_examination?.coordination) {
      centralNervousSystemSummaryItems.push({
        key: "9",
        label: "Co-ordination",
        children: (
          <>
            {getLabelTag(
              coordinationOptions,
              formData?.systemic_examination?.coordination
            )}
            {getLabelTagInput(
              "Finger-to-nose test",
              formData?.systemic_examination?.finger_to_nose_test
            )}
            {getLabelTagInput(
              "Finger-to-finger test",
              formData?.systemic_examination?.finger_to_finger_test
            )}
            {getLabelTagInput(
              "Adiadokokinesia or dysdiadokokinesia",
              formData?.systemic_examination?.adiadokokinesia
            )}
            {getLabelTagInput(
              "Rebound phenomena",
              formData?.systemic_examination?.rebound_phenomena
            )}
            {getLabelTagInput(
              "Walking along a straight line",
              formData?.systemic_examination?.walking_along_a_straight_line
            )}
            {getLabelTagInput(
              "Rombergs test",
              formData?.systemic_examination?.rombergs_test
            )}
            {getLabelTagInput(
              "Heel on shin test",
              formData?.systemic_examination?.heel_on_shin_test
            )}
          </>
        ),
      });
    }

    if (formData?.systemic_examination?.tcms_details?.status) {
      centralNervousSystemSummaryItems.push({
        key: "10",
        label: "TCMS",
        children: (
          <>
            {getLabelTag(
              notAssessableAndAssessableOptions,
              formData?.systemic_examination?.tcms_details?.status
            )}
            {getLabelScalesTags(
              tcmOptions,
              formData?.systemic_examination?.tcms_details
            )}
          </>
        ),
      });
    }
    return centralNervousSystemSummaryItems;
  };

  const skinSummaryItems = constructskinSummaryItems();
  const cardioVascularSystemSummaryItems =
    constructCardioVascularSystemSummaryItems();
  const respiratorySystemSummaryItems =
    constructRespiratorySystemSummaryItems();
  const musculoSkeletalSummaryItems = constructMusculoSkeletalSummaryItems();
  const centralNervousSystemSummaryItems =
    constructCentralNervousSystemSummaryItems();
  return (
    <div>
      {/* <Typography.Title level={5}>Systemic Examination</Typography.Title> */}
      {skinSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title="Skin"
          column={1}
          items={skinSummaryItems}
        />
      )}
      {cardioVascularSystemSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title="Cardio Vascular System"
          column={1}
          items={cardioVascularSystemSummaryItems}
        />
      )}
      {respiratorySystemSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title="Respiratory System"
          column={1}
          items={respiratorySystemSummaryItems}
        />
      )}

      {musculoSkeletalSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title="Musculo Skeletal"
          column={1}
          items={musculoSkeletalSummaryItems}
        />
      )}
      {centralNervousSystemSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title="Central Nervous System"
          column={1}
          items={centralNervousSystemSummaryItems}
        />
      )}
    </div>
  );
};

export default PhysioSystemicExaminationSummary;
