import React, { useContext, useEffect } from "react";
import { Col, Collapse, Row } from "antd";
import CardioForm from "./CardioForm/CardioForm";
import AbdomenForm from "./AbdomenForm/AbdomenForm";
import RespiratoryForm from "./RespiratoryForm/RespiratoryForm";
import MusculoSkeletalForm from "./MusculoSkeletalForm/MusculoSkeletalForm";
import CNSForm from "./CNSForm/CNSForm";
import Ambulation from "../FunctionalExaminationForm/Ambulation";
import { DailyReviewContext } from "../../../Doctor/DailyReview/DailyReviewFormProvider";
import "./SystemicExaminationForm.css";
const { Panel } = Collapse;

const DrSystemicExaminationFormDR = ({ form = {} }) => {
  const { formData } = useContext(DailyReviewContext);
  useEffect(() => {
    form.setFieldsValue(formData?.systemic_examination);
  }, [formData]);
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="Cardio Vascular System" key="1">
              <CardioForm context={DailyReviewContext} />
            </Panel>
          </Collapse>
          <Collapse defaultActiveKey={["3"]}>
            <Panel header="Abdomen" key="3">
              <AbdomenForm />
            </Panel>
          </Collapse>
          <Collapse defaultActiveKey={["5"]}>
            <Panel header="Central Nervous System" key="5">
              <CNSForm dailyReview={true} />
            </Panel>
          </Collapse>
        </Col>
        <Col span={12}>
          <Collapse defaultActiveKey={["2"]}>
            <Panel header="Respiratory System" key="2">
              <RespiratoryForm context={DailyReviewContext} />
            </Panel>
          </Collapse>
          <Collapse defaultActiveKey={["4"]}>
            <Panel header="Musculo Skeletal System" key="4">
              <MusculoSkeletalForm dailyReview={true} />
            </Panel>
          </Collapse>
          <Collapse defaultActiveKey={["6"]}>
            <Panel header="Ambulation" key="6">
              <Ambulation dailyReview={true} context={DailyReviewContext} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default DrSystemicExaminationFormDR;
