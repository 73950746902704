import React, { useContext, useEffect, useMemo, useState } from "react";
import { Radio, Form, Typography } from "antd";

import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  sensoryExtremitiesOptions,
  sensorySystemAbnormalOptions,
  sensorySystemOptions,
} from "../../../../Options/systemicExamOptions";
import {
  normalAbsentOptions,
  positiveNegativeOptions,
} from "../../../../Options/commonOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
const { Text } = Typography;
const SensorySystem = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [showAssessable, setShowAssessable] = useState(
    initialValues?.systemic_examination?.sensory_system === "assessable"
      ? true
      : false
  );
  const [checkboxes, setCheckboxes] = useState([]);

  const handleSensoryChange = (e) => {
    setShowAssessable(e.target.value === "assessable");
  };

  useEffect(() => {
    setShowAssessable(
      initialValues?.systemic_examination?.sensory_system === "assessable"
        ? true
        : false
    );
    const initialCheckboxes = sensorySystemAbnormalOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Form.Item label="Sensory System" name="sensory_system">
        <Radio.Group
          options={sensorySystemOptions}
          onChange={handleSensoryChange}
        />
      </Form.Item>
      {showAssessable && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={sensorySystemAbnormalOptions}
            radioOptions={sensoryExtremitiesOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />

          <Text>Tactile Localization</Text>
          <div style={{ marginLeft: "20px" }}>
            <Form.Item
              label="2 Point Discrimination"
              name="two_point_discrimination"
            >
              <Radio.Group options={normalAbsentOptions} />
            </Form.Item>
            <Form.Item label="Stereognosis" name="stereognosis">
              <Radio.Group options={normalAbsentOptions} />
            </Form.Item>
            <Form.Item label="Graphesthesia" name="graphesthesia">
              <Radio.Group options={normalAbsentOptions} />
            </Form.Item>
          </div>

          <Form.Item label="Romberg's Sign" name="rombergs_sign">
            <Radio.Group options={positiveNegativeOptions} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default SensorySystem;
