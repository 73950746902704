import { Form, Input, InputNumber, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";

const Bmi = ({ context }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState();
  const calculateBmi = () => {
    let status = "Underweight";
    if (bmi >= 30) {
      status = "Obese";
    } else if (bmi >= 25) {
      status = "Overweight";
    } else if (bmi >= 18.5) {
      status = "Healthy Weight";
    } else {
      status = "Underweight";
    }
    return (
      <span>
        {bmi} {status}
      </span>
    );
  };
  const bmi = (states?.weight / (states?.height * states?.height)).toFixed(2);

  useEffect(() => {
    setStates({
      height: formData?.clinical_evaluation?.bmi?.height / 100,
      weight: formData?.clinical_evaluation?.bmi?.weight,
    });
  }, [formData]);

  return (
    <>
      <Typography>
        BMI: {states?.height && states?.weight && calculateBmi()}
      </Typography>
      <div className="subsection_card">
        <Form.Item label="Height" name={["bmi", "height"]}>
          <InputNumber
            addonAfter="cm"
            onChange={(value) => {
              setStates((prev) => ({ ...prev, height: value / 100 }));
            }}
          />
        </Form.Item>
        <Form.Item label="Weight" name={["bmi", "weight"]}>
          <InputNumber
            addonAfter="kg"
            onChange={(value) => {
              setStates((prev) => ({ ...prev, weight: value }));
            }}
          />
        </Form.Item>
        <Form.Item name={["bmi", "value"]} hidden>
          <Input value={bmi} hidden />
        </Form.Item>
      </div>
    </>
  );
};

export default Bmi;
