import axiosInstance from "../config/axios.config";

export const psychologistInspectionAPI = (payload) => {
  return axiosInstance.post(
    `inpatient/pyschology-initial-assessment/`,
    payload
  );
};
export const updatePsychologistInspectionAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/pyschology-initial-assessment/${id}/`,
    payload
  );
};

export const getPsychologistInspectionAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/pyschology-initial-assessment/${admisssionId}/`
  );
};

export const psychologistReviewAPI = (payload) => {
  return axiosInstance.post(`inpatient/pyschology-review/`, payload);
};
export const updatePsychologistReviewAPI = (id, payload) => {
  return axiosInstance.patch(`inpatient/pyschology-review/${id}/`, payload);
};

export const getPsychologistReviewAPI = (admisssionId) => {
  return axiosInstance.get(`inpatient/pyschology-review/${admisssionId}/`);
};

export const getLatestPsychologistReviewAPI = (admissionId) => {
  return axiosInstance.get(
    `inpatient/latest-psychology-review/?admission_id=${admissionId}`
  );
};
