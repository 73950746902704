import React from "react";
import { Form, Radio } from "antd";
import { bowelIncontinenceOptions } from "../../../Options/funcExamOptions";

const BowelIncontinence = () => {
  return (
    <Form.Item name="bowel_incontinence" label="Bowel Incontinence">
      <Radio.Group options={bowelIncontinenceOptions} />
    </Form.Item>
  );
};

export default BowelIncontinence;
