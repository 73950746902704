import { Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  dietOptions,
  entrealDietOptions,
  entrealFeedTypeOptions,
  oralDietOptions,
  oralDietTypeOptions,
} from "../../Options/dietAdviceOptions";

const TypeOfDiet = ({ context }) => {
  const { formData } = useContext(context);
  const [showSubOptions, setShowSubOptions] = useState(
    formData?.diet_advice?.diet_details?.diet
  );

  useEffect(() => {
    setShowSubOptions(formData?.diet_advice?.diet_details?.diet);
  }, [formData]);

  return (
    <>
      <Form.Item label="Diet" name={["diet_details", "diet"]}>
        <Radio.Group
          options={dietOptions}
          onChange={(e) => setShowSubOptions(e.target.value)}
        />
      </Form.Item>
      {showSubOptions && showSubOptions !== "parenteral" && (
        <>
          <Form.Item
            label="Type of Diet"
            name={["diet_details", "type_of_diet"]}
          >
            <Radio.Group
              options={
                showSubOptions === "oral" ? oralDietOptions : entrealDietOptions
              }
            />
          </Form.Item>
          <Form.Item
            label={
              showSubOptions === "oral"
                ? "Type of Oral Diet"
                : "Type of Enteral Feed"
            }
            name={[
              "diet_details",
              showSubOptions === "oral"
                ? "type_of_oral_diet"
                : "type_of_enteral_feed",
            ]}
          >
            <Radio.Group
              options={
                showSubOptions === "oral"
                  ? oralDietTypeOptions
                  : entrealFeedTypeOptions
              }
            />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default TypeOfDiet;
