import React, { useState } from "react";
import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import GrbsModal from "./GrbsModal";
import dayjs from "dayjs";

const GrbsMonitoring = ({ data, refresh }) => {
  const [editingKey, setEditingKey] = useState("");
  const [open, setOpen] = useState(false);
  const columns = [
    {
      title: "Time",
      dataIndex: "action_time",
      width: "10%",
      render: (value) => dayjs(value)?.format("MMM DD, hh:mm A"),
    },
    {
      title: "GRBS",
      dataIndex: "grbs",
      width: "10%",
    },
    {
      title: "Insulin",
      dataIndex: "insulin",
      width: "10%",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "10%",
    },

    {
      title: "Action",
      width: "10%",
      render: (_, record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                setOpen(true);
                setEditingKey(record);
              }}
              style={{ cursor: "pointer" }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        scroll={{ y: "calc(100vh - 360px)", x: "400px" }}
        bordered
        dataSource={data}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
      />
      {open && (
        <GrbsModal
          open={open}
          setOpen={setOpen}
          refresh={refresh}
          editData={editingKey}
        />
      )}
    </>
  );
};

export default GrbsMonitoring;
