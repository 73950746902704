import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";
import { specialCareOptions } from "../../Options/functionalAsessmentOptions";
import { NurseInitialContext } from "../HistoryForm/NurseInitialAssessmentFormProvider";

const NurseSpecialCareRequirement = ({ form, formData }) => {
  // const { formData } = useContext(NurseInitialContext);
  const [checkboxes, setCheckboxes] = useState([]);
  useEffect(() => {
    form.setFieldsValue(formData?.special_care);
    const initialCheckboxes = specialCareOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = formData?.special_care?.[checkbox.value] || false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, []);
  return (
    <>
      <CheckListFBYInput
        options={specialCareOptions}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    </>
  );
};

export default NurseSpecialCareRequirement;
