import React, { useContext, useEffect, useState } from "react";
import { Button, Image, Input, Space, Tabs, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import btnStyles from "../../../styles/Custom/Button.module.css";
import InPatientModal from "./InPatientModal";
import { getIpAdmissionApi } from "../../../services/receptionist.services";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import IpPatientTable from "./IpPatientTable";
import CustomLayout from "../../Layout/CustomLayout";
import { useParams } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import ReceptionistSideMenu from "../../Layout/CustomLayout/ReceptionistSideMenu";
import textStyles from "../../../styles/Custom/Typography.module.css";
import { debounce } from "../../../helpers/utility";

const items = [
  {
    key: "all",
    label: "All",
  },
  {
    key: "active",
    label: "Active",
  },
  {
    key: "discharged",
    label: "Discharged",
  },
];

const Inpatients = ({ refresh }) => {
  const query = useParams();
  const params = useQuery();
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [ipRefresh, setIpRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("all");

  const refreshIpPage = () => {
    setIpRefresh((prev) => !prev);
  };
  useEffect(() => {
    let params = {
      page: page,
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    if (currentTab !== "all") {
      params.is_active = currentTab === "active" ? "True" : "False";
    }
    const queryString = new URLSearchParams(params);
    getIpAdmissionApi(queryString)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    Number(getCookie(FacilityID)) || userDetails?.currFacility,
    ipRefresh,
    searchQuery,
    page,
    currentTab,
  ]);

  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };

  const onChange = (key) => {
    setCurrentTab(key);
  };
  return (
    <CustomLayout
      sideContent={
        params?.patient ? null : <ReceptionistSideMenu refresh={refresh} />
      }
      header={
        params?.patient ? (
          <>
            {userDetails?.activeOrg?.org_logo ? (
              <Image
                preview={false}
                src={userDetails?.activeOrg?.org_logo}
                alt={"Logo"}
                style={{
                  width: "40px",
                  marginTop: "10px",
                }}
              />
            ) : (
              userDetails?.activeOrg?.org_name
            )}
          </>
        ) : (
          <div
            style={{
              gap: "5%",
              width: "100%",
            }}
            className="flex-start"
          >
            <Typography className={textStyles.h6}>In Patients</Typography>
            <Tabs
              style={{ width: "80%", paddingTop: "16px" }}
              onChange={onChange}
              defaultActiveKey={currentTab}
              items={items}
            />
          </div>
        )
      }
    >
      {data?.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80%",
          }}
        >
          <Button
            type="primary"
            className={btnStyles.mdBtn}
            onClick={() => setOpen(true)}
          >
            <PlusOutlined /> Add In Patient
          </Button>
        </div>
      ) : (
        <>
          <div className="flex-between">
            <Input.Search
              style={{ width: "30%", marginBottom: "10px" }}
              size="large"
              placeholder="Search Patient (Name,Contact)"
              onChange={debounce(onContactSearch, 300)}
            ></Input.Search>
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => setOpen(true)}
            >
              <PlusOutlined /> Add In Patient
            </Button>
          </div>
          <IpPatientTable
            data={data}
            refreshIpPage={refreshIpPage}
            page={page}
            setPage={setPage}
          />
        </>
      )}
      {open && (
        <InPatientModal
          open={open}
          setOpen={setOpen}
          refreshIpPage={refreshIpPage}
          refresh={refresh}
        />
      )}
    </CustomLayout>
  );
};

export default Inpatients;
