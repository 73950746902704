import { Empty } from "antd";
import React from "react";

const PageNotFound = () => {
  return (
    <div style={{ minHeight: "100vh" }} className="flex-center">
      <Empty description="No page found" />
    </div>
  );
};

export default PageNotFound;
