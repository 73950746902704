import { Descriptions } from "antd";
import React from "react";
import {
  cardioGoalsOptions,
  neuroGoalsOptions,
  orthoGoalsOptions,
  sportsGoalsOptions,
} from "../../Options/physioOptions";
import {
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";

const PhysioTherapeuticGoalsSummary = ({ formData }) => {
  const constructTherapeuticGoalsSummaryItems = () => {
    const therapeuticGoalsSummaryItems = [];
    const goals = formData?.therapeutic_goals || {};
    const pushGoalsItems = (key, label, children) => {
      therapeuticGoalsSummaryItems.push({ key, label, children });
    };
    if (goals?.cardio) {
      pushGoalsItems(
        "1",
        "Cardio",
        <>
          {getLabelTagsArray(cardioGoalsOptions, goals?.cardio_details)}
          {getLabelTagInput("Other Details", goals?.cardio_description)}
        </>
      );
    }
    if (goals?.ortho) {
      pushGoalsItems(
        "2",
        "Ortho",
        <>
          {getLabelTagsArray(orthoGoalsOptions, goals?.ortho_details)}
          {getLabelTagInput("Other Details", goals?.ortho_description)}
        </>
      );
    }
    if (goals?.sports) {
      pushGoalsItems(
        "3",
        "Sports",
        <>
          {getLabelTagsArray(sportsGoalsOptions, goals?.sports_details)}
          {getLabelTagInput("Other Details", goals?.sports_description)}
        </>
      );
    }
    if (goals?.neuro) {
      pushGoalsItems(
        "4",
        "Neuro",
        <>
          {getLabelTagsArray(neuroGoalsOptions, goals?.neuro_details)}
          {getLabelTagInput("Other Details", goals?.neuro_description)}
        </>
      );
    }
    return therapeuticGoalsSummaryItems;
  };
  const therapeuticGoalsSummaryItems = constructTherapeuticGoalsSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title="Therapeutic Goals"
        items={therapeuticGoalsSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </div>
  );
};

export default PhysioTherapeuticGoalsSummary;
