import { Form, Radio } from "antd";
import React from "react";
import { normalAbnormalOptions } from "../../../Options/commonOptions";

const SpeechSwallowAssessment = () => {
  return (
    <>
      <Form.Item
        label="Speech and Swallow Assessment"
        name="speech_and_swallow_assessment"
      >
        <Radio.Group options={normalAbnormalOptions} />
      </Form.Item>
    </>
  );
};

export default SpeechSwallowAssessment;
