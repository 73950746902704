import React, { useContext, useEffect, useState } from "react";
import {
  trachealConsistencyOptions,
  trachealSecretionOptions,
  trachealVolumeOptions,
} from "../../Options/RespiratoryTherapistOptions";
import { Checkbox, Form, Input, Typography } from "antd";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";
import { RespiratoryReviewContext } from "../../RespiratoryTherapist/Review/RespiratoryTherapistReviewProvider";

const TrachealSecretions = () => {
  const { formData } = useContext(RespiratoryReviewContext);
  const [states, setStates] = useState({});
  const [checkboxes, setCheckboxes] = useState({});

  const handleCheckboxChange = (value) => {
    setStates((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  useEffect(() => {
    setStates((prev) => ({
      ...prev,
      volume: formData?.patient_assessment?.tracheal_secretions?.volume,
      consistency:
        formData?.patient_assessment?.tracheal_secretions?.consistency,
    }));
    const volumeCheckboxes = trachealVolumeOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.patient_assessment?.tracheal_secretions_volume?.[
          checkbox.value
        ];
      return acc;
    }, {});
    const consistencyCheckboxes = trachealConsistencyOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          formData?.patient_assessment?.tracheal_secretions_consistency?.[
            checkbox.value
          ];
        return acc;
      },
      {}
    );
    setCheckboxes({ ...volumeCheckboxes, ...consistencyCheckboxes });
  }, [formData]);

  return (
    <>
      <Typography>Tracheal Secretions</Typography>
      {trachealSecretionOptions.map((option) => (
        <React.Fragment key={option.value}>
          <Form.Item
            name={["tracheal_secretions", option.value]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={states[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {states[option.value] && (
            <div className="subsection-card">
              <CheckListFBYInput
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                options={
                  option.value === "volume"
                    ? trachealVolumeOptions
                    : trachealConsistencyOptions
                }
                keyChain={`tracheal_secretions_${option.value}`}
              />
            </div>
          )}
        </React.Fragment>
      ))}
      <Form.Item name={["tracheal_secretions", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default TrachealSecretions;
