import axiosInstance from "../config/axios.config";
export const createRoomTypeApi = (payload) => {
  return axiosInstance.post(`/facility/room-type/`, payload);
};
export const updateRoomTypeApi = (room_type_id, payload) => {
  return axiosInstance.patch(`/facility/room-type/${room_type_id}/`, payload);
};
export const getRoomTypeApi = () => {
  return axiosInstance.get(`/facility/room-type/`);
};

export const getBuildingTreeApi = (facility_id) => {
  return axiosInstance.get(`/facility/building-tree/${facility_id}/`);
};
export const getBuildingListApi = (facility_id) => {
  return axiosInstance.get(`/facility/facility-building-list/${facility_id}/`);
};
export const createBuildingApi = (payload) => {
  return axiosInstance.post(`/facility/building/`, payload);
};
export const updateBuildingApi = (id, payload) => {
  return axiosInstance.patch(`/facility/building/${id}/`, payload);
};
export const createFloorApi = (payload) => {
  return axiosInstance.post(`/facility/floor/`, payload);
};

export const updateFloorApi = (floor_id, payload) => {
  return axiosInstance.patch(`/facility/floor/${floor_id}/`, payload);
};
export const createRoomApi = (payload) => {
  return axiosInstance.post(`/facility/room/`, payload);
};

export const updateRoomApi = (room_id, payload) => {
  return axiosInstance.patch(`/facility/room/${room_id}/`, payload);
};
export const createBedApi = (payload) => {
  return axiosInstance.post(`/facility/bed/`, payload);
};

export const updateBedApi = (bed_id, payload) => {
  return axiosInstance.patch(`/facility/bed/${bed_id}/`, payload);
};
export const getAllBedsListApi = (facility_id) => {
  return axiosInstance.get(`/facility/all-beds-list/${facility_id}/`);
};
