import { Form, Radio } from "antd";
import React from "react";
import { nutritionistFunctionalOptions } from "../../../Options/historyOptions";

const NutritionistFunctionalCapacity = () => {
  return (
    <>
      <Form.Item label="Functional Capacity" name="functional_capacity">
        <Radio.Group options={nutritionistFunctionalOptions} />
      </Form.Item>
    </>
  );
};

export default NutritionistFunctionalCapacity;
