import React, { createContext, useState } from "react";
export const PsychologistReviewContext = createContext({});
const PsychologistReviewProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <PsychologistReviewContext.Provider value={{ formData, setFormData }}>
      {children}
    </PsychologistReviewContext.Provider>
  );
};

export default PsychologistReviewProvider;
