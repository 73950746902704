import React, { useEffect, useState } from "react";
import { Menu, Button } from "antd";
import { useLocation } from "react-router-dom";
import { getpatientMedicationsAPI } from "../../../../services/patient.services";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import ScanModule from "./ScanModule";
import AddMedicineTable from "../../../PatientsIP/MedicationsIp/AddMedicineTable";

const items = [
  { label: "Add Medicine Manually", key: "add-medicine" },
  // { label: "Scan Images", key: "scan" },
];

const MedicinesInitial = ({ setCurrent }) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [currentTab, setCurrentTab] = useState("add-medicine");
  const [add, setAdd] = useState(true);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getpatientMedicationsAPI(`ip_admission=${params?.patient}`)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  const onClick = (e) => {
    setCurrentTab(e.key);
  };

  return (
    <>
      {/* <Menu
        items={items}
        mode="horizontal"
        selectedKeys={[currentTab]}
        onClick={onClick}
        style={{ marginBottom: "10px" }}
      /> */}
      <div style={{ height: "85%", overflow: "hidden", position: "relative" }}>
        <div style={{ height: "calc(100% - 38px)", overflowY: "auto" }}>
          {currentTab === "add-medicine" ? (
            <>
              <AddMedicineTable
                data={data}
                setData={setData}
                refreshPage={refreshPage}
                loading={loading}
                initial={true}
              />

              <div
                className="flex-end"
                style={{
                  gap: "24px",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  background: "#fff",
                  paddingTop: "10px",
                }}
              >
                <Button
                  htmlType="submit"
                  type="primary"
                  className={btnStyles.mdBtn}
                  onClick={() => {
                    currentTab === "add-medicine"
                      ? setCurrent(3)
                      : setAdd(false);
                  }}
                >
                  {currentTab === "scan" ? "Submit" : "Proceed"}
                </Button>
              </div>
            </>
          ) : (
            <ScanModule setCurrent={setCurrent} />
          )}
        </div>
      </div>
    </>
  );
};

export default MedicinesInitial;
