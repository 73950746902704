import { Form, InputNumber, Radio, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { bergScaleOptions } from "../../../Options/physioOptions";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";

const BergScale = ({ context }) => {
  const { formData } = useContext(context);
  const [showScale, setShowScale] = useState(formData);
  const [states, setStates] = useState({});
  useEffect(() => {
    setShowScale(
      formData?.systemic_examination?.berg_scale?.status === "assessable"
    );
    // console.log(
    //   Object.entries(formData?.systemic_examination?.berg_scale)?.filter(
    //     ([key]) => key != "status"
    //   )
    // );
    const { status, ...filteredBerg } =
      formData?.systemic_examination?.berg_scale || {};
    setStates(filteredBerg);
  }, [formData]);

  const calculateScore = () => {
    return Object.values(states)?.reduce((acc, cur) => acc + (cur || 0), 0);
  };

  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <Form.Item label="Berg Balance Scale" name={["berg_scale", "status"]}>
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) => setShowScale(e.target.value === "assessable")}
        />
      </Form.Item>
      {showScale && (
        <>
          <Typography>Score: {calculateScore()}</Typography>

          {bergScaleOptions.map((option) => (
            <Form.Item
              key={option.name}
              label={option.label}
              name={["berg_scale", option.value]}
            >
              <InputNumber
                placeholder={"0 to 4"}
                max={4}
                min={0}
                style={{ width: "100%" }}
                onChange={(value) => handleChange(value, option.value)}
              />
            </Form.Item>
          ))}
        </>
      )}
    </>
  );
};

export default BergScale;
