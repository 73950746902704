export const clinicalFindingOptions = [
  { label: "Pulmonary", value: "pulmonary" },
  { label: "Surgical Status", value: "surgical_status" },
  { label: "Chest X-Ray", value: "chest_x_ray" },
];

export const pulmonaryOptions = [
  { label: "No Smoking History", value: "no_smoking_history" },
  { label: "Smoking < 1 pack/day", value: "smoking_less_than_1_pack_per_day" },
  { label: "Smoking > 1 pack/day", value: "smoking_more_than_1_pack_per_day" },
  {
    label: "Pulmonary Impairment (Acute / Chronic)",
    value: "pulmonary_impairment_acute_chronic",
  },
  {
    label: "Severe Or Chronic With Exacerbation",
    value: "severe_or_chronic_with_exacerbation",
  },
];

export const surgicalStatusOptions = [
  { label: "No Surgery", value: "no_surgery" },
  { label: "General Surgery", value: "general_surgery" },
  { label: "Lower Abdominal", value: "lower_abdominal" },
  { label: "Thoracic With Lung Disease", value: "thoracic_with_lung_disease" },
  { label: "Neuro Surgery", value: "neuro_surgery" },
  { label: "CT-Surgery", value: "ct_surgery" },
  { label: "RTA", value: "rta" },
];

export const chestXRayOptions = [
  { label: "Clear Or Not Indicated", value: "clear_or_not_indicated" },
  {
    label: "Chronic Changes Or X-Ray Pending",
    value: "chronic_changes_or_xray_pending",
  },
  { label: "Infiltrates", value: "infiltrates" },
  { label: "Atelectasis", value: "atelectasis" },
  {
    label: "Unilateral Pleural Effusion",
    value: "unilateral_pleural_effusion",
  },
  { label: "Bilateral Pleural Effusion", value: "bilateral_pleural_effusion" },
  {
    label: "Infiltrates in 1 Lobe Right",
    value: "infiltrates_in_1_lobe_right",
  },
  { label: "Infiltrates in 1 Lobe Left", value: "infiltrates_in_1_lobe_left" },
  {
    label: "Infiltrates in more than 1 Lobe (Right)",
    value: "infiltrates_in_more_than_1_lobe_right",
  },
  {
    label: "Infiltrates in more than 1 Lobe (Left)",
    value: "infiltrates_in_more_than_1_lobe_left",
  },
];

export const artificialAirwayOptions = [
  { label: "Tracheostomy", value: "tracheostomy" },
  { label: "Laryngeal Mask", value: "laryngeal_mask" },
  { label: "Oropharyngeal", value: "oropharyngeal" },
  { label: "Nasopharyngeal", value: "nasopharyngeal" },
];

export const airwaySizeOptions = [
  { label: "Small", value: "small" },
  { label: "Medium", value: "medium" },
  { label: "Large", value: "large" },
];

export const airwayTubeTypeOptions = [
  { label: "Single Lumen", value: "single_lumen" },
  { label: "Double Lumen", value: "double_lumen" },
  { label: "Fenetrated with Subglottic", value: "fenetrated_with_subglottic" },
  {
    label: "Fenetrated without Subglottic",
    value: "fenetrated_without_subglottic",
  },
  {
    label: "Non Fenetrated with Subglottic",
    value: "non_fenetrated_with_subglottic",
  },
  {
    label: "Non Fenetrated without Subglottic",
    value: "non_fenetrated_without_subglottic",
  },
  { label: "Metal Tracheostomy Tube", value: "metal_tracheostomy_tube" },
];

export const trachealStomaOptions = [
  { label: "Healthy", value: "healthy" },
  { label: "Unhealthy", value: "unhealthy" },
];

export const trachealSecretionConsistencyOptions = [
  { label: "Thin", value: "thin" },
  { label: "Thick", value: "thick" },
  { label: "Frothy", value: "frothy" },
  { label: "Tenacious", value: "tenacious" },
  { label: "Purulent", value: "purulent" },
  { label: "Clear / White", value: "clear_white" },
  { label: "Blood Tinged", value: "blood_tinged" },
  { label: "Yellow", value: "yellow" },
  { label: "Hemoptysis", value: "hemoptysis" },
];

export const ventilationStatusOptions = [
  { label: "No Ventilatory Assist", value: "no_ventilatory_assist" },
  { label: "Nocturnal CPAP / BiPAP", value: "nocturnal_cpap_bipap" },
  { label: "Non-Invasive Ventilation", value: "non_invasive_ventilation" },
  { label: "High Flow Nasal Cannula", value: "high_flow_nasal_cannula" },
  {
    label: "Continuous Mechanical Ventilation",
    value: "continuous_mechanical_ventilation",
  },
];

export const respiratoryPatternOptions = [
  { label: "Eupnea - Normal Breathing", value: "eupnea" },
  { label: "Orthopnea - Breath Better Sitting up", value: "orthopnea" },
  { label: "Hypernea - Deep Breathing", value: "hypernea" },
  { label: "Hypopnea - Shallow Breathing", value: "hypopnea" },
  { label: "Tachypnea - Rapid Breathing", value: "tachypnea" },
  { label: "Bradypnea - Slow Breathing", value: "bradypnea" },
  { label: "Dysnea - Labored Breathing", value: "dysnea" },
];

export const mentalStatusOptions = [
  { label: "Alert Oriented", value: "alert_oriented" },
  { label: "Confused follows commands", value: "confused_follows_commands" },
  {
    label: "Lethargic, does not follow commands",
    value: "lethargic_does_not_follow_commands",
  },
  { label: "Obtunded", value: "obtunded" },
  { label: "Comatose", value: "comatose" },
];

export const chestExpansionOptions = [
  { label: "Symmetrical", value: "symmetrical" },
  { label: "Asymmetrical", value: "asymmetrical" },
  { label: "Paradoxical", value: "paradoxical" },
  { label: "Tracheal Tug", value: "tracheal_tug" },
];

export const breathSoundOptions = [
  { label: "Clear", value: "clear" },
  { label: "Decreased", value: "decreased" },
  { label: "Wheezes", value: "wheezes" },
  { label: "Fine", value: "fine" },
  { label: "Crepitations Coarse", value: "crepitations_coarse" },
  { label: "Crepitations Absent", value: "crepitations_absent" },
  { label: "Bronchial", value: "bronchial" },
  { label: "Inspiratory / Expiratory", value: "inspiratory_expiratory" },
];

export const coughEffectivenessOptions = [
  {
    label: "Strong Spontaneous Non-Productive",
    value: "strong_spontaneous_non_productive",
  },
  { label: "Strong Productive", value: "strong_productive" },
  { label: "Weak Non-Productive", value: "weak_non_productive" },
  {
    label: "Weak Productive or Weak With Rhonchi",
    value: "weak_productive_or_weak_with_rhonchi",
  },
  {
    label: "No Spontaneous Cough or Any Required Suctioning",
    value: "no_spontaneous_cough_or_any_required_suctioning",
  },
];

export const activityLevelOptions = [
  { label: "Ambulatory", value: "ambulatory" },
  { label: "Ambulatory With Assistance", value: "ambulatory_with_assistance" },
  { label: "Non-Ambulatory", value: "non_ambulatory" },
  {
    label: "Bed Rest, Able To Position Self",
    value: "bed_rest_able_to_position_self",
  },
  {
    label: "Bed Rest, Unable To Position Self",
    value: "bed_rest_unable_to_position_self",
  },
];

export const oxygenRequiredOptions = [
  { label: "No Oxygen", value: "no_oxygen" },
  { label: "1 - 3 litres", value: "1_3_litres" },
  { label: "4 - 6 litres", value: "4_6_litres" },
  { label: "> 6 litres", value: "more_than_6_litres" },
];

export const coughOptions = [
  { label: "Spontaneous", value: "spontaneous" },
  { label: "Strong", value: "strong" },
  { label: "Weak", value: "weak" },
  { label: "Stimulated by Suctioning", value: "stimulated_by_suctioning" },
  { label: "Moderate", value: "moderate" },
  { label: "Absent", value: "absent" },
];

export const respiratoryTreatmentOptions = [
  { label: "Incentive Spirometry", value: "incentive_spirometry" },
  { label: "Chest Physiotherapy", value: "chest_physiotherapy" },
  { label: "Breathing Exercises", value: "breathing_exercises" },
  { label: "Cough Assist", value: "cough_assist" },
  { label: "Aerosol Therapy", value: "aerosol_therapy" },
];

export const trachealSecretionOptions = [
  { label: "Volume", value: "volume" },
  { label: "Consistency", value: "consistency" },
];

export const trachealVolumeOptions = [
  { label: "Plenty", value: "plenty" },
  { label: "Moderate", value: "moderate" },
  { label: "Minimal", value: "minimal" },
];

export const trachealConsistencyOptions = [
  { label: "Thick", value: "thick" },
  { label: "Thin", value: "thin" },
  { label: "Purulent", value: "purulent" },
  { label: "Blood Tinged", value: "blood_tinged" },
];

export const tracheostomyTubeOptions = [
  { label: "Continuing Same", value: "continuing_same" },
  { label: "Downsized", value: "downsized" },
  { label: "Decannulated", value: "decannulated" },
];

export const treatmentGivenOptions = [
  { label: "Incentive Spirometer", value: "incentive_spirometer" },
  { label: "Chest Physiotherapy", value: "chest_physiotherapy" },
  { label: "Breathing Exercise", value: "breathing_exercise" },
  { label: "Suctioning", value: "suctioning" },
  { label: "Cough Assist", value: "cough_assist" },
  { label: "Aerosol Therapy", value: "aerosol_therapy" },
];
