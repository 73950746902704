export const ambulanceOptions = [
  { label: "Independent", value: "independent" },
  { label: "Assisted", value: "assisted" },
  { label: "Bed Bound", value: "bed_bound" },
];
export const ambulanceAssistanceOptions = [
  { label: "Wheel Chair", value: "wheel_chair" },
  { label: "Cane", value: "cane" },
  { label: "Walker", value: "walker" },
  { label: "Braces", value: "braces" },
  { label: "Prosthesis", value: "prosthesis" },
  { label: "Helper", value: "helper" },
  { label: "Others", value: "others" },
];
export const ambulanceBedBoundOptions = [
  { label: "Independent Bed Mobility", value: "independent_bed_mobility" },
  { label: "Assisted Bed Mobility", value: "assisted_bed_mobility" },
  { label: "Dependent Bed Mobility", value: "dependent_bed_mobility" },
];

export const bladderIncontinenceOptions = [
  { label: "No", value: "no" },
  { label: "Yes", value: "yes" },
  { label: "Occasionally", value: "occasionally" },
  { label: "Catheterized", value: "catheterized" },
];

export const bowelIncontinenceOptions = [
  { label: "No", value: "no" },
  { label: "Yes", value: "yes" },
  { label: "Occasionally", value: "occasionally" },
  { label: "Colostomy", value: "bowel_colostomy" },
];

export const speechFunctionalOptions = [
  { label: "Dysphagia", value: "dysphagia" },
  { label: "Dysarthria", value: "dysarthria" },
  { label: "Aphasia", value: "aphasia" },
  { label: "Voice", value: "voice" },
];
