import React, { useContext } from "react";
import DoctorHistorySummary from "../../InspectionSummaries/HistorySummary/DoctorHistorySummary";
import DrGeneralExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/DrGeneralExaminationSummary";
import DrSystemicExaminationSummary from "../../InspectionSummaries/SystemicExaminationSummary/DrSystemicExaminationSummary";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import { Col, Row } from "antd";
import DrFunctionalExaminationSummary from "../../InspectionSummaries/FunctionalExaminationSummary/DrFunctionalExaminationSummary";
import DrClinicalImpressionSummary from "../../InspectionSummaries/ClinicalImpressionSummary/DrClinicalImpressionSummary";
import "../../summary.css";

const InitialInspectionSummary = () => {
  const { formData, setFormData } = useContext(DoctorInitialContext);

  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.history && <DoctorHistorySummary formData={formData} />}
        {formData?.clinical_diagnosis && (
          <DrClinicalImpressionSummary context={DoctorInitialContext} />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.general_examination && <DrGeneralExaminationSummary />}
        {formData?.systemic_examination && <DrSystemicExaminationSummary />}
        {formData?.functional_examination && <DrFunctionalExaminationSummary />}
      </Col>
    </Row>
  );
};

export default InitialInspectionSummary;
