import { Checkbox, Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { goalsOptions } from "../../Options/goalsOptions";

const Goals = ({ context }) => {
  const { formData } = useContext(context);
  const [showOthers, setShowOthers] = useState(
    formData?.goals?.goals?.includes("others") || false
  );
  const handleGoals = (values) => {
    console.log("Selected values:", values);

    if (Array.isArray(values)) {
      setShowOthers(values?.includes("others"));
    }
  };

  useEffect(() => {
    setShowOthers(formData?.goals?.goals?.includes("others"));
  }, [formData]);

  return (
    <>
      <Form.Item label="Goals" name="goals">
        <Checkbox.Group options={goalsOptions} onChange={handleGoals} />
      </Form.Item>
      {showOthers && (
        <Form.Item name="others_descriptions">
          <Input
            placeholder="Please enter the description"
            autoComplete="off"
          />
        </Form.Item>
      )}
    </>
  );
};

export default Goals;
