import React, { createContext, useState } from "react";

export const RiskAssessmentContext = createContext({});
const RiskAssessmentFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <RiskAssessmentContext.Provider value={{ formData, setFormData }}>
      {children}
    </RiskAssessmentContext.Provider>
  );
};

export default RiskAssessmentFormProvider;
