import { Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";
import { psychologistDiagnosisOptions } from "../../Options/psychologistOptions";

const PsychologistDiagnosis = ({ form, context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState({});
  useEffect(() => {
    form.setFieldsValue(formData?.psychology_diagnosis);
    const initialCheckboxes = psychologistDiagnosisOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          formData?.psychology_diagnosis?.[checkbox.value] || false;
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <Form.Item name={"psychology_diagnosis"}>
        <CheckListFBYInput
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          options={psychologistDiagnosisOptions}
        />
      </Form.Item>
    </>
  );
};

export default PsychologistDiagnosis;
