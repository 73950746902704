import React, { createContext, useState } from "react";

export const PhysioInitialContext = createContext({});
const PhysiotherapistInitialAssessmentProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <PhysioInitialContext.Provider value={{ formData, setFormData }}>
      {children}
    </PhysioInitialContext.Provider>
  );
};

export default PhysiotherapistInitialAssessmentProvider;
