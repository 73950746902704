import axiosInstance from "../config/axios.config";

export const doctorInspectionAPI = (payload) => {
  return axiosInstance.post(`inpatient/doctor-initial-assessment/`, payload);
};
export const updateDoctorInspectionAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/doctor-initial-assessment/${id}/`,
    payload
  );
};

export const getDoctorInspectionAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/doctor-initial-assessment/${admisssionId}/`
  );
};

export const doctorDailyReviewAPI = (payload) => {
  return axiosInstance.post(`inpatient/doctor-daily-review/`, payload);
};
export const updateDoctorDailyReviewAPI = (id, payload) => {
  return axiosInstance.patch(`inpatient/doctor-daily-review/${id}/`, payload);
};

export const getDoctorDailyReviewAPI = (admisssionId) => {
  return axiosInstance.get(`inpatient/doctor-daily-review/${admisssionId}/`);
};

export const getLatestDailyReviewAPI = (admissionId) => {
  return axiosInstance.get(`inpatient/get-latest-daily-review/${admissionId}/`);
};
//appointments

export const doctorAppointmentsApi = (params, payload) => {
  return axiosInstance.post(`appointments/doctors/?${params}`, payload);
};
export const doctorUpdateAppointmentsApi = (id, payload) => {
  return axiosInstance.patch(`appointments/doctors/update/${id}/`, payload);
};
export const getDoctorAppointmentsApi = (params) => {
  return axiosInstance.get(`appointments/doctors/?${params}`);
};
export const viewDoctorAppointmentsApi = (id) => {
  return axiosInstance.get(`appointments/doctor-appointments/${id}/`);
};
