import {
  Form,
  Input,
  message,
  Button,
  List,
  Typography,
  Radio,
  Col,
  Row,
} from "antd";
import React, { useEffect } from "react";
import {
  coordinationAPI,
  getCoordinationAPI,
  updateCoordinationAPI,
} from "../../../../../services/physio.services";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Coordination = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const coordinationOptions = [
    { label: "Finger-to-Nose Test", name: "finger_to_nose" },
    { label: "Finger-to-Finger Test", name: "finger_to_finger" },
    {
      label: "Adiadokokinesia or Dysdiadokokinesia",
      name: "adiadochokinesia_or_dysdiadokokinesia",
    },
    { label: "Rebound phenomena", name: "rebound_phenomenon" },
    {
      label: "Walking along a straight line",
      name: "walking_along_straight_line",
    },
    { label: "Rombergs test", name: "rombergs_test" },
    { label: "Heel on shin test", name: "heel_to_shin" },
  ];
  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };

    try {
      if (scalesId?.coordination) {
        await updateCoordinationAPI(scalesId?.coordination, finalData);
      } else {
        const response = await coordinationAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, coordination: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.coordination) {
      getCoordinationAPI(admission, scalesId?.coordination)
        .then((response) => {
          form.setFieldsValue(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Form id="coordination" onFinish={handleSubmit} form={form}>
          {/* {coordinationOptions.map((option) => (
          <Form.Item label={option.label} name={option.name}>
            <Select placeholder="Select status" allowClear>
              {intactImpairedOptions.map((opt) => (
                <Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ))} */}
          <List
            bordered
            header={
              <Row
                style={{ width: "100%", textAlign: "center" }}
                align="middle"
              >
                <Col span={12}></Col>
                <Col span={6}>
                  <Typography.Text strong>First Test</Typography.Text>
                </Col>
                <Col span={6}>
                  <Typography.Text strong>Second Test</Typography.Text>
                </Col>
              </Row>
            }
            style={{ background: "#fff", width: "600px" }}
            dataSource={coordinationOptions}
            renderItem={(option) => (
              <List.Item>
                <Row style={{ width: "100%" }} align="middle">
                  <Col span={12}>
                    <Typography>{option.label}</Typography>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={option.value} style={{ marginBottom: 0 }}>
                      <Radio.Group style={{ width: "100%" }}>
                        <Row justify="space-around">
                          <Col>
                            <Radio.Button
                              value={"yes"}
                              style={{ backgroundColor: "#E9D7FE" }}
                            >
                              <LikeOutlined />
                            </Radio.Button>
                          </Col>
                          <Col>
                            <Radio.Button
                              value={"no"}
                              style={{ backgroundColor: "#FFF5F5" }}
                            >
                              <DislikeOutlined
                                style={{ backgroundColor: "" }}
                              />
                            </Radio.Button>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
          <Form.Item name="comments">
            <Input placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default Coordination;
