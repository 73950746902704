import { Modal } from "antd";
import React from "react";

const ClosePopup = ({ open, setOpen, setVisible, type }) => {
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title="Discard changes"
      onOk={() => {
        setVisible(false);
        setOpen(false);
      }}
    >
      Are you sure to discard changes?
    </Modal>
  );
};

export default ClosePopup;
