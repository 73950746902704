import { Tabs } from "antd";
import React, { useState } from "react";
import DailyRoutineFormTable from "./DailyRoutineFormTable";

const DailyRoutine = ({ formData, getData }) => {
  console.log(formData);
  const [activeTab, setActiveTab] = useState("bath_sponge");

  const items = [
    {
      key: "bath_sponge",
      label: "Bath/Sponge",
      children: (
        <DailyRoutineFormTable
          type="bath_sponge"
          nurseData={formData?.bath_sponge}
          getData={getData}
        />
      ),
    },
    {
      key: "oral_care",
      label: "Oral Care",
      children: (
        <DailyRoutineFormTable
          type="oral_care"
          nurseData={formData?.oral_care}
          getData={getData}
        />
      ),
    },
    {
      key: "hair_care",
      label: "Hair Care",
      children: (
        <DailyRoutineFormTable
          type="hair_care"
          nurseData={formData?.hair_care}
          getData={getData}
        />
      ),
    },
    {
      key: "skin_care",
      label: "Skin Care",
      children: (
        <DailyRoutineFormTable
          type="skin_care"
          nurseData={formData?.skin_care}
          getData={getData}
        />
      ),
    },
    {
      key: "nail_care",
      label: "Nail Care",
      children: (
        <DailyRoutineFormTable
          type="nail_care"
          nurseData={formData?.nail_care}
          getData={getData}
        />
      ),
    },
    {
      key: "bowel",
      label: "Bowel",
      children: (
        <DailyRoutineFormTable
          type="bowel"
          nurseData={formData?.bowel}
          getData={getData}
        />
      ),
    },
  ];
  const onChange = (key) => {
    setActiveTab(key);
  };
  return (
    <>
      <Tabs defaultActiveKey="bath_sponge" items={items} onChange={onChange} />
    </>
  );
};

export default DailyRoutine;
