import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Pagination, Space, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import InPatientModal from "./InPatientModal";
import dayjs from "dayjs";

const IpPatientTable = ({ data, refreshIpPage, page, setPage }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  const columns = [
    { title: "Patient ID", key: "id", dataIndex: "id" },
    {
      title: "Full Name",
      dataIndex: "patient_name",
      key: "patient_name",
      render: (value) => capitalizeFirstLetter(value),
    },
    {
      title: "Date of Admission",
      key: "admission_date",
      dataIndex: "admission_date",
      render: (value) => dayjs(value)?.format("MMM DD,YYYY"),
    },
    { title: "Contact Number", key: "contact_no", dataIndex: "contact_no" },
    { title: "Consent Form", render: (value) => <Button>Upload</Button> },
    { title: "Discharge Summary", render: (value) => <Button>Upload</Button> },
    {
      title: "Bed Assignment",
      render: (value, record) => (
        <>
          {record?.bed_name ? (
            <Space>
              <Tag color="green" className={tagStyles.smRndTag}>
                Alloted{" "}
              </Tag>
              <Tag color="blue" className={tagStyles.smRndTag}>
                {record?.bed_name}
              </Tag>
            </Space>
          ) : (
            <Button
              onClick={() =>
                navigate(
                  `/receptionist/ip?patient=${record?.id}&type=bed-management`
                )
              }
            >
              Allot Bed
            </Button>
          )}
        </>
      ),
    },
    {
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() =>
              navigate(`/receptionist/ip?patient=${record?.id}&type=packages`)
            }
          >
            View Details
          </Button>
          <EditOutlined
            onClick={() => {
              setOpen(true);
              setEditData(record);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Table
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: "400px" }}
      />
      <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
        <Pagination
          current={page}
          pageSize={50}
          total={data?.total_pages}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </Col>
      {open && (
        <InPatientModal
          open={open}
          setOpen={setOpen}
          ipData={editData}
          refreshIpPage={refreshIpPage}
        />
      )}
    </div>
  );
};

export default IpPatientTable;
