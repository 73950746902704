export const cardiovascularOptions = [
  { label: "Heart Failure", value: "heart_failure" },
  { label: "Coronary Artery Disease", value: "coronary_artery_disease" },
  { label: "Others", value: "others" },
];

export const neurologicalExaminationOptions = [
  { label: "CVA-Cerebral Thrombosis", value: "cva-cerebral_thrombosis" },
  {
    label: "CVA-Intracerebral Hemorrhage",
    value: "cva-intracerebral_hemorrhage",
  },
  { label: "CVA-CSVT", value: "cva-csvt" },
  { label: "Subdural Hemorrhage", value: "subdural_hemorrhage" },
  { label: "Subarachnoid Hemorrhage", value: "subarachnoid_hemorrhage" },
  { label: "Head Injury", value: "head_injury" },
  { label: "Spine Injury", value: "spine_injury" },
  { label: "Parkinsons Disease", value: "parkinsons_disease" },
  { label: "Multiple Sclerosis", value: "multiple_sclerosis" },
  { label: "Motor Neuron Disease", value: "motor_neuron_disease" },
  { label: "GB Syndrome", value: "gb_syndrome" },
  { label: "Cerebellar Ataxia", value: "cerebellar_ataxia" },
  { label: "Peripheral Neuropathy", value: "peripheral_neuropathy" },
  { label: "Others", value: "others" },
];

export const orthopedicOptions = [
  { label: "Fractures", value: "fractures" },
  { label: "Joint Replacements", value: "joint_replacements" },
  { label: "Arthritis", value: "arthritis" },
];

export const respiratoryOptions = [
  { label: "Pneumonia", value: "pneumonia" },
  { label: "COPD", value: "copd" },
  { label: "Asthma", value: "asthma" },
  { label: "Interstitial Lung Disease", value: "interstitial_lung_disease" },
  { label: "Others", value: "others" },
];
export const traumaOptions = [
  { label: "Motor Vehicle Accident", value: "motor_vehicle_accident" },
  { label: "Accidental Fall", value: "accidental_fall" },
  { label: "Facial Trauma", value: "facial_trauma" },
  { label: "Others", value: "others" },
];
