import React from "react";
import { useLocation } from "react-router-dom";
import DoctorLayout from "../Layout/DoctorLayout";
import PatientsIP from "../PatientsIP/PatientsIP";
import NurseCards from "./NurseCards";

const NurseView = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));

  return (
    <DoctorLayout>
      {params?.patient ? <PatientsIP /> : <NurseCards />}
    </DoctorLayout>
  );
};

export default NurseView;
