import { Form, Radio, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { noYesOptions } from "../../../Options/commonOptions";

const RiskOfFall = ({ context }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState({
    previous_fall: 0,
    ambulation: 0,
    abnormal_gait: 0,
    sensory_deficit: 0,
    muscular_weakness: 0,
    age_greater_than_65: 0,
  });

  const handleStates = (value, target) => {
    let risk_of_fall = 0;
    if (value === "yes") {
      if (target === "abnormal_gait") {
        risk_of_fall = 20;
      } else if (target === "age_greater_than_65") {
        risk_of_fall = 15;
      } else if (
        target === "sensory_deficit" ||
        target === "muscular_weakness"
      ) {
        risk_of_fall = 10;
      } else {
        risk_of_fall = 25;
      }
    }
    setStates((prev) => ({ ...prev, [target]: risk_of_fall }));
  };

  useEffect(() => {
    const initialStates = {
      previous_fall:
        formData?.functional_status?.risk_of_fall?.previous_fall === "yes"
          ? 25
          : 0,
      ambulation:
        formData?.functional_status?.risk_of_fall?.needs_aids_for_ambulation ===
        "yes"
          ? 25
          : 0,
      abnormal_gait:
        formData?.functional_status?.risk_of_fall?.abnormal_gait === "yes"
          ? 20
          : 0,
      sensory_deficit:
        formData?.functional_status?.risk_of_fall?.sensory_deficit === "yes"
          ? 10
          : 0,
      muscular_weakness:
        formData?.functional_status?.risk_of_fall?.muscular_weakness === "yes"
          ? 10
          : 0,
      age_greater_than_65:
        formData?.functional_status?.risk_of_fall?.age_greater_than_65 === "yes"
          ? 15
          : 0,
    };
    setStates(initialStates);
  }, [formData]);

  const aggregate = () => {
    const total = Object.values(states).reduce((sum, value) => sum + value, 0);
    let risk_of_fall = "";
    if (total > 45) {
      risk_of_fall = "High Risk of Fall";
    } else if (total > 30) {
      risk_of_fall = "Moderate Risk of Fall";
    } else {
      risk_of_fall = "Low Risk of Fall";
    }
    return (
      <span>
        {total}: {risk_of_fall}
      </span>
    );
  };

  return (
    <>
      <Typography>Risk of Fall Balance Scale</Typography>
      <div className="subsection-card">
        <Typography>{aggregate()}</Typography>
        <Form.Item
          label="H/O Previous Fall"
          name={["risk_of_fall", "previous_fall"]}
        >
          <Radio.Group
            options={noYesOptions}
            onChange={(e) => {
              handleStates(e.target.value, "previous_fall");
            }}
          />
        </Form.Item>
        <Form.Item
          label="Needs aids for Ambulation"
          name={["risk_of_fall", "needs_aids_for_ambulation"]}
        >
          <Radio.Group
            options={noYesOptions}
            onChange={(e) => {
              handleStates(e.target.value, "ambulation");
            }}
          />
        </Form.Item>
        <Form.Item
          label="Abnormal Gait"
          name={["risk_of_fall", "abnormal_gait"]}
        >
          <Radio.Group
            options={noYesOptions}
            onChange={(e) => {
              handleStates(e.target.value, "abnormal_gait");
            }}
          />
        </Form.Item>
        <Form.Item
          label="Sensory Deficit"
          name={["risk_of_fall", "sensory_deficit"]}
        >
          <Radio.Group
            options={noYesOptions}
            onChange={(e) => {
              handleStates(e.target.value, "sensory_deficit");
            }}
          />
        </Form.Item>
        <Form.Item
          label="Muscular Weakness"
          name={["risk_of_fall", "muscular_weakness"]}
        >
          <Radio.Group
            options={noYesOptions}
            onChange={(e) => {
              handleStates(e.target.value, "muscular_weakness");
            }}
          />
        </Form.Item>
        <Form.Item
          label="Age > 65 years"
          name={["risk_of_fall", "age_greater_than_65"]}
        >
          <Radio.Group
            options={noYesOptions}
            onChange={(e) => {
              handleStates(e.target.value, "age_greater_than_65");
            }}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default RiskOfFall;
