import React, { useState } from "react";
import { Button, Card, List, Space, Row, Col, Typography } from "antd";
import { medicineFrequency } from "../../../helpers/medicineKeys";
import StatMedicineModal from "./StatMedicineModal";
import buttonStyle from "../../../styles/Custom/Button.module.css";
import dayjs from "dayjs";

const { Paragraph } = Typography;
const StatMedicines = ({ statData, refreshPage }) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  return (
    <Card title="Stat / SOS Medicines" style={{ marginBottom: "10px" }}>
      <List
        // bordered
        itemLayout="horizontal"
        dataSource={[...new Set(statData)]}
        renderItem={(item) => (
          <List.Item>
            <Button
              type="primary"
              ghost
              style={{
                borderColor:
                  item?.frequency === "Immediately" &&
                  item?.actions?.[0]?.processed !== true &&
                  "#FED7D7",
                color:
                  item?.frequency === "Immediately" &&
                  item?.actions?.[0]?.processed !== true &&
                  "#C64141",
              }}
              className={buttonStyle.mdBtn}
              disabled={
                item?.frequency === "Immediately" &&
                item?.actions?.[0]?.processed === true
              }
              onClick={() => handleOpenModal(item)}
            >
              {item?.name} -{" "}
              {
                medicineFrequency?.find((med) => med?.value === item?.frequency)
                  ?.label
              }
            </Button>
            {item?.frequency === "Immediately" &&
              item?.actions?.[0]?.processed === true && (
                <Space direction="vertical" style={{ marginLeft: "10px" }}>
                  <Typography>
                    {item?.actions?.[0]?.process_time &&
                      dayjs(item?.actions?.[0]?.process_time)?.format(
                        "hh:mm A"
                      )}
                  </Typography>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: "more",
                    }}
                    style={{ width: 100 }}
                  >
                    {item?.actions?.[0]?.remarks}
                  </Paragraph>
                </Space>
              )}
            <Row
              gutter={[16, 16]}
              style={{ width: "100%", marginLeft: "10px" }}
            >
              {item?.frequency === "If_Required" &&
                item?.actions?.[0]?.processed === true &&
                item?.actions?.[0]?.nurse_data?.map((nurseItem, index) => (
                  <Col xs={12} sm={12} md={6} lg={4}>
                    <Typography>
                      {dayjs(nurseItem?.process_time)?.format("hh:mm A")}
                    </Typography>
                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: "more",
                      }}
                      style={{ width: "100%" }}
                    >
                      {nurseItem?.remarks}
                    </Paragraph>
                  </Col>
                ))}
            </Row>

            {open && selectedItem?.name === item?.name && (
              <StatMedicineModal
                open={open}
                setOpen={handleCloseModal}
                frequency={selectedItem?.frequency}
                actions={selectedItem?.actions}
                refreshPage={refreshPage}
              />
            )}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default StatMedicines;
