import React, { useEffect } from "react";
import {
  List,
  Typography,
  Button,
  Space,
  Form,
  Input,
  Switch,
  message,
} from "antd";
import { EditOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { updateRoomTypeApi } from "../../../services/beds.services";
import inputStyles from "../../../styles/Custom/Input.module.css";

const RoomTypeList = ({
  editListData,
  refreshPage,
  refresh,
  editingId,
  setEditingId,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const editingItem = editListData?.find((item) => item.id === editingId);
    if (editingItem) {
      form.setFieldsValue(editingItem);
    }
  }, [editingId, form, editListData, refresh]);

  const onEdit = (id) => {
    setEditingId(id);
  };

  const onCancel = () => {
    setEditingId(null);
  };

  const onFinish = (values) => {
    updateRoomTypeApi(editingId, values)
      .then((res) => {
        message.success(res?.data?.message);
        refreshPage();
        setEditingId(null);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        console.log(err);
      });
  };

  return (
    <List
      dataSource={editListData}
      renderItem={(item) => (
        <List.Item
          actions={[
            editingId === item.id ? (
              <Space>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={() => form.submit()}
                />
                <Button onClick={onCancel} icon={<CloseOutlined />} />
              </Space>
            ) : (
              <EditOutlined onClick={() => onEdit(item.id)} />
            ),
          ]}
        >
          {editingId === item.id ? (
            <Form form={form} layout="horizontal" onFinish={onFinish}>
              <Space>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Enter room type" }]}
                >
                  <Input
                    placeholder="Enter room type"
                    className={inputStyles.mdInput}
                  />
                </Form.Item>
                <Form.Item name="ac" valuePropName="checked">
                  <Switch checkedChildren="AC" unCheckedChildren="Non AC" />
                </Form.Item>
              </Space>
            </Form>
          ) : (
            <Typography.Text>
              {item.name} {item.ac ? "AC" : "Non AC"}
            </Typography.Text>
          )}
        </List.Item>
      )}
    />
  );
};

export default RoomTypeList;
