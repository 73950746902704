import React, { useState } from "react";
import { Row, Col, Segmented } from "antd";
import { servicesKeys } from "../../../Receptionist/receptionKeys";
import ServicesIpTable from "./ServicesIpTable";
import AssignedServicesTable from "./AssignedServicesTable";

const ServicesTabs = () => {
  const [activeSegment, setActiveSegment] = useState("all_services");
  return (
    <Row>
      <Col span={24}>
        <Segmented
          size="large"
          options={servicesKeys?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))}
          value={activeSegment}
          onChange={(e) => setActiveSegment(e)}
        />
      </Col>
      <Col span={24}>
        {activeSegment === "all_services" && <ServicesIpTable />}
        {activeSegment === "assigned_services" && <AssignedServicesTable />}
      </Col>
    </Row>
  );
};

export default ServicesTabs;
