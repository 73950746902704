import React, { useContext } from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithDescription,
  getLabelTagsWithSelectItems,
} from "../../InspectionSummaries/constructTextFromValues";
import { Descriptions, Tag, Typography } from "antd";
import {
  abdomenAppearanceOptions,
  abnormalBreathZoneOptions,
  auscultationOptions,
  bowelMovementOptions,
  breathSoundsOptions,
  heartSoundsOptions,
  jvpOptions,
  periPulsesVolumeOptions,
} from "../../Options/systemicExamOptions";
import {
  presentAbsentOptions,
  regularIrregularOptions,
} from "../../Options/commonOptions";
import { DailyReviewContext } from "../../Doctor/DailyReview/DailyReviewFormProvider";

const DrSystemicExaminationSummaryDR = () => {
  const { formData } = useContext(DailyReviewContext);

  const constructCardioVascularSystemSummaryDRItems = () => {
    const cardioVascularSystemSummaryDRItems = [];

    if (
      formData?.systemic_examination?.peripheral_regular ||
      formData?.systemic_examination?.peripheral_volume ||
      formData?.systemic_examination?.peripheral_desc
    ) {
      cardioVascularSystemSummaryDRItems.push({
        key: "1",
        label: "Peripheral Pulses",
        children: (
          <>
            {getLabelTag(
              regularIrregularOptions,
              formData?.systemic_examination?.peripheral_regular
            )}
            {getLabelTag(
              periPulsesVolumeOptions,
              formData?.systemic_examination?.peripheral_volume
            )}

            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.peripheral_desc
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.jvp) {
      cardioVascularSystemSummaryDRItems.push({
        key: "2",
        label: "JVP",
        children: getLabelTag(jvpOptions, formData?.systemic_examination?.jvp),
      });
    }
    if (
      getLabelTagsWithDescription(
        heartSoundsOptions,
        formData?.systemic_examination
      )
    ) {
      cardioVascularSystemSummaryDRItems.push({
        key: "3",
        label: "Heart Sounds",
        children: getLabelTagsWithDescription(
          heartSoundsOptions,
          formData?.systemic_examination
        ),
      });
    }
    return cardioVascularSystemSummaryDRItems;
  };

  const constructRespiratorySystemSummaryDRItems = () => {
    const respiratorySystemSummaryDRItems = [];

    if (formData?.systemic_examination?.type_of_breathing) {
      respiratorySystemSummaryDRItems.push({
        key: "1",
        label: "Type of Breathing",
        children: (
          <>
            <Tag bordered={false} color="purple" key={"type_of_breathing"}>
              {formData?.systemic_examination?.type_of_breathing}
            </Tag>
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.auscultation) {
      respiratorySystemSummaryDRItems.push({
        key: "2",
        label: "Auscultation",
        children: getLabelTag(
          auscultationOptions,
          formData?.systemic_examination?.auscultation
        ),
      });
    }
    if (
      getLabelTagsWithSelectItems(
        abnormalBreathZoneOptions,
        breathSoundsOptions,
        formData?.systemic_examination
      )
    ) {
      respiratorySystemSummaryDRItems.push({
        key: "3",
        label: "Abnormality",
        children: getLabelTagsWithSelectItems(
          abnormalBreathZoneOptions,
          breathSoundsOptions,
          formData?.systemic_examination
        ),
      });
    }
    return respiratorySystemSummaryDRItems;
  };
  const constructAbdomenSummaryDRItems = () => {
    const abdomenSummaryDRItems = [];

    if (formData?.systemic_examination?.appearance) {
      abdomenSummaryDRItems.push({
        key: "1",
        label: "Appearance",
        children: getLabelTag(
          abdomenAppearanceOptions,
          formData?.systemic_examination?.appearance
        ),
      });
    }
    if (formData?.systemic_examination?.bowel_sounds) {
      abdomenSummaryDRItems.push({
        key: "2",
        label: "Bowel Sounds",
        children: getLabelTag(
          presentAbsentOptions,
          formData?.systemic_examination?.bowel_sounds
        ),
      });
    }
    if (formData?.systemic_examination?.last_bowel_movement) {
      abdomenSummaryDRItems.push({
        key: "3",
        label: "Bowel Movement",
        children: getLabelTag(
          bowelMovementOptions,
          formData?.systemic_examination?.bowel_movement
        ),
      });
    }
    if (formData?.systemic_examination?.last_bowel_movement) {
      abdomenSummaryDRItems.push({
        key: "4",
        label: "Last Bowel Moment",
        children: (
          <Tag bordered={false} color="purple" key={"last_bowel_movement"}>
            {formData?.systemic_examination?.last_bowel_movement}
          </Tag>
        ),
      });
    }

    return abdomenSummaryDRItems;
  };

  const constructMusculoSkeletalSummaryDRItems = () => {
    const musculoSkeletalSummaryDRItems = [];

    if (formData?.systemic_examination?.musculo_skeletal_data) {
      musculoSkeletalSummaryDRItems.push({
        key: "5",
        label: "Musculo Skeletal System",
        children: getLabelTagInput(
          "Details",
          formData?.systemic_examination?.musculo_skeletal_data
        ),
      });
    }
    return musculoSkeletalSummaryDRItems;
  };
  const constructCentralNervousSystemSummaryItems = () => {
    const centralNervousSystemSummaryDRItems = [];

    if (formData?.systemic_examination?.cns_data) {
      centralNervousSystemSummaryDRItems.push({
        key: "6",
        label: "Central Nervous System",
        children: getLabelTagInput(
          "Details",
          formData?.systemic_examination?.cns_data
        ),
      });
    }
    return centralNervousSystemSummaryDRItems;
  };
  const constructAmbulationSummaryDRItem = () => {
    const ambulationSummaryDRItem = [];

    if (formData?.systemic_examination?.ambulation_desc) {
      ambulationSummaryDRItem.push({
        key: "7",
        label: "Ambulation",
        children: getLabelTagInput(
          "Details",
          formData?.systemic_examination?.ambulation_desc
        ),
      });
    }
    return ambulationSummaryDRItem;
  };
  const cardioVascularSystemSummaryDRItems =
    constructCardioVascularSystemSummaryDRItems();
  const respiratorySystemSummaryDRItems =
    constructRespiratorySystemSummaryDRItems();
  const abdomenSummaryDRItems = constructAbdomenSummaryDRItems();
  const musculoSkeletalSummaryDRItems =
    constructMusculoSkeletalSummaryDRItems();
  const centralNervousSystemSummaryDRItems =
    constructCentralNervousSystemSummaryItems();
  const ambulationSummaryDRItems = constructAmbulationSummaryDRItem();

  return (
    <div>
      {cardioVascularSystemSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title="Cardio Vascular System"
          column={1}
          items={cardioVascularSystemSummaryDRItems}
        />
      )}
      {respiratorySystemSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title="Respiratory System"
          column={1}
          items={respiratorySystemSummaryDRItems}
        />
      )}
      {abdomenSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title="Abdomen"
          column={1}
          items={abdomenSummaryDRItems}
        />
      )}
      {musculoSkeletalSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title="Musculo Skeletal"
          column={1}
          items={musculoSkeletalSummaryDRItems}
        />
      )}
      {centralNervousSystemSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title="Central Nervous System"
          column={1}
          items={centralNervousSystemSummaryDRItems}
        />
      )}
      {ambulationSummaryDRItems?.length > 0 && (
        <Descriptions
          bordered
          title="Ambulation"
          column={1}
          items={ambulationSummaryDRItems}
        />
      )}
    </div>
  );
};

export default DrSystemicExaminationSummaryDR;
