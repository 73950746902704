import React, { useState } from "react";
import {
  Button,
  Form,
  Space,
  Input,
  Switch,
  InputNumber,
  Row,
  Col,
  Card,
  message,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { createBedApi, updateBedApi } from "../../../../services/beds.services";
import inputStyles from "../../../../styles/Custom/Input.module.css";

const Beds = ({ beds, refreshPage, roomId }) => {
  const [form] = Form.useForm();
  const [editingBed, setEditingBed] = useState(null); // To track the Bed being edited
  const [isAddingNewBed, setIsAddingNewBed] = useState(false); // Track whether to show the add form

  const handleEditBed = (bed) => {
    setIsAddingNewBed(false);
    setEditingBed(bed); // Set the current bed to edit
    form.setFieldsValue({ ...bed }); // Set form values to the bed being edited
  };

  const handleAddNewBed = () => {
    setIsAddingNewBed(true);
    setEditingBed(null);
    form.resetFields();
    form.setFieldsValue({ is_available: true });
  };

  const onFinish = (values) => {
    if (editingBed) {
      updateBedApi(editingBed?.bed_id, { ...values })
        .then((res) => {
          refreshPage();
          setEditingBed(null);
          message.success(res?.data?.message);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
          console.error(err);
        });
    } else {
      createBedApi({ ...values, room: roomId })
        .then((res) => {
          refreshPage();
          setIsAddingNewBed(false);
          message.success(res?.data?.message);
        })
        .catch((err) => {
          console.error(err);
          message?.error(err?.response?.data?.message);
        });
    }
  };

  const customForm = () => (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
    >
      <Space>
        <Form.Item
          label="Bed Number"
          name="bed_no"
          rules={[{ required: true, message: "Enter bed number" }]}
        >
          <Input
            placeholder="Enter bed number"
            className={inputStyles.mdInput}
            style={{ width: "200px" }}
          />
        </Form.Item>
        <Form.Item
          label="Cash Price"
          name="cash_price"
          rules={[{ required: true, message: "Enter price" }]}
        >
          <InputNumber
            min={0}
            placeholder="Cash Price"
            prefix="Rs"
            style={{ width: "200px" }}
            className={inputStyles.mdNumberInput}
          />
        </Form.Item>
      </Space>
      <br />
      <Space>
        <Form.Item
          label="Insurance Price"
          name="insurance_price"
          rules={[{ required: true, message: "Enter price" }]}
        >
          <InputNumber
            min={0}
            placeholder="Insurance Price"
            prefix="Rs"
            style={{ width: "200px" }}
            className={inputStyles.mdNumberInput}
          />
        </Form.Item>
        <Form.Item name="is_available" valuePropName="checked">
          <Switch checkedChildren="Active" unCheckedChildren="In active" />
        </Form.Item>
        <Button htmlType="submit" type="primary" icon={<CheckOutlined />} />
        <Button
          icon={<CloseOutlined />}
          onClick={() => {
            isAddingNewBed ? setIsAddingNewBed(false) : setEditingBed(false);
          }}
        />
      </Space>
    </Form>
  );

  return (
    <Row gutter={[12, 12]}>
      {beds?.map((bed, index) => (
        <Col span={8} key={index}>
          <Card>
            {editingBed?.bed_id === bed.bed_id ? (
              customForm()
            ) : (
              <>
                {bed.bed_no}
                <EditOutlined
                  style={{ marginLeft: 8 }}
                  onClick={() => handleEditBed(bed)}
                />
              </>
            )}
          </Card>
        </Col>
      ))}
      <Col span={24}>
        {isAddingNewBed ? (
          customForm()
        ) : (
          <Button type="primary" onClick={handleAddNewBed}>
            <PlusOutlined /> Bed
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default Beds;
