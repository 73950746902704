import React, { useContext, useState, useEffect } from "react";
import { Form, Radio, InputNumber } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";
import { respiratorySupportOptions } from "../../../../Options/genExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const RespiratorySupport = () => {
  const { formData } = useContext(DoctorInitialContext);
  const [showOptions, setShowOptions] = useState(
    formData?.general_examination?.respiratory_support === "yes"
  );
  const [supportType, setSupportType] = useState(
    formData?.general_examination?.respiratory_support_type
  );

  useEffect(() => {
    setShowOptions(
      formData?.general_examination?.respiratory_support === "yes"
    );
    setSupportType(formData?.general_examination?.respiratory_support_type);
  }, [formData]);

  const handleRespiratorySupportChange = (e) => {
    setShowOptions(e.target.value === "yes");
  };

  const handleSupportTypeChange = (value) => {
    setSupportType(value);
  };

  const getDynamicLabel = (type) => {
    if (type === "cpap_support") return "PS above PEEP";
    return "TV"; // Default label
  };

  return (
    <>
      <Form.Item
        name="respiratory_support"
        label="Respiratory Support"
        rules={[]}
      >
        <Radio.Group
          options={yesNoOptions}
          onChange={handleRespiratorySupportChange}
        />
      </Form.Item>

      {showOptions && (
        <Form.Item name="respiratory_support_type" rules={[]}>
          <Radio.Group
            options={respiratorySupportOptions.respiratorySupport}
            onChange={(e) => handleSupportTypeChange(e.target.value)}
          />
        </Form.Item>
      )}

      {showOptions && (
        <>
          {[
            "oxygen_nasal_prongs",
            "thermo_ventilator",
            "oxygen_hudson_mask",
            "oxygen_hi_flow_mask",
            "oxygen_rebreathing_mask",
          ].includes(supportType) && (
            <Form.Item
              name={`respiratory_support_type_${supportType}`}
              rules={[]}
            >
              <InputNumber addonAfter="Lts/Min" />
            </Form.Item>
          )}

          {supportType === "bipap_support" && (
            <>
              <Form.Item label="EPAP" name="bipap_epap" rules={[]}>
                <InputNumber addonAfter="Cm of H2O" />
              </Form.Item>
              <Form.Item label="IPAP" name="bipap_ipap" rules={[]}>
                <InputNumber addonAfter="Cm of H2O" />
              </Form.Item>
              <Form.Item label="RR" name="bipap_rr" rules={[]}>
                <InputNumber addonAfter="/min" />
              </Form.Item>
              <Form.Item label="FiO2" name="bipap_fio2" rules={[]}>
                <InputNumber addonAfter="%" />
              </Form.Item>
            </>
          )}
          {supportType === "mechanical_ventilation" && (
            <Form.Item
              label="Mode"
              name="mechanical_ventilation_mode"
              rules={[]}
            >
              <Radio.Group
                options={respiratorySupportOptions.mechanicalVentilation}
              />
            </Form.Item>
          )}
          {/* Add similar blocks for "cpap_support" and "mechanical_ventilation" */}
          {["cpap_support", "mechanical_ventilation"].includes(supportType) && (
            <>
              <Form.Item label="PEEP" name={`${supportType}_peep`} rules={[]}>
                <InputNumber addonAfter="Cm of H2O" />
              </Form.Item>
              <Form.Item
                label={getDynamicLabel(supportType)}
                name={`${supportType}_ps_peep`} /**Add the label to the name */
                rules={[]}
              >
                <InputNumber addonAfter="Cm of H2O" />
              </Form.Item>
              <Form.Item label="RR" name={`${supportType}_rr`} rules={[]}>
                <InputNumber addonAfter="/min" />
              </Form.Item>
              <Form.Item label="FiO2" name={`${supportType}_fio2`} rules={[]}>
                <InputNumber addonAfter="%" />
              </Form.Item>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RespiratorySupport;
