import axios from "axios";
import {
  clear,
  clearAllCookies,
  extractDomainFromUrl,
  getCookie,
  setCookie,
  setItem,
} from "../helpers/localStorage";

// import customToast from "../blocks/CustomToast";
import { redirectToDashboard } from "../helpers/auth/helper";
import {
  ACCESS_TOKEN,
  ACTIVEDOMAIN,
  ORGANISATONID,
  REFRESH_TOKEN,
} from "../constants/defaultKeys";
import { message } from "antd";

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use((config) => {
  const token = getCookie(ACCESS_TOKEN);

  const Organization = getCookie(ORGANISATONID);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (Organization) {
    config.headers.Organization = Organization;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const refreshTokenApi = "accounts/refresh-token/";
    const originalRequest = error?.config;
    // const errorCode = error?.response?.data?.code;
    const errorMessage =
      error?.response?.data?.detail?.code || error?.response?.data?.code;
    const errorStatusCode = error?.response?.status;
    const tokenInvalid = "token_not_valid";
    const accountNotFound = "UNAUTHORIZED_USER";

    // Prevent infinite loops
    if (errorStatusCode === 401 && originalRequest.url === refreshTokenApi) {
      clear();
      clearAllCookies();
      window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/`;
      return Promise.reject(error);
    }
    // if (errorStatusCode === 403) {
    //   redirectToDashboard(getCookie(ACTIVEDOMAIN));
    //   error?.response?.data?.message &&
    //     setItem("customError", error?.response?.data?.message);
    // }

    //Invalid credentials or user not exist
    if (errorMessage === accountNotFound && errorStatusCode === 401) {
      clear();
      window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
    }

    //triggers when user session is expired
    if (errorMessage === tokenInvalid && errorStatusCode === 401) {
      const refreshToken = getCookie(REFRESH_TOKEN);
      const mainDomainUrl = process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL;
      const mainDomain = extractDomainFromUrl(mainDomainUrl);
      if (refreshToken) {
        const regex = new RegExp(
          "^[A-Za-z0-9-_=]+.[A-Za-z0-9-_=]+.?[A-Za-z0-9-_.+/=]*$"
        );

        if (regex.test(refreshToken)) {
          const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);

          //triggers if refresh token is not expired
          if (tokenParts.exp > now) {
            return axios
              .post(`${process.env.REACT_APP_API_BASE_URL}${refreshTokenApi}`, {
                refresh: refreshToken,
              })
              .then((response) => {
                setCookie(
                  ACCESS_TOKEN,
                  response?.data?.access,
                  `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                  "/"
                );
                return axiosInstance(originalRequest);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            clear();
            window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
            message.error("Your session has been expired, please login again");
          }
        } else {
          clear();
          window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
        }
      } else {
        clear();
        window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
        message.error("Your session has been expired, please login again");
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
