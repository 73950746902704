import React, { useContext, useEffect, useState } from "react";
import { Form, Divider, Button, message } from "antd";
import Ambulation from "./Ambulation";
import BowelIncontinence from "./BowelIncontinence";
import BladderIncontinence from "./BladderIncontinence";
import { DoctorInitialContext } from "../../HistoryForm/DoctorHistoryFormProvider";

const FunctionalExaminationForm = ({ form }) => {
  const { formData } = useContext(DoctorInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.functional_examination);
  }, [formData]);
  return (
    <>
      {/* <Form className="ip-forms" layout="vertical"> */}
      <Ambulation context={DoctorInitialContext} />
      <Divider />
      <BowelIncontinence />
      <Divider />
      <BladderIncontinence />
      {/* <Button type="primary" htmlType="submit">
        Save & Proceed
      </Button> */}
      {/* </Form> */}
    </>
  );
};

export default FunctionalExaminationForm;
