import React, { Suspense, useEffect, useState, useMemo, lazy } from "react";
import { Badge, Col, Image, Radio, Row, Spin } from "antd";
import useQuery from "../../../../hooks/useQuery";
import { nurseCareItemsList } from "../../Options/careItemsOptions";
import { getNurseCommonData } from "../../../../services/nurse.services";
import dayjs from "dayjs";
import DailyRoutine from "./DailyRoutine/DailyRoutine";
// Dynamic import map
const componentMap = {
  nebulization: lazy(() => import("./Nebulisation")),
  iv_fluids: lazy(() => import("./IvFluids")),
  blood_products: lazy(() => import("./BloodProducts")),
  parental_nutrition: lazy(() => import("./ParentalNutrition")),
  respiratory_care: lazy(() => import("./RespiratoryCare/RespiratoryCare")),
  urinary_catheter_care: lazy(() => import("./UrinaryCatheter")),
  open_wound_care: lazy(() => import("./WoundCare/WoundCare")),
  preventive_care: lazy(() => import("./PreventiveCare/PreventiveCare")),
  eye_care: lazy(() => import("./EyeCare/EyeCare")),
  feeding_tube: lazy(() => import("./FeedingTube")),
  pressure_sore_care: lazy(() => import("./PressureSore/PressureSore")),
  ostomy_care: lazy(() => import("./Ostomy")),
};
const dateFormat = "YYYY-MM-DD";
function CareItemTabs({ isSpecialArea = false }) {
  const curDate = dayjs().format(dateFormat);
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [activeRadio, setActiveRadio] = useState("nebulization");
  const [dailyData, setDailyData] = useState({});
  async function getData() {
    try {
      const response = await getNurseCommonData(
        query?.patient,
        `care_items`,
        curDate
      );

      setFormData(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function getdailyCareData() {
    try {
      const response = await getNurseCommonData(
        query?.patient,
        "daily_routine_care",
        curDate
      );

      setDailyData(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (query?.patient) getData();
    {
      activeRadio === "daily_routine_care" && getdailyCareData();
    }
  }, [query?.patient, activeRadio]);

  const ActiveComponent = useMemo(
    () => componentMap[activeRadio] || componentMap["nebulization"],
    [activeRadio]
  );

  function imageLoader(name) {
    try {
      return name === activeRadio
        ? require(`../../../../assets/careItemIcons/${name}_light.png`)
        : require(`../../../../assets/careItemIcons/${name}.png`);
    } catch (error) {
      console.error(`Image not found: ${name}`, error);
      return null;
    }
  }

  const getFormDataValue = () => {
    if (formData[activeRadio]) return formData[activeRadio];

    if (Object.keys(formData).some((item) => item.startsWith(activeRadio)))
      return formData;

    return undefined;
  };

  return (
    <Row>
      <Col span={24} style={{ marginTop: "20px" }}>
        <Radio.Group
          onChange={(e) => setActiveRadio(e.target.value)}
          buttonStyle="solid"
          defaultValue={activeRadio}
        >
          {nurseCareItemsList.map(({ label, key }) => (
            <Radio.Button
              key={key}
              value={key}
              style={{ marginBottom: "10px" }}
            >
              {label}
              <Image
                preview={false}
                src={imageLoader(key)}
                style={{ height: "20px", width: "auto", marginBottom: "8px" }}
              />
              {Object.keys(formData).some((item) => item.startsWith(key)) && (
                <Badge
                  color="green"
                  status="processing"
                  className="custom-badge-size"
                  style={{
                    position: "absolute",
                    top: -10,
                    right: 3,
                  }}
                />
              )}
            </Radio.Button>
          ))}
          <Radio.Button value="daily_routine_care" key="daily_routine_care">
            Daily Routine
            <Image
              preview={false}
              src={imageLoader("daily_routine_care")}
              style={{ height: "18px", width: "auto", marginBottom: "4px" }}
            />
            <Badge
              color="green"
              status="processing"
              className="custom-badge-size"
              style={{
                position: "absolute",
                top: -10,
                right: 3,
              }}
            />
          </Radio.Button>
        </Radio.Group>
      </Col>
      <Col span={24} style={{ marginTop: "20px" }}>
        {activeRadio === "daily_routine_care" ? (
          <DailyRoutine formData={dailyData} getData={getdailyCareData} />
        ) : (
          <Suspense fallback={<Spin />}>
            {!loading && (
              <ActiveComponent
                formData={getFormDataValue()}
                getData={getData}
                isSpecialArea={isSpecialArea}
                activeRadio={activeRadio}
              />
            )}
          </Suspense>
        )}
      </Col>
    </Row>
  );
}

export default CareItemTabs;
