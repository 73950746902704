import { Modal, Timeline } from "antd";

function NurseData({ nurseModalStatus, setNurseModalStatus, data }) {
  return (
    <Modal
      footer={false}
      title="Nurse Data"
      open={nurseModalStatus}
      onCancel={() => setNurseModalStatus(false)}
    >
      <Timeline
        items={[
          { children: "Create a services site 2015-09-01" },
          { children: "Solve initial network problems 2015-09-01" },
          { children: "Technical testing 2015-09-01" },
          { children: "Network problems being solved 2015-09-01" },
        ]}
      />
    </Modal>
  );
}

export default NurseData;
