import React, { useContext, useState, useEffect, useMemo } from "react";
import { Form, Checkbox, Input, Typography } from "antd";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import { edemaOptions } from "../../../../Options/genExamOptions";
const { Text } = Typography;
const Edema = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState({});
  const initialState = edemaOptions.reduce(
    (state, checkbox) => ({
      ...state,
      [checkbox.value]:
        initialValues?.general_examination?.[checkbox.value] === true
          ? true
          : false,
    }),
    {}
  );
  const [state, setState] = useState(initialState);

  const handleChange = (value, checked) => {
    setState((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Use useEffect to initialize checkboxes state based on initialValues
  useEffect(() => {
    const newState = edemaOptions.reduce(
      (newState, checkbox) => ({
        ...newState,
        [checkbox.value]:
          initialValues?.general_examination?.[checkbox.value] === true
            ? true
            : false,
      }),
      {}
    );
    setState(newState);

    edemaOptions.forEach(({ value }) => {
      setCheckboxes((prev) => {
        const res = getMoreEdemaOptions(value).reduce((acc, option) => {
          acc[option.value] = !!getInitialValuesForChild(
            getMoreEdemaOptions(value),
            initialValues?.general_examination || {}
          )[option.value];
          return acc;
        }, {});

        return { ...res, ...prev };
      });
    });
  }, [initialValues]); // Dependency array for re-initialization

  const getMoreEdemaOptions = (value) => [
    { label: "Pitting", value: `${value}_pitting` },
    { label: "Non Pitting", value: `${value}_non_pitting` },
  ];

  return (
    <>
      <Text>Edema</Text>
      {edemaOptions.map((checkbox) => (
        <>
          <Form.Item
            key={checkbox.value}
            valuePropName="checked"
            name={checkbox.value}
          >
            <Checkbox
              onChange={(e) => handleChange(checkbox.value, e.target.checked)}
              checked={state[checkbox.value]}
            >
              {checkbox.label}
            </Checkbox>
          </Form.Item>
          {state[checkbox.value] &&
            checkbox.value !== "none" &&
            checkbox.value !== "sacral" &&
            (checkbox.value === "edema_others" ? (
              <Form.Item name="edema_others_text">
                <Input placeholder="Please specify" />
              </Form.Item>
            ) : (
              <div className="subsection-card">
                <CheckListFBYInput
                  options={getMoreEdemaOptions(checkbox.value)}
                  checkboxes={checkboxes}
                  setCheckboxes={setCheckboxes}
                />
              </div>
            ))}
        </>
      ))}
    </>
  );
};

export default Edema;
