import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Select,
  Typography,
  DatePicker,
  Button,
  Radio,
  message,
  Tag,
} from "antd";
import dayjs from "dayjs";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import PackageTable from "../../../Receptionist/Billing/PackageTable";
import PatientPackageDetails from "./PatientPackageDetails";
import { getPackages } from "../../../../services/service.services";
import { assignPackageApi } from "../../../../services/receptionist.services";
import useQuery from "../../../../hooks/useQuery";

const { Option } = Select;

const AssignPackages = () => {
  const params = useQuery();
  const [form] = Form.useForm();
  const [packages, setPackages] = useState([]);
  const [selectPackageId, setSelectPackageId] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("₹");
  const [totalPrice, setTotalPrice] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const refreshPackage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFinish = (values) => {
    let discountInRupees = discount;

    if (discountType === "%") {
      discountInRupees = (discount / 100) * totalPrice;
    }

    const formData = {
      start_date: dayjs(values?.start_date).format("YYYY-MM-DD"),
      package: values?.package,
      discount: discountInRupees,
    };

    assignPackageApi(params?.patient, formData)
      .then((res) => {
        message.success(res?.data?.message);
        form.resetFields();
        form.setFieldValue("discount", "");
        refreshPackage();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  const onDiscountTypeChange = (value) => {
    setDiscountType(value);
    setDiscount("");
    form.setFieldValue("discount", "");
    form.setFields([{ name: "discount", errors: [] }]);
  };

  return (
    <>
      <Card title="Assign Package" className="care-item-card">
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{
            start_date: dayjs(), // Ensure start_date is a dayjs object
          }}
        >
          <Form.Item
            label="Package Name"
            name="package"
            rules={[{ required: true, message: "Please select a package" }]}
          >
            <Select
              className={inputStyles.mdSelect}
              style={{ width: "300px" }}
              onChange={(value, children) => {
                setSelectPackageId(value);
                setTotalPrice(children?.children[1]?.props?.children);
              }}
            >
              {packages?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                  <Tag>{item.total_price}</Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectPackageId && (
            <>
              {!viewDetails && (
                <Typography.Link onClick={() => setViewDetails(true)}>
                  View More Details
                </Typography.Link>
              )}

              {viewDetails && (
                <PackageTable
                  selectPackageId={selectPackageId}
                  setSelectPackageId={setSelectPackageId}
                  setViewDetails={setViewDetails}
                  viewDetails={viewDetails}
                />
              )}
            </>
          )}

          <Form.Item
            name="discount"
            label="Discount"
            initialValue={discount ?? null}
            rules={[
              {
                validator: (_, value, callback) => {
                  if (value && value !== "") {
                    if (/^[0-9]*\.?[0-9]{1,3}$/.test(value)) {
                      if (discountType === "%" && Number(value) > 100) {
                        callback("Discount cannot be greater than 100%");
                      } else if (
                        discountType === "₹" &&
                        Number(value) > Number(totalPrice)
                      ) {
                        callback(
                          "Discount cannot be greater than total amount"
                        );
                      } else {
                        callback();
                      }
                    } else {
                      callback("Please enter a valid discount");
                    }
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input
              placeholder="Enter discount"
              size="large"
              className={inputStyles.discountInput}
              addonAfter={
                <Radio.Group
                  style={{ height: "38px" }}
                  onChange={(e) => onDiscountTypeChange(e.target.value)}
                  defaultValue={"₹"}
                  value={discountType}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio.Button
                    style={{
                      height: "38px",
                      borderRadius: "0px",
                      border: "0px",
                    }}
                    value="%"
                  >
                    %
                  </Radio.Button>
                  <Radio.Button
                    style={{
                      height: "38px",
                      border: "0px",
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                    }}
                    value="₹"
                  >
                    ₹
                  </Radio.Button>
                </Radio.Group>
              }
              value={discount}
              onChange={(e) => {
                let value = e?.target?.value;
                setDiscount(value);
              }}
              style={{ width: "40%" }}
            />
          </Form.Item>

          <Form.Item label="Date" name="start_date">
            <DatePicker
              allowClear={false}
              defaultValue={dayjs()} // Correct initialization
              className={inputStyles.mdDateInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              className={btnStyles.mdBtn}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <PatientPackageDetails refresh={refresh} />
    </>
  );
};

export default AssignPackages;
