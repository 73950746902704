import React from "react";
import { Form, Input } from "antd";

const MusculoSkeletalDetails = () => {
  return (
    <>
      <Form.Item name="musculo_skeletal_data">
        <Input.TextArea placeholder="Enter Musculo Skeletal Data" />
      </Form.Item>
    </>
  );
};

export default MusculoSkeletalDetails;
