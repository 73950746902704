import React, { useContext, useEffect } from "react";
import AirWay from "./AirWay";
import { Divider } from "antd";
import VentilationStatus from "./VentilationStatus";
import RespiratoryPattern from "./RespiratoryPattern";
import RespiratoryMentalStatus from "./RespiratoryMentalStatus";
import ChestExpansion from "./ChestExpansion";
import BreathSounds from "./BreathSounds";
import CoughEffectiveness from "./CoughEffectiveness";
import ActivityLevel from "./ActivityLevel";
import Oxygen from "./Oxygen";
import Cough from "./Cough";
import TrachealSecretions from "./TrachealSecretions";
import TracheostomyTube from "./TracheostomyTube";

const RespiratoryPatientAssessment = ({ review = false, context, form }) => {
  const { formData } = useContext(context);
  useEffect(() => {
    form.setFieldsValue(formData?.patient_assessment);
  }, [formData]);
  return review ? (
    <>
      <RespiratoryPattern />
      <Divider />
      <BreathSounds />
      <Divider />
      <Cough />
      <Divider />
      <Oxygen />
      <Divider />
      <TrachealSecretions />
      <Divider />
      <TracheostomyTube />
    </>
  ) : (
    <>
      <AirWay />
      <Divider />
      <VentilationStatus />
      <Divider />
      <RespiratoryPattern />
      <Divider />
      <RespiratoryMentalStatus />
      <Divider />
      <ChestExpansion />
      <Divider />
      <BreathSounds />
      <Divider />
      <CoughEffectiveness />
      <Divider />
      <ActivityLevel />
      <Divider />
      <Oxygen />
      <Divider />
      <Cough />
    </>
  );
};

export default RespiratoryPatientAssessment;
