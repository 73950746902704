import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  message,
  InputNumber,
} from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { CloseOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { validateConfig } from "../../../config/validate.config";
import {
  addAppointmentApi,
  getPatientDetailsApi,
} from "../../../services/receptionist.services";
import PatientMobileSearch from "../PatientMobileSearch";
import AppointmentFormList from "./AppointmentFormList";
import dayjs from "dayjs";

const AppointsModal = ({ open, setOpen, refresh, editData }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [facilityId, setFacilityId] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedEndSlot, setSelectedEndSlot] = useState(null);

  useEffect(() => {
    if (selectedId || editData?.id) {
      getPatientDetailsApi(selectedId || editData?.id)
        .then((res) => {
          form?.setFieldsValue({
            ...res?.data,
            patient_first_name: res?.data?.first_name,
            patient_last_name: res?.data?.last_name,
            patient_age: res?.data?.age,
            patient_mobile: res?.data?.mobile,
            patient: res?.data?.id,
          });
          setFacilityId(res?.data?.facility);
          setSelectedId(res?.data?.id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedId, editData?.id]);

  const onFinish = (values) => {
    setLoading(true);
    const { name, ...formData } = values;
    formData.appointment_date = dayjs(values?.appointment_date).format(
      "YYYY-MM-DD"
    );
    formData.doctor = selectedPractitioner;
    formData.appointment_time = selectedSlot;
    formData.appointment_end_time = selectedEndSlot;
    formData.facility = facilityId;
    formData.patient = selectedId;
    formData.patient_mobile = values?.patient_mobile;
    console.log(formData);
    addAppointmentApi(formData)
      .then((res) => {
        message.success(res?.data?.message);
        setOpen(false);
        setLoading(false);
        refresh();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const onValuesChange = (changedValues, values) => {
    setFormValues({ ...values, ...changedValues });
  };

  return (
    <div>
      <Drawer
        width={"40%"}
        open={open}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                Add Appointment
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined onClick={() => setOpen(false)} />
            </Col>
          </Row>
        }
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        }
        onClose={() => setOpen(false)}
        closeIcon={null}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          form={form}
          ref={formRef}
          onValuesChange={onValuesChange}
        >
          <PatientMobileSearch
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            searchedText={searchedText}
            setSearchedText={setSearchedText}
            form={form}
            appointment={true}
            id={editData?.id}
          />
          <div className="flex-between">
            <Form.Item
              label="First Name"
              name="patient_first_name"
              rules={validateConfig.isRequired("First Name")}
            >
              <Input
                className={inputStyles.mdInput}
                style={{ width: "300px" }}
                placeholder="Enter first name"
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="patient_last_name"
              // rules={validateConfig.isRequired("Last Name")}
            >
              <Input
                className={inputStyles.mdInput}
                style={{ width: "300px" }}
                placeholder="Enter first name"
              />
            </Form.Item>
          </div>

          <Form.Item
            label="Age"
            name="patient_age"
            rules={validateConfig.isRequired("Age")}
          >
            <InputNumber
              placeholder="Enter age"
              className={inputStyles.mdNumberInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <AppointmentFormList
            facilityId={facilityId}
            setFacilityId={setFacilityId}
            formValues={formValues}
            selectedPractitioner={selectedPractitioner}
            setSelectedPractitioner={setSelectedPractitioner}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            selectedEndSlot={selectedEndSlot}
            setSelectedEndSlot={setSelectedEndSlot}
            selectedId={selectedId}
          />
        </Form>
      </Drawer>
    </div>
  );
};

export default AppointsModal;
