import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App"; // Assuming App handles routing

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App /> {/* Assuming App handles routing */}
  </StrictMode>
);
