import React from "react";
import MentalStatus from "./MentalStatus";
import CranialNerves from "./CranialNerves";
import SensorySystem from "./SensorySystem";
import MotorSystem from "./MotorSystem";
import Coordination from "./Coordination";
import Gait from "./Gait";
import MeningealSigns from "./MeningealSigns";
import CNSDetail from "./CNSDetail";
import { Form } from "antd";
import ReflexesForm from "./ReflexesForm";
import BergScale from "../../PhysioScales/BergScale";
import TCMScale from "../../PhysioScales/TCMScale";
import Posture from "./Posture";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const CNSForm = ({ context, dailyReview = false }) => {
  return (
    <>
      {dailyReview ? (
        <CNSDetail />
      ) : (
        <>
          {context ? (
            <>
              <MentalStatus context={context} />
              <Gait context={context} />
              <Posture />
              <BergScale context={context} />
              <CranialNerves context={context} />
              <SensorySystem context={context} />
              <MotorSystem context={context} />
              <ReflexesForm />
              <Coordination context={context} />
              <TCMScale context={context} />
            </>
          ) : (
            <>
              <MentalStatus context={DoctorInitialContext} />
              <CranialNerves context={DoctorInitialContext} />
              <SensorySystem context={DoctorInitialContext} />
              <MotorSystem context={DoctorInitialContext} />
              <ReflexesForm />
              <Coordination context={DoctorInitialContext} />
              <Gait context={DoctorInitialContext} />
              <MeningealSigns />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CNSForm;
