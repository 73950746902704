import React, { useState, useEffect } from "react";
import { Form, Button, Typography, Divider, message, Slider } from "antd";
import {
  getMocaScaleAPI,
  mocaScaleAPI,
  updateMocaScaleAPI,
} from "../../../../../services/physio.services";
import Visual from "./MOCAScaleComponents/Visual";
import Naming from "./MOCAScaleComponents/Naming";
import Memory from "./MOCAScaleComponents/Memory";
import Attention from "./MOCAScaleComponents/Attention";
import Language from "./MOCAScaleComponents/Language";
import Abstraction from "./MOCAScaleComponents/Abstraction";
import DelayedRecall from "./MOCAScaleComponents/DelayedRecall";
import Orientation from "./MOCAScaleComponents/Orientation";

const calculateAggregate = (fields, values, specialCase, nestedKey) => {
  return fields.reduce((acc, field) => {
    let value;
    if (nestedKey) {
      value = values[nestedKey]?.[field.value] ?? values[nestedKey]?.[field];
    } else {
      value = values[field.value] ?? values[field];
    }

    if (specialCase && field.value === "attention_4") {
      const count = value?.length || 0;
      if (count >= 4) {
        return acc + 3;
      } else if (count >= 2) {
        return acc + 2;
      } else {
        return acc + count;
      }
    }
    return acc + (value === "yes" ? 1 : 0);
  }, 0);
};

const MOCAScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [states, setStates] = useState({});

  const steps = [
    <Visual />,
    <Naming />,
    <Memory />,
    <Attention />,
    <Language />,
    <Abstraction />,
    <DelayedRecall />,
    <Orientation />,
  ];

  const marks = {
    0: "Visual/Executive",
    1: "Naming",
    2: "Memory",
    3: "Attention",
    4: "Language",
    5: "Abstraction",
    6: "Delayed Recall",
    7: "Orientation",
  };

  const onValuesChange = (changedValues, allValues) => {
    const visualFields = [
      "begin_end",
      "copy_cube",
      "contour",
      "numbers",
      "hands",
    ];
    const namingFields = ["lion", "hippo", "camel"];
    const memoryFields = ["face", "velvet", "church", "daisy", "red"];
    const attentionFields = [
      { value: "attention_1" },
      { value: "attention_2" },
      { value: "attention_3" },
      { value: "attention_4" },
    ];
    const languageFields = [
      { value: "language_1" },
      { value: "language_2" },
      { value: "language_3" },
    ];
    const abstractionFields = [
      { value: "abstraction_1" },
      { value: "abstraction_2" },
    ];
    const orientationFields = ["date", "month", "year", "day", "place", "city"];

    const newStates = {};

    if (allValues.hasOwnProperty("visual_executive_data")) {
      newStates.visual_executive = calculateAggregate(
        visualFields,
        allValues,
        false,
        "visual_executive_data"
      );
    }

    if (allValues.hasOwnProperty("naming_data")) {
      newStates.naming = calculateAggregate(
        namingFields,
        allValues,
        false,
        "naming_data"
      );
    }

    if (allValues.hasOwnProperty("attention_data")) {
      newStates.attention = calculateAggregate(
        attentionFields,
        allValues,
        true,
        "attention_data"
      );
    }

    if (allValues.hasOwnProperty("language_data")) {
      newStates.language = calculateAggregate(
        languageFields,
        allValues,
        false,
        "language_data"
      );
    }

    if (allValues.hasOwnProperty("abstraction_data")) {
      newStates.abstraction = calculateAggregate(
        abstractionFields,
        allValues,
        false,
        "abstraction_data"
      );
    }

    if (allValues.hasOwnProperty("delayed_recall_data")) {
      newStates.delayed_recall = calculateAggregate(
        memoryFields,
        allValues,
        false,
        "delayed_recall_data"
      );
    }

    if (allValues.hasOwnProperty("orientation_data")) {
      newStates.orientation = calculateAggregate(
        orientationFields,
        allValues,
        false,
        "orientation_data"
      );
    }

    // Update states only when the relevant keys are present
    setStates((prevStates) => ({
      ...prevStates,
      ...newStates,
    }));
  };

  const handleSubmit = async (values) => {
    console.log("moca values", values);
    const finalData = {
      ...states,
      ...values,
      ip_admission: admission,
    };
    console.log("moca final", finalData);

    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateMocaScaleAPI(scalesId?.moca, finalData);
      } else {
        const response = await mocaScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, moca: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      message.success("Form filled successfully");
      if (currentStep === 7) {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else {
        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
      }
    } catch (error) {
      console.log(`Error:${error}`);
      // message.error("Failed to load data");
    }
  };

  const handleView = () => {
    if (scalesId?.moca) {
      getMocaScaleAPI(admission, scalesId?.moca)
        .then((response) => {
          const data = response.data;
          setFormData(data);
          form.setFieldsValue(data);
          setStates({
            visual_executive: data?.visual_executive,
            naming: data?.naming,
            memory: data?.memory,
            attention: data?.attention,
            language: data?.language,
            abstraction: data?.abstraction,
            delayed_recall: data?.delayed_recall,
            orintation: data?.orientation,
          });
        })
        .catch((error) => {
          console.log(`Error:${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission, currentStep]);

  console.log("moca states", states);
  const aggregate = Object.values(states).reduce((acc, cur) => acc + cur, 0);

  return (
    <>
      <Typography.Text className="score-counter">
        {aggregate}
        <br />
        —
        <br />
        30
        <div className="score-string">
          {aggregate >= 26 ? "Normal Cognition" : "Abnormal Cognition"}
        </div>
      </Typography.Text>
      {/* <Divider /> */}

      <div className="cardless-scale" style={{ height: "70vh" }}>
        <Slider
          min={0}
          max={steps.length - 1}
          marks={marks}
          value={currentStep}
          style={{ width: "90%" }}
          disabled
        />
        <br />
        <br />
        {/* <Card style={{ width: "50%" }}> */}
        <Form
          id="moca-scale"
          form={form}
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
        >
          {steps[currentStep]}{" "}
          {/* Display the component based on the current step */}
          <Divider />
        </Form>
        {/* </Card> */}
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default MOCAScale;
