import React, { useContext, useEffect, useState } from "react";
import { Col, Segmented, Row, Button, Form, message } from "antd";
import DoctorLayout from "../../Layout/DoctorLayout";
import btnStyles from "../../../styles/Custom/Button.module.css";
import MySlots from "./MySlots";
import {
  doctorAppointmentsApi,
  doctorUpdateAppointmentsApi,
  getDoctorAppointmentsApi,
} from "../../../services/doctor.services";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import AppointmentsView from "./AppointmentsView";
import dayjs from "dayjs";

const items = ["My Appointments", "My Slots"];
const DoctorAppointments = () => {
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const [appointmentsData, setAppointmentsData] = useState(null);
  const [timeSlots, setTimeSlots] = useState({
    Monday: [1],
    Tuesday: [1],
    Wednesday: [1],
    Thursday: [1],
    Friday: [1],
    Saturday: [1],
    Sunday: [1],
  });
  const [activeSegment, setActiveSegment] = useState("My Appointments");

  useEffect(() => {
    getDoctorAppointmentsApi(
      `facility=${
        getCookie(FacilityID) || userDetails?.currFacility
      }&doctor_id=${userDetails?.id}`
    )
      .then((res) => {
        const availabilityTimings = res?.data?.data?.availability_timings;

        // Transform the availability_timings into the required format
        const formattedTimings = Object?.keys(availabilityTimings)?.reduce(
          (acc, day) => {
            acc[day] = {
              availability: availabilityTimings[day]?.availability,

              availability_timings: availabilityTimings[
                day
              ].availability_timings?.map((range) =>
                range
                  ? [dayjs(range[0], "HH:mm"), dayjs(range[1], "HH:mm")]
                  : []
              ),
            };
            return acc;
          },
          {}
        );

        // Set the form values
        form.setFieldsValue({
          ...formattedTimings,
          slot_duration: res?.data?.data?.slot_duration,
        });

        setAppointmentsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChange = (e) => {
    setActiveSegment(e);
  };

  const addTimeSlot = (day) => {
    setTimeSlots({
      ...timeSlots,
      [day]: [...timeSlots[day], timeSlots[day].length + 1],
    });
  };

  const onFinish = (values) => {
    const formattedValues = Object.keys(values).reduce((acc, day) => {
      const availability = values[day]?.availability;

      // Map timings if availability is true, otherwise set an empty array
      const timings = availability
        ? values[day].availability_timings?.map((range) =>
            range
              ? [
                  range[0].format("HH:mm"), // Start time
                  range[1].format("HH:mm"), // End time
                ]
              : ""
          )
        : [];

      // Always include both availability and availability_timings (even empty array)
      acc[day] = {
        availability,
        availability_timings: timings || [],
      };

      return acc;
    }, {});
    if (appointmentsData && appointmentsData?.availability_timings) {
      doctorUpdateAppointmentsApi(appointmentsData?.id, {
        ...formattedValues,
        slot_duration: values.slot_duration,
        doctor_id: userDetails?.id,
      })
        ?.then((res) => {
          message.success(res?.data?.message);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    } else {
      doctorAppointmentsApi(
        `facility=${getCookie(FacilityID) || userDetails?.currFacility}`,
        {
          ...formattedValues,
          slot_duration: values.slot_duration,
          doctor_id: userDetails?.id,
        }
      )
        ?.then((res) => {
          message.success(res?.data?.message);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <DoctorLayout
        footer={
          activeSegment === "My Slots" && (
            <div className="flex-end" style={{ gap: "24px" }}>
              <Button
                type="primary"
                onClick={() => form.submit()}
                className={btnStyles.mdBtn}
              >
                Confirm
              </Button>
            </div>
          )
        }
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Segmented
              size="large"
              options={items}
              value={activeSegment}
              onChange={onChange}
            />
          </Col>
          <Col span={24}>
            {activeSegment === "My Appointments" ? (
              <AppointmentsView />
            ) : (
              <Form form={form} onFinish={onFinish} layout="vertical">
                <MySlots
                  timeSlots={timeSlots}
                  addTimeSlot={addTimeSlot}
                  form={form}
                />
              </Form>
            )}
          </Col>
        </Row>
      </DoctorLayout>
    </>
  );
};

export default DoctorAppointments;
