import React from "react";
import JVP from "./JVP";
import PeripheralPulses from "./PeripheralPulses";
import HeartSounds from "./HeartSounds";
import { Divider } from "antd";

const CardioForm = ({ context }) => {
  return (
    <>
      <PeripheralPulses />
      <Divider />
      <JVP />
      <Divider />
      <HeartSounds context={context} />
    </>
  );
};

export default CardioForm;
