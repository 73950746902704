import React from "react";
import { Card, Image, Typography } from "antd";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import dayjs from "dayjs";

const { Meta } = Card;
const PatientImageView = ({ data }) => {
  const fileData = [
    ...new Set(
      data?.flatMap((imgs) =>
        imgs.paths.map((action) => {
          return { ...action, date: imgs?.uploaded_at };
        })
      )
    ),
  ];

  return (
    <div
      style={{
        overflowY: "auto",
        height: "calc(100vh - 260px)",
      }}
    >
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 300: 3, 500: 4, 700: 3, 900: 5 }}
        // gutter={"6px"}
      >
        <Masonry gutter={"10px"}>
          {fileData?.map((item) => (
            <Card
              hoverable
              style={{ width: 250 }}
              cover={
                <Image
                  alt={item?.path?.replace("curengo/", "")}
                  src={item?.url}
                />
              }
            >
              <Meta
                title={
                  <Typography>{item?.path?.replace("curengo/", "")}</Typography>
                }
                description={dayjs(item?.date)?.format("DD MMM")}
              />
            </Card>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default PatientImageView;
