import { Descriptions, Card } from "antd";
import React from "react";

const SummaryInvestigations = ({ data }) => {
  return (
    <Card title="Recent Investigations" style={{ marginTop: "10px" }}>
      <Descriptions>
        {data?.map((invs) => (
          <Descriptions.Item span={2}>
            {invs?.name || invs?.investigation?.name}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
};

export default SummaryInvestigations;
