import { Descriptions } from "antd";
import React from "react";
import {
  getLabelTagInput,
  getLabelTagsWithDescription,
} from "../constructTextFromValues";
import { speechCheckboxOptions } from "../../Options/functionalAsessmentOptions";

const SpeechSwallowFunctionalDiagnosisSummary = ({ formData }) => {
  const constructFunctionalDiagnosisSummaryItems = () => {
    const functionalDiagnosisSummaryItems = [];
    const diagnosis = formData?.functional_diagnosis || {};
    const pushFunctionalItem = (key, label, children) => {
      functionalDiagnosisSummaryItems.push({ key, label, children });
    };
    if (diagnosis) {
      pushFunctionalItem(
        "1",
        "Functional Diagnosis",
        <>
          {getLabelTagsWithDescription(speechCheckboxOptions, diagnosis)}
          {getLabelTagInput("Comments", diagnosis?.comments)}
        </>
      );
    }
    return functionalDiagnosisSummaryItems;
  };
  const functionalDiagnosisSummaryItems =
    constructFunctionalDiagnosisSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Functional Diagnosis"
        items={functionalDiagnosisSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default SpeechSwallowFunctionalDiagnosisSummary;
