import React, { useContext, useEffect, useState } from "react";
import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  nursePressureSoreGradeOptions,
  pressureSoreAreaOptions,
} from "../../../../Options/systemicExamOptions";
import { Form, Radio } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";

const NursePressureSore = ({ context }) => {
  const { formData } = useContext(context);
  const [showPressureSore, setShowPressureSore] = useState(
    formData?.examination?.pressure_sore_details?.pressure_sores === "yes" ||
      formData?.systemic_examination?.pressure_sore_details?.pressure_sores ===
        "yes"
  );

  const [checkboxes, setCheckboxes] = useState([]);
  useEffect(() => {
    setShowPressureSore(
      formData?.examination?.pressure_sore_details?.pressure_sores === "yes" ||
        formData?.systemic_examination?.pressure_sore_details
          ?.pressure_sores === "yes"
    );
    const initialCheckboxes = pressureSoreAreaOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          formData?.examination?.pressure_sore_details?.[checkbox.value] ||
          formData?.systemic_examination?.pressure_sore_details?.[
            checkbox.value
          ] ||
          false;
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <Form.Item
        label="Pressure Sores"
        name={["pressure_sore_details", "pressure_sores"]}
      >
        <Radio.Group
          options={yesNoOptions}
          onChange={(e) => {
            setShowPressureSore(e.target.value === "yes");
          }}
        />
      </Form.Item>
      {showPressureSore && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={pressureSoreAreaOptions}
            radioOptions={nursePressureSoreGradeOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            keyChain={"pressure_sore_details"}
          />
        </div>
      )}
    </>
  );
};

export default NursePressureSore;
