import React, { useContext } from "react";
import { PhysioInitialContext } from "./PhysiotherapistInitialAssessmentProvider";
import { Col, Row } from "antd";
import DoctorHistorySummary from "../../InspectionSummaries/HistorySummary/DoctorHistorySummary";
import PhysiotherapistGeneralExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/PhysiotherapistGeneralExaminationSummary";
import PhysioFunctionalStatusSummary from "../../InspectionSummaries/FunctionalExaminationSummary/PhysioFunctionalStatusSummary";
import PhysioFunctionalDiagnosisSummary from "../../InspectionSummaries/FunctionalExaminationSummary/PhysioFunctionalDiagnosisSummary";
import PhysioTherapeuticGoalsSummary from "../../InspectionSummaries/Goals/PhysioTherapeuticGoalsSummary";
import PhysioSystemicExaminationSummary from "../../InspectionSummaries/SystemicExaminationSummary/PhysioSystemicExaminationSummary";
import PhysioTherapySummary from "../../InspectionSummaries/Therapy/PhysioTherapySummary";
import PhysioTreatmentPlanSummary from "../../InspectionSummaries/TreatmentPlan/PhysioTreatmentPlanSummary";

const PhysiotherapistInitialInspectionSummary = () => {
  const { formData, setFormData } = useContext(PhysioInitialContext);

  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.history && <DoctorHistorySummary formData={formData} />}
        {formData?.systemic_examination && (
          <PhysioSystemicExaminationSummary formData={formData} />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.general_examination && (
          <PhysiotherapistGeneralExaminationSummary formData={formData} />
        )}
        {formData?.functional_status && (
          <PhysioFunctionalStatusSummary formData={formData} />
        )}
        {formData?.functional_diagnosis && (
          <PhysioFunctionalDiagnosisSummary formData={formData} />
        )}
        {formData?.therapeutic_goals && (
          <PhysioTherapeuticGoalsSummary formData={formData} />
        )}
        {formData?.therapy && <PhysioTherapySummary formData={formData} />}
        {formData?.treatment_plan && (
          <PhysioTreatmentPlanSummary formData={formData} />
        )}
      </Col>
    </Row>
  );
};

export default PhysiotherapistInitialInspectionSummary;
