import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Typography,
  Image,
  Row,
  Col,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import textStyles from "../../../styles/Custom/Typography.module.css";
import {
  mobileOtpResendApi,
  orgVerificationApi,
  userMetaDataApi,
  verifyOtpApi,
} from "../../../services/auth.services";
import { getItem, setCookie, setItem } from "../../../helpers/localStorage";
import {
  ACCESS_TOKEN,
  ACTIVEDOMAIN,
  ORGANISATONID,
  REFRESH_TOKEN,
  USER,
} from "../../../constants/defaultKeys";
import logo from "../../../assets/images/curengo.svg";
import { redirectToDashboard } from "../../../helpers/auth/helper";
import { UserContext } from "../../../context/UserContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ChooseOrgModal from "./ChooseOrgModal";

function Otp() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useParams();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [loading, setLoading] = useState(false);
  const { setUserDetails } = useContext(UserContext);
  const userData = JSON.parse(getItem(USER));
  const [openModal, setOpenModal] = useState(false);
  const [orgDetails, setOrgDetails] = useState({});
  const domain = window.location.hostname;

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const resp = await verifyOtpApi({ ...values, token: userData?.token });
      if (resp) {
        setCookie(
          ACCESS_TOKEN,
          resp?.data?.access,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie(
          REFRESH_TOKEN,
          resp?.data?.refresh,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        getUserDetails();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const resendOtp = async (values) => {
    setLoading(true);
    try {
      const resp = await mobileOtpResendApi({
        token: query?.token,
      });
      const data = resp?.data;
      message.success(data?.message);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    try {
      let resp = await userMetaDataApi();
      if (params?.org) {
        setCookie(
          ORGANISATONID,
          params?.org,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        const activeOrg = params?.org
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == params?.org
            )?.[0]
          : {};

        setUserDetails({
          ...resp?.data,
          activeOrg: activeOrg,
          currFacility: activeOrg?.facilities?.[0]?.facility_id,
        });
        if (activeOrg?.domain) {
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          redirectToDashboard(activeOrg?.domain, activeOrg?.default_role);
        }
      }
      if (resp?.data?.organizations?.length == 1) {
        let orgId = resp?.data?.organizations?.[0].org_id;
        setCookie(
          ORGANISATONID,
          orgId,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        const activeOrg = orgId
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgId
            )?.[0]
          : {};
        setUserDetails({
          ...resp?.data,
          activeOrg: activeOrg,
          currFacility: activeOrg?.facilities?.[0]?.facility_id,
        });
        if (activeOrg?.domain) {
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          redirectToDashboard(activeOrg?.domain, activeOrg?.default_role);
        }
      } else {
        setOpenModal(true);
        setUserDetails({
          ...resp?.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading && setLoading(false);
    }
  };
  const orgVerification = async (domain) => {
    try {
      const resp = await orgVerificationApi(domain);
      setOrgDetails(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(domain.split(".")[0]);
    if (domain !== process.env.REACT_APP_PUBLIC_DOMAIN) {
      if (domain.split(".")[0] !== "app") {
        orgVerification(domain.split(".")[0]);
      }
    }
  }, []);
  return (
    <>
      <ChooseOrgModal openModal={openModal} />
      <div className="parentContainer">
        <Image
          preview={false}
          alt="Logo"
          src={orgDetails?.logo || logo}
          style={{
            height: "80px",
            width: "auto",
            marginLeft: "20%",
            cursor: "pointer",
          }}
          className="mobileHidden"
        />
        <div className="webHidden">
          <Image
            preview={false}
            alt="Logo"
            src={orgDetails?.logo || logo}
            style={{
              height: "100px",
              width: "auto",
              paddingLeft: "20%",
              cursor: "pointer",
            }}
          />
        </div>
        <Row
          style={{
            minHeight: "calc(100vh - 400px)",
            justifyContent: "center",
          }}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            style={{
              alignSelf: "center",
            }}
          >
            {" "}
            <Typography
              className={`text-center `}
              style={{ paddingTop: "12px", paddingBottom: "32px" }}
            >
              <br />
              <Typography
                className={`text-center ${textStyles.h5}`}
                // style={{ marginTop: "24px" }}
              >
                Check your mobile
              </Typography>
              <br />
              We have sent a verification code to {userData?.contact_number}
              <br />
            </Typography>{" "}
            <Form
              autoComplete="off"
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
              style={{ width: "100%" }}
            >
              <Form.Item
                label="Enter OTP"
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Enter otp",
                  },
                ]}
              >
                <Input
                  placeholder="Otp"
                  className={inputStyles.mdInput}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <br />
              <Form.Item style={{ width: "100%", margin: "auto" }}>
                <Button
                  loading={loading}
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  className={btnStyles.mdBtn}
                >
                  Submit
                </Button>
              </Form.Item>
              <Divider>Or</Divider>
              <Typography
                className="text-center"
                style={{ paddingTop: "12px" }}
              >
                Didn't receive code? {"  "}
                <span
                  style={{ color: "#7F56D9", cursor: "pointer" }}
                  onClick={resendOtp}
                >
                  Resend OTP
                </span>
              </Typography>
              <div className="text-center">
                <Typography
                  className="text-center"
                  style={{ paddingTop: "12px", textAlign: "center" }}
                  onClick={() => navigate("/login?type=mobile")}
                >
                  <ArrowLeftOutlined /> Back to login
                </Typography>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Otp;
