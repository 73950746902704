import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/Auth/Login/Login";
import PageNotFound from "../components/ErrorPages/PageNotFound";
import Dashboard from "../components/Dashboard";
import PrivateRoute from "./PrivateRoutes";
import { UserProvider } from "../context/UserContext";
import AuthProvider from "../context/AuthProvider";
import Logout from "../components/Auth/Logout";
import Otp from "../components/Auth/Login/Otp";
import Buildings from "../components/Dashboard/Facilities/FloorPlan/Buildings";
import AccountDashboard from "../components/Account";
import OrganizationDashboard from "../components/OrganizationSettings";
import { ConfigProvider } from "antd";
import { customLightTheme } from "../theme/customTheme";
import DoctorView from "../components/DoctorView/DoctorView";
import NurseView from "../components/NurseView/NurseView";
import NutritionistView from "../components/NutritionistView/NutritionistView";
import Receptionist from "../components/Receptionist/Receptionist";
import PhysiotherapistView from "../components/PhysiotherapistView/PhysiotherapistView";
import TimeTest from "../components/TimeTest";
import SpeechAndSwallowView from "../components/SpeechAndSwallowView/SpeechAndSwallowView";
import RespiratoryTherapistView from "../components/RespiratoryTherapistView/RespiratoryTherapistView";
import PsychologistView from "../components/PsychologistView/PsychologistView";
const ProjectRoutes = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <AuthProvider>
          <ConfigProvider theme={customLightTheme}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/otp/:token" element={<Otp />} />
              <Route path="/time-formats" element={<TimeTest />} />
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/logout" element={<Logout />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard/:tab" element={<Dashboard />} />
                <Route path="/receptionist/:tab" element={<Receptionist />} />
                <Route path="/buildings" element={<Buildings />} />
                <Route path="/accounts/:tab" element={<AccountDashboard />} />
                <Route path="/doctor/:tab" element={<DoctorView />} />
                <Route path="/nurse/:tab" element={<NurseView />} />
                <Route
                  path="/nutritionist/:tab"
                  element={<NutritionistView />}
                />
                <Route
                  path="/physiotherapist/:tab"
                  element={<PhysiotherapistView />}
                />
                <Route
                  path="/respiratory-therapist/:tab"
                  element={<RespiratoryTherapistView />}
                />
                <Route
                  path="/speech-swallow-therapist/:tab"
                  element={<SpeechAndSwallowView />}
                />
                <Route
                  path="/psychologist/:tab"
                  element={<PsychologistView />}
                />
                <Route
                  path="/organization-settings/:tab"
                  element={<OrganizationDashboard />}
                />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </ConfigProvider>
        </AuthProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default ProjectRoutes;
