import React from "react";
import { Form, Radio } from "antd";
import { cyanosisOptions } from "../../../../Options/genExamOptions";

const Cyanosis = () => {
  return (
    <>
      <Form.Item name="cyanosis" label="Cyanosis" rules={[]}>
        <Radio.Group options={cyanosisOptions} />
      </Form.Item>
    </>
  );
};

export default Cyanosis;
