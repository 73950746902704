import React, { useEffect, useState } from "react";
import {
  Form,
  Table,
  Button,
  Modal,
  Select,
  InputNumber,
  Tag,
  Popconfirm,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const drainTypeOptions = [
  { value: "chest_drain", label: "Chest Drain" },
  { value: "abdominal_drain", label: "Abdominal Drain" },
  { value: "subcutaneous_drain", label: "Subcutaneous Drain" },
];

const sideOptions = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
];

const yesNoOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const colorOptions = [
  { value: "blood", label: "Blood" },
  { value: "serosanguinous", label: "Serosanguinous" },
  { value: "serous", label: "Serous" },
  { value: "purulent", label: "Purulent" },
  { value: "others", label: "Others" },
];

const drainNumberOptions = [
  { value: "drain_1", label: "Drain 1" },
  { value: "drain_2", label: "Drain 2" },
];

const Drain = ({ drainData, setDrainData }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drainType, setDrainType] = useState("");

  const columns = [
    {
      title: "Drain Type",
      dataIndex: "drain_type",
      key: "drain_type",
      render: (text) =>
        drainTypeOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Chest Side",
      dataIndex: "chest_side",
      key: "chest_side",
      render: (text) =>
        sideOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Drain Number",
      dataIndex: "drain_number",
      key: "drain_number",
      render: (text) =>
        drainNumberOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: "Drain Color",
      dataIndex: "color",
      key: "color",
      render: (text) =>
        colorOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Underwater Seal",
      dataIndex: "underwater_seal",
      key: "underwater_seal",
      render: (text) =>
        yesNoOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Air Column",
      dataIndex: "air_column",
      key: "air_column",
      render: (text) =>
        yesNoOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Negative Pressure",
      dataIndex: "negative_pressure",
      key: "negative_pressure",
      render: (text) =>
        yesNoOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        record.action ? (
          <Tag color="red">×</Tag>
        ) : (
          <Popconfirm
            title="Are you sure to stop?"
            onConfirm={() => confirmAction(record.key)}
            okText="Agree"
            cancelText="Disagree"
          >
            <Button>Stop</Button>
          </Popconfirm>
        ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDrainType("");
    form.resetFields();
  };

  const handleAdd = () => {
    form
      .validateFields()
      .then((values) => {
        const newData = [...drainData, { ...values, key: drainData.length }];
        setDrainData(newData);
        setIsModalVisible(false);
        setDrainType("");
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const confirmAction = (recordKey) => {
    const newData = drainData.map((item) =>
      item.key === recordKey ? { ...item, action: true } : item
    );
    setDrainData(newData);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...drainData,
    });
  }, [drainData]);

  const handleDrainTypeChange = (value) => {
    setDrainType(value);
  };

  return (
    <Form.Item label="Drains">
      <Table dataSource={drainData} columns={columns} pagination={false} />
      <Button
        type="primary"
        onClick={showModal}
        icon={<PlusOutlined />}
        style={{ marginTop: 16 }}
      >
        Add Row
      </Button>
      <Modal
        title="Add New Drain"
        open={isModalVisible}
        onOk={handleAdd}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAdd}>
            Add Row
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="drain_type" label="Drain Type">
            <Select
              placeholder="Select a drain type"
              onChange={handleDrainTypeChange}
            >
              {drainTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {drainType === "chest_drain" && (
            <>
              <Form.Item name="chest_side" label="Side">
                <Select placeholder="Select a side">
                  {sideOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="underwater_seal" label="Underwater Seal">
                <Select placeholder="Select underwater seal">
                  {yesNoOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="air_column" label="Air Column Movement">
                <Select placeholder="Select air column movement">
                  {yesNoOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          {drainType === "subcutaneous_drain" && (
            <Form.Item name="negative_pressure" label="Negative Pressure">
              <Select placeholder="Select negative pressure">
                {yesNoOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item name="volume" label="Drain Volume in ml">
            <InputNumber placeholder="Volume in ml" />
          </Form.Item>
          <Form.Item name="color" label="Drain Color">
            <Select placeholder="Select a color">
              {colorOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="drain_number" label="Drain">
            <Select placeholder="Select a drain number">
              {drainNumberOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Form.Item>
  );
};

export default Drain;
