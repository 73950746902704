import React, { useState } from "react";
import { Drawer, Form, Row, Col, Typography, Button, message } from "antd";
import ScanImage from "./ScanImage";
import { CloseOutlined } from "@ant-design/icons";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import ScanImageList from "./ScanImageList";
import { isMobile, isTablet } from "react-device-detect";

const ScanImageDrawer = ({ open, setOpen, refreshPage }) => {
  const [uploadFile, setUploadFile] = useState([]);
  const [add, setAdd] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      width={isTablet || isMobile ? "100%" : "70%"}
      keyboard={false}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              Scan or Capture Image
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: "right" }}>
          {add ? (
            <Button
              className={btnStyles.lgBtn}
              type="primary"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          ) : (
            <Form.Item>
              <Button
                className={btnStyles.lgBtn}
                type="primary"
                onClick={() => {
                  uploadFile?.length >= 1
                    ? setAdd(true)
                    : message.error("Please upload images to proceed");
                }}
              >
                Next
              </Button>
            </Form.Item>
          )}
        </div>
      }
      closeIcon={null}
    >
      <div>
        {add ? (
          <ScanImageList
            uploadFile={uploadFile}
            refreshPage={refreshPage}
            setAdd={setAdd}
            setOpen={setOpen}
          />
        ) : (
          <ScanImage setUploadFile={setUploadFile} uploadFile={uploadFile} />
        )}
      </div>
    </Drawer>
  );
};

export default ScanImageDrawer;
