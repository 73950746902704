import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, message, Slider, Typography } from "antd";
import { nihssOptions } from "../../../Options/physioOptions";

import {
  getNihsScaleAPI,
  nihsScaleAPI,
  updateNihsScaleAPI,
} from "../../../../../services/physio.services";

const NIHSScale = ({
  admission,
  reviewDate,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [states, setStates] = useState({});
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();

  const handleChange = (value, category) => {
    setStates((prev) => ({ ...prev, [category]: value }));
  };

  // const aggregateScore = () => {
  //   return Object.values(states).reduce((acc, val) => acc + (val || 0), 0);
  // };

  const aggregate = Object.values(states).reduce(
    (acc, val) => acc + (val || 0),
    0
  );

  const marks = {
    level_of_Consiousness: { 0: "0", 1: "1", 2: "2", 3: "3" },
    loc_questions: { 0: "0", 1: "1", 2: "2" },
    loc_commands: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    best_gaze: { 0: "0", 1: "1", 2: "2" },
    visual: { 0: "0", 1: "1", 2: "2", 3: "3" },
    facial_palsy: { 0: "0", 1: "1", 2: "2", 3: "3" },
    motor_arm_left: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    motor_arm_right: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    motor_leg_left: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    motor_leg_right: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    limb_ataxia: { 0: "0", 1: "1", 2: "2" },
    sensory: { 0: "0", 1: "1", 2: "2" },
    best_language: { 0: "0", 1: "1", 2: "2", 3: "3" },
    dysarthria: { 0: "0", 1: "1", 2: "2" },
    extinction_and_inattention: { 0: "0", 1: "1", 2: "2" },
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
      ...states,
    };

    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateNihsScaleAPI(scalesId?.nihss, finalData);
      } else {
        const response = await nihsScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, nihss: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.nihss) {
      try {
        const response = await getNihsScaleAPI(admission, scalesId?.nihss);
        const data = response.data;
        setFormData(data);
        form.setFieldsValue(data);
        setStates({
          best_gaze: data?.best_gaze,
          best_language: data?.best_language,
          dysarthria: data?.dysarthria,
          extinction_and_inattention: data?.extinction_and_inattention,
          facial_palsy: data?.facial_palsy,
          level_of_Consiousness: data?.level_of_Consiousness,
          limb_ataxia: data?.limb_ataxia,
          loc_commands: data?.loc_commands,
          loc_questions: data?.loc_questions,
          motor_arm_left: data?.motor_arm_left,
          motor_arm_right: data?.motor_arm_right,
          motor_leg_left: data?.motor_leg_left,
          motor_leg_right: data?.motor_leg_right,
          sensory: data?.sensory,
          visual: data?.visual,
        });
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [reviewDate]);

  return (
    <>
      <div className="cardless-scale" style={{ height: "110vh" }}>
        <Typography className="score-counter">
          {aggregate}
          <br />
          —
          <br />
          44
          <span className="score-string">
            {aggregate < 6
              ? "Mild Stroke"
              : aggregate < 14
              ? "Moderate Stroke"
              : aggregate < 25
              ? "Severe Stroke"
              : "Very Severe Stroke"}
          </span>
        </Typography>
        <Card style={{ width: "600px" }}>
          <Form id="nihs-scale" onFinish={handleSubmit} form={form}>
            {nihssOptions.map((option) => (
              <Form.Item
                key={option.value}
                label={option.label}
                name={option.value}
              >
                <Slider
                  marks={marks[option.value]}
                  step={1}
                  value={states[option.value]}
                  onChange={(value) => handleChange(value, option.value)}
                  max={option.max}
                  min={0}
                  style={{ width: "50%" }}
                />
              </Form.Item>
            ))}
            <Form.Item label="Comments" name="comments">
              <Input placeholder="Comments" style={{ width: "40%" }} />
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => {
            form.submit();
            setActiveTab("3");
          }}
        >
          Save
        </Button>

        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default NIHSScale;
