import React, { useEffect, useState, useCallback, useContext } from "react";
import { Form, Typography, Checkbox, Radio } from "antd";
import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import { noYesOptions } from "../../../../Options/commonOptions";
import {
  fractureAreasOptions,
  fractureTypeOptions,
} from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const { Text } = Typography;

const Fractures = ({ context }) => {
  const { formData } = useContext(context || DoctorInitialContext);

  const [showFractureAreas, setShowFractureAreas] = useState(false);
  const [fractureAreas, setFractureAreas] = useState({});
  const [checkboxes, setCheckboxes] = useState({});

  const toggleFractureArea = useCallback((value, e) => {
    setFractureAreas((prev) => ({
      ...prev,
      [value]: e.target.checked,
    }));
  }, []);

  const getFractureRightLeftOptions = useCallback(
    (area) => [
      { label: "Right-Closed", value: `${area}_right_closed` },
      { label: "Right-Open", value: `${area}_right_open` },
      { label: "Left-Closed", value: `${area}_left_closed` },
      { label: "Left-Open", value: `${area}_left_open` },
    ],
    []
  );

  useEffect(() => {
    if (formData?.systemic_examination) {
      const systemicExamination = formData.systemic_examination;

      setShowFractureAreas(systemicExamination.fractures === "yes");

      setFractureAreas(
        fractureAreasOptions.reduce((acc, { value }) => {
          acc[value] = systemicExamination[value] || false;
          return acc;
        }, {})
      );

      const initialCheckboxes = {};
      fractureAreasOptions.forEach(({ value }) => {
        const options = getFractureRightLeftOptions(value);
        const initialValues = getInitialValuesForChild(
          options,
          systemicExamination
        );
        Object.assign(initialCheckboxes, initialValues);
      });
      setCheckboxes(initialCheckboxes);
    }
  }, [formData, getFractureRightLeftOptions]);

  return (
    <>
      <Text>Fractures</Text>
      <Form.Item name="fractures">
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => setShowFractureAreas(e.target.value === "yes")}
        />
      </Form.Item>
      {showFractureAreas && (
        <div className="subsection-card">
          {fractureAreasOptions.map(({ label, value }) => (
            <div key={value}>
              <Form.Item shouldUpdate name={value} valuePropName="checked">
                <Checkbox onChange={(e) => toggleFractureArea(value, e)}>
                  {label}
                </Checkbox>
              </Form.Item>
              {fractureAreas[value] && (
                <div className="subsection-card">
                  <CheckListFBYRadioItems
                    checkBoxOptions={getFractureRightLeftOptions(value)}
                    radioOptions={fractureTypeOptions}
                    checkboxes={checkboxes}
                    setCheckboxes={setCheckboxes}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Fractures;
