import React, { useState, useEffect, useContext } from "react";
import { message, Popconfirm, Space, Table, Tag, Typography } from "antd";
import { getRandomColor, userRoles } from "../../../helpers/utility";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import {
  EditOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import UsersForm from "./UsersForm";
import { deactivateUserApi } from "../../../services/auth.services";
import { getFacilityListApi } from "../../../services/facilities.services";
import { UserContext } from "../../../context/UserContext";

const UsersTable = ({ data, loading, refreshPage }) => {
  const { userDetails } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [editListData, setEditListData] = useState(null);
  const [facilities, setFacilities] = useState(null);

  const onDeactivate = (id) => {
    deactivateUserApi({ user_id: id })
      .then((res) => {
        message?.success(res?.data?.message);
        refreshPage();
      })
      .catch((err) => {
        message?.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    getFacilityListApi()
      .then((res) => {
        setFacilities(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const roleFilters = userRoles?.map((role) => {
    return { text: role?.label, value: role?.value };
  });
  const facilityFilters = facilities?.map((role) => {
    return { text: role?.name, value: role?.name };
  });

  const roleEditPermission = (defaultRole) => {
    const role = defaultRole?.map((item) => item?.role);

    const editRole =
      //user is facility admin doesn't have permission to edit org admin and org owner
      userDetails?.activeOrg?.default_role === "Facility_Admin"
        ? !(
            role?.includes("Organization_Admin") ||
            role?.includes("Organization_Owner")
          )
        : //user is org admin doesn't have permission to edit org owner
        userDetails?.activeOrg?.default_role === "Organization_Admin"
        ? !role?.includes("Organization_Owner")
        : true;

    return editRole;
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "10%",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: "10%",
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      width: "10%",
      sorter: (a, b) => a.mobile - b.mobile,
    },

    {
      title: "Role",
      dataIndex: "roles",
      key: "roles",
      width: "20%",
      filters: [...roleFilters],
      render: (value) =>
        value
          ? value?.map((role) => (
              <Typography>{role?.role?.replaceAll("_", " ")}</Typography>
            ))
          : "-",
      onFilter: (value, record) =>
        record.roles.some((role) => role.role === value),
    },
    {
      title: "Facility",
      dataIndex: "roles",
      key: "roles",
      width: "20%",
      // filters: facilities && [...facilityFilters],
      render: (value) => {
        return (
          <>
            {value?.map((facility) => {
              return facility?.facility_name ? (
                <Tag
                  style={{ marginBottom: "4px" }}
                  key={facility?.facility}
                  className={tagStyles.mdRndTag}
                  color={getRandomColor(facility?.facility_name)}
                >
                  {facility?.facility_name}
                </Tag>
              ) : null;
            })}
          </>
        );
      },
      // onFilter: (value, record) =>
      //   record.facilities.some((facility) => facility.facility === value),
    },
    {
      title: "Action",
      width: "5%",
      render: (record) => (
        <Space>
          {roleEditPermission(record?.roles) ? (
            <Space>
              {" "}
              <EditOutlined
                onClick={() => {
                  setOpen(true);
                  setEditListData(record);
                }}
              />
              <Popconfirm
                title="Deactivate the user"
                description="User would loose access to the app"
                okText="Yes"
                cancelText="No"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
                onConfirm={() => onDeactivate(record?.user_id)}
              >
                <StopOutlined />
              </Popconfirm>
            </Space>
          ) : null}
        </Space>
      ),
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        scroll={{ y: "calc(100vh - 290px)" }}
      />
      {open && (
        <UsersForm
          isVisible={open}
          setIsVisible={setOpen}
          editListData={editListData}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default UsersTable;
