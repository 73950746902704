import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Skeleton, Space } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import InvestigationModal from "./InvestigationModal";
import { patientInvestigationsViewAPI } from "../../../services/patient.services";
import InvestigationTable from "./InvestigationTable";
import { UploadOutlined } from "@ant-design/icons";
import InvestigationsUploadModal from "./InvestigationsUploadModal";
import InvestigationGallery from "./InvestigationGallery/InvestigationGallery";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";

const InvestigationsIP = () => {
  const { userDetails } = useContext(UserContext);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [open, setOpen] = useState(false);
  const [investigationData, setInvestigationData] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    patientInvestigationsViewAPI(
      `patient_id=${params?.patient}&ip_admission=${params?.patient}`
    )
      .then((res) => {
        setInvestigationData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {investigationData && investigationData?.length > 0 ? (
            <Row gutter={[12, 12]}>
              <Col span={24} style={{ textAlign: "right", marginTop: "16px" }}>
                <Space>
                  {userDetails?.activeOrg?.default_role === "Doctor" && (
                    <Button
                      type="primary"
                      className={btnStyles.mdBtn}
                      onClick={() => setOpen(true)}
                    >
                      Request Investigation
                    </Button>
                  )}
                  <Button
                    type="primary"
                    ghost
                    className={btnStyles.mdBtn}
                    onClick={() => setGalleryModal(true)}
                  >
                    Investigation Gallery View
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    className={btnStyles.mdBtn}
                    onClick={() => setUploadModal(true)}
                  >
                    <UploadOutlined /> Initial Investigation
                  </Button>
                </Space>
              </Col>
              <Col span={24}>
                <InvestigationTable
                  data={investigationData}
                  refreshPage={refreshPage}
                  id={params?.patient}
                />
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <Space>
                  {userDetails?.activeOrg?.default_role !== "Nurse" && (
                    <Button
                      type="primary"
                      className={btnStyles.mdBtn}
                      onClick={() => setOpen(true)}
                    >
                      Request Investigation
                    </Button>
                  )}
                  <Button
                    type="primary"
                    ghost
                    className={btnStyles.mdBtn}
                    onClick={() => setUploadModal(true)}
                  >
                    <UploadOutlined /> Initial Investigation
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
        </>
      )}

      {open && (
        <InvestigationModal
          open={open}
          setOpen={setOpen}
          refreshPage={refreshPage}
          id={params?.patient}
        />
      )}
      {uploadModal && (
        <InvestigationsUploadModal
          open={uploadModal}
          setOpen={setUploadModal}
          refreshPage={refreshPage}
          id={params?.patient}
        />
      )}
      {galleryModal && (
        <InvestigationGallery
          open={galleryModal}
          setOpen={setGalleryModal}
          refreshPage={refreshPage}
          id={params?.patient}
        />
      )}
    </>
  );
};

export default InvestigationsIP;
