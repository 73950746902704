import React, { useContext, useState, useEffect } from "react";
import { Space, Select } from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import {
  getBuildingListApi,
  getRoomTypeApi,
} from "../../../../services/beds.services";
import { getCookie } from "../../../../helpers/localStorage";
import { FacilityID } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserContext";
const defaultValue = { id: 0, name: "All" };
const BedFilterCard = ({ buildingId, setBuildingId, roomId, setRoomId }) => {
  const { userDetails } = useContext(UserContext);
  const [roomTypes, setRoomTypes] = useState([defaultValue]);
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    getRoomTypeApi()
      .then((res) => setRoomTypes([...roomTypes, ...res?.data]))
      .catch((err) => {
        console.log(err);
        setRoomTypes([]);
      });
  }, []);

  useEffect(() => {
    getBuildingListApi(
      Number(getCookie(FacilityID) || userDetails?.currFacility)
    )
      .then((res) => {
        setBuildings(res?.data);
        if (res?.data?.length > 0) {
          setBuildingId(res?.data?.[0]?.id);
        }
      })
      .catch((err) => {
        console.log(err);
        setBuildings([]);
      });
  }, [userDetails]);

  return (
    <Space>
      <Space direction="vertical" size={4}>
        <p style={{ margin: 0 }}>Buildings</p>
        <Select
          className={inputStyles.mdSelect}
          style={{ width: "300px" }}
          value={buildingId}
          onChange={(e) => setBuildingId(e)}
        >
          {buildings?.map((item) => (
            <Select.Option key={item?.id} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <Space direction="vertical" size={4}>
        <p style={{ margin: 0 }}>Room Types</p>
        <Select
          className={inputStyles.mdSelect}
          style={{ width: "300px" }}
          defaultValue="All"
          onChange={(e) => setRoomId(e)}
        >
          {roomTypes?.map((item) => (
            <Select.Option key={item?.id} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </Space>
  );
};

export default BedFilterCard;
