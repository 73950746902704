import React, { useState, useCallback, useEffect } from "react";
import { AutoComplete, Form, Input, Row, Col, Space } from "antd";
import { searchMedicationApi } from "../../../../services/medication.services";
import { getCookie } from "../../../../helpers/localStorage";
import { ORGANISATONID } from "../../../../constants/defaultKeys";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import MedicineFields from "./MedicineFields";
import { debounce } from "../../../../helpers/utility";

const setSearchOptions = (data) => {
  const searchOptions = data.map((options) => ({
    value: options?.name,
    key: options?.record_id,
    name: options?.name,
    drug: options?.drug1_name,
    label: (
      <Row>
        <Col span={10}>
          <span>{options?.name}</span>
        </Col>
      </Row>
    ),
  }));

  return searchOptions;
};

const AddInitialMedicine = ({
  editData,
  form,
  selectedId,
  setSelectedId,
  setMatched,
  matched,
  initial,
}) => {
  const [data, setData] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState(null);

  useEffect(() => {
    setSearchedText(editData?.name);
  }, [editData]);

  const searchMedicines = (searchedValue) => {
    if (searchedValue) {
      searchMedicationApi(
        `query=${searchedValue.toLowerCase()}&organization_id=${getCookie(
          ORGANISATONID
        )}`
      )
        .then((res) => {
          setData(setSearchOptions(res?.data));
        })
        .catch((err) => console.log(err));
    } else {
      setData([]);
    }
  };

  const handleSearch = (value) => {
    setSearchedText(value);
  };

  useEffect(() => {
    searchMedicines(searchedText);
  }, [searchedText]);

  useEffect(() => {
    if (setMatched) {
      const filterKey = data?.filter((item) =>
        item?.name?.includes(editData?.name)
      );

      setMatched(filterKey?.[0] || {});
    }
  }, [data]);

  const optimizedSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSelect = (value, option) => {
    setSelectedId(option?.key);
    form.setFieldsValue({ name: option?.value, drug: option?.drug });
  };

  const handleChange = (value) => {
    setSearchedText(value);
    optimizedSearch(value);
  };

  const handleClear = () => {
    setSearchedText("");
    setData([]);
    form.resetFields(["name"]);
  };

  return (
    <>
      <Space>
        <Form.Item
          name="name"
          rules={[{ required: "true", message: "Please enter medicine" }]}
        >
          <AutoComplete
            options={data}
            className="search"
            onSearch={optimizedSearch} // Only search when user types
            onSelect={handleSelect} // Handle selection from dropdown
            onChange={handleChange} // Update state and search on input change
            value={searchedText}
          >
            <Input
              style={{ width: "400px", marginTop: "-10px" }}
              size="large"
              prefix={<SearchOutlined />}
              placeholder="Search Medicine"
              // style={{ width: "400px" }}
              className={inputStyles.mdInput}
            />
          </AutoComplete>
        </Form.Item>
        {matched && !(Object?.keys?.(matched)?.length > 0 || selectedId) && (
          <CloseCircleOutlined
            style={{ fontSize: "18px", color: "red" }}
            onClick={handleClear}
          />
        )}
      </Space>

      {(selectedId || editData?.name) && (
        <MedicineFields
          initial={initial}
          selectedId={selectedId}
          editData={editData}
          form={form}
          selectedFrequency={selectedFrequency}
          setSelectedFrequency={setSelectedFrequency}
        />
      )}
    </>
  );
};

export default AddInitialMedicine;
