import React, { useContext, useEffect, useState } from "react";
import { Form, Radio, Select } from "antd";
import {
  abnormalGaitOptions,
  gaitOptions,
} from "../../../../Options/systemicExamOptions";

const Gait = ({ context }) => {
  const { formData } = useContext(context);
  const [showAbnormal, setShowAbnormal] = useState(
    formData?.systemic_examination?.gait === "abnormal" ? true : false
  );
  const handleGaitChange = (e) => {
    setShowAbnormal(e.target.value === "abnormal");
  };

  useEffect(() => {
    setShowAbnormal(
      formData?.systemic_examination?.gait === "abnormal" ? true : false
    );
  }, [formData]);

  return (
    <>
      <Form.Item label="Gait" name="gait">
        <Radio.Group options={gaitOptions} onChange={handleGaitChange} />
      </Form.Item>
      {showAbnormal && (
        <Form.Item name="gait_details">
          <Select
            placeholder="Choose Gait Option"
            options={abnormalGaitOptions}
          />
        </Form.Item>
      )}
    </>
  );
};

export default Gait;
