import React, { useEffect, useState } from "react";
import { Collapse, Button, Input, message, Space } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Floors from "./Floors";
import {
  createBuildingApi,
  getBuildingTreeApi,
  updateBuildingApi,
} from "../../../../services/beds.services";
import inputStyles from "../../../../styles/Custom/Input.module.css";

const { Panel } = Collapse;

const Buildings = ({ facilityId, refreshRoom }) => {
  const [editBuildingId, setEditBuildingId] = useState(null);
  const [editBuildingName, setEditBuildingName] = useState("");
  const [newBuildingName, setNewBuildingName] = useState("");
  const [addingBuilding, setAddingBuilding] = useState(false);
  const [buildings, setBuildings] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    if (facilityId) {
      getBuildingTreeApi(facilityId)
        .then((res) => setBuildings(res?.data))
        .catch((err) => {
          console.log(err);
          setBuildings([]);
        });
    }
  }, [refresh, facilityId]);

  const handleEditBuildingName = (buildingId, buildingName) => {
    setEditBuildingId(buildingId);
    setEditBuildingName(buildingName);
  };

  const handleEditBuildingSubmit = (buildingId) => {
    updateBuildingApi(buildingId, { name: editBuildingName })
      .then((res) => {
        message.success(res?.data?.message);
        setEditBuildingId(null);
        refreshPage();
      })
      .catch((err) => {
        message.error(err?.res?.data?.message);
      });
  };

  const handleAddBuilding = () => {
    if (newBuildingName.trim() === "") {
      message.error("Building name cannot be empty");
      return;
    }

    createBuildingApi({ name: newBuildingName, facility: facilityId })
      .then((res) => {
        message.success(res?.data?.message);
        setAddingBuilding(false);
        setNewBuildingName("");
        refreshPage();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  const handleNewBuildingNameChange = (e) => {
    setNewBuildingName(e.target.value);
  };

  const handleAddButtonClick = () => {
    setAddingBuilding(true); // Show the input field for adding a new building
  };

  return (
    <div>
      <Collapse>
        {buildings?.length > 0 &&
          buildings?.map((building, index) => (
            <Panel
              header={
                editBuildingId === building.building_id ? (
                  <Space>
                    <Input
                      className={inputStyles.mdInput}
                      value={editBuildingName}
                      onChange={(e) => setEditBuildingName(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleEditBuildingSubmit(building?.building_id);
                        }
                      }}
                    />
                    <Button
                      onClick={() =>
                        handleEditBuildingSubmit(building?.building_id)
                      }
                      type="primary"
                      icon={<CheckOutlined />}
                    />
                    <Button
                      onClick={() => setEditBuildingId(null)}
                      icon={<CloseOutlined />}
                    />
                  </Space>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {building?.building_name}
                    <EditOutlined
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        handleEditBuildingName(
                          building?.building_id,
                          building?.building_name
                        )
                      }
                    />
                  </div>
                )
              }
              key={`building-${index}`}
            >
              <Floors
                floors={building?.floors}
                id={building?.building_id}
                refreshPage={refreshPage}
                buildingName={building?.building_name}
                refreshRoom={refreshRoom}
              />
            </Panel>
          ))}
      </Collapse>
      {addingBuilding ? (
        <Space>
          <Input
            className={inputStyles.mdInput}
            placeholder="Enter building name"
            value={newBuildingName}
            onChange={handleNewBuildingNameChange}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddBuilding();
              }
            }}
          />
          <Button
            onClick={() => handleAddBuilding()}
            type="primary"
            icon={<CheckOutlined />}
          />
          <Button
            onClick={() => setAddingBuilding(null)}
            icon={<CloseOutlined />}
          />
        </Space>
      ) : (
        <Button onClick={handleAddButtonClick} type="primary">
          <PlusOutlined /> Building
        </Button>
      )}
    </div>
  );
};

export default Buildings;
