import { Descriptions } from "antd";
import React from "react";
import { getLabelTag } from "../constructTextFromValues";
import {
  hourlyOptions,
  onceTwiceOptions,
  twiceThriceOptions,
} from "../../Options/physioOptions";

const PhysioTreatmentPlanSummary = ({ formData }) => {
  const constructTreatmentSummaryItems = () => {
    const treatmentSummaryItems = [];
    const plan = formData?.treatment_plan;
    const pushPlanItem = (key, label, children) => {
      treatmentSummaryItems.push({ key, label, children });
    };
    if (plan?.ambulation_schedule) {
      pushPlanItem(
        "1",
        "Ambulation Schedule",
        getLabelTag(twiceThriceOptions, plan?.ambulation_schedule)
      );
    }
    if (plan?.recreation_schedule) {
      pushPlanItem(
        "2",
        "Recreation Schedule",
        getLabelTag(onceTwiceOptions, plan?.recreation_schedule)
      );
    }
    if (plan?.position_change_schedule) {
      pushPlanItem(
        "3",
        "Position Change Schedule",
        getLabelTag(hourlyOptions, plan?.position_change_schedule)
      );
    }
    if (plan?.physio_schedule) {
      pushPlanItem(
        "4",
        "Physiotherapy Schedule",
        getLabelTag(onceTwiceOptions, plan?.physio_schedule)
      );
    }
    return treatmentSummaryItems;
  };
  const treatmentSummaryItems = constructTreatmentSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title="Treatment Plan"
        items={treatmentSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </div>
  );
};

export default PhysioTreatmentPlanSummary;
