import { Descriptions } from "antd";
import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagYesNO,
} from "../constructTextFromValues";
import {
  nutritionalRatingOptions,
  nutritionalRiskType,
} from "../../Options/clinicalEvaluationOptions";

const NutritionistClinicalEvaluationSummary = ({ formData }) => {
  const constructClinicalEvaulationSummaryItems = () => {
    const clinicalEvaluationSummaryItems = [];
    const pushClinicalEvaluationItem = (key, label, children) => {
      clinicalEvaluationSummaryItems.push({ key, label, children });
    };
    const evaluation = formData?.clinical_evaluation || {};
    if (evaluation?.bmi?.height || evaluation?.bmi?.weight) {
      pushClinicalEvaluationItem(
        "1",
        "Height & Weight",
        <>
          {getLabelTagInput("Height", evaluation?.bmi?.height)}
          {getLabelTagInput("Weight", evaluation?.bmi?.weight)}
        </>
      );
    }
    if (
      evaluation?.investigations?.hemoglobin ||
      evaluation?.investigations?.rbs ||
      evaluation?.investigations?.albumin ||
      evaluation?.investigations?.urea ||
      evaluation?.investigations?.creatinine ||
      evaluation?.investigations?.sodium ||
      evaluation?.investigations?.potassium ||
      evaluation?.investigations?.chlorine
    ) {
      pushClinicalEvaluationItem(
        "3",
        "Investigations",
        <>
          {getLabelTagInput(
            "Hb",
            evaluation?.investigations?.hemoglobin,
            "g/dl"
          )}
          {getLabelTagInput("RBS", evaluation?.investigations?.rbs, "mg/dl")}
          {getLabelTagInput(
            "Albumin",
            evaluation?.investigations?.albumin,
            "g/dl"
          )}
          {getLabelTagInput("Urea", evaluation?.investigations?.urea, "mg/dl")}
          {getLabelTagInput(
            "Sr. Creatinine",
            evaluation?.investigations?.creatinine,
            "mg/dl"
          )}
          {getLabelTagInput(
            "Na+",
            evaluation?.investigations?.sodium,
            "meq/dl"
          )}
          {getLabelTagInput(
            "K+",
            evaluation?.investigations?.potassium,
            "meq/dl"
          )}
          {getLabelTagInput(
            "Cl-",
            evaluation?.investigations?.chlorine,
            "meq/dl"
          )}
        </>
      );
    }
    if (evaluation?.nutritional_screen_rating) {
      pushClinicalEvaluationItem(
        "4",
        "Nutritional Screen Rating",
        getLabelTag(
          nutritionalRatingOptions,
          evaluation?.nutritional_screen_rating
        )
      );
    }
    if (evaluation?.nutritional_risk_details?.risk) {
      pushClinicalEvaluationItem(
        "5",
        "Nutritional Risk",
        <>
          {getLabelTagYesNO(evaluation?.nutritional_risk_details?.risk)}
          {getLabelTag(
            nutritionalRiskType,
            evaluation?.nutritional_risk_details?.risk_type
          )}
          {getLabelTagInput(
            "Details",
            evaluation?.nutritional_risk_details?.risk_description
          )}
        </>
      );
    }
    return clinicalEvaluationSummaryItems;
  };
  const clinicalEvaluationSummaryItems =
    constructClinicalEvaulationSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={"Clinical Evaluation"}
        items={clinicalEvaluationSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </div>
  );
};

export default NutritionistClinicalEvaluationSummary;
