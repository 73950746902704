import React from "react";
import { Row, Col, Form, Input } from "antd";
const { TextArea } = Input;

const Malignancy = () => {
  return (
    <>
      <Row gutter={[32, 8]}>
        <Col span={12}>
          <Form.Item name="clinical_diagnosis_notes" label="Clinical Diagnosis">
            <TextArea type="text" placeholder="Please specify" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="final_diagnosis_notes" label="Final Diagnosis">
            <TextArea type="text" placeholder="Please specify" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Malignancy;
