import { Form, InputNumber, Table, Typography } from "antd";
import React from "react";

const ThumbJoint = () => {
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Left Active",
      dataIndex: "left_active",
      key: "left_active",
      render: (text, record) => (
        <Form.Item name={["thumb_joint", `left_active_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Left Passive",
      dataIndex: "left_passive",
      key: "left_passive",
      render: (text, record) => (
        <Form.Item name={["thumb_joint", `left_passive_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Active",
      dataIndex: "right_active",
      key: "right_active",
      render: (text, record) => (
        <Form.Item name={["thumb_joint", `right_active_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
    {
      title: "Right Passive",
      dataIndex: "right_passive",
      key: "right_passive",
      render: (text, record) => (
        <Form.Item name={["thumb_joint", `right_passive_${record.value}`]}>
          <InputNumber
            max={record.max}
            min={record.min}
            placeholder={`${record.min} to ${record.max}`}
          />
        </Form.Item>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      action: "CMC Flexion",
      value: "cmc_flexion",
      max: 15,
      min: 0,
    },
    {
      key: "2",
      action: "CMC Extension",
      value: "cmc_extension",
      max: 20,
      min: 0,
    },
    {
      key: "3",
      action: "CMC Abduction",
      value: "cmc_abduction",
      max: 70,
      min: 0,
    },
    {
      key: "4",
      action: "IP Flexion",
      value: "ip_flexion",
      max: 80,
      min: 0,
    },
  ];

  return (
    <>
      <Typography>Thumb Joint</Typography>
      <Table dataSource={data} columns={columns} pagination={false} />
    </>
  );
};

export default ThumbJoint;
