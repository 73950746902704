import React from "react";
import { useLocation } from "react-router-dom";
import DoctorLayout from "../Layout/DoctorLayout";
import PatientsIP from "../PatientsIP/PatientsIP";
import PatientCards from "../DoctorView/PatientCards";

const PsychologistView = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));

  return (
    <DoctorLayout>
      {params?.patient ? <PatientsIP /> : <PatientCards />}
    </DoctorLayout>
  );
};

export default PsychologistView;
