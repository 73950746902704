import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Segmented,
  theme,
  Typography,
  Tag,
  Space,
  List,
  Image,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import tagStyles from "../../styles/Custom/Tag.module.css";
import { getPatientListApi } from "../../services/patient.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserContext";
import CustomAvatar from "../../blocks/Avatars/CustomAvatar";
import bedImg from "../../assets/images/patientIcons/bed.svg";
import admissionIcon from "../../assets/images/patientIcons/admission.svg";
import dischargeIcon from "../../assets/images/patientIcons/discharge.svg";
import dayjs from "dayjs";

const outPatientLabels = [
  { label: "Today", value: "today" },
  { label: "Week", value: "week" },
];

const inPatientLabels = [
  { label: "Active", value: "current" },
  { label: "Discharged", value: "older" },
];

const { useToken } = theme;
const PatientCards = () => {
  const { token } = useToken();
  const { userDetails } = useContext(UserContext);
  const [patientList, setPatientList] = useState([]);
  const [activeOutSegment, setActiveOutSegment] = useState("today");
  const [activeInSegment, setActiveInSegment] = useState("current");
  const navigate = useNavigate();
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    .replaceAll("_", "-");
  useEffect(() => {
    getPatientListApi(
      `facility=${Number(getCookie(FacilityID)) || userDetails?.currFacility}`
    )
      .then((res) => {
        setPatientList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getCookie(FacilityID) || userDetails?.currFacility]);

  const onInpatientSegmentChange = (value) => {
    setActiveInSegment(value);
  };
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <List
          style={{ background: "#fff" }}
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 10,
          }}
          bordered
          header={
            <Row>
              <Col span={12}>Out Patients</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Segmented
                  options={outPatientLabels}
                  defaultValue={activeOutSegment}
                />
              </Col>
            </Row>
          }
          itemLayout="horizontal"
          size="small"
          dataSource={patientList?.data}
          renderItem={(item) => (
            <List.Item
              key={item?.title}
              extra={
                <Typography.Link
                  style={{ color: token.colorPrimary }}
                  onClick={() =>
                    navigate(`/${role}/home?patient=${item?.id}&type=landing`)
                  }
                >
                  Start Consultation <ArrowRightOutlined />
                </Typography.Link>
              }
            >
              <List.Item.Meta
                avatar={
                  <CustomAvatar
                    name={`${item?.patient_name}`}
                    indexId={item?.id}
                    size="medium"
                  />
                }
                title={
                  <Space size="small">
                    <Typography>{item?.patient_name}</Typography>
                  </Space>
                }
                description={
                  <>
                    {(item?.patient_age || item?.patient_gender) && (
                      <Tag color="blue">
                        {item?.patient_gender} {item?.patient_age}
                      </Tag>
                    )}
                    {/* <Tag className={tagStyles.smTag}>
                      Admission Date:{" "}
                      {item?.admission_date &&
                        dayjs(item?.admission_date).format("MMM,DD")}
                    </Tag> */}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <List
          style={{ background: "#fff" }}
          bordered
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 10,
          }}
          header={
            <Row>
              <Col span={12}>In Patients</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Segmented
                  options={inPatientLabels}
                  defaultValue={activeInSegment}
                  onChange={onInpatientSegmentChange}
                />
              </Col>
            </Row>
          }
          itemLayout="horizontal"
          size="small"
          dataSource={patientList?.data}
          renderItem={(item) => (
            <List.Item
              key={item?.title}
              extra={
                <Typography.Link
                  style={{ color: token.colorPrimary }}
                  onClick={() =>
                    navigate(`/${role}/home?patient=${item?.id}&type=landing`)
                  }
                >
                  View EMR <ArrowRightOutlined />
                </Typography.Link>
              }
            >
              <List.Item.Meta
                avatar={
                  <CustomAvatar
                    name={`${item?.patient_name}`}
                    indexId={item?.id}
                    size="medium"
                  />
                }
                title={
                  <Space size="small">
                    <Typography>{item?.patient_name}</Typography>
                    <Tag>IP ID:{item?.id}</Tag>
                    <Tag color="orange">
                      <Image src={bedImg} preview={false} /> {item?.bed_name}
                    </Tag>
                  </Space>
                }
                description={
                  <>
                    {(item?.patient_age || item?.patient_gender) && (
                      <Tag color="blue">
                        {item?.patient_gender} {item?.patient_age}
                      </Tag>
                    )}
                    <Tag className={tagStyles.smTag}>
                      <Image
                        src={
                          activeInSegment === "current"
                            ? admissionIcon
                            : dischargeIcon
                        }
                        style={{ height: "14px" }}
                      />{" "}
                      {item?.date_of_admission &&
                        dayjs(item?.date_of_admission).format("MMM DD")}
                    </Tag>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default PatientCards;
