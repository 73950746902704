import React from "react";
import { Form, Slider, Typography } from "antd";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
const Gcs = ({ handleChange }) => {
  return (
    <>
      <Typography>Glasgow Coma Scale</Typography>
      <Typography>Eye Opening Response</Typography>
      {/* <DislikeOutlined /> */}

      <Form.Item name="eye_opening_response">
        <Slider
          marks={{ 0: "NT", 1: "1", 2: "2", 3: "3", 4: "4" }}
          step={1}
          defaultValue={0}
          max={4}
          min={0}
          style={{ width: "90%" }}
          onChange={(value) => handleChange("eye_opening_response", value)}
        />
      </Form.Item>
      {/* <LikeOutlined /> */}

      <Typography>Verbal Response</Typography>
      <Form.Item name="verbal_response">
        <Slider
          marks={{ 0: "NT", 1: "1", 2: "2", 3: "3", 4: "4", 5: "5" }}
          step={1}
          defaultValue={0}
          max={5}
          min={0}
          style={{ width: "90%" }}
          onChange={(value) => handleChange("verbal_response", value)}
        />
      </Form.Item>
      <Typography>Motor Response</Typography>
      <Form.Item name="motor_response">
        <Slider
          marks={{ 0: "NT", 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6" }}
          step={1}
          defaultValue={0}
          max={6}
          min={0}
          style={{ width: "90%" }}
          onChange={(value) => handleChange("motor_response", value)}
        />
      </Form.Item>
    </>
  );
};

export default Gcs;
