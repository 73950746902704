import axiosInstance from "../config/axios.config";
import { getCookie } from "../helpers/localStorage";

export const getDepartmentsApi = () => {
  return axiosInstance.get(`/careservices/list-investigation-departments/`);
};
export const createInvestigationsApi = (payload) => {
  return axiosInstance.post(`/careservices/investigations/`, payload);
};
export const getInvestigationsApi = () => {
  return axiosInstance.get(`/careservices/investigations/`);
};
export const getFacilityInvestigationsApi = (facility_id) => {
  return axiosInstance.get(
    `/careservices/investigations/facility/${facility_id}/`
  );
};
export const updateInvestigationsApi = (id, payload) => {
  return axiosInstance.patch(`/careservices/investigations/${id}/`, payload);
};
export const uploadInvestigationsApi = (payload) => {
  return axiosInstance.post(
    `/careservices/investigations/bulk-upload/`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getCookie("access_token")}`,
        Organization: `${getCookie("organisation_id")}`,
      },
    }
  );
};
export const getInvestigationsSearchApi = (params) => {
  return axiosInstance.get(
    `/careservices/search/investigations/?query=${params}`
  );
};
