import React, { useState, useEffect } from "react";
import { Button, Collapse, message, Form, Space, Input, Select } from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import {
  createRoomApi,
  getRoomTypeApi,
  updateRoomApi,
} from "../../../../services/beds.services";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Beds from "./Beds";

const { Panel } = Collapse;
const { Option } = Select;

const Rooms = ({
  rooms,
  refreshPage,
  buildingName,
  floorNo,
  floorId,
  refreshRoom,
}) => {
  const [form] = Form.useForm();
  const [roomTypes, setRoomTypes] = useState([]);
  const [editingRoom, setEditingRoom] = useState(null); // To track the room being edited
  const [isAddingNewRoom, setIsAddingNewRoom] = useState(false); // Track whether to show the add form

  useEffect(() => {
    getRoomTypeApi()
      .then((res) => setRoomTypes(res.data))
      .catch((err) => {
        console.error(err);
      });
  }, [refreshRoom]);

  const handleEditRoom = (room) => {
    setEditingRoom(room); // Set the current room to edit
    form.setFieldsValue({ ...room, room_type: room?.room_type_id });
  };

  const handleAddNewRoom = () => {
    setIsAddingNewRoom(true);
    setEditingRoom(null);
    form.resetFields();
  };

  const onFinish = (values) => {
    if (editingRoom) {
      updateRoomApi(editingRoom?.room_id, { ...values })
        .then((res) => {
          message.success(res.data.message);
          refreshPage();
          setEditingRoom(null);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    } else {
      createRoomApi({ ...values, floor: floorId })
        .then((res) => {
          message.success(res.data.message);
          refreshPage();
          setIsAddingNewRoom(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        });
    }
  };

  const customForm = () => {
    return (
      <Form form={form} layout="horizontal" onFinish={onFinish}>
        <Space>
          <Form.Item
            name="room_type"
            rules={[{ required: true, message: "Enter room type" }]}
          >
            <Select
              placeholder="Choose room type"
              className={inputStyles.mdSelect}
            >
              {roomTypes?.map((type) => (
                <Option value={type.id} key={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="room_no"
            rules={[{ required: true, message: "Enter room number" }]}
          >
            <Input
              placeholder="Enter room number"
              className={inputStyles.mdInput}
            />
          </Form.Item>
          <Button htmlType="submit" type="primary" icon={<CheckOutlined />} />
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              isAddingNewRoom
                ? setIsAddingNewRoom(false)
                : setEditingRoom(false);
            }}
          />
        </Space>
      </Form>
    );
  };

  return (
    <>
      <Collapse>
        {rooms?.map((room, roomIndex) => (
          <Panel
            key={`room-${roomIndex}`}
            header={
              editingRoom?.room_id === room.room_id ? (
                <>{customForm()}</>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {room.room_no}
                  <EditOutlined
                    style={{ marginLeft: 8 }}
                    onClick={() => handleEditRoom(room)}
                  />
                </div>
              )
            }
          >
            <Beds
              beds={room?.beds}
              floorNo={floorNo}
              roomNo={room?.room_no}
              buildingName={buildingName}
              roomId={room?.room_id}
              refreshPage={refreshPage}
            />
          </Panel>
        ))}
      </Collapse>
      {isAddingNewRoom ? (
        <>{customForm()}</>
      ) : (
        <Button type="primary" onClick={handleAddNewRoom}>
          <PlusOutlined /> Room
        </Button>
      )}
    </>
  );
};

export default Rooms;
