import React from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";
import { Descriptions } from "antd";
import { goalsOptions } from "../../Options/goalsOptions";

const NutritionistGoalsSummary = ({ formData }) => {
  const constructGoalsSummaryItems = () => {
    const goalsSummaryItems = [];
    const pushGoalsItem = (key, label, children) => {
      goalsSummaryItems.push({ key, label, children });
    };
    const goals = formData?.goals || {};
    if (goals?.goals) {
      pushGoalsItem(
        "1",
        "Goals",
        <>
          {getLabelTagsArray(goalsOptions, goals?.goals)}
          {getLabelTagInput("Other Details", goals?.others_descriptions)}
        </>
      );
    }
    return goalsSummaryItems;
  };
  const goalsSummaryItems = constructGoalsSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={"Goals"}
        items={goalsSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </div>
  );
};

export default NutritionistGoalsSummary;
