import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckListFBYInput from "../../../../../blocks/FormItems/CheckListFBYInput";
import { Typography } from "antd";
import { presentIllnessOptions } from "../../../Options/historyOptions";
const { Text } = Typography;

const PresentIllness = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = presentIllnessOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = initialValues?.history?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Text>Present Illness</Text>
      <CheckListFBYInput
        options={presentIllnessOptions}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    </>
  );
};

export default PresentIllness;
