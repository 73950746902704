import React, { useState } from "react";
import {
  TimePicker,
  Input,
  message,
  Button,
  Space,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { InfoCircleOutlined } from "@ant-design/icons";

dayjs.extend(customParseFormat);

const TimeTest = () => {
  const [time12Hour, setTime12Hour] = useState("");
  const [time24Hour, setTime24Hour] = useState("");
  const [error12Hour, setError12Hour] = useState("");
  const [error24Hour, setError24Hour] = useState("");

  const validateTime12Hour = (value) => {
    const timeFormat = "hh:mm A";
    const time = dayjs(value, timeFormat, true);

    if (!time.isValid()) {
      return "Invalid time format";
    }

    const minutes = time.minute();
    if (minutes % 5 !== 0) {
      return "Minutes must be in multiples of 5";
    }

    return "";
  };

  const validateTime24Hour = (value) => {
    const timeFormat = "HH:mm";
    const time = dayjs(value, timeFormat, true);

    if (!time.isValid()) {
      return "Invalid time format";
    }

    const minutes = time.minute();
    if (minutes % 5 !== 0) {
      return "Minutes must be in multiples of 5";
    }

    return "";
  };

  const handleChange12Hour = (e) => {
    const { value } = e.target;
    setTime12Hour(value);
    setError12Hour(validateTime12Hour(value));
  };

  const handleChange24Hour = (e) => {
    const { value } = e.target;
    setTime24Hour(value);
    setError24Hour(validateTime24Hour(value));
  };

  const handleSubmit = () => {
    const validationError12Hour = validateTime12Hour(time12Hour);
    const validationError24Hour = validateTime24Hour(time24Hour);

    if (validationError12Hour) {
      setError12Hour(validationError12Hour);
    } else {
      setError12Hour("");
    }

    if (validationError24Hour) {
      setError24Hour(validationError24Hour);
    } else {
      setError24Hour("");
    }

    if (!validationError12Hour && !validationError24Hour) {
      message.success(`12-Hour Time entered: ${time12Hour}`);
      message.success(`24-Hour Time entered: ${time24Hour}`);
    }
  };

  return (
    <Space
      direction="vertical"
      style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
    >
      <Space>
        <h3>Time Pickers</h3>
      </Space>
      <Space direction="vertical">
        <h5>24 hour format</h5>
        <TimePicker
          format="HH:mm"
          minuteStep={10}
          placeholder="24-Hour Picker"
        />
        <h5>12 hour format</h5>
        <TimePicker
          format="hh:mm A"
          use12Hours
          minuteStep={10}
          placeholder="12-Hour Picker"
        />
      </Space>
      <Space direction="vertical" style={{ marginTop: "20px" }}>
        <h3>Custom Inputs</h3>
        <h5>12 hour format</h5>
        <Space>
          <Input
            placeholder="Enter time (hh:mm AM/PM)"
            value={time12Hour}
            onChange={handleChange12Hour}
            style={{ width: "150px" }}
          />
          <Tooltip title="Enter time (00:00 AM/PM) 12 hr format">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>

        {error12Hour && <div style={{ color: "red" }}>{error12Hour}</div>}
        <h5>24 hour format</h5>

        <Space>
          <Input
            placeholder="Enter time (HH:mm)"
            value={time24Hour}
            onChange={handleChange24Hour}
            style={{ width: "150px" }}
          />
          <Tooltip title="Enter time (00:00) 24 hr format">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>

        {error24Hour && <div style={{ color: "red" }}>{error24Hour}</div>}
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Space>
    </Space>
  );
};

export default TimeTest;
