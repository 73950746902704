import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Radio,
  Space,
  InputNumber,
  Divider,
  Tooltip,
  TimePicker,
} from "antd";
import {
  dosageTiming,
  medicineFormTypes,
  medicineFrequency,
  medicineRoutes,
  unitTags,
} from "../../../../helpers/medicineKeys";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { isTablet } from "react-device-detect";
import dayjs from "dayjs";

const { Option } = Select;
const format = "hh A";

const defaultTimes = {
  Once_Daily: ["08 AM"],
  Twice_Daily: ["08 AM", "08 PM"],
  Thrice_Daily: ["08 AM", "01 PM", "08 PM"],
  Four_Times_Daily: ["08 AM", "01 PM", "04 PM", "08 PM"],
};

const MedicineFields = ({
  editData,
  form,
  startMedicine,
  selectedFrequency,
  setSelectedFrequency,
  initial,
}) => {
  const [times, setTimes] = useState([]);

  const handleFrequencyChange = (value) => {
    setSelectedFrequency(value);
    const defaultTimeValues = defaultTimes[value] || [];
    setTimes(defaultTimeValues.map((time) => dayjs(time, format)));
    form.setFieldsValue({
      schedule: { times: defaultTimeValues.map((time) => dayjs(time, format)) },
    });
  };

  const handleTimeChange = (time, index) => {
    const newTimes = [...times];
    newTimes[index] = time;
    setTimes(newTimes);
    form.setFieldsValue({
      schedule: { times: newTimes },
    });
  };

  useEffect(() => {
    if (editData?.frequency) {
      setSelectedFrequency(editData?.frequency);
      const editTimes = editData?.schedule?.times
        ? editData?.schedule?.times?.map((time) => dayjs(time, "HH"))
        : times;
      setTimes(editTimes);
      form.setFieldsValue({
        schedule: { times: editTimes },
      });
    }
  }, [editData?.frequency]);

  const renderTimePickers = () => {
    let numPickers = 0;
    let labels = [];
    if (selectedFrequency === "Once_Daily") {
      numPickers = 1;
      labels = "";
    } else if (selectedFrequency === "Twice_Daily") {
      numPickers = 2;
      labels = ["Morning", "Evening"];
    } else if (selectedFrequency === "Thrice_Daily") {
      numPickers = 3;
      labels = ["Morning", "Afternoon", "Night"];
    } else if (selectedFrequency === "Four_Times_Daily") {
      numPickers = 4;
      labels = ["Morning", "Afternoon", "Evening", "Night"];
    }

    const timePickers = Array.from({ length: numPickers }, (_, index) => (
      <Col span={12} key={index}>
        <Form.Item
          // label={labels[index]}
          name={["schedule", "times", index]}
        >
          <TimePicker
            value={times[index]}
            format={format}
            onChange={(time) => handleTimeChange(time, index)}
          />
        </Form.Item>
      </Col>
    ));

    return <Row gutter={[8, 8]}>{timePickers}</Row>;
  };

  return (
    <Row>
      <Col span={4}>
        <Form.Item label="Route" name="route">
          <Select className={inputStyles.mdSelect} style={{ width: "100px" }}>
            {medicineRoutes?.map((item) => (
              <Option value={item?.value} key={item?.value}>
                {item?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={6}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Frequency"
              name="frequency"
              rules={[{ required: true, message: "Please choose frequency" }]}
            >
              <Select
                onChange={handleFrequencyChange}
                style={{ width: "150px" }}
                className={inputStyles.mdSelect}
              >
                {medicineFrequency?.map((item) => (
                  <Option value={item?.value} key={item?.value}>
                    {item?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!startMedicine && <Col span={24}>{renderTimePickers()}</Col>}

          <Col span={24}>
            {selectedFrequency === "Every_X_Hours" && (
              <Form.Item label="Enter hours" name={["schedule", "time"]}>
                <InputNumber
                  style={{ width: "300px" }}
                  min={0}
                  max={24}
                  className={inputStyles.mdNumberInput}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Col>

      <>
        <Col span={4}>
          <Form.Item name="dosage_timing" label="Timing">
            <Select
              style={{ width: "150px" }}
              className={inputStyles.mdSelect}
              placeholder="Timing"
            >
              {dosageTiming?.map((item) => (
                <Option value={item?.value} key={item?.value}>
                  {item?.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          {initial ? (
            <Form.Item label="Days Left" name="days_left_initial">
              <InputNumber
                className={`${inputStyles?.mdNumberInput}`}
                style={{ width: "80px" }}
              />
            </Form.Item>
          ) : (
            <Form.Item label="Duration (days)" name="duration">
              <InputNumber className={`${inputStyles?.mdNumberInput}`} />
            </Form.Item>
          )}
        </Col>
      </>

      <Col span={6}>
        <Form.Item label="Notes" name="comments">
          <Input.TextArea
            className={inputStyles.mdInput}
            style={{ width: "250px" }}
            rows={2}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default MedicineFields;
