import React from "react";
import {
  Row,
  Col,
  Card,
  List,
  Form,
  InputNumber,
  Checkbox,
  Button,
  Select,
} from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { paymentKeys } from "../receptionKeys";
import RecordPayments from "./RecordPayments";
import { formatToINR } from "../../../helpers/utility";

const SummaryBillCards = ({
  form,
  totalPrice,
  setTotalPrice,
  partialPaymentAmount,
  finalDiscountedPrice,
  partialPayment,
  setPartialPayment,
  id,
  loading,
  paymentData,
}) => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Card title="Summary Bill" bordered={false}>
          <List>
            <List.Item>
              <List.Item.Meta title="SubTotal (Including Taxes)" />
              <Form.Item name="gross_amount">
                <div> {formatToINR(totalPrice)}</div>
              </Form.Item>
            </List.Item>

            <List.Item>
              <List.Item.Meta title="Discount (In rupees)" />
              <Form.Item
                name="total_discount"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && Number(value) > Number(totalPrice)) {
                        return Promise.reject(
                          "Discount cannot be greater than sub total amount"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  disabled={id}
                  style={{ width: "300px" }}
                  min={0}
                  className={inputStyles.mdNumberInput}
                />
              </Form.Item>
            </List.Item>
            <List.Item>
              <List.Item.Meta title="Total" />
              <Form.Item name="total_amount">
                <div> {formatToINR(finalDiscountedPrice)}</div>
              </Form.Item>
            </List.Item>
          </List>
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Record Payment" bordered={false}>
          <List>
            <List.Item>
              <List.Item.Meta title="Amount" />
              <Form.Item
                name={["payment", "amount"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        Number(value) > Number(finalDiscountedPrice)
                      ) {
                        return Promise.reject(
                          "Amount cannot be greater than total amount"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  prefix="Rs"
                  disabled={!partialPayment}
                  // value={
                  //   partialPayment ? partialPaymentAmount : finalDiscountedPrice
                  // }
                  style={{ width: "300px" }}
                  className={inputStyles.mdNumberInput}
                />
              </Form.Item>
            </List.Item>

            <Form.Item name="partial_payment">
              <Checkbox
                checked={partialPayment}
                onChange={(e) => setPartialPayment(e.target.checked)}
              />{" "}
              Partial Payment
            </Form.Item>
            {id && (
              <List.Item>
                <List.Item.Meta title="Discount (In rupees)" />
                <Form.Item
                  name="discount"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          Number(value) > Number(partialPaymentAmount)
                        ) {
                          return Promise.reject(
                            "Discount cannot be greater than amount"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "300px" }}
                    min={0}
                    className={inputStyles.mdNumberInput}
                  />
                </Form.Item>
              </List.Item>
            )}
            <List.Item>
              <List.Item.Meta title="Payment Mode" />
              <Form.Item
                name={["payment", "mode"]}
                rules={[
                  { required: true, message: "Please select a payment mode" },
                ]}
              >
                <Select
                  style={{ width: "300px" }}
                  options={paymentKeys}
                  placeholder="Select Payment Mode"
                  className={inputStyles.mdSelect}
                />
              </Form.Item>
            </List.Item>
          </List>
          {id && <RecordPayments paymentData={paymentData?.payments} />}
          <div style={{ textAlign: "center" }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className={btnStyles.lgBtn}
            >
              Generate Receipt
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default SummaryBillCards;
