import React, { useContext, useEffect } from "react";
import SpeechSwallowFunctionalDiagnosis from "./SpeechSwallowFunctionalDiagnosis";

const SpeechSwallowInitialFunctionalDiagnosis = ({ form, context }) => {
  const { formData } = useContext(context);
  useEffect(() => {
    form.setFieldsValue(formData?.functional_diagnosis);
  }, [formData]);
  return (
    <>
      <SpeechSwallowFunctionalDiagnosis context={context} />
    </>
  );
};

export default SpeechSwallowInitialFunctionalDiagnosis;
