import React, { useContext, useEffect, useMemo, useState } from "react";
import { Select, Form, Radio } from "antd";

import CheckListFBYSelect from "../../../../../../blocks/FormItems/CheckListFBYSelect";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import {
  abnormalBreathZoneOptions,
  auscultationOptions,
  breathSoundsOptions,
  typeOfBreathingOptions,
} from "../../../../Options/systemicExamOptions";

const RespiratoryForm = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [abnormalBreath, setAbnormalBreath] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});

  const handleAbnormalBreathChange = (e) => {
    setAbnormalBreath(e.target.value === "abnormal_breath_sounds");
  };

  // useEffect to update state when initialValues change
  useEffect(() => {
    setAbnormalBreath(
      initialValues?.systemic_examination?.auscultation ===
        "abnormal_breath_sounds"
        ? true
        : false
    );
    const initialCheckboxes = abnormalBreathZoneOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          getInitialValuesForChild(
            abnormalBreathZoneOptions,
            initialValues?.systemic_examination !== null &&
              initialValues?.systemic_examination
          )[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Form.Item label="Type of Breathing" name="type_of_breathing">
        <Select placeholder="Select" options={typeOfBreathingOptions} />
      </Form.Item>

      <Form.Item label="Ausculation" name="auscultation">
        <Radio.Group
          options={auscultationOptions}
          onChange={handleAbnormalBreathChange}
        />
      </Form.Item>
      {abnormalBreath && (
        <div className="subsection-card">
          <CheckListFBYSelect
            checkBoxOptions={abnormalBreathZoneOptions}
            selectOptions={breathSoundsOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default RespiratoryForm;
