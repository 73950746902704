import React, { useContext, useEffect, useMemo, useState } from "react";
import { Typography } from "antd";
import CheckListFBYInput from "../../../../../blocks/FormItems/CheckListFBYInput";
import {
  medicalTreatmentOptions,
  surgicalTreatmentOptions,
} from "../../../Options/historyOptions";

const { Text } = Typography;

const TreatmentHistory = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = [
      ...medicalTreatmentOptions,
      ...surgicalTreatmentOptions,
    ].reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.history?.treatment_history?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Text>Treatment History</Text>
      <div className="subsection-card">
        <Text>Medical Treatment History</Text>
        <CheckListFBYInput
          options={medicalTreatmentOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          keyChain={"treatment_history"}
        />
      </div>
      <div className="subsection-card">
        <Text level={5}>Surgical Treatment History</Text>
        <CheckListFBYInput
          options={surgicalTreatmentOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          keyChain={"treatment_history"}
        />
      </div>
    </>
  );
};

export default TreatmentHistory;
