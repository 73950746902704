export const presentIllnessOptions = [
  { label: "Motor Vehicle Accident", value: "accident" },
  { label: "Accidental Fall", value: "fall" },
  { label: "Head Injury", value: "head_injury" },
  { label: "Spine Injury", value: "spine_injury" },
  { label: "Stroke", value: "stroke" },
  { label: "Others", value: "illness_others" },
];

export const chiefComplaintsOptions = [
  { label: "Pain With Radiation", value: "pain_with_radiation" },
  { label: "Pain Without Radiation", value: "pain_without_radiation" },
  { label: "Stiffness", value: "stiffness" },
  { label: "General Weakness", value: "general_weakness" },
  { label: "Balance and Gait Disorder", value: "balance_an_gai_disorder" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "SOB", value: "sob" },
  { label: "Incoordination", value: "incoordination" },
  { label: "Cognitive Dysfunction", value: "cognitive_dysfunction" },
  { label: "Speech Disorder", value: "speech_disorder" },
  {
    label: "Agility / Endurance Training",
    value: "agility_endurance_training",
  },
  { label: "Delayed Milestones", value: "delayed_milestones" },
  { label: "ADHD", value: "adhd" },
  { label: "Autism Spectrum", value: "autism_spectrum" },
  { label: "Swelling", value: "swelling" },
  { label: "Wheeze", value: "wheeze" },
  { label: "Vertigo", value: "vertigo" },
  { label: "Dysphagia", value: "dysphagia" },
  { label: "Memory Loss Disorder", value: "memory_loss_disorder" },
  { label: "Tingling & Numbness", value: "tingling_numbness" },
  { label: "Others", value: "others" },
];

export const maritalStatusOptions = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Divorced", value: "divorced" },
  { label: "Widowed", value: "widowed" },
];
export const educationalOptions = [
  { label: "Post Graduate", value: "post_graduate" },
  { label: "Graduate", value: "graduate" },
  { label: "High Schooling", value: "high_schooling" },
  { label: "Primary Schooling", value: "primary_schooling" },
  { label: "Uneducated", value: "uneducated" },
];
export const smokingOptions = [
  { label: "Smoker", value: "smoker" },
  { label: "Non Smoker", value: "non_smoker" },
  { label: "Stopped Smoking", value: "stopped_smoking" },
];

export const alcoholOptions = [
  { label: "Alcoholic", value: "alcoholic" },
  { label: "Social Drinking", value: "social_drinking" },
  { label: "Non Alcoholic", value: "non_alcoholic" },
];

export const functionalOptions = [
  { label: "Independent", value: "independent" },
  { label: "Dependent", value: "dependent" },
  { label: "Assisted Living", value: "assisted_living" },
  { label: "Bed Bound", value: "bed_bound" },
  { label: "Wheel Chair Bound", value: "wheel_chair_bound" },
];
export const informantOptions = [
  { label: "Self", value: "self" },
  { label: "Relative", value: "relative" },
];
export const relationOptions = [
  { label: "Father", value: "father" },
  { label: "Mother", value: "mother" },
  { label: "Brother", value: "brother" },
  { label: "Sister", value: "sister" },
  { label: "Son", value: "son" },
  { label: "Daughter", value: "daughter" },
  { label: "Spouse", value: "spouse" },
  { label: "Other", value: "other" },
];

export const knownAllergiesOptions = [
  { label: "Drugs", value: "drugs" },
  { label: "Food", value: "food" },
  { label: "Dust", value: "dust" },
  { label: "Others", value: "others" },
];

export const pastMedicalOptions = [
  { label: "Hypertension", value: "hypertension" },
  { label: "Diabetes", value: "diabetes" },
  { label: "IHD", value: "ihd" },
  { label: "CVA", value: "cva" },
  { label: "TIA", value: "tia" },
  { label: "Seizures", value: "seizures" },
  { label: "Asthma", value: "asthma" },
  { label: "COPD", value: "copd" },
  { label: "CKD", value: "ckd" },
  { label: "Others", value: "past_medical_others" },
];

export const controlOptions = [
  { label: "Well Controlled", value: "well_controlled" },
  { label: "Fairly Controlled", value: "fairly_controlled" },
  { label: "Poorly Controlled", value: "poorly_controlled" },
];

export const medicalTreatmentOptions = [
  { label: "ICU Care", value: "icu_care" },
  { label: "Ventilatory Support", value: "ventilatory_support" },
  { label: "Tracheostomy", value: "tracheostomy" },
  { label: "Others", value: "medical_treatment_others" },
];

export const surgicalTreatmentOptions = [
  { label: "Craniotomy", value: "craniotomy" },
  { label: "Spine Fixation", value: "spine_fixation" },
  { label: "Fracture Fixation", value: "fracture_fixation" },
  { label: "Others", value: "surgical_treatment_others" },
];

export const modeOfArrivalOptions = [
  { label: "Walking", value: "walking" },
  { label: "Wheel Chair", value: "wheel_chair" },
  { label: "Stretcher", value: "stretcher" },
];

export const nutritionistFunctionalOptions = [
  { label: "No Dysfunction", value: "no_dysfunction" },
  { label: "Working Sub Optimally", value: "working_sub_optimally" },
  { label: "Ambulatory (Not Working)", value: "ambulatory" },
  { label: "Bed Bound", value: "bed_bound" },
  { label: "Wheel Chair Bound", value: "wheel_chair_bound" },
  { label: "Comatose", value: "comatose" },
];

export const metabolicStressOptions = [
  { label: "Mild", value: "mild" },
  { label: "Moderate", value: "moderate" },
  { label: "Severe", value: "severe" },
];

export const appetiteOptions = [
  { label: "Good", value: "good" },
  { label: "Fair", value: "fair" },
  { label: "Poor", value: "poor" },
  { label: "RT Feeds", value: "rt_feeds" },
  { label: "Peg Feed", value: "peg_feed" },
];

export const gLSymptomsTimelineOptions = [
  { label: "Less than 2 weeks", value: "less_than_2_weeks" },
  { label: "Greater than 2 weeks", value: "greater_than_2_weeks" },
];

export const gLSymptomsTypeOptions = [
  { label: "None", value: "none" },
  { label: "Nausea", value: "nausea" },
  { label: "Vomiting", value: "vomiting" },
  { label: "Constipation", value: "constipation" },
  { label: "Diarrhea", value: "diarrhea" },
  { label: "Difficulty Swallowing", value: "difficulty_swallowing" },
  { label: "Difficuly Chewing", value: "difficulty_chewing" },
  { label: "Non Functional GIT", value: "non_functional_git" },
];
