import { Descriptions } from "antd";
import React from "react";
import {
  getDepressionScaleTags,
  getLabelScalesTags,
  getLabelTagInput,
} from "../constructTextFromValues";
import {
  beckAnxietyOptions,
  beckDepressionOptions,
  emotionalIntelligenceOptions,
} from "../../Options/psychologistOptions";

const PsychologistScalesReviewSummary = ({ formData }) => {
  const constructScalesSummaryItems = () => {
    const scalesSummaryItems = [];
    const scales = formData?.scales || {};
    const pushScalesItem = (key, label, children) => {
      scalesSummaryItems.push({ key, label, children });
    };
    if (scales?.emotional_intelligence) {
      pushScalesItem(
        "1",
        "Emotional Intelligence Scale",
        <>
          {getLabelScalesTags(
            emotionalIntelligenceOptions,
            scales?.emotional_intelligence
          )}
        </>
      );
    }
    if (scales?.beck_anxiety) {
      pushScalesItem(
        "2",
        "Beck Anxiety Inventory",
        <>{getLabelScalesTags(beckAnxietyOptions, scales?.beck_anxiety)}</>
      );
    }
    if (scales?.beck_depression) {
      pushScalesItem(
        "3",
        "Beck Depression Inventory",
        <>
          {getDepressionScaleTags(
            beckDepressionOptions,
            scales?.beck_depression
          )}
        </>
      );
    }
    return scalesSummaryItems;
  };
  const scalesSummaryItems = constructScalesSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={"Scales"}
        items={scalesSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PsychologistScalesReviewSummary;
