import React from "react";
import { Tabs } from "antd";
import NIHSScale from "./NIHSScale";
import MOCAScale from "./MOCAScale";
import RASScale from "./RASScale";
import ASIAScale from "./ASIAScale";
import VAScale from "./VAScale";
import MAScale from "./MAScale";
import MMTScale from "./MMTScale";
import ROMScale from "./ROMScale";
import VCGScale from "./VCGScale";
import RPEScale from "./RPEScale";
import PeakFlowMeterTest from "./PeakFlowMeterTest";
import SixMinuteWalkTest from "./SixMinuteWalkTest";
import Coordination from "./Coordination";
import TCMScale from "./TCMScale";
import FingerGripTest from "./FingerGripTest";
import BarthelIndex from "./BarthelIndex";
import { PhysioReviewContext } from "../../../Physiotherapist/PhysiotherapistReview/PhysioReviewFormProvider";
import GCScale from "./GCScale";
import Reflexes from "./Reflexes";
// import "./PhysioScales.css"; // Custom CSS

const { TabPane } = Tabs;

const PhysioScales = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  scaleTab,
  setScaleTab,
  setActiveTab,
}) => {
  // if (scaleTab === "19") {
  //   setActiveTab((prev) => (parseInt(prev) + 1).toString());
  // }
  return (
    <div className="physio-scales">
      <Tabs
        activeKey={scaleTab}
        tabPosition="top"
        animated={{ tabPane: true }}
        onChange={(key) => {
          setScaleTab(key);
        }}
      >
        <TabPane tab="GCS" key="1">
          <GCScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            scalesId={scalesId}
            setScalesId={setScalesId}
            scaleTab={scaleTab}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="NIHSS" key="2">
          <NIHSScale
            onScalesSubmit={onScalesSubmit}
            admission={admission}
            setActiveTab={setActiveTab}
            scalesId={scalesId}
            setScalesId={setScalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="MOCA" key="3">
          <MOCAScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="RAS" key="4">
          <RASScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            scalesId={scalesId}
            setScalesId={setScalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="ASIA" key="5">
          <ASIAScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="VAS" key="6">
          <VAScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="MAS" key="7">
          <MAScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="MMT" key="8">
          <MMTScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="ROM" key="9">
          <ROMScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="VCG" key="10">
          <VCGScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="RPE" key="11">
          <RPEScale
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="Reflexes" key="12">
          <Reflexes
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="Peak Flow Meter Test" key="13">
          <PeakFlowMeterTest
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="Six Minute Walk Test" key="14">
          <SixMinuteWalkTest
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="Coordination" key="15">
          <Coordination
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="TCM" key="16">
          <TCMScale
            context={PhysioReviewContext}
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="Finger Grip Test" key="17">
          <FingerGripTest
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
        <TabPane tab="Barthel Index" key="18">
          <BarthelIndex
            admission={admission}
            setActiveTab={setActiveTab}
            onScalesSubmit={onScalesSubmit}
            setScalesId={setScalesId}
            scalesId={scalesId}
            setScaleTab={setScaleTab}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PhysioScales;
