import React, { useContext, useEffect } from "react";
import Informant from "./Informant/Informant";
import { Form, Input } from "antd";
import { PsychologistInitialContext } from "../../Psychologist/InitialInspection/PsychologistInitialAssessmentProvider";

const PsychologistPatientDemographics = ({ form }) => {
  const { formData } = useContext(PsychologistInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.patient_demographics);
  }, [formData]);
  return (
    <>
      <Informant context={PsychologistInitialContext} />
      <Form.Item label="Education" name={"education"}>
        <Input placeholder="Please enter the details" />
      </Form.Item>
      <Form.Item label="Occupation" name={"occupation"}>
        <Input placeholder="Please enter the details" />
      </Form.Item>
      <Form.Item label="Marital Status" name={"marital_status"}>
        <Input placeholder="Please enter the details" />
      </Form.Item>
      <Form.Item label="Type of marriage" name={"type_of_marriage"}>
        <Input placeholder="Please enter the details" />
      </Form.Item>
    </>
  );
};

export default PsychologistPatientDemographics;
