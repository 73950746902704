import { Form, Input, Radio } from "antd";
import React from "react";
import { coughEffectivenessOptions } from "../../Options/RespiratoryTherapistOptions";

const CoughEffectiveness = () => {
  return (
    <>
      <Form.Item
        label="Cough Effectiveness"
        name={["cough_effectiveness", "status"]}
      >
        <Radio.Group options={coughEffectivenessOptions} />
      </Form.Item>
      <Form.Item name={["cough_effectiveness", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default CoughEffectiveness;
