import React, { useContext } from "react";
import { Col, Menu, Row, Image, Drawer } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dashboardIcon from "../../../assets/images/menuImages/dashboard.svg";
import patients from "../../../assets/images/menuImages/patients.svg";
import patientsActive from "../../../assets/images/menuImages/patientsActive.svg";
import homeActive from "../../../assets/images/menuImages/homeActive.svg";
import { UserContext } from "../../../context/UserContext";
import { CalendarOutlined } from "@ant-design/icons";

const DocSideMenu = ({ collapsed, open, setOpen }) => {
  const { userDetails } = useContext(UserContext);
  const router = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    .replaceAll("_", "-");
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const menuImages = (icon) => {
    return (
      <span
        role="img"
        aria-label="user"
        class="anticon anticon-user ant-menu-item-icon"
      >
        <Image
          src={icon}
          preview={false}
          style={{ height: "20px", width: "auto", marginBottom: "8px" }}
        />
      </span>
    );
  };
  const items = [
    getItem(
      "Home",
      "home",
      router?.tab === "home"
        ? menuImages(homeActive)
        : menuImages(dashboardIcon)
    ),
    getItem("Manage Appointments", "appointments", <CalendarOutlined />),
    getItem(
      "Patients",
      "patients",
      router?.tab === "patients"
        ? menuImages(patientsActive)
        : menuImages(patients)
    ),
  ];
  const onClick = (e) => {
    navigate(`/${role}/${e.key}`);
  };
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Drawer
              //   title={userDetails?.activeApp?.name}
              placement={"left"}
              closable={true}
              onClose={() => setOpen(false)}
              open={open}
              style={{ height: "100vh" }}
            >
              <Menu
                style={{ border: 0 }}
                onClick={onClick}
                selectedKeys={[router?.tab]}
                defaultSelectedKeys={[router?.tab]}
                inlineCollapsed={collapsed}
                items={items}
              />
            </Drawer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DocSideMenu;
