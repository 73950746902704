import React, { useState, useEffect, useContext } from "react";
import { Button, message, Statistic, Table, Tag } from "antd";
import {
  deleteIpServicesApi,
  getIpServicesListApi,
} from "../../../../services/receptionist.services";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";
import AssignServiceModal from "./AssignServiceModal";
import { getFacilityServicesApi } from "../../../../services/service.services";
import { getCookie } from "../../../../helpers/localStorage";
import { UserContext } from "../../../../context/UserContext";
import { FacilityID } from "../../../../constants/defaultKeys";
import { DeleteOutlined } from "@ant-design/icons";
import {
  capitalizeFirstLetter,
  formatToINR,
} from "../../../../helpers/utility";

const AssignedServicesTable = () => {
  const { userDetails } = useContext(UserContext);
  const params = useQuery();
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);

  const refreshServices = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getIpServicesListApi(`${params?.patient}`)
      .then((res) => {
        setServices(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  const onDelete = (patient_service_id) => {
    deleteIpServicesApi(params?.patient, patient_service_id)
      .then((res) => {
        message.success(res?.data?.message);
        refreshServices();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Price (Rs)",
      dataIndex: "price",
      key: "price",
      render: (value) => formatToINR(value),
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
      render: (value) => {
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      title: "To Date",
      dataIndex: "till_date",
      key: "till_date",
      render: (value) => {
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        return (
          <Tag color={value === "Ordered" ? "blue" : "green"}>{value}</Tag>
        );
      },
    },
    {
      title: "Ordered By",
      dataIndex: "ordered_by_name",
      key: "ordered_by_name",
      render: (value) => {
        return capitalizeFirstLetter(value);
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button
          onClick={() => {
            onDelete(record.id);
          }}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={services}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </>
  );
};

export default AssignedServicesTable;
