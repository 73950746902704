import React from "react";
import { Form, Radio } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";

const MeningealSigns = () => {
  return (
    <>
      <Form.Item label="Neck Stiffness" name="neck_stiffness">
        <Radio.Group options={yesNoOptions} />
      </Form.Item>
      <Form.Item label="Kernig's Sign" name="kernig_sign">
        <Radio.Group options={yesNoOptions} />
      </Form.Item>
    </>
  );
};

export default MeningealSigns;
