import { ConfigProvider } from "antd";
import "./App.css";
import ProjectRoutes from "./routes/Routes";
import { customLightTheme } from "./theme/customTheme";

function App() {
  return <ProjectRoutes />;
}

export default App;
