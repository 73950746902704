import React, { useContext } from "react";
import { NurseInitialContext } from "../../InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import { Col, Row } from "antd";
import NurseHistorySummary from "../../InspectionSummaries/HistorySummary/NurseHistorySummary";
import NurseFunctionalAssessmentSummary from "../../InspectionSummaries/FunctionalExaminationSummary/NurseFunctionalAssessmentSummary";
import NurseExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/NurseExaminationSummary";
import NurseSpecialCareRequirementSummary from "../../InspectionSummaries/NurseSpecialCareRequirementSummary/NurseSpecialCareRequirementSummary";

const NurseInitialInspectionSummary = () => {
  const { formData } = useContext(NurseInitialContext);
  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.history && <NurseHistorySummary formData={formData} />}
        {formData?.functional_status && (
          <NurseFunctionalAssessmentSummary formData={formData} />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.examination && (
          <NurseExaminationSummary formData={formData} />
        )}
        {formData?.special_care && (
          <NurseSpecialCareRequirementSummary formData={formData} />
        )}
      </Col>
    </Row>
  );
};

export default NurseInitialInspectionSummary;
