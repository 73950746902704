import React, { useContext, useEffect, useState } from "react";
import {
  oralIntakeOptions,
  totalOralIntakeOptions,
  tubeDependentOptions,
} from "../../../Options/speechSwallowOptions";
import { Checkbox, Form, Input, Typography } from "antd";
import CheckListFBYInput from "../../../../../blocks/FormItems/CheckListFBYInput";

const FunctionalOralIntake = ({ context }) => {
  const { formData } = useContext(context);
  const [showSubOptions, setShowSubOptions] = useState({});
  const [checkboxes, setCheckboxes] = useState({});

  const handleCheckboxChange = (value) => {
    setShowSubOptions((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  useEffect(() => {
    const intialSubOptions = oralIntakeOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.functional_oral_intake?.[checkbox.value] || false;
      return acc;
    }, {});
    setShowSubOptions(intialSubOptions);
    const tubeDependent = tubeDependentOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.functional_oral_intake?.[checkbox.value] || false;
      return acc;
    }, {});
    const oralIntake = totalOralIntakeOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.functional_oral_intake?.[checkbox.value] || false;
      return acc;
    }, {});
    setCheckboxes({ ...tubeDependent, ...oralIntake });
  }, [formData]);

  return (
    <>
      <Typography.Title level={5}>
        Functional Oral Intake Scale
      </Typography.Title>
      {oralIntakeOptions.map((option) => (
        <React.Fragment key={option.value}>
          <Form.Item
            name={["functional_oral_intake", option.value]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={showSubOptions[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {showSubOptions[option.value] && (
            <div className="subsection-card">
              <CheckListFBYInput
                options={
                  option.value === "tube_dependent"
                    ? tubeDependentOptions
                    : totalOralIntakeOptions
                }
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                keyChain={"functional_oral_intake"}
              />
            </div>
          )}
        </React.Fragment>
      ))}
      <Form.Item name={["functional_oral_intake", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default FunctionalOralIntake;
