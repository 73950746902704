import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Select, Typography, message } from "antd";
import {
  getVcgScaleAPI,
  updateVcgScaleAPI,
  vcgScaleAPI,
} from "../../../../../services/physio.services";

const VCGScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const maScaleOptions = [
    { label: "1/6 (Movement can only be a reflex action)", value: 1 },
    {
      label: "2/6 (Movement can be performed when placed in a mass pattern)",
      value: 2,
    },
    {
      label:
        "3/6 (Movement can be performed over a limited range with synergy)",
      value: 3,
    },
    {
      label:
        "4/6 (Movement can be performed in a facilitated position with synergy)",
      value: 4,
    },
    {
      label:
        "5/6 (Movement can be performed in the prescribed range with slight synergy)",
      value: 5,
    },
    {
      label:
        "6/6 (Movement can be performed in full range in positions without any synergy)",
      value: 6,
    },
  ];

  const limbOptions = [
    {
      title: "Left",
      options: [
        { label: "Upper Limb", value: "upper_left_limb" },
        { label: "Lower Limb", value: "lower_left_limb" },
      ],
    },
    {
      title: "Right",
      options: [
        { label: "Upper Limb", value: "upper_right_limb" },
        { label: "Lower Limb", value: "lower_right_limb" },
      ],
    },
  ];

  const handleSubmit = async (values) => {
    const finalData = { ...values, ip_admission: admission };
    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateVcgScaleAPI(scalesId?.vcgs, finalData);
      } else {
        const response = await vcgScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, vcgs: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully!");
    } catch (error) {
      console.error(`Error: ${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.vcgs) {
      try {
        const response = await getVcgScaleAPI(admission, scalesId?.vcgs);
        form.setFieldsValue(response.data);
        setFormData(response.data);
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Form form={form} onFinish={handleSubmit} style={{ width: "600px" }}>
          {limbOptions.map((option) => (
            <>
              <Card key={option.title} title={option.title}>
                {option.options.map((limb) => (
                  <React.Fragment key={limb.value}>
                    <Typography>{limb.label}</Typography>
                    <Form.Item name={limb.value}>
                      <Select options={maScaleOptions} />
                    </Form.Item>
                  </React.Fragment>
                ))}
              </Card>
              <br />
            </>
          ))}
          <Form.Item name="comments">
            <Input placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default VCGScale;
