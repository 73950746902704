import { Col, Form, List, Radio, Row, Typography } from "antd";
import React from "react";
import { orientationFieldsOptions } from "../../../../Options/physioOptions";
import { LikeOutlined } from "@ant-design/icons";

const Orientation = () => {
  return (
    <>
      <List
        bordered
        style={{ background: "#fff", width: "600px" }}
        dataSource={orientationFieldsOptions}
        renderItem={(field) => (
          <List.Item>
            <Row style={{ width: "100%" }} align="middle">
              <Col span={12}>
                <Typography>{field.label}</Typography>
              </Col>
              <Col span={12}>
                <Form.Item
                  key={field.value}
                  name={["orientation_data", field.value]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="space-around" align={"middle"}>
                      <Col>
                        <Radio.Button
                          value={"yes"}
                          style={{ backgroundColor: "#F9F5FF" }}
                        >
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button
                          value={""}
                          style={{ backgroundColor: "#FFF5F5" }}
                        >
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default Orientation;
