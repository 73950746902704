import React, { useContext } from "react";
import { getLabelTag } from "../constructTextFromValues";
import {
  bowelIncontinenceOptions,
  ambulanceOptions,
  bladderIncontinenceOptions,
  ambulanceAssistanceOptions,
  ambulanceBedBoundOptions,
} from "../../Options/funcExamOptions";
import { Descriptions, Tag } from "antd";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";

const DrFunctionalExaminationSummary = () => {
  const { formData } = useContext(DoctorInitialContext);
  console.log("funcional", formData?.functional_examination);
  const functionalExaminationSummaryItems = [];

  const constructFunctionalExaminationSummaryItems = () => {
    if (formData?.functional_examination?.ambulation) {
      functionalExaminationSummaryItems.push({
        key: "1",
        label: "Ambulation",
        children: (
          <>
            {getLabelTag(
              ambulanceOptions,
              formData?.functional_examination?.ambulantion
            )}
            {getLabelTag(
              ambulanceAssistanceOptions,
              formData?.functional_examination?.ambulation_assistance
            )}
            {getLabelTag(
              ambulanceBedBoundOptions,
              formData?.functional_examination?.ambulation_bed_bound
            )}
          </>
        ),
      });
    }
    if (formData?.functional_examination?.bowel_incontinence) {
      functionalExaminationSummaryItems.push({
        key: "2",
        label: "Bowel Incontinence",
        children: getLabelTag(
          bowelIncontinenceOptions,
          formData?.functional_examination?.bowel_incontinence
        ),
      });
    }
    if (formData?.functional_examination?.bladder_incontinence) {
      functionalExaminationSummaryItems.push({
        key: "3",
        label: "Bladder Incontinence",
        children: getLabelTag(
          bladderIncontinenceOptions,
          formData?.functional_examination?.bladder_incontinence
        ),
      });
    }
    return functionalExaminationSummaryItems;
  };

  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title="Functional Examination"
        items={constructFunctionalExaminationSummaryItems()}
      />
    </div>
  );
};

export default DrFunctionalExaminationSummary;
