import { Form, Select } from "antd";
import React from "react";
import { postureOptions } from "../../../../Options/physioOptions";

const Posture = () => {
  return (
    <>
      <Form.Item label="Posture" name={"posture"}>
        <Select options={postureOptions} />
      </Form.Item>
    </>
  );
};

export default Posture;
