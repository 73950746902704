import React, { useRef, useState, useEffect, useContext } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Input,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { getFacilityListApi } from "../../../services/facilities.services";
import { validateConfig } from "../../../config/validate.config";
import { userRoles } from "../../../helpers/utility";
import { addUserApi, updateUserApi } from "../../../services/auth.services";
import RoleForm from "./RoleForm";
import { useLocation } from "react-router-dom";
import { setCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";

const UsersForm = ({ isVisible, setIsVisible, editListData, refreshPage }) => {
  const { userDetails } = useContext(UserContext);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (editListData) {
      form.setFieldsValue({
        ...editListData,
      });
    }
  }, [editListData]);

  useEffect(() => {
    getFacilityListApi()
      .then((res) => {
        setFacilities(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFinish = (values) => {
    const formData = {
      ...values,
      roles: values?.roles?.map((role) => {
        return {
          ...role,
          facility: setCookie(FacilityID) || userDetails?.currFacility,
        };
      }),
    };
    const formSubmitData =
      userDetails?.activeOrg?.default_role === "Facility_Admin"
        ? formData
        : values;

    setBtnLoading(true);
    if (editListData) {
      updateUserApi({ ...formSubmitData, user_id: editListData?.user_id })
        .then((res) => {
          message.success(res?.data?.message);
          setIsVisible(false);
          setBtnLoading(false);
          refreshPage();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    } else {
      addUserApi(formSubmitData)
        .then((res) => {
          message.success(res?.data?.message);
          setIsVisible(false);
          setBtnLoading(false);
          refreshPage();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    }
  };

  const onClose = () => {
    setIsVisible(false);
  };

  return (
    <Drawer
      open={isVisible}
      width={"70%"}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              {editListData ? "Edit User" : "Add User"}
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: "right" }}>
          <Form.Item>
            <Button
              loading={btnLoading}
              className={btnStyles.lgBtn}
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      }
      onClose={onClose}
      closeIcon={null}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        ref={formRef}
        requiredMark={false}
        initialValues={{
          roles: [{}],
        }}
      >
        <Form.Item
          label="First Name"
          name="first_name"
          rules={validateConfig.isRequired("First Name")}
        >
          <Input
            className={inputStyles.mdInput}
            style={{ width: "300px" }}
            placeholder="Enter first name"
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          rules={validateConfig.isRequired("Last Name")}
        >
          <Input
            className={inputStyles.mdInput}
            style={{ width: "300px" }}
            placeholder="Enter last name"
          />
        </Form.Item>
        <Form.Item
          label="Contact Number"
          name="mobile"
          rules={[
            { required: true, message: "Please enter mobile number" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          ]}
        >
          <Input
            style={{ width: "300px" }}
            maxLength="10"
            placeholder="Mobile number"
            addonBefore={"+91"}
            className={inputStyles.mdAddonInput}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { type: "email", message: "Please enter a valid email" },
            { required: true, message: "Please enter email" },
          ]}
        >
          <Input
            placeholder="Enter your email"
            className={inputStyles.mdInput}
            style={{ width: "300px" }}
          />
        </Form.Item>
        <RoleForm
          userRoles={userRoles}
          editListData={editListData}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          form={form}
          facilities={facilities}
          params={params}
          userDetails={userDetails}
        />
      </Form>
    </Drawer>
  );
};

export default UsersForm;
