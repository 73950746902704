import React, { createContext, useState } from "react";

export const NurseInitialContext = createContext({});
const NurseInitialAssessmentFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <NurseInitialContext.Provider value={{ formData, setFormData }}>
      {children}
    </NurseInitialContext.Provider>
  );
};

export default NurseInitialAssessmentFormProvider;
