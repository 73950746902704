import React, { createContext, useState } from "react";
export const RespiratoryReviewContext = createContext({});
const RespiratoryTherapistReviewProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <RespiratoryReviewContext.Provider value={{ formData, setFormData }}>
      {children}
    </RespiratoryReviewContext.Provider>
  );
};

export default RespiratoryTherapistReviewProvider;
