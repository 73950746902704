import { Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import BarthelIndex from "../../Examination/PhysioScales/BarthelIndex";

const PhysioDailyActivities = ({ context }) => {
  const { formData } = useContext(context);
  const [showDailyActivites, setShowDailyActivites] = useState(
    formData?.functional_status?.daily_activities === "assessable"
  );
  useEffect(() => {
    setShowDailyActivites(
      formData?.functional_status?.daily_activities === "assessable"
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Daily Activities" name="daily_activities">
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) =>
            setShowDailyActivites(e.target.value === "assessable")
          }
        />
      </Form.Item>
      {showDailyActivites && <BarthelIndex />}
    </>
  );
};

export default PhysioDailyActivities;
