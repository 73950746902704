//Give a function to return if a field is required or not rule. The forms are built using antd.
export const validateConfig = {
  isRequired: (fieldName) => {
    return [
      {
        required: true,
        message: `Please input ${fieldName}`,
      },
    ];
  },
};
