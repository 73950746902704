import { Form, Radio, Typography } from "antd";
import React from "react";
import { regularIrregularOptions } from "../../../../Options/commonOptions";
import { pulseVolumeOptions } from "../../../../Options/genExamOptions";

const Pulse = () => {
  return (
    <>
      <Typography>Pulse</Typography>
      <div className="subsection-card">
        <Form.Item name={["pulse_details", "pulse_regular"]}>
          <Radio.Group options={regularIrregularOptions} />
        </Form.Item>
        <Form.Item name={["pulse_details", "pulse_volume"]}>
          <Radio.Group options={pulseVolumeOptions} />
        </Form.Item>
      </div>
    </>
  );
};

export default Pulse;
