import React, { useContext, useState, useEffect } from "react";
import {
  Form,
  Radio,
  Checkbox,
  Input,
  InputNumber,
  Row,
  Col,
  Space,
  Typography,
} from "antd";
import {
  cannulaOptions,
  centralLineOptions,
  peripheralCannulaOptions,
} from "../../../../Options/genExamOptions";

const IVAccess = ({ context }) => {
  const { formData } = useContext(context);
  const cannulaValue =
    formData?.general_examination?.peripheral_cannula ||
    formData?.examination?.peripheral_cannula;
  const [cannulaSelection, setCannulaSelection] = useState(
    cannulaValue === "yes_2"
      ? "yes_2"
      : cannulaValue === "yes_1"
      ? "yes_1"
      : "no"
  );
  const [centralLineSelection, setCentralLineSelection] = useState(
    formData?.general_examination?.central_line === "yes" ||
      formData?.examination?.central_line === "yes"
  );

  useEffect(() => {
    setCannulaSelection(
      cannulaValue === "yes_2"
        ? "yes_2"
        : cannulaValue === "yes_1"
        ? "yes_1"
        : "no"
    );
    setCentralLineSelection(
      formData?.general_examination?.central_line === "yes" ||
        formData?.examination?.central_line === "yes"
    );
  }, [formData]);
  return (
    <>
      <Typography>IV Access</Typography>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Peripheral Cannula" name="peripheral_cannula">
            <Radio.Group
              options={peripheralCannulaOptions}
              value={cannulaSelection}
              onChange={(e) => setCannulaSelection(e.target.value)}
            />
          </Form.Item>
        </Col>
        {cannulaSelection !== "no" && (
          <div className="subsection-card">
            <Space>
              <Form.Item label="Cannula 1 Site" name={`cannula_site1`}>
                <Input placeholder="Cannula 1 Site" />
              </Form.Item>
              <Form.Item label="Cannula 1 Type" name={`cannula_type1`}>
                <Checkbox.Group options={cannulaOptions} />
              </Form.Item>
            </Space>
            {cannulaSelection === "yes_2" && (
              <>
                <Space>
                  <Form.Item label="Cannula 2 Site" name={`cannula_site2`}>
                    <Input placeholder="Cannula 2 Site" />
                  </Form.Item>
                  <Form.Item label="Cannula 2 Type" name={`cannula_type2`}>
                    <Checkbox.Group options={cannulaOptions} />
                  </Form.Item>
                </Space>
              </>
            )}
          </div>
        )}
        <Col span={24}>
          <Form.Item label="Central Line" name="central_line">
            <Radio.Group
              options={centralLineOptions}
              value={centralLineSelection}
              onChange={(e) => setCentralLineSelection(e.target.value)}
            />
          </Form.Item>
        </Col>
        {centralLineSelection && (
          <Col span={12}>
            <Form.Item label="Cumulative Days" name="central_line_days">
              <InputNumber
                placeholder="Cumulative Days"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default IVAccess;
