import React, { useContext, useEffect } from "react";
import EatingAssessmentTool from "../../InspectionForms/Examination/SpeechScales/EatingAssessmentTool";
import { Divider } from "antd";
import GUSS from "../../InspectionForms/Examination/SpeechSwallowExaminationForm/GUSS";
import MASA from "../../InspectionForms/Examination/SpeechSwallowExaminationForm/MASA";
import FunctionalOralIntake from "../../InspectionForms/Examination/SpeechScales/FunctionalOralIntake";
import AphasiaSeverityRating from "../../InspectionForms/Examination/SpeechScales/AphasiaSeverityRating";
import SpeechIntelligibility from "../../InspectionForms/Examination/SpeechScales/SpeechIntelligibility";
import { SpeechReviewContext } from "../../SpeechAndSwallowTherapist/Review/SpeechSwallowReviewFormProvider";
import Doss from "../../InspectionForms/Examination/SpeechScales/Doss";

const SpeechSwallowScalesReview = ({ form }) => {
  const { formData } = useContext(SpeechReviewContext);
  useEffect(() => {
    form.setFieldsValue(formData?.scales);
  }, [formData]);
  return (
    <>
      <EatingAssessmentTool />
      <Divider />
      <GUSS context={SpeechReviewContext} />
      <Divider />
      <MASA context={SpeechReviewContext} />
      <Divider />
      <FunctionalOralIntake context={SpeechReviewContext} />
      <Divider />
      <AphasiaSeverityRating />
      <Divider />
      <Doss context={SpeechReviewContext} />
      <Divider />
      <SpeechIntelligibility />
    </>
  );
};

export default SpeechSwallowScalesReview;
