import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { limbExtremityOptions } from "../../../../Options/systemicExamOptions";
import { Form, Radio } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";

const NurseFractures = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState([]);
  const [showFractures, setShowFractures] = useState(
    formData?.examination?.fractures?.status === "yes"
  );
  useEffect(() => {
    setShowFractures(formData?.examination?.fractures?.status === "yes");
    const initialCheckboxes = limbExtremityOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = formData?.examination?.fractures?.[checkbox.value];
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <Form.Item label="Fractures" name={["fractures", "status"]}>
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => {
            setShowFractures(e.target.value === "yes");
          }}
        />
      </Form.Item>
      {showFractures && (
        <CheckListFBYInput
          options={limbExtremityOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          keyChain="fractures"
        />
      )}
    </>
  );
};

export default NurseFractures;
