import { Col, Row } from "antd";
import React, { useContext } from "react";
import { NutritionistInitialContext } from "../../InspectionForms/HistoryForm/NutritionistInitialAssessmentProvider";
import DrClinicalImpressionSummary from "../../InspectionSummaries/ClinicalImpressionSummary/DrClinicalImpressionSummary";
import NutritionistHistorySummary from "../../InspectionSummaries/HistorySummary/NutritionistHistorySummary";
import NutritionistClinicalEvaluationSummary from "../../InspectionSummaries/ClinicalEvaluation/NutritionistClinicalEvaluationSummary";
import NutritionistGoalsSummary from "../../InspectionSummaries/Goals/NutritionistGoalsSummary";
import NutritionistDietAdviceSummary from "../../InspectionSummaries/DietAdvice/NutritionistDietAdviceSummary";

const NutritionistInitialInspectionSummary = () => {
  const { formData } = useContext(NutritionistInitialContext);
  return (
    <>
      <Row className="summaries" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {formData?.clinical_diagnosis && (
            <DrClinicalImpressionSummary context={NutritionistInitialContext} />
          )}
          {formData?.clinical_evaluation && (
            <NutritionistClinicalEvaluationSummary formData={formData} />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {formData?.history && (
            <NutritionistHistorySummary formData={formData} />
          )}
          {formData?.goals && <NutritionistGoalsSummary formData={formData} />}
          {formData?.diet_advice && (
            <NutritionistDietAdviceSummary formData={formData} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default NutritionistInitialInspectionSummary;
