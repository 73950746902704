import { Form, InputNumber, Typography } from "antd";
import React from "react";

const NutritionistInvestigations = () => {
  return (
    <>
      <Typography>Investigations</Typography>
      <div className="subsection-card">
        <Form.Item
          label="Hemoglobin(Hb)"
          name={["investigations", "hemoglobin"]}
        >
          <InputNumber addonAfter="g/dl" />
        </Form.Item>
        <Form.Item
          label="Random Blood Sugar(RBS)"
          name={["investigations", "rbs"]}
        >
          <InputNumber addonAfter="mg/dl" />
        </Form.Item>
        <Form.Item label="Albumin" name={["investigations", "albumin"]}>
          <InputNumber addonAfter="g/dl" />
        </Form.Item>
        <Form.Item label="Urea" name={["investigations", "urea"]}>
          <InputNumber addonAfter="mg/dl" />
        </Form.Item>
        <Form.Item
          label="sr. Creatinine"
          name={["investigations", "creatinine"]}
        >
          <InputNumber addonAfter="mg/dl" />
        </Form.Item>
        <Form.Item label="Na +" name={["investigations", "sodium"]}>
          <InputNumber addonAfter="meq/dl" />
        </Form.Item>
        <Form.Item label="K+" name={["investigations", "potassium"]}>
          <InputNumber addonAfter="meq/dl" />
        </Form.Item>
        <Form.Item label="Cl-" name={["investigations", "chlorine"]}>
          <InputNumber addonAfter="meq/dl" />
        </Form.Item>
      </div>
    </>
  );
};

export default NutritionistInvestigations;
