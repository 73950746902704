import { Form, InputNumber, Radio, Typography } from "antd";
import React from "react";
import { yesNoOptions } from "../../../Options/commonOptions";
import {
  incontinenceOptions,
  stoolOptions,
} from "../../../Options/functionalAsessmentOptions";

const BowelNeeds = () => {
  return (
    <>
      <Typography>Bowel Needs</Typography>
      <div className="subsection-card">
        <Form.Item
          label="Indicates Urge to Pass Motiion"
          name={["bowel_needs", "indicates_need_to_pass_motion"]}
        >
          <Radio.Group options={yesNoOptions} />
        </Form.Item>
        <Form.Item label="Incontinece" name={["bowel_needs", "incontinence"]}>
          <Radio.Group options={incontinenceOptions} />
        </Form.Item>
        <Form.Item label="Stools" name={["bowel_needs", "stools"]}>
          <Radio.Group options={stoolOptions} />
        </Form.Item>
        <Form.Item
          label="Days After Last Motion"
          name={["bowel_needs", "days_after_last_motion"]}
        >
          <InputNumber addonAfter="Days" />
        </Form.Item>
        <Form.Item label="Colostomy" name={["bowel_needs", "colostomy"]}>
          <Radio.Group options={yesNoOptions} />
        </Form.Item>
      </div>
    </>
  );
};

export default BowelNeeds;
