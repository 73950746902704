import { Form, Input, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { noYesOptions } from "../../Options/commonOptions";
import { nutritionalRiskType } from "../../Options/clinicalEvaluationOptions";

const NutritionalRisk = ({ context }) => {
  const { formData } = useContext(context);
  const [showNutritiionalRisk, setShowNutritionalRisk] = useState(
    formData?.clinical_evaluation?.nutritional_risk_details?.risk === "yes" ||
      formData?.functional_diagnosis?.nutritional_risk_details?.risk === "yes"
  );
  useEffect(() => {
    setShowNutritionalRisk(
      formData?.clinical_evaluation?.nutritional_risk_details?.risk === "yes" ||
        formData?.functional_diagnosis?.nutritional_risk_details?.risk === "yes"
    );
  }, [formData]);
  return (
    <>
      <Form.Item
        label="Nutritional Risk"
        name={["nutritional_risk_details", "risk"]}
      >
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => {
            setShowNutritionalRisk(e.target.value === "yes");
          }}
        />
      </Form.Item>
      {showNutritiionalRisk && (
        <div className="subsection-card">
          <Form.Item
            label="Type"
            name={["nutritional_risk_details", "risk_type"]}
          >
            <Radio.Group options={nutritionalRiskType} />
          </Form.Item>
          <Form.Item name={["nutritional_risk_details", "risk_description"]}>
            <Input placeholder="Reason for Risk" autoComplete="off" />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default NutritionalRisk;
