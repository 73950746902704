import React from "react";
import NurseInitialInspection from "../IpEMR/Nurse/NurseInitialInspection/NurseInitialInspection";
import NurseInitialAssessmentFormProvider from "../IpEMR/InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import NurseMonitoring from "./Monitoring/NurseMonitoring/NurseMonitoring";
import NurseMedicine from "./NurseMedicine/NurseMedicine";
import MonitoringTabs from "./Monitoring/NurseMonitoring/MonitoringTabs";
import CareItemTabs from "../IpEMR/Nurse/CareItems/CareItemTabs";

const NurseIP = ({ patientData, params, refreshPage }) => {
  return (
    <>
      {params?.type === "ia" || params?.type === "ia" ? (
        <NurseInitialAssessmentFormProvider>
          <NurseInitialInspection
            refreshPage={refreshPage}
            editId={patientData?.nurse_initial_assessment}
            patientData={patientData}
          />
        </NurseInitialAssessmentFormProvider>
      ) : params?.type === "monitoring" ? (
        <MonitoringTabs />
      ) : params?.type === "medicine" ? (
        <NurseMedicine />
      ) : (
        params?.type === "order-sheet" && <CareItemTabs />
      )}
    </>
  );
};

export default NurseIP;
