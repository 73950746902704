import { Form, Input } from "antd";
import React from "react";

const CNSDetail = () => {
  return (
    <>
      <Form.Item name="cns_data">
        <Input.TextArea placeholder="Enter Central Nervous System Data" />
      </Form.Item>
    </>
  );
};

export default CNSDetail;
