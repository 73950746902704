import React, { useEffect, useState } from "react";
import { Card, Form, InputNumber, Select, Space, Typography } from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { getRoomTypeApi } from "../../../../services/beds.services";
const { Option } = Select;

const priceTagList = [
  { name: "%", id: true },
  { name: "₹", id: false },
];
const RoomDetails = () => {
  const [roomTypes, setRoomTypes] = useState(null);
  useEffect(() => {
    getRoomTypeApi()
      .then((res) => setRoomTypes(res.data))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <Typography
        style={{
          fontSize: "20px",
          fontWeignt: 500,
          color: "#000000",
          background: "#F0ECFE",
          padding: "8px",
          borderRadius: "10px",
        }}
      >
        Room Types
      </Typography>

      <Space style={{ display: "flex", marginBottom: 8 }}>
        <Form.Item label="Choose Room Type" name="room_type">
          <Select
            allowClear
            className={inputStyles.mdSelect}
            style={{ width: "300px" }}
            placeholder="Choose Room Type"
            showSearch
            optionFilterProp="children"
          >
            {roomTypes?.map((room) => (
              <Option value={room?.id} key={room?.id}>
                {room?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Price"
          name="room_price"
          // rules={[{ required: true, message: "Enter price" }]}
        >
          <InputNumber
            prefix="Rs"
            placeholder="EnterPrice"
            className={inputStyles.mdNumberInput}
            style={{ width: "250px" }}
          />
        </Form.Item>
        <Space.Compact>
          <Form.Item label="Discount" name="room_discount">
            <InputNumber
              placeholder="Enter Discount"
              className={`${inputStyles.mdNumberInput}`}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item
            label=" "
            name="room_is_percentage"
            style={{ width: "50%" }}
          >
            <Select className={inputStyles.mdSelect} defaultValue={false}>
              {priceTagList?.map((data) => {
                return (
                  <Select.Option key={data?.id} value={data?.id}>
                    {data?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Space.Compact>
      </Space>
    </div>
  );
};

export default RoomDetails;
