import axiosInstance from "../config/axios.config";

export const createDietApi = (payload) => {
  return axiosInstance.post(`/careservices/dietitems/`, payload);
};
export const getDietApi = (params) => {
  return axiosInstance.get(`/careservices/dietitems/?${params}`);
};
export const updateDietApi = (diet_id, payload) => {
  return axiosInstance.patch(`/careservices/dietitems/${diet_id}/`, payload);
};
