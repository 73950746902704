import { Col, Form, List, Radio, Row, Typography } from "antd";
import React from "react";
import { memoryFieldsOptions } from "../../../../Options/physioOptions";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const DelayedRecall = () => {
  return (
    <>
      <List
        bordered
        dataSource={memoryFieldsOptions}
        style={{ background: "#fff", width: "600px" }}
        renderItem={(field) => (
          <List.Item>
            <Row style={{ width: "100%" }} align="middle">
              <Col span={12}>
                <Typography>{field.label}</Typography>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["delayed_recall_data", field.value]}
                  style={{ marginBottom: 0 }}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="space-around">
                      <Col>
                        <Radio.Button
                          value={"yes"}
                          style={{ backgroundColor: "#E9D7FE" }}
                        >
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button
                          value={"no"}
                          style={{ backgroundColor: "#FFF5F5" }}
                        >
                          <DislikeOutlined style={{ backgroundColor: "" }} />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default DelayedRecall;
