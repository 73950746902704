import React, { useRef, useState } from "react";
import {
  Drawer,
  Form,
  Space,
  Input,
  Button,
  Row,
  Col,
  Typography,
  message,
  InputNumber,
} from "antd";
import { PlusOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import btnStyles from "../../../styles/Custom/Button.module.css";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { createDietApi } from "../../../services/diet.services";
import { getCookie } from "../../../helpers/localStorage";
import { ORGANISATONID } from "../../../constants/defaultKeys";

function DietForm({ open, setOpen, refreshPage }) {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);

  const orgId = getCookie(ORGANISATONID);

  const onFinish = (values) => {
    const updatedValues = values?.users?.map((user) => ({
      ...user,
      organization: orgId,
    }));

    setBtnLoading(true);
    createDietApi(updatedValues)
      .then((res) => {
        message.success(res?.data?.message);
        setOpen(false);
        refreshPage();
        setBtnLoading(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        console.log(err);
        setBtnLoading(false);
      });
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      width={"50%"}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              Add Menu Item
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: "right" }}>
          <Form.Item>
            <Button
              loading={btnLoading}
              className={btnStyles.lgBtn}
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      }
      onClose={onClose}
      closeIcon={null}
    >
      <Form
        ref={formRef}
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        initialValues={{
          users: [{}],
        }}
      >
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Enter item name" }]}
                  >
                    <Input
                      placeholder="Enter item name"
                      className={inputStyles.mdInput}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "protien"]}
                    rules={[{ required: true, message: "Enter protein" }]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Enter calories"
                      className={inputStyles.mdNumberInput}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "calories"]}
                    rules={[{ required: true, message: "Enter calories" }]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Enter proteins(Grams)"
                      className={inputStyles.mdNumberInput}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>

                  {fields?.length > 1 && (
                    <DeleteOutlined onClick={() => remove(name)} />
                  )}
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add({ name: "", ac: true })}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Menu Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
}

export default DietForm;
