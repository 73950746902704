import { Descriptions } from "antd";
import React, { useContext } from "react";
import { getLabelTagsWithDescription } from "../constructTextFromValues";
import { treatmentGivenOptions } from "../../Options/RespiratoryTherapistOptions";

const RespiratoryTreatmentPlanSummary = ({ context }) => {
  const { formData } = useContext(context);
  const constructTreatmentSummaryItems = () => {
    const treatmentSummaryItems = [];
    treatmentSummaryItems.push({
      key: "1",
      label: "Treatment Plan",
      children: (
        <>
          {getLabelTagsWithDescription(
            treatmentGivenOptions,
            formData?.treatment_plan
          )}
        </>
      ),
    });
    return treatmentSummaryItems;
  };
  const treatmentSummaryItems = constructTreatmentSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Treatment"
        items={treatmentSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default RespiratoryTreatmentPlanSummary;
