import { Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import CheckListFBYRadioItems from "../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  leftRightBothOptions,
  visionOptions,
} from "../../../Options/functionalAsessmentOptions";

const Vision = ({ context }) => {
  const { formData } = useContext(context);
  const [showVision, setShowVision] = useState(
    formData?.functional_status?.vision_details?.vision === "assessable"
  );
  const [checkboxes, setCheckBoxes] = useState([]);
  useEffect(() => {
    setShowVision(
      formData?.functional_status?.vision_details?.vision === "assessable"
    );
    const initialCheckboxes = visionOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.functional_status?.vision_details?.[checkbox.value];
      return acc;
    }, {});
    setCheckBoxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <Form.Item label="Vision" name={["vision_details", "vision"]}>
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) => {
            setShowVision(e.target.value === "assessable");
          }}
        />
      </Form.Item>
      {showVision && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={visionOptions}
            radioOptions={leftRightBothOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckBoxes}
            keyChain={"vision_details"}
          />
        </div>
      )}
    </>
  );
};

export default Vision;
