import { Form, Radio } from "antd";
import React from "react";
import { aphasiaSeverityOptions } from "../../../Options/speechSwallowOptions";

const AphasiaSeverityRating = () => {
  return (
    <>
      <Form.Item
        label="Aphasia Severity Rating"
        name={"aphasia_severity_rating"}
      >
        <Radio.Group options={aphasiaSeverityOptions} />
      </Form.Item>
    </>
  );
};

export default AphasiaSeverityRating;
