import React, { useContext, useEffect, useState } from "react";
import { Avatar, Dropdown, Image, Select, Typography } from "antd";
import {
  ArrowRightOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./CustomLayout.css";
import { useNavigate } from "react-router-dom";
import { getAvatarBackgroundColor } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserContext";
import { downloadUrlApi } from "../../../services/common.services";
import { getItem, setItem } from "../../../helpers/localStorage";

const UserSettings = () => {
  const { userDetails } = useContext(UserContext);
  const [logo, setLogo] = useState(getItem("profile_pic", true));
  const navigate = useNavigate();

  const selectedColor = getAvatarBackgroundColor(userDetails?.first_name);

  const items = [
    ...[
      {
        key: "1",
        title: "User profile",
        href: "/accounts/details",
        icon: <UserOutlined />,
      },
      {
        key: "2",
        title: "Organization Profile",
        href: "/organization-settings/org-details",
        icon: <HomeOutlined />,
      },
      {
        key: "3",
        title: "Switch Role",
        // href: "/organization-settings/org-details",
        icon: <SettingOutlined />,
      },
    ].map((item) => {
      return {
        key: item.key,
        label: (
          <div
            style={{ gap: "12px" }}
            className="flex-start"
            onClick={() => navigate(item?.href)}
          >
            {item?.icon}
            {item.title}
          </div>
        ),
      };
    }),

    {
      key: "5",
      label: (
        <div
          className="flex-start"
          style={{ gap: "12px" }}
          onClick={() => {
            // window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/logout`;
            navigate("/logout");
          }}
        >
          <ArrowRightOutlined />
          <Typography style={{}}> Log out</Typography>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   const fetchLogo = async () => {
  //     if (
  //       !(logo?.key && logo?.key === userDetails?.activeOrg?.usr_profile_pic)
  //     ) {
  //       try {
  //         const res = await downloadUrlApi(
  //           `path_key=${userDetails?.activeOrg?.usr_profile_pic}&used_for=profile_pic`
  //         );
  //         setLogo({
  //           path: res?.data,
  //           key: userDetails?.activeOrg?.usr_profile_pic,
  //         });
  //         setItem(
  //           "profile_pic",
  //           JSON.stringify({
  //             path: res?.data,
  //             key: userDetails?.activeOrg?.usr_profile_pic,
  //           })
  //         );
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     } else {
  //       setLogo(getItem("profile_pic", true));
  //     }
  //   };

  //   fetchLogo();
  // }, [userDetails]);
  // downloadUrlApi(`path_key=${userDetails?.activeOrg?.usr_profile_pic}`)
  //   .then((res) => setLogo(res?.data))
  //   .catch((err) => console.log(err));

  return (
    <Dropdown
      menu={{
        items,
      }}
      // open={true}
      dropdownRender={(menu) => (
        <div className={"profileMenu"} style={{ marginTop: "-12px" }}>
          <div
            className="text-left"
            style={{
              padding: "16px",
              gap: "16px",
              borderBottom: "1px solid #EAECF0",
            }}
          >
            <Typography style={{ fontWeight: 600, textAlign: "left" }}>
              {userDetails?.first_name} {userDetails?.last_name}
              <br />
              <span style={{ color: "#475467", fontWeight: 400 }}>
                {userDetails?.email}
              </span>
            </Typography>
          </div>

          {React.cloneElement(menu, {
            className: "settingsdropDown",
            style: {
              boxShadow: "none",
              padding: "6px",
            },
          })}
        </div>
      )}
      className={"profileDropDown"}
      placement="bottomRight"
    >
      {userDetails?.activeOrg?.usr_profile_pic ? (
        <Image
          src={userDetails?.activeOrg?.usr_profile_pic}
          alt={"Profile Pic"}
          width="40px"
          preview={false}
        />
      ) : (
        <Avatar
          style={{
            cursor: "pointer",
            backgroundColor: selectedColor,
            backgroundColor: userDetails?.profile_pic ? "" : selectedColor,
          }}
          size={40}
        >
          <span style={{ fontSize: "18px" }}>
            {userDetails?.first_name?.slice(0, 1).toUpperCase()}
          </span>
        </Avatar>
      )}
    </Dropdown>
  );
};

export default UserSettings;
