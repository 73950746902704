import { Descriptions } from "antd";
import React, { useContext } from "react";
import { getLabelTagsWithDescription } from "../constructTextFromValues";
import { psychologistDiagnosisOptions } from "../../Options/psychologistOptions";

const PsychologyDiagnosisSummary = ({ context }) => {
  const { formData } = useContext(context);
  const constructDiagnosisSummaryItems = () => {
    const diagnosisSummaryItems = [];
    if (formData?.psychology_diagnosis) {
      diagnosisSummaryItems.push({
        key: "1",
        label: "Psychology Diagnosis",
        children: (
          <>
            {getLabelTagsWithDescription(
              psychologistDiagnosisOptions,
              formData?.psychology_diagnosis
            )}
          </>
        ),
      });
    }
    return diagnosisSummaryItems;
  };
  const diagnosisSummaryItems = constructDiagnosisSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={"Psychology Diagnosis"}
        items={diagnosisSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PsychologyDiagnosisSummary;
