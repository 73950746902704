import axiosInstance from "../config/axios.config";

export const nutritionistInspectionAPI = (payload) => {
  return axiosInstance.post(`inpatient/nutrition-initial-assessment/`, payload);
};
export const updateNutritionistInspectionAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/nutrition-initial-assessment/${id}/`,
    payload
  );
};

export const getNutritionistInspectionAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/nutrition-initial-assessment/${admisssionId}/`
  );
};

export const nutritionistRiskAssessmentAPI = (payload) => {
  return axiosInstance.post(`inpatient/nutrition-risk-assessment/`, payload);
};
export const updateNutritionistRiskAssessmentAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/nutrition-risk-assessment/${id}/`,
    payload
  );
};

export const getNutritionistRiskAssessmentAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/nutrition-risk-assessment/${admisssionId}/`
  );
};

export const getLatestNutritionRiskAssessmentAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/latest-nutrition-risk-assessment/?admission_id=${admisssionId}`
  );
};
