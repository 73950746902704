import { Form, Radio } from "antd";
import React from "react";
import { modeOfArrivalOptions } from "../../../Options/historyOptions";

const ModeOfArrival = () => {
  return (
    <>
      <Form.Item label="Mode of Arrival" name="mode_of_arrival">
        <Radio.Group options={modeOfArrivalOptions} />
      </Form.Item>
    </>
  );
};

export default ModeOfArrival;
