import { Form, Input, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { noYesOptions } from "../../../Options/commonOptions";

const FoodAllergies = ({ context }) => {
  const { formData } = useContext(context);
  const [showAllergyInput, setShowAllergyInput] = useState(
    formData?.history?.allergy_details?.food_allergies === "yes"
  );
  useEffect(() => {
    setShowAllergyInput(
      formData?.history?.allergy_details?.food_allergies === "yes"
    );
  }, [formData]);
  return (
    <>
      <Form.Item
        label="Food Allergies"
        name={["allergy_details", "food_allergies"]}
      >
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => {
            setShowAllergyInput(e.target.value === "yes");
          }}
        />
      </Form.Item>
      {showAllergyInput && (
        <Form.Item name={["allergy_details", "allergy_description"]}>
          <Input autoComplete="off" />
        </Form.Item>
      )}
    </>
  );
};

export default FoodAllergies;
