import React, { useContext, useRef, useState } from "react";
import { Form, Upload, message, Button } from "antd";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { useLocation } from "react-router-dom";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { patientInvestigationsOrderAPI } from "../../../services/patient.services";
import { getUploadUrlApi } from "../../../services/common.services";
import { convertToFormData } from "../../../helpers/utility";
import dayjs from "dayjs";
import axios from "axios";
import InitialInvestigationForm from "../../PatientsIP/InvestigationsIp/InitialInvestigationForm";

const InvestigationsInitial = ({ setVisible }) => {
  const location = useLocation();
  const id = Object.fromEntries(new URLSearchParams(location?.search));
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [uploadFile, setUploadFile] = useState([]);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      patient: Number(id?.patient),
      ip_admission: Number(id?.patient),
      name: values?.name,
      investigation_date: dayjs(values?.date).format("YYYY-MM-DD"),
      report_path: uploadFile,
      is_initial: true,
    };

    patientInvestigationsOrderAPI(formData)
      .then((res) => {
        message.success(res?.data?.message);
        setVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ height: "100%", overflow: "hidden", position: "relative" }}>
        <div style={{ height: "calc(100% - 38px)", overflowY: "auto" }}>
          <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
            <InitialInvestigationForm
              setUploadFile={setUploadFile}
              setUploadFileLoading={setUploadFileLoading}
              uploadFile={uploadFile}
            />
            <div
              className="flex-end"
              style={{
                gap: "24px",
                gap: "24px",
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "#fff",
                paddingTop: "10px",
                borderTop: "1px solid #e8e8e8",
              }}
            >
              <Form.Item>
                <Button
                  loading={loading}
                  disabled={uploadFileLoading}
                  htmlType="submit"
                  type="primary"
                  className={btnStyles.mdBtn}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default InvestigationsInitial;
