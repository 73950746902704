import { Form, Radio } from "antd";
import React from "react";
import { metabolicStressOptions } from "../../../Options/historyOptions";

const MetabolicStress = () => {
  return (
    <>
      <Form.Item label="Metabolic Stress" name="metabolic_stress">
        <Radio.Group options={metabolicStressOptions} />
      </Form.Item>
    </>
  );
};

export default MetabolicStress;
