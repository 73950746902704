import React, { useContext, useEffect } from "react";
import { Collapse } from "antd";
import SkinForm from "./SkinForm/SkinForm";
import CardioForm from "./CardioForm/CardioForm";
import AbdomenForm from "./AbdomenForm/AbdomenForm";
import RespiratoryForm from "./RespiratoryForm/RespiratoryForm";
import MusculoSkeletalForm from "./MusculoSkeletalForm/MusculoSkeletalForm";
import CNSForm from "./CNSForm/CNSForm";
import "./SystemicExaminationForm.css";
import { DoctorInitialContext } from "../../HistoryForm/DoctorHistoryFormProvider";
const { Panel } = Collapse;

const SystemicExaminationForm = ({
  form,
  openWounds,
  setOpenWounds,
  pressureSore,
  setPressureSore,
}) => {
  // const onFinish = (values) => {
  //   console.log("Form values:", values);
  // };
  const { formData } = useContext(DoctorInitialContext);

  useEffect(() => {
    if (formData?.systemic_examination) {
      form.setFieldsValue({
        ...formData?.systemic_examination,
        pressure_sore: formData?.systemic_examination?.pressure_sore,
        open_wounds: formData?.systemic_examination?.open_wounds,
      });
    }
  }, [formData]);

  return (
    <>
      {/* <Form className="ip-forms" onFinish={onFinish} layout="vertical"> */}
      <Collapse defaultActiveKey={["1", "2", "3", "4", "5", "6"]}>
        <Panel header="Skin" key="1">
          <SkinForm
            pressureSore={pressureSore}
            setPressureSore={setPressureSore}
            openWounds={openWounds}
            setOpenWounds={setOpenWounds}
          />
        </Panel>
        <Panel header="Cardio Vascular System" key="2">
          <CardioForm context={DoctorInitialContext} />
        </Panel>

        <Panel header="Respiratory System" key="3">
          <RespiratoryForm context={DoctorInitialContext} />
        </Panel>
        <Panel header="Abdomen" key="4">
          <AbdomenForm />
        </Panel>
        <Panel header="Musculo Skeletal System" key="5">
          <MusculoSkeletalForm />
        </Panel>
        <Panel header="Central Nervous System" key="6">
          <CNSForm />
        </Panel>
      </Collapse>
      <br />

      {/* <Button type="primary" htmlType="submit">
           Save & Proceed //{" "}
</Button>
</Form> */}
    </>
  );
};

export default SystemicExaminationForm;
