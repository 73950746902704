import React, { useState } from "react";
import { Button, Col, Descriptions, Form, Radio, Row } from "antd";
import CareItemForm from "./CareItemForm";
import { vitalOptions } from "../../Options/careItemsOptions";

const formItems = (
  <Form.Item name="vitals" label="Vitals">
    <Radio.Group options={vitalOptions} />
  </Form.Item>
);

const items = (item) => [
  {
    key: "vitals",
    label: "Vitals",
    children:
      vitalOptions?.find((option) => option.value === item?.vitals)?.label ||
      "-",
  },
];

function Vitals({ formData, getData }) {
  const [formState, setFormState] = useState({
    editingIndex: null,
  });

  return (
    <Row gutter={[12, 12]}>
      {formData?.data?.map((item, index) => (
        <CareItemForm
          key={index}
          formData={item}
          formType="vitals"
          getData={getData}
          formState={formState}
          setFormState={setFormState}
          formItems={
            item === undefined || formState?.editingIndex === item?.id ? (
              formItems
            ) : (
              <Descriptions
                bordered
                column={1}
                items={items(item?.data)}
                className="descriptions-custom-column-width"
              />
            )
          }
        />
      ))}

      {(formData === undefined || formData?.isallstopstate) && (
        <>
          {formData !== undefined && !formState?.adding ? (
            <Col span={12} style={{ textAlign: "center" }}>
              <Button
                type="dashed"
                onClick={() =>
                  setFormState((prev) => ({ ...prev, adding: true }))
                }
              >
                Add Another
              </Button>
            </Col>
          ) : (
            <CareItemForm
              getData={getData}
              formItems={formItems}
              formState={formState}
              formType="vitals"
              setFormState={setFormState}
            />
          )}
        </>
      )}
    </Row>
  );
}

export default Vitals;
