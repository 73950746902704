import React, { useState, useEffect } from "react";
import IndirectSwallowingTest from "./SpeechSwallowComponents/IndirectSwallowingTest";
import { Divider, Form, Input, Typography } from "antd";
import DirectSwallowingTestSS from "./SpeechSwallowComponents/DirectSwallowingTestSS";
import DirectSwallowingTestLiquid from "./SpeechSwallowComponents/DirectSwallowingTestLiquid";
import DirectSwallowingTestSolid from "./SpeechSwallowComponents/DirectSwallowingTestSolid";

const GUSS = ({ context }) => {
  const [gussStates, setGussStates] = useState({});

  const handleGussStates = (key, value) => {
    setGussStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const calculateScore = () => {
    console.log(
      "calc guss",
      Object.values(gussStates).reduce((acc, cur) => acc + cur, 0)
    );
    return Object.values(gussStates).reduce((acc, cur) => acc + cur, 0);
  };
  console.log("guss states", gussStates);
  return (
    <>
      <Typography.Title level={5}>GUSS: {calculateScore()}</Typography.Title>
      <Form.Item name={"guss_score"} hidden initialValue={calculateScore()}>
        <Input value={calculateScore()} hidden />
      </Form.Item>
      <IndirectSwallowingTest
        gussStates={gussStates}
        handleGussStates={handleGussStates}
        context={context}
      />
      <Divider />
      <DirectSwallowingTestSS
        handleGussStates={handleGussStates}
        context={context}
      />
      <Divider />
      <DirectSwallowingTestLiquid
        handleGussStates={handleGussStates}
        context={context}
      />
      <Divider />
      <DirectSwallowingTestSolid
        handleGussStates={handleGussStates}
        context={context}
      />
    </>
  );
};

export default GUSS;
