import React from "react";
import { Form, Radio, Descriptions } from "antd";
import { nmmsOptions } from "../../../../Options/genExamOptions";

const Pallor = () => {
  return (
    <>
      <Form.Item label="Pallor" name="pallor">
        <Radio.Group options={nmmsOptions} />
      </Form.Item>
    </>
  );
};

export default Pallor;
