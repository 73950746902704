import axiosInstance from "../config/axios.config";

export const nurseInspectionAPI = (payload) => {
  return axiosInstance.post(`inpatient/nurse-initial-assessment/`, payload);
};
export const updateNurseInspectionAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/nurse-initial-assessment/${id}/`,
    payload
  );
};

export const getNurseInspectionAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/nurse-initial-assessment/${admisssionId}/`
  );
};
export const getNurseCommonData = (admission_id, params, date) => {
  return date
    ? axiosInstance.get(
        `inpatient/nurse-care-items/${admission_id}/?type=${params}&date=${date}`
      )
    : axiosInstance.get(
        `inpatient/nurse-care-items/${admission_id}/?type=${params}`
      );
};
export const updateNurseCommonData = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/nurse-care-items/update/${id}/`,
    payload
  );
};
export const getNurseMonitoringData = (admission_id, params) => {
  return params
    ? axiosInstance.get(`inpatient/monitoring-charts/${admission_id}/${params}`)
    : axiosInstance.get(`inpatient/monitoring-charts/${admission_id}/`);
};
export const updateNurseVitalsMonitoringApi = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/monitoring-charts/update/${id}/`,
    payload
  );
};
export const addVitalsMonitoringApi = (id, payload) => {
  return axiosInstance.post(`inpatient/monitoring-charts/${id}/`, payload);
};
export const getNurseIpPatientApi = (params) => {
  return axiosInstance.get(`inpatient/nurse-ip-list/${params}`);
};
export const floorListApi = (params) => {
  return axiosInstance.get(`/bed-setup/floor-list/${params}`);
};
export const nurseDailyRoutineApi = (id, payload) => {
  return axiosInstance.post(`inpatient/daily-routine-care/${id}/`, payload);
};
export const updateDailyRoutineApi = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/daily-routine-care/update/${id}/`,
    payload
  );
};
