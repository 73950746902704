import React, { useEffect, useState } from "react";
import {
  Segmented,
  Row,
  Col,
  Button,
  Result,
  Typography,
  Space,
  Tabs,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import UsersForm from "./UsersForm";
import { usersListApi } from "../../../services/auth.services";
import UsersTable from "./UsersTable";
import btnStyles from "../../../styles/Custom/Button.module.css";
import SideMenu from "../../Layout/CustomLayout/SideMenu";
import CustomLayout from "../../Layout/CustomLayout";
import typoStyles from "../../../styles/Custom/Typography.module.css";

function Users() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [activeSegment, setActiveSegment] = useState(params?.role || "all");
  const [users, setUsers] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    const role = params?.role !== "all" && params?.role;
    const updatedRole =
      role === "admins"
        ? ["Organization_Owner", "Organization_Admin", "Facility_Admin"]
        : role === "practioners"
        ? [
            "Doctor",
            "Nurse",
            "Speech_Swallow_Therapist",
            "Respiratory_Therapist",
            "Nutritionist",
            "Physiotherapist",
          ]
        : role === "receptionist" && ["Receptionist"];
    usersListApi(updatedRole)
      .then((res) => {
        setUsers(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [params?.role, refresh]);

  const onChange = (value) => {
    setActiveSegment(value);
    navigate(`/dashboard/users?role=${value}`);
  };
  const roles = [
    { label: "Admins", key: "admins" },
    { label: "Practioners", key: "practioners" },
    { label: "Receptionist", key: "receptionist" },
  ];
  return (
    <CustomLayout
      header={
        <div
          style={{
            gap: "5%",
            width: "100%",
          }}
          className="flex-start"
        >
          <Typography className={typoStyles.h6}>Users</Typography>
          <Tabs
            style={{ width: "80%", paddingTop: "16px" }}
            items={[{ label: "All", key: "all" }, ...roles]}
            onChange={onChange}
            defaultActiveKey={activeSegment}
          />
        </div>
      }
      sideContent={<SideMenu />}
    >
      <Row gutter={[0, 12]}>
        <Col span={20}></Col>

        <Col span={4} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => setOpen(true)}
            className={btnStyles.lgBtn}
          >
            <PlusOutlined /> User
          </Button>
        </Col>
        <Col span={24}>
          {users ? (
            <UsersTable
              data={users}
              loading={loading}
              refreshPage={refreshPage}
            />
          ) : (
            !loading && <Result title="No users" />
          )}
        </Col>
        {open && (
          <UsersForm
            isVisible={open}
            setIsVisible={setOpen}
            refreshPage={refreshPage}
          />
        )}
      </Row>
    </CustomLayout>
  );
}

export default Users;
