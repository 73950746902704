import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import React, { useContext, useEffect } from "react";
import {
  getLatestNutritionRiskAssessmentAPI,
  getNutritionistInspectionAPI,
  getNutritionistRiskAssessmentAPI,
  nutritionistRiskAssessmentAPI,
  updateNutritionistRiskAssessmentAPI,
} from "../../../../services/nutritionist.services";
import { useLocation } from "react-router-dom";
import { RiskAssessmentContext } from "./RiskAssessmentFormProvider";
import NutritionistRiskAssessmentForm from "../../InspectionForms/DietAdvice/NutritionistRiskAssessmentForm";

const RiskAssessmentForm = ({
  editId,
  visible,
  setVisible,
  refreshPage,
  addMode,
  setAddMode,
  setActiveSegment,
  riskAssessment,
  riskAssessmentRefresh,
  riskRefresh,
  segmentData,
  patientData,
  iAssessment,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [riskForm] = Form.useForm();
  const { formData, setFormData } = useContext(RiskAssessmentContext);
  const newReview = riskAssessment?.length === 0;
  const reviewId = riskAssessment?.[riskAssessment.length - 1]?.id;
  const newReviewId = addMode === false && reviewId ? reviewId : null;
  const reviewDate =
    segmentData?.find(
      (option) => option.value === editId && option.value !== "others"
    )?.label ||
    new Date().toLocaleDateString("en-US", { month: "short", day: "2-digit" });

  const handleSubmit = async (values) => {
    try {
      if (editId || newReviewId) {
        await updateNutritionistRiskAssessmentAPI(
          editId || newReviewId,
          values
        );
        riskAssessmentRefresh();
      } else {
        await nutritionistRiskAssessmentAPI(values);
        refreshPage();
        setAddMode(false);
        riskAssessmentRefresh();
      }

      message.success("Form submitted successfully");

      //   setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
      console.log("Error", error);
    }
  };

  const onRiskSubmit = (values) => {
    handleSubmit({
      ...formData,
      functional_diagnosis: values,
      admission_id: params?.patient,
    });
  };

  const openEdit = async () => {
    if (editId && newReview === false) {
      try {
        const response = await getNutritionistRiskAssessmentAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    } else if (newReview === false) {
      try {
        const response = await getLatestNutritionRiskAssessmentAPI(
          params?.patient
        );
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    } else {
      try {
        const response = await getNutritionistInspectionAPI(iAssessment);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, riskRefresh]);

  return (
    <div>
      <Drawer
        open={visible}
        width="100%"
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
                ({reviewDate})
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  Modal.confirm({
                    title: "Confirmation",
                    content: "Are you sure you want to close?",
                    onOk: () => {
                      setVisible(false);
                    },
                  });
                }}
              />
            </Col>
          </Row>
        }
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                riskForm.submit();
                setVisible(false);
              }}
              type="primary"
            >
              Done
            </Button>
          </div>
        }
        onClose={() => {
          setVisible(false);
          setActiveSegment(riskAssessment[0]?.id);
        }}
        closeIcon={null}
        keyboard={false}
      >
        <Form
          id="nutrition-risk-assessment"
          form={riskForm}
          layout="vertical"
          onFinish={onRiskSubmit}
          initialValues={formData}
          className="ip-forms"
        >
          <NutritionistRiskAssessmentForm form={riskForm} />
        </Form>
      </Drawer>
    </div>
  );
};

export default RiskAssessmentForm;
