import { Form, Select, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  activitySelectionOptions,
  frictionSelectionOptions,
  mobilitySelectionOptions,
  moistureSelectionOptions,
  nutritionSelectionOptions,
  perceptionSelectionOptions,
} from "../../../Options/functionalAsessmentOptions";

const Braden = ({ context }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState({
    sensory_perception: 0,
    moisture: 0,
    activity: 0,
    mobility: 0,
    nutrition: 0,
    friction_and_shear: 0,
  });

  console.log("states", states);

  useEffect(() => {
    setStates({ ...formData?.functional_status?.braden_scale });
  }, [formData]);

  const handleChange = (value, target) => {
    console.log(value);
    setStates((prev) => ({ ...prev, [target]: value }));
  };

  const aggregate = () => {
    const total = Object.values(states).reduce((sum, value) => sum + value, 0);
    let risk = "Severe Risk";
    if (total > 19) {
      risk = "Low Risk";
    } else if (total > 15) {
      risk = "At Risk";
    } else if (total > 13) {
      risk = "Moderate Risk";
    } else if (total > 10) {
      risk = "High Risk";
    } else {
      risk = "Severe Risk";
    }
    return (
      <span>
        {total} : {risk}
      </span>
    );
  };

  return (
    <>
      <Typography>Braden Scale for Predicting Pressure Ulcer Risk</Typography>
      <div className="subsection-card">
        <Typography>{aggregate()}</Typography>
        <Form.Item
          label="Sensory Perception"
          name={["braden_scale", "sensory_perception"]}
        >
          <Select
            options={perceptionSelectionOptions}
            onChange={(value) => {
              handleChange(value, "sensory_perception");
            }}
          />
        </Form.Item>
        <Form.Item label="Moisture" name={["braden_scale", "moisture"]}>
          <Select
            options={moistureSelectionOptions}
            onChange={(value) => {
              handleChange(value, "moisture");
            }}
          />
        </Form.Item>
        <Form.Item label="Activity" name={["braden_scale", "activity"]}>
          <Select
            options={activitySelectionOptions}
            onChange={(value) => {
              handleChange(value, "activity");
            }}
          />
        </Form.Item>
        <Form.Item label="Mobility" name={["braden_scale", "mobility"]}>
          <Select
            options={mobilitySelectionOptions}
            onChange={(value) => {
              handleChange(value, "mobility");
            }}
          />
        </Form.Item>
        <Form.Item label="Nutrition" name={["braden_scale", "nutrition"]}>
          <Select
            options={nutritionSelectionOptions}
            onChange={(value) => {
              handleChange(value, "nutrition");
            }}
          />
        </Form.Item>
        <Form.Item
          label="Friction & Shear"
          name={["braden_scale", "friction_and_shear"]}
        >
          <Select
            options={frictionSelectionOptions}
            onChange={(value) => {
              handleChange(value, "friction_and_shear");
            }}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default Braden;
