import React, { useEffect, useState } from "react";
import {
  Form,
  InputNumber,
  Input,
  Typography,
  message,
  Button,
  Card,
  Row,
  Col,
} from "antd";
import {
  fingerGripAPI,
  getFingerGripAPI,
  updateFingerGripAPI,
} from "../../../../../services/physio.services";

const FingerGripTest = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});
  const gripTest = [
    { ageMin: 10, ageMax: 11, weak: 12.6, strong: 22.4 },
    { ageMin: 12, ageMax: 13, weak: 19.4, strong: 31.2 },
    { ageMin: 14, ageMax: 15, weak: 28.5, strong: 44.3 },
    { ageMin: 16, ageMax: 17, weak: 32.6, strong: 52.4 },
    { ageMin: 18, ageMax: 19, weak: 35.7, strong: 55.5 },
    { ageMin: 20, ageMax: 24, weak: 36.8, strong: 56.6 },
    { ageMin: 25, ageMax: 29, weak: 37.7, strong: 57.5 },
    { ageMin: 30, ageMax: 34, weak: 36.0, strong: 55.8 },
    { ageMin: 35, ageMax: 39, weak: 35.8, strong: 55.6 },
    { ageMin: 40, ageMax: 44, weak: 35.5, strong: 55.3 },
    { ageMin: 45, ageMax: 49, weak: 34.7, strong: 54.5 },
    { ageMin: 50, ageMax: 54, weak: 32.9, strong: 50.7 },
    { ageMin: 55, ageMax: 59, weak: 30.7, strong: 48.5 },
    { ageMin: 60, ageMax: 64, weak: 30.2, strong: 48.0 },
    { ageMin: 65, ageMax: 69, weak: 28.2, strong: 44.0 },
    { ageMin: 70, ageMax: 99, weak: 21.3, strong: 35.0 },
  ];

  const checkGripStrength = (grip) => {
    if (states.age && grip) {
      for (let group of gripTest) {
        if (states.age >= group.ageMin && states.age <= group.ageMax) {
          if (grip < group.weak) {
            return "Weak";
          } else if (grip > group.strong) {
            return "Strong";
          } else {
            return "Normal";
          }
        }
      }
    }
    return "N/A"; // Default if conditions are not met
  };

  const handleChange = (key, value) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };

    try {
      if (scalesId?.finger_grip) {
        await updateFingerGripAPI(scalesId?.finger_grip, finalData);
      } else {
        const response = await fingerGripAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, finger_grip: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.finger_grip) {
      getFingerGripAPI(admission, scalesId?.finger_grip)
        .then((response) => {
          const formData = response.data;
          form.setFieldsValue(formData);
          setStates({
            age: formData?.age,
            left_hand: formData?.left_hand,
            right_hand: formData?.right_hand,
          });
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Card style={{ width: "600px" }}>
          <Form
            id="finger-grip-test"
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
          >
            <Form.Item label="Age" name="age">
              <InputNumber
                max={99}
                min={0}
                onChange={(value) => handleChange("age", value)}
                addonAfter="yrs"
                style={{ width: "24%" }}
              />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Left Hand Grip Strength" name="left_hand">
                  <InputNumber
                    onChange={(value) => handleChange("left_hand", value)}
                    max={200}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {states.left_hand ? (
                  <Typography>{checkGripStrength(states.left_hand)}</Typography>
                ) : null}
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Right Hand Grip Strength" name="right_hand">
                  <InputNumber
                    onChange={(value) => handleChange("right_hand", value)}
                    max={200}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {states?.right_hand ? (
                  <Typography>
                    {checkGripStrength(states.right_hand)}
                  </Typography>
                ) : null}
              </Col>
            </Row>

            <Form.Item label="Comments" name="comments">
              <Input placeholder="Comments" />
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default FingerGripTest;
