import React, { useContext, useState, useEffect } from "react";
import { Form, Typography, Radio } from "antd";
import {
  consiousnessOptions,
  moreConsiousnessOptions,
} from "../../../../Options/genExamOptions";

const LevelOfConsciousness = ({ context }) => {
  const { formData } = useContext(context);
  const [pConscious, setPConscious] = useState(
    formData?.general_examination?.level_of_consciousness === "p"
      ? "p"
      : formData?.examination?.level_of_consciousness === "p"
      ? "p"
      : null
  );
  useEffect(() => {
    setPConscious(
      formData?.general_examination?.level_of_consciousness === "p"
        ? "p"
        : formData?.examination?.level_of_consciousness === "p"
        ? "p"
        : null
    );
  }, [formData]);

  return (
    <>
      <Form.Item
        label="Level of consciousness"
        name="level_of_consciousness"
        rules={[]}
      >
        {/* <Typography>AVPU Scale</Typography> */}
        <Radio.Group
          options={consiousnessOptions}
          onChange={(e) => setPConscious(e.target.value)}
        />
      </Form.Item>
      {pConscious === "p" && (
        <Form.Item name="level_of_consciousness_options" rules={[]}>
          <Radio.Group options={moreConsiousnessOptions} />
        </Form.Item>
      )}
    </>
  );
};

export default LevelOfConsciousness;
