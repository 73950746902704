import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";
import { speechFunctionalOptions } from "../../Options/funcExamOptions";
import { Form, Input } from "antd";

const SpeechSwallowFunctionalDiagnosis = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState({});
  useEffect(() => {
    const initialCheckboxes = speechFunctionalOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] = formData?.functional_diagnosis?.[checkbox.value];

        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <CheckListFBYInput
        options={speechFunctionalOptions}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
      <Form.Item name={"comments"}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default SpeechSwallowFunctionalDiagnosis;
