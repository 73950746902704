import React from "react";
import { ConfigProvider, Tabs, theme } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
const { useToken } = theme;
const AccountTabs = () => {
  const navigate = useNavigate();
  const router = useLocation();

  const { token } = useToken();
  const onChange = (key) => {
    navigate(`/accounts/${key}`);
  };

  const items = [
    {
      key: "details",
      label: "My details",
    },
    {
      key: "password",
      label: "Password",
    },
    {
      key: "experience",
      label: "Experience",
    },
  ];
  return (
    <div style={{ width: "50%", paddingTop: "16px" }}>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              horizontalItemPadding: "21px 0px",
              colorBorderSecondary: "#d0d5dd",
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey={router.pathname.split("/")[2]}
          onChange={onChange}
        >
          {items.map((item) => (
            <Tabs.TabPane
              key={item.key}
              tab={
                <Link
                  href={`/accounts/${item.key}`}
                  style={{
                    color: router?.pathname?.includes(item.key)
                      ? token.colorPrimary
                      : token.colorText,
                  }}
                >
                  {item.label}
                </Link>
              }
            />
          ))}
        </Tabs>
      </ConfigProvider>
    </div>
  );
};

export default AccountTabs;
