import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Image,
  Pagination,
  Space,
  Table,
  Tag,
  Col,
  Tooltip,
  Statistic,
} from "antd";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import InPatientModal from "../Inpatients/InPatientModal";
import AddPatientModal from "../AddPatientModal";
import AppointsModal from "../Appointments/AppointsModal";
import ipIcon from "../../../assets/receptionistIcons/ipIcon.svg";
import billingopIcon from "../../../assets/receptionistIcons/billingopIcon.svg";
import appointmentIcon from "../../../assets/receptionistIcons/appointmentIcon.svg";
import btnStyles from "../../../styles/Custom/Button.module.css";
import bedIcon from "../../../assets/images/patientIcons/bed.svg";
import { useNavigate } from "react-router-dom";

const AllPatientTable = ({ data, refresh, setPage, page }) => {
  const navigate = useNavigate();
  const [editPatientModal, setEditPatientModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [addAppointment, setAddAppointment] = useState(false);

  const customImageIcon = (icon) => {
    return (
      <Image src={icon} alt="ipIcon" width={20} height={20} preview={false} />
    );
  };

  const columns = [
    { title: "Patient ID", key: "id", dataIndex: "id", width: 100 },
    { title: "Phone Number", key: "mobile", dataIndex: "mobile", width: 150 },
    {
      title: "Full Name",
      width: 350,
      render: (_, record) => (
        <Space>
          {capitalizeFirstLetter(record?.first_name) +
            " " +
            capitalizeFirstLetter(record?.last_name)}

          {`${record?.age ? record?.age : ""} ${
            record?.gender === "Male"
              ? "M"
              : record?.gender === "Female"
              ? "F"
              : ""
          }`}
          {record?.is_inpatient && (
            <Space>
              {record?.ipadmission__bed__bed_no && (
                <Tag color="red">
                  {" "}
                  <Image src={bedIcon} /> {record?.ipadmission__bed__bed_no}
                </Tag>
              )}
              {record?.ipadmission__bed__room__room_no && (
                <Tag color="blue">
                  {record?.ipadmission__bed__room__room_no}
                </Tag>
              )}{" "}
            </Space>
          )}
        </Space>
      ),
    },
    {
      title: "Balance",
      key: "balance",
      dataIndex: "balance",
      width: 150,
      render: (value, record) =>
        value < 0 ? (
          <Statistic
            value={value}
            prefix="-"
            valueStyle={{ color: "#FD6050", fontSize: "18px" }}
          />
        ) : (
          <Statistic
            value={value}
            prefix="+"
            valueStyle={{ color: "#40C164", fontSize: "18px" }}
          />
        ),
    },
    {
      title: "Action",
      width: 350,
      render: (_, record) => (
        <>
          {record?.is_inpatient ? (
            <Button
              type="primary"
              ghost
              className={btnStyles.mdBtn}
              onClick={() =>
                navigate(
                  `/receptionist/home?patient=${record?.ipadmission}&type=billing`
                )
              }
            >
              View Billing
            </Button>
          ) : (
            <Space>
              <Tooltip title="In Patient">
                <Button
                  onClick={() => {
                    setOpen(true);
                    setEditData(record);
                  }}
                >
                  {customImageIcon(ipIcon)}
                  {record?.is_inpatient && (
                    <Badge
                      color="green"
                      status="processing"
                      className="custom-badge-size"
                      style={{
                        position: "absolute",
                        top: -5,
                        right: 3,
                      }}
                    />
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Appointments">
                <Button
                  onClick={() => {
                    setAddAppointment(true);
                    setEditData(record);
                  }}
                >
                  {customImageIcon(appointmentIcon)}
                </Button>
              </Tooltip>

              <Tooltip title="Billing">
                <Button
                  onClick={() =>
                    navigate(
                      `/receptionist/billing/?patient=true&patient_id=${record?.id}`
                    )
                  }
                >
                  {customImageIcon(billingopIcon)}
                </Button>
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
    {
      width: 100,
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            setEditPatientModal(true);
            setEditData(record);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <Table
        bordered
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: "400px" }}
      />
      <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
        <Pagination
          current={page}
          pageSize={50}
          total={data?.total_pages}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </Col>

      {open && (
        <InPatientModal
          open={open}
          setOpen={setOpen}
          editData={editData}
          refresh={refresh}
        />
      )}
      {editPatientModal && (
        <AddPatientModal
          open={editPatientModal}
          setOpen={setEditPatientModal}
          editData={editData}
          refresh={refresh}
        />
      )}
      {addAppointment && (
        <AppointsModal
          open={addAppointment}
          setOpen={setAddAppointment}
          refresh={refresh}
          editData={editData}
        />
      )}
    </div>
  );
};

export default AllPatientTable;
