import { Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import { speechTypeOptions } from "../../../Options/functionalAsessmentOptions";

const Speech = ({ context }) => {
  const { formData } = useContext(context);
  const [showSpeech, setShowSpeech] = useState(
    formData?.functional_status?.speech_details?.speech === "assessable"
  );

  useEffect(() => {
    setShowSpeech(
      formData?.functional_status?.speech_details?.speech === "assessable"
    );
  }, [formData]);

  return (
    <>
      <Form.Item label="Speech" name={["speech_details", "speech"]}>
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) => {
            setShowSpeech(e.target.value === "assessable");
          }}
        />
      </Form.Item>
      {showSpeech && (
        <div className="subsection-card">
          <Form.Item name={["speech_details", "speech_type"]}>
            <Radio.Group options={speechTypeOptions} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default Speech;
