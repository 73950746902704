import React, { useContext, useEffect } from "react";
import TreatmentPlan from "./TreatmentPlan";
import { PhysioReviewContext } from "../../Physiotherapist/PhysiotherapistReview/PhysioReviewFormProvider";

const PhysioTreatmentPlanReview = ({ form }) => {
  const { formData } = useContext(PhysioReviewContext);
  useEffect(() => {
    form.setFieldsValue(formData?.treatment_plan);
  }, [formData]);
  return <TreatmentPlan />;
};

export default PhysioTreatmentPlanReview;
