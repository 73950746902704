import React, { useState } from "react";
import { Upload, message, Button } from "antd";
import { getUploadUrlApi } from "../../../services/common.services";
import { convertToFormData } from "../../../helpers/utility";
import { uploadPatientImagesApi } from "../../../services/patient.services";
import useQuery from "../../../hooks/useQuery";
import axios from "axios";

const PatientGalleryUpload = ({ refresh }) => {
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const params = useQuery();

  const uploadProps = {
    beforeUpload: (file) => {
      let file_ext = file.name.split(".").slice(-1)[0];
      const fileType =
        file_ext === "png" ||
        file_ext === "jpg" ||
        file_ext === "jpeg" ||
        file_ext === "svg";

      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      if (file.size > 5 * 1024 * 1024) {
        message.error(
          `${file.name} is too large. File must be smaller than 5 mb.`
        );
        return Upload.LIST_IGNORE;
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await getUploadUrlApi(`path_key=${info.file.name}`);
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          await uploadPatientFiles({
            paths: [{ path: res?.data?.fields?.key, type: info.file.type }],
          });
        } catch (err) {
          message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadPatientFiles = async (val) => {
    uploadPatientImagesApi({ ...val, ip_admission: params?.patient })
      .then((response) => {
        message.success(response.data.message);
        refresh();
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };
  return (
    <>
      <Upload
        style={{ width: "300px" }}
        accept=".png,.jpg,.jpeg,.svg,application/pdf"
        {...uploadProps}
        showUploadList={false}
        customRequest={dummyRequest}
      >
        <Button type="primary">Upload</Button>
      </Upload>
    </>
  );
};

export default PatientGalleryUpload;
