import { Descriptions } from "antd";
import React from "react";
import { getLabelTagsWithDescription } from "../constructTextFromValues";
import { specialCareOptions } from "../../Options/functionalAsessmentOptions";

const NurseSpecialCareRequirementSummary = ({ formData }) => {
  const constructNurseSpecialCareSummaryItems = () => {
    const nurseSpecialCareSummaryItems = [];
    const special_care = formData?.special_care || {};

    if (getLabelTagsWithDescription(specialCareOptions, special_care)) {
      nurseSpecialCareSummaryItems.push({
        key: "1",
        label: "Area(s)",
        children: getLabelTagsWithDescription(specialCareOptions, special_care),
      });
    }
    return nurseSpecialCareSummaryItems;
  };
  const nurseSpecialCareSummaryItems = constructNurseSpecialCareSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Area of Special Care Requirement"
        items={nurseSpecialCareSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default NurseSpecialCareRequirementSummary;
