import { Form, Radio, Select, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  moreSubjectiveOptions,
  subjectiveOptions,
} from "../../../Options/functionalAsessmentOptions";

const SubjectiveAssessment = ({ context }) => {
  const { formData } = useContext(context);
  const [showVision, setShowVision] = useState(
    formData?.functional_status?.subjective_assessment?.vision_status ===
      "affected"
  );
  const [showHearing, setShowHearing] = useState(
    formData?.functional_status?.subjective_assessment?.hearing_status ===
      "affected"
  );

  useEffect(() => {
    setShowVision(
      formData?.functional_status?.subjective_assessment?.vision_status ===
        "affected"
    );
    setShowHearing(
      formData?.functional_status?.subjective_assessment?.hearing_status ===
        "affected"
    );
  }, [formData]);
  return (
    <>
      <Typography>Subjective Assessment</Typography>
      <Form.Item
        label="Vision"
        name={["subjective_assessment", "vision_status"]}
      >
        <Radio.Group
          options={subjectiveOptions}
          onChange={(e) => setShowVision(e.target.value === "affected")}
        />
      </Form.Item>
      {showVision && (
        <>
          <Form.Item name={["subjective_assessment", "vision_affected_status"]}>
            <Select options={moreSubjectiveOptions} style={{ width: "30%" }} />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Hearing"
        name={["subjective_assessment", "hearing_status"]}
      >
        <Radio.Group
          options={subjectiveOptions}
          onChange={(e) => setShowHearing(e.target.value === "affected")}
        />
      </Form.Item>
      {showHearing && (
        <>
          <Form.Item
            name={["subjective_assessment", "hearing_affected_status"]}
          >
            <Select options={moreSubjectiveOptions} style={{ width: "30%" }} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default SubjectiveAssessment;
