import React, { useContext, useEffect, useState } from "react";
import { Form, Radio, Select } from "antd";
import {
  mentalAbnormalOptions,
  mentalStatusOptions,
} from "../../../../Options/systemicExamOptions";

const MentalStatus = ({ context }) => {
  const { formData } = useContext(context);
  const [showAbnormal, setShowAbnormal] = useState(
    formData?.systemic_examination?.mental_status === "abnormal" ? true : false
  );

  const handleStatusChange = (e) => {
    setShowAbnormal(e.target.value === "abnormal");
  };

  useEffect(() => {
    setShowAbnormal(
      formData?.systemic_examination?.mental_status === "abnormal"
        ? true
        : false
    );
  }, [formData]);

  return (
    <>
      <Form.Item label="Mental Status" name="mental_status">
        <Radio.Group
          options={mentalStatusOptions}
          onChange={handleStatusChange}
        />
      </Form.Item>
      {showAbnormal && (
        <Form.Item name="mental_status_details">
          <Select
            placeholder="Choose abnormality"
            options={mentalAbnormalOptions}
          />
        </Form.Item>
      )}
    </>
  );
};

export default MentalStatus;
