import React, { useContext, useState, useEffect } from "react";
import { Form, Radio, InputNumber, DatePicker } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";
import {
  tracheostomySecretionOptions,
  tracheostomyTypeOptions,
} from "../../../../Options/genExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
import dayjs from "dayjs";

const Tracheostomy = ({ dailyReview = false }) => {
  const { formData } = useContext(DoctorInitialContext);
  const [tracheostomyValid, setTracheostomyValid] = useState(
    formData?.general_examination?.tracheostomy === "yes" ? true : false
  );
  useEffect(() => {
    setTracheostomyValid(
      formData?.general_examination?.tracheostomy === "yes" ? true : false
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Tracheostomy" name="tracheostomy">
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => setTracheostomyValid(e.target.value === "yes")}
        />
      </Form.Item>
      {tracheostomyValid && (
        <div className="subsection-card">
          <Form.Item label="Type" name="tracheostomy_type">
            <Radio.Group options={tracheostomyTypeOptions} />
          </Form.Item>
          {dailyReview ? (
            <Form.Item label="Started On" name="tracheostomy_started_on">
              <DatePicker
                disabledDate={(current) =>
                  current && current > dayjs().endOf("day")
                }
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Number of days"
              name="tracheostomy_number_of_days"
            >
              <InputNumber style={{ width: "40%" }} />
            </Form.Item>
          )}

          <Form.Item label="Secretion" name="tracheostomy_secretion">
            <Radio.Group options={tracheostomySecretionOptions} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default Tracheostomy;
