import React, { useEffect, useState } from "react";
import {
  Radio,
  Button,
  Form,
  Typography,
  message,
  Input,
  List,
  Row,
  Col,
} from "antd";
import { barthelIndexOptions } from "../../../Options/functionalAsessmentOptions";
import "./PhysioScales.css";
import {
  barthelIndexAPI,
  getBarthelIndexAPI,
  updateBarthelIndexAPI,
} from "../../../../../services/physio.services";

const BarthelIndex = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});

  const handleChange = (category, value) => {
    setStates((prev) => ({ ...prev, [category]: value }));
  };
  const aggregateScore = Object.values(states).reduce(
    (acc, curr) => acc + (curr || 0),
    0
  );
  const checkDependence = () => {
    if (aggregateScore < 20) {
      return "Totally Dependent";
    } else if (aggregateScore < 40) {
      return "Very Dependent";
    } else if (aggregateScore < 60) {
      return "Partially Dependent";
    } else if (aggregateScore < 80) {
      return "Minimally Dependent";
    } else {
      return "Independent";
    }
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (scalesId?.barthel_index) {
        await updateBarthelIndexAPI(scalesId?.barthel_index, finalData);
      } else {
        const response = await barthelIndexAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, barthel_index: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      // setScaleTab((prev) => (parseInt(prev) + 1).toString());
      setActiveTab("3");
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.barthel_index) {
      getBarthelIndexAPI(admission, scalesId?.barthel_index)
        .then((response) => {
          form.setFieldsValue(response.data);
          setStates(
            Object.fromEntries(
              Object.entries(response.data).filter(
                ([key]) =>
                  key !== "id" && key !== "ip_admission" && key !== "comments"
              )
            )
          );
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Typography.Text className="score-counter">
          {aggregateScore}
          <br />
          —
          <br />
          100
          <div className="score-string">{checkDependence()}</div>
        </Typography.Text>
        <Form id="barthel-index" onFinish={handleSubmit} form={form}>
          <List
            bordered
            dataSource={barthelIndexOptions}
            style={{ background: "#fff", width: "600px" }}
            renderItem={(option) => (
              <List.Item>
                <Row
                  style={{ width: "100%" }}
                  align="middle"
                  justify="space-between"
                >
                  <Col span={12}>
                    <Typography>{option.label}</Typography>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Form.Item name={option.value} style={{ marginBottom: 0 }}>
                      <Radio.Group
                        defaultValue={states[option.value]}
                        onChange={(e) =>
                          handleChange(option.value, e.target.value)
                        }
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Radio.Button
                          value={0}
                          className={
                            states[option.value] === 0 ? "primary-button" : ""
                          }
                          style={{ flex: 1, minWidth: "130px" }}
                        >
                          0
                        </Radio.Button>
                        <Radio.Button
                          value={5}
                          className={
                            states[option.value] === 5 ? "primary-button" : ""
                          }
                          style={{ flex: 1, minWidth: "80px" }}
                        >
                          +5
                        </Radio.Button>
                        {!["bathing", "grooming"].includes(option.value) && (
                          <Radio.Button
                            value={10}
                            className={
                              states[option.value] === 10
                                ? "primary-button"
                                : ""
                            }
                            style={{ flex: 1, minWidth: "80px" }} // Adjust minWidth as needed
                          >
                            +10
                          </Radio.Button>
                        )}
                        {["transfers", "mobility"].includes(option.value) && (
                          <Radio.Button
                            value={15}
                            className={
                              states[option.value] === 15
                                ? "primary-button"
                                : ""
                            }
                            style={{ flex: 1, minWidth: "80px" }} // Adjust minWidth as needed
                          >
                            +15
                          </Radio.Button>
                        )}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </List.Item>
            )}
          />

          <Form.Item name="comments">
            <Input placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default BarthelIndex;
