import React, { useState } from "react";
import { Card, Radio, Typography, Space } from "antd";
import ServicesTableIp from "./ServicesTableIp";

const ServicesBilling = ({ billData }) => {
  const [value, setValue] = useState("summary");

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <Card
      title={
        <Space>
          <Typography>Services Billing</Typography>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="summary">Summary</Radio>
            <Radio value="detailed">Detailed</Radio>
          </Radio.Group>
        </Space>
      }
    >
      <ServicesTableIp billData={billData} />
    </Card>
  );
};

export default ServicesBilling;
