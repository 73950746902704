import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import { Typography } from "antd";
import { spineOptions } from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
const { Text } = Typography;
const Spine = ({ context }) => {
  const { formData } = useContext(context ? context : DoctorInitialContext);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = spineOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Text>Spine</Text>
      <CheckListFBYInput
        options={spineOptions}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    </>
  );
};

export default Spine;
