import React, { useEffect, useState } from "react";
import MotorSensoryScale from "./ASIAScaleComponents/MotorSensoryScale";
import { Button, Collapse, Form, message } from "antd";
import NeurologicalLevels from "./ASIAScaleComponents/NeurologicalLevels";
import DapVac from "./ASIAScaleComponents/DapVac";
import {
  asiaScaleApi,
  getAsiaScaleAPI,
  updateAsiaScaleAPI,
} from "../../../../../services/physio.services";
const { Panel } = Collapse;

const ASIAScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (scalesId?.asia) {
        await updateAsiaScaleAPI(scalesId?.asia, finalData);
      } else {
        const response = await asiaScaleApi(finalData);
        setScalesId((prev) => {
          const newScalesId = {
            ...prev,
            asia: response?.data?.id,
          };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.asia) {
      getAsiaScaleAPI(admission, scalesId?.asia)
        .then((response) => {
          form.setFieldsValue(response.data);
          form.validateFields();
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const [scores, setScores] = useState({});
  const upperExtremityOptions = ["c5", "c6", "c7", "c8", "t1"];
  const lowerExtremityOptions = ["l2", "l3", "l4", "l5", "s1"];

  const onValuesChange = (changedValues, allValues) => {
    const updatedValues = { ...changedValues, ...allValues };
    console.log("Asia ChangedValues", changedValues);
    console.log("Asia AllValues", allValues);
    if ("motor_sensory_scale" in changedValues) {
      const key = Object.keys(changedValues?.motor_sensory_scale);
      console.log(key, typeof key);
      switch (key[0]) {
        case "ltl":
          const ltl = Object.values(
            updatedValues?.motor_sensory_scale?.ltl ?? {}
          )
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ltl }));
          break;
        case "ppl":
          const ppl = Object.values(allValues?.motor_sensory_scale?.ppl ?? {})
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ppl }));
          break;
        case "ppr":
          const ppr = Object.values(allValues?.motor_sensory_scale?.ppr ?? {})
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ppr }));
          break;
        case "ltr":
          const ltr = Object.values(allValues?.motor_sensory_scale?.ltr || {})
            .filter((value) => typeof value === "number" && !isNaN(value))
            .reduce((acc, cur) => acc + cur, 0);
          setScores((prev) => ({ ...prev, ltr }));
        case "left_motor":
          const leftUpperMotor = Object.entries(
            allValues?.motor_sensory_scale?.left_motor || {}
          )
            .filter(([key, value]) => upperExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          const leftLowerMotor = Object.entries(
            allValues?.motor_sensory_scale?.left_motor || {}
          )
            .filter(([key, value]) => lowerExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          setScores((prev) => ({ ...prev, leftUpperMotor, leftLowerMotor }));
          break;
        case "right_motor":
          const rightUpperMotor = Object.entries(
            allValues?.motor_sensory_scale?.right_motor || {}
          )
            .filter(([key, value]) => upperExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          const rightLowerMotor = Object.entries(
            allValues?.motor_sensory_scale?.right_motor || {}
          )
            .filter(([key, value]) => lowerExtremityOptions.includes(key))
            .reduce(
              (acc, [key, value]) =>
                acc + (typeof value === "number" ? value : 0),
              0
            );
          setScores((prev) => ({ ...prev, rightUpperMotor, rightLowerMotor }));
          break;
        default:
          console.log("Unexpected key", key);
      }
    }
  };
  return (
    <>
      <div className="cardless-scale">
        <Form
          id="asia-scale"
          form={form}
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          style={{ width: "100%" }}
        >
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel header="Motor and Sensory Scale" key={"1"}>
              <MotorSensoryScale scores={scores} />
            </Panel>
            <DapVac />
            <Panel header="Neurological Levels" key={"2"}>
              <NeurologicalLevels />
            </Panel>
          </Collapse>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default ASIAScale;
