import React, { useState, useEffect, useContext } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { Typography } from "antd";
import { drainNumberOptions } from "../../../../Options/genExamOptions";

const NurseDrain = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState([]);
  useEffect(() => {
    const initialCheckboxes = drainNumberOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.examination?.drain?.[checkbox.value] || false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <Typography>Drain</Typography>
      <CheckListFBYInput
        options={drainNumberOptions}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
        keyChain={"drain"}
      />
    </>
  );
};

export default NurseDrain;
