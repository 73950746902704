import { Form, Input, Radio } from "antd";
import React from "react";
import { oxygenRequiredOptions } from "../../Options/RespiratoryTherapistOptions";

const Oxygen = () => {
  return (
    <>
      <Form.Item
        label="O2 Required for SPO2 > 92%"
        name={["oxygen_required", "status"]}
      >
        <Radio.Group options={oxygenRequiredOptions} />
      </Form.Item>
      <Form.Item name={["oxygen_required", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default Oxygen;
