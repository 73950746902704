import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { viewDoctorAppointmentsApi } from "../../../services/doctor.services";

const AppointmentsView = () => {
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    viewDoctorAppointmentsApi(userDetails?.id)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return <div></div>;
};

export default AppointmentsView;
