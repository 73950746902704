import { Button, Form, Typography, Input, message, Card } from "antd";
import React, { useEffect, useState } from "react";

import ReflexesForm from "../SystemicExaminationForm/CNSForm/ReflexesForm";
import {
  getReflexesAPI,
  reflexesAPI,
  updateReflexesAPI,
} from "../../../../../services/physio.services";

const Reflexes = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (Object.keys(formData)?.length !== 0) {
        await updateReflexesAPI(scalesId?.reflexes, finalData);
      } else {
        const response = await reflexesAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, reflexes: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.reflexes) {
      getReflexesAPI(admission, scalesId?.reflexes)
        .then((response) => {
          form.setFieldsValue(response.data);
          setFormData(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Card style={{ width: "600px" }}>
          <Form onFinish={handleSubmit} form={form}>
            <ReflexesForm />
            <Form.Item label="Comments" name="comments">
              <Input />
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default Reflexes;
