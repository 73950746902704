import React, { useState } from "react";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Table,
  Tag,
  Typography,
} from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import tagStyles from "../../../../styles/Custom/Tag.module.css";
import checkboxStyles from "../../../../styles/Custom/Checkbox.module.css";
import { updateCategoryApi } from "../../../../services/service.services";
const { Paragraph, Text } = Typography;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input className={inputStyles?.mdInput} />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: record?.key === "name" && true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const CategoryTable = ({
  loading,
  data,
  setData,
  refreshPage,
  userDetails,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record?.id === editingKey?.id;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      protien: "",
      calories: "",
      ...record,
    });
    setEditingKey(record);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const updatedValues = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...updatedValues });
        setData(newData);
        const res = await updateCategoryApi(record.id, updatedValues);
        refreshPage();
        message.success(res?.data?.message);
        setEditingKey("");
      } else {
        message.error("Row not found");
      }
    } catch (err) {
      console.error("Error:", err);
      message.error("Error updating medication");
    }
  };
  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      editable: true,
      width: "15%",
      sorter: (a, b) => a?.name.localeCompare(b?.name),

      render: (value, record) =>
        record?.is_antiboitic ? (
          <Tag className={tagStyles.mdRndTag} color="magenta">
            {value}
          </Tag>
        ) : (
          value
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
      width: "20%",
      sorter: (a, b) => a?.description.localeCompare(b?.description),
      render: (value) => (
        <Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "more",
          }}
          style={{ width: 320 }}
        >
          {value}
        </Paragraph>
      ),
    },

    ...(userDetails?.activeOrg?.default_role === "Facility_Admin"
      ? []
      : [
          {
            title: "Action",
            dataIndex: "operation",
            width: "5%",
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link
                    onClick={() => save(record)}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Save
                  </Typography.Link>
                  <Typography.Link
                    onClick={cancel}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    Cancel
                  </Typography.Link>
                </span>
              ) : (
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  Edit
                </Typography.Link>
              );
            },
          },
        ]),
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          loading={loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ y: "calc(100vh - 310px)", x: 0 }}
        />
      </Form>
    </>
  );
};
export default CategoryTable;
