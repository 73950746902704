import { Form, Radio } from "antd";
import React from "react";
import { yesNoOptions } from "../../Options/commonOptions";

const PatientFamilyEducation = () => {
  return (
    <Form.Item
      label="Patient Family Education"
      name={"patient_family_education"}
    >
      <Radio.Group options={yesNoOptions} />
    </Form.Item>
  );
};

export default PatientFamilyEducation;
