import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Form, Input, Radio } from "antd";
import {
  chestXRayOptions,
  clinicalFindingOptions,
  pulmonaryOptions,
  surgicalStatusOptions,
} from "../../Options/RespiratoryTherapistOptions";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";

const ClinicalFindings = () => {
  const { formData } = useContext(RespiratoryInitialContext);
  const [checkboxes, setCheckboxes] = useState({});
  const [states, setStates] = useState({}); // Initialize state for checkboxes

  const handleCheckboxChange = (value) => {
    setStates((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  useEffect(() => {
    setStates((prev) => ({
      ...prev,
      pulmonary: formData?.chart_assessment?.pulmonary,
      surgical_status: formData?.chart_assessment?.surgical_status,
      chest_x_ray: formData?.chart_assessment?.chest_x_ray,
    }));
    const surgicalCheckboxes = surgicalStatusOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.chart_assessment?.surgical_status_details?.[checkbox.value];
      return acc;
    }, {});
    const chestCheckboxes = chestXRayOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.chart_assessment?.chest_x_ray_details?.[checkbox.value];
      return acc;
    }, {});
    setCheckboxes({ ...surgicalCheckboxes, ...chestCheckboxes });
  }, [formData]);

  return (
    <>
      {clinicalFindingOptions.map((option) => (
        <div key={option.value}>
          <Form.Item name={option.value} valuePropName="checked">
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={states[option.value] || false}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {states[option.value] && (
            <>
              {option.value !== "pulmonary" ? (
                <CheckListFBYInput
                  checkboxes={checkboxes}
                  setCheckboxes={setCheckboxes}
                  options={
                    option.value === "surgical_status"
                      ? surgicalStatusOptions
                      : chestXRayOptions
                  }
                  keyChain={`${option.value}_details`}
                />
              ) : (
                <>
                  <Form.Item name="pulmonary_details">
                    <Radio.Group options={pulmonaryOptions} />
                  </Form.Item>
                  <Form.Item name="pulmonary_others">
                    <Input placeholder="Others" />
                  </Form.Item>
                </>
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default ClinicalFindings;
