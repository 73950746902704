import React, { useContext, useState, useEffect } from "react";
import { Checkbox, InputNumber, Form, Radio, Typography, Row, Col } from "antd";
import {
  pastMedicalOptions,
  controlOptions,
} from "../../../Options/historyOptions";
const { Text } = Typography;

const PastMedicalHistory = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState(() =>
    pastMedicalOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = !!formData?.history?.[checkbox.value];
      return acc;
    }, {})
  );

  useEffect(() => {
    setCheckboxes(
      pastMedicalOptions.reduce((acc, checkbox) => {
        acc[checkbox.value] = !!formData?.history?.[checkbox.value];
        return acc;
      }, {})
    );
  }, [formData]);

  const handleCheckboxChange = (value) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: !prevCheckboxes[value],
    }));
  };

  return (
    <>
      <Text>Past Medical History</Text>
      {pastMedicalOptions?.map((checkbox) => (
        <div key={checkbox.value}>
          <Form.Item name={checkbox.value} valuePropName="checked">
            <Checkbox onChange={() => handleCheckboxChange(checkbox.value)}>
              {checkbox.label}
            </Checkbox>
          </Form.Item>
          {checkboxes?.[checkbox.value] && (
            <div className="subsection-card">
              <Row>
                <Col span={6}>
                  <Form.Item name={`${checkbox.value}_yrs`}>
                    <InputNumber
                      min={0}
                      max={80}
                      step={0.1}
                      stringMode
                      addonBefore="Since"
                      addonAfter="yrs"
                    />
                  </Form.Item>
                </Col>
                <Col span={17} offset={1}>
                  <Form.Item name={`${checkbox.value}_control`}>
                    <Radio.Group options={controlOptions} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default PastMedicalHistory;
