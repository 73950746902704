import React from "react";
import { Table } from "antd";

const ServicePackageTable = ({ paymentData }) => {
  const tableData = paymentData && [
    ...paymentData?.service,
    ...paymentData?.package,
  ];

  const columns = [
    {
      title: "Service / Package",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
  ];
  return (
    <div>
      <Table dataSource={tableData} columns={columns} pagination={false} />
    </div>
  );
};

export default ServicePackageTable;
