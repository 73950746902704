import React from "react";
import { Checkbox, Input, Form, Row, Col } from "antd";

// CheckListFBYInput Component
// This component renders a checklist with checkboxes and an optional input field for each checkbox.
// Props:
// - options: An array of objects representing the checkboxes.
// - initialValues: An object containing the initial values for the checkboxes.
// Usage: <CheckListFBYInput options={checkboxOptions} initialValues={initialValues} />

const CheckListFBYInput = ({
  options,
  checkboxes,
  setCheckboxes,
  keyChain = null,
}) => {
  const checkboxesData = options || [];

  const handleCheckboxChange = (value) => {
    // Toggle the checkbox value in the checkboxes state

    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: !prevCheckboxes[value], // Toggle the checkbox value
    }));
  };

  // console.log(checkboxesData, checkboxes);

  return (
    <Row>
      {checkboxesData.map((checkbox, index) => (
        <Col span={8} key={index}>
          <Form.Item
            name={keyChain ? [[keyChain], [checkbox.value]] : checkbox.value}
            valuePropName="checked"
          >
            <Checkbox onChange={() => handleCheckboxChange(checkbox.value)}>
              {checkbox.label}
            </Checkbox>
          </Form.Item>
          {/* Render an input field if the checkbox is checked */}
          {checkboxes[checkbox.value] && (
            <Form.Item
              style={{ marginLeft: "20px", marginRight: "20px" }}
              name={
                keyChain
                  ? [[keyChain], `${checkbox.value}_description`]
                  : `${checkbox.value}_description`
              }
            >
              <Input type="text" placeholder="Enter Details" />
            </Form.Item>
          )}
        </Col>
      ))}
    </Row>
  );
};

export default CheckListFBYInput;
