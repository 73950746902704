import React, { useEffect, useState } from "react";
import { Card, List, Typography } from "antd";
import useQuery from "../../../../hooks/useQuery";
import { getInPatientDetailsApi } from "../../../../services/receptionist.services";
import { capitalizeFirstLetter } from "../../../../helpers/utility";

const PatientDetails = () => {
  const [patientDetails, setPatientDetails] = useState({});
  const params = useQuery();

  useEffect(() => {
    getInPatientDetailsApi(params?.patient)
      .then((res) => {
        setPatientDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params?.patient]);

  const filterObjectByKeys = (obj, keysToKeep) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key]) => keysToKeep.includes(key))
    );
  };

  const getRemainingKeys = (obj, keysToExclude) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key]) => keysToExclude.includes(key))
    );
  };

  // Specify the keys you want to keep for Basic Details
  const keysToKeep = [
    "admission_date",
    "patient_email",
    "patient_mobile",
    "facility_name",
    "is_active",
  ];
  const keysToInclude = [
    "patient_blood_group",
    "patient_marital_status",
    "primary_consultant_name",
    "primary_consultant_designation",
    "emergency_contact_name",
    "emergency_contact_no",
    "primary_hospital_name",
  ];

  const labelMapping = {
    admission_date: "Admission Date",
    patient_email: "Email",
    patient_mobile: "Mobile Number",
    facility_name: "Facility",
    is_active: "Current Status",
    patient_blood_group: "Blood Group",
    patient_marital_status: "Marital Status",
    primary_consultant_name: "Primary Consultant Name",
    primary_consultant_designation: "Primary Consultant Designation",
    emergency_contact_name: "Emergency Contact Name",
    emergency_contact_no: "Emergency Contact No",
    primary_hospital_name: "Primary Hospital Name",
  };

  // Get filtered data for Basic Details
  const filteredData = filterObjectByKeys(patientDetails, keysToKeep);

  // Get remaining data for More Information
  const remainingData = getRemainingKeys(patientDetails, keysToInclude);

  return (
    <>
      <Card title="Basic Details" className="care-item-card">
        <List>
          {Object.keys(filteredData).map((item) => (
            <List.Item key={item}>
              <List.Item.Meta title={labelMapping[item]} />
              <Typography>
                {item === "is_active"
                  ? "In Facility"
                  : filteredData[item] ?? "-"}
              </Typography>
            </List.Item>
          ))}
        </List>
      </Card>

      <Card
        title="More Information"
        className="care-item-card"
        style={{ marginTop: "8px" }}
      >
        <List>
          {Object.keys(remainingData).map((item) => (
            <List.Item key={item}>
              <List.Item.Meta title={labelMapping[item]} />
              <Typography>{remainingData[item] ?? "-"}</Typography>
            </List.Item>
          ))}
        </List>
      </Card>
    </>
  );
};

export default PatientDetails;
