import { Form, InputNumber } from "antd";
import React from "react";

const CaloriesAndProtien = () => {
  return (
    <>
      <Form.Item label="Calories" name="calories">
        <InputNumber addonAfter="K.cal/day" />
      </Form.Item>
      <Form.Item label="Protein" name="protein">
        <InputNumber addonAfter="gms/day" />
      </Form.Item>
    </>
  );
};

export default CaloriesAndProtien;
