import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox, Typography } from "antd";
import CheckListFBYInput from "../../../../../blocks/FormItems/CheckListFBYInput";
import {
  generalPatientOptions,
  masaOptions,
  oralPhaseOptions,
  oralPreparationOptions,
  pharyngealOptions,
} from "../../../Options/speechSwallowOptions";

const MASA = ({ context }) => {
  const { formData } = useContext(context);
  const [masaSelected, setMasaSelected] = useState({});
  const [checkboxes, setCheckboxes] = useState({});

  const handleCheckboxChange = (value) => {
    setMasaSelected((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const getOptions = (value) => {
    switch (value) {
      case "general_patient_examination":
        return generalPatientOptions;
      case "oral_preparation_main":
        return oralPreparationOptions;
      case "oral_phase":
        return oralPhaseOptions;
      case "pharyngeal_phase":
        return pharyngealOptions;
      default:
        return [];
    }
  };

  useEffect(() => {
    const initialMasaStates = masaOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.masa_details?.[checkbox.value] || false;
      return acc;
    }, {});
    setMasaSelected(initialMasaStates);
    const generalStates = generalPatientOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.general_patient_examination_details?.[checkbox.value];
      return acc;
    }, {});
    const phaseStates = oralPhaseOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.oral_phase_details?.[checkbox.value];
      return acc;
    }, {});
    const preparationStates = oralPreparationOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.oral_preparation_main_details?.[checkbox.value];
      return acc;
    }, {});
    const pharyngealStates = pharyngealOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.scales?.pharyngeal_phase_details?.[checkbox.value];
      return acc;
    }, {});
    setCheckboxes({
      ...generalStates,
      ...phaseStates,
      ...preparationStates,
      ...pharyngealStates,
    });
  }, [formData]);

  return (
    <>
      <Typography.Title level={5}>MASA</Typography.Title>
      {masaOptions.map((option) => (
        <React.Fragment key={option.value}>
          <Form.Item
            name={["masa_details", option.value]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={masaSelected[option.value] || false}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {masaSelected[option.value] && (
            <div className="subsection-card">
              <CheckListFBYInput
                options={getOptions(option.value)}
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                keyChain={`${option.value}_details`}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default MASA;
