import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spin, Typography, Button } from "antd";
import { downloadUrlsApi } from "../../../../services/common.services";
import { aiVisionApi } from "../../../../services/patient.services";
import ScanMedicineForm from "./ScanMedicineForm";
import AddInitialMedicineModal from "../AddMedicine/AddInitialMedicineModal";
import btnStyles from "../../../../styles/Custom/Button.module.css";

const ScanImageList = ({
  uploadFile,
  refreshPage,
  setOpen,
  initial = false,
  setCurrent,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState([]);
  const [url, setUrl] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const paths = uploadFile?.map((path) => path?.path);

  useEffect(() => {
    setLoading(true);
    if (uploadFile) {
      downloadUrlsApi(paths)
        .then((res) => {
          setUrl(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [uploadFile]);

  useEffect(() => {
    if (url) {
      aiVisionApi(url)
        .then((res) => {
          setLoading(false);
          if (res?.data?.data) {
            setData(res?.data?.data);

            setKeys(res?.data?.data?.map((_, index) => index));
          } else {
            setShowErrorModal(true);
          }
        })
        .catch((err) => {
          setShowErrorModal(true);
          setLoading(false);
        });
    }
  }, [url]);

  const handleDelete = (index) => {
    setData((prevData) => {
      const newData = prevData.filter((_, i) => i !== index);
      if (newData.length === 0 && !initial) {
        setOpen(false);
      } else if (newData.length === 0 && initial) {
        setCurrent(3);
      }
      return newData;
    });
    setKeys((prevKeys) => prevKeys.filter((_, i) => i !== index));
  };

  const errorModal = () => {
    return (
      <Row>
        <Col span={24} style={{ textAlign: "center" }}>
          <Typography>
            Currently AI is down, Please add medicine manually
          </Typography>
          <Button type="primary" onClick={() => setAddModal(true)}>
            Add Medicine
          </Button>
          {addModal && (
            <AddInitialMedicineModal
              open={addModal}
              setOpen={setAddModal}
              refreshPage={refreshPage}
              setDrawerOpen={setOpen}
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row>
        {loading ? (
          <Col
            span={24}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "75vh",
            }}
          >
            <Spin size="large">Please wait, fetching data from AI</Spin>
          </Col>
        ) : (
          data?.map((item, index) => (
            <Col span={24} key={keys[index]} style={{ padding: "10px" }}>
              <Card>
                <ScanMedicineForm
                  item={item}
                  index={index}
                  keys={keys}
                  handleDelete={handleDelete}
                  setData={setData}
                  setKeys={setKeys}
                  refreshPage={refreshPage}
                  initial={initial}
                />
              </Card>
            </Col>
          ))
        )}
      </Row>
      {initial && (
        <div
          className="flex-end"
          style={{
            gap: "24px",
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: "#fff",
            paddingTop: "10px",
            borderTop: "1px solid #e8e8e8",
            // textAlign: "right",
          }}
        >
          <Button
            // loading={btnLoading}
            disabled={loading}
            className={btnStyles.lgBtn}
            type="primary"
            onClick={() => {
              setCurrent(3);
            }}
          >
            Proceed without saving
          </Button>
        </div>
      )}
      {showErrorModal && errorModal()}
    </>
  );
};

export default ScanImageList;
