import React from "react";
import { Space, Table, Image, Tag, Button } from "antd";
import { capitalizeFirstLetter, formatToINR } from "../../../helpers/utility";
import paymentIcon from "../../../assets/receptionistIcons/paymentIcon.svg";
import exportIcon from "../../../assets/receptionistIcons/exportIcon.svg";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const BillingTable = ({ data, loading }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Bill No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => dayjs(date).format("MMM DD"),
    },
    {
      title: "Patient",
      dataIndex: ["patient", "name"], // Correctly access nested data
      key: "patient_name",
    },
    {
      title: "Total Amount",
      dataIndex: "gross_amount",
      key: "gross_amount",
      render: (value, record) => (
        <div> {formatToINR(value - record?.total_discount)}/-</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={status === "paid" ? "green" : "blue"}
          className={tagStyles.mdRndTag}
        >
          {capitalizeFirstLetter(status)?.replaceAll("_", " ")}
        </Tag>
      ),
    },
    {
      title: "Discount",
      dataIndex: "total_discount",
      key: "total_discount",
    },
    {
      title: "Due Amount",
      dataIndex: "due_amount",
      key: "due_amount",
      render: (value) => formatToINR(value),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            disabled={record?.status === "paid"}
            onClick={() =>
              navigate(
                `/receptionist/billing/?patient=true&id=${record?.id}&patient_id=${record?.patient?.id}`
              )
            }
          >
            <Image src={paymentIcon} alt="payment" preview={false} />
          </Button>

          <Image
            src={exportIcon}
            alt="export"
            preview={false}
            onClick={() => console.log("payment")}
            style={{ cursor: "pointer", width: "22px" }}
          />
          <EyeOutlined
            style={{ cursor: "pointer", fontSize: "20px", color: "#475467" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      dataSource={data?.data}
      columns={columns}
      loading={loading}
      pagination={false}
      scroll={{ y: "calc(100vh - 300px)", x: 0 }}
    />
  );
};

export default BillingTable;
