import axiosInstance from "../config/axios.config";

export const getOrderSheetApi = (id) => {
  return axiosInstance.get(`/inpatient/get-doctor-order-sheet/${id}/`);
};

export const createOrderSheetApi = (payload) => {
  return axiosInstance.post(`/inpatient/doctor-order-sheet/`, payload);
};

export const updateOrderSheetApi = (payload, id) => {
  return axiosInstance.patch(`/inpatient/doctor-order-sheet/${id}/`, payload);
};
