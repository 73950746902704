export const dietOptions = [
  { label: "Oral", value: "oral" },
  { label: "Enteral", value: "enteral" },
  { label: "Parenteral", value: "parenteral" },
];
export const oralDietOptions = [
  { label: "Liquid", value: "liquid" },
  { label: "Soft", value: "soft" },
  { label: "Normal", value: "normal" },
];
export const oralDietTypeOptions = [
  { label: "Normal", value: "normal" },
  { label: "Diabetic", value: "diabetic" },
  { label: "Renal", value: "renal" },
];
export const entrealDietOptions = [
  { label: "Continuous", value: "continuous" },
  { label: "Intermittent", value: "intermittent" },
];
export const entrealFeedTypeOptions = [
  { label: "RT Feed", value: "rt_feed" },
  { label: "PEG Feed", value: "peg_feed" },
];

export const otherSpecificationsOptions = [
  { label: "Hypertensive Diet", value: "hypertensive_diet" },
  { label: "Diabetic Diet", value: "diabetic_diet" },
  { label: "Renal Diet", value: "renal_diet" },
  { label: "Hepatic Diet", value: "hepatic_diet" },
  { label: "Normal", value: "normal" },
  { label: "High Protein", value: "high_protein" },
  { label: "Others", value: "others" },
];
