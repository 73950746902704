import React from "react";
import { Table } from "antd";
import { formatToINR } from "../../../../helpers/utility";

const ServicesTableIp = ({ billData }) => {
  const columns = [
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value) => formatToINR(value),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Total",
      dataIndex: "total_price",
      key: "total_price",
      render: (value) => formatToINR(value),
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={billData?.data?.services_details}
        pagination={false}
      />
    </>
  );
};

export default ServicesTableIp;
