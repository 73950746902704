import { Layout, Image, Select, Space } from "antd";
import React, { useContext, useState } from "react";
import UserSettings from "../CustomLayout/UserSettings";
import { UserContext } from "../../../context/UserContext";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import DocSideMenu from "./DocSidemenu";
import { FacilityID } from "../../../constants/defaultKeys";
import "./DoctorLayout.css";
import Search from "../CustomLayout/Search";

const { Option } = Select;
const { Header, Footer, Content } = Layout;

const DoctorLayout = ({
  children,
  footer,
  sideContent,
  headerExtra = true,
  header,
}) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(
    Number(getCookie(FacilityID)) ||
      userDetails?.activeOrg?.facilities?.[0]?.facility_id
  );
  const role = userDetails?.activeOrg?.default_role
    ?.toLowerCase()
    .replaceAll("_", "-");
  const handleFacilityChange = (value) => {
    navigate(`/${role}/home`);
    setSelectedFacility(value);
    setCookie(
      FacilityID,
      value,
      `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
      "/"
    );
    setUserDetails((prev) => ({ ...prev, currFacility: value }));
  };
  return (
    <Layout className={"layoutContent"}>
      <Layout className={"contentPushWithoutSide"}>
        <Header className={`headerStyle ${"headerStylesWithoutSide"}`}>
          <div className="flex-between" style={{ height: "64px" }}>
            <div className="tabWebDisplay" style={{ width: "100%" }}>
              <Space>
                <MenuOutlined
                  style={{ fontSize: "18px" }}
                  onClick={() => setMenuOpen(true)}
                />
                {userDetails?.activeOrg?.org_logo ? (
                  <Image
                    preview={false}
                    src={userDetails?.activeOrg?.org_logo}
                    alt={"Logo"}
                    style={{
                      width: "30px",
                      marginBottom: "10px",
                    }}
                  />
                ) : (
                  userDetails?.activeOrg?.org_name
                )}
                {header}
              </Space>
            </div>
            {headerExtra && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Search selectedFacility={selectedFacility} />

                <Select
                  defaultValue={selectedFacility}
                  onChange={handleFacilityChange}
                  style={{ width: "250px" }}
                >
                  {userDetails?.activeOrg?.facilities?.map((facility) => (
                    <Option
                      value={facility?.facility_id}
                      key={facility?.facility_id}
                    >
                      {facility?.facility}
                    </Option>
                  ))}
                </Select>

                <UserSettings />
              </div>
            )}
          </div>
        </Header>

        <Content
          className={"contentStyle"}
          style={{
            paddingBottom: footer ? "132px" : "32px",
          }}
        >
          {children}
        </Content>
        {footer && (
          <Footer
            className={`footerStyle ${
              sideContent
                ? collapsed
                  ? "footerStyleWithCollapsedSide"
                  : "footerStyleWithNOCollapsedSide"
                : "footerStyleWithOutSide"
            }`}
          >
            {footer}
          </Footer>
        )}
      </Layout>
      {menuOpen && <DocSideMenu open={menuOpen} setOpen={setMenuOpen} />}
    </Layout>
  );
};

export default DoctorLayout;
