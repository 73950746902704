import { Fragment, forwardRef } from "react";
import { Button, Col, Descriptions, Form, Input, Radio, Row } from "antd";

import CareItemForm from "../CareItemForm";
import { yesNoOptions } from "../../../../../constants/defaultKeys";

const formItems = (
  <Fragment>
    <Form.Item label="Central Line Care" name="central_line">
      <Radio.Group options={yesNoOptions} />
    </Form.Item>
    <Form.Item label="Comments" name="preventive_care_comments">
      <Input.TextArea placeholder="comments" />
    </Form.Item>
  </Fragment>
);

const items = (item) => [
  {
    key: "central_line",
    label: "Central Line Care",
    children: item?.central_line === "yes" ? "Yes" : "No",
  },
  {
    key: "preventive_care_comments",
    label: "Comments",
    children: item?.preventive_care_comments || "-",
  },
];

function CentralLine(props, ref) {
  const {
    getData,
    formData,
    iaAction,
    formState,
    isIaCareItem,
    setFormState,
    updateIACareItemRecord,
  } = props;

  return (
    <Row gutter={[12, 12]}>
      {formData?.data?.map((item, index) => (
        <CareItemForm
          ref={ref}
          key={index}
          formData={item}
          getData={getData}
          iaAction={iaAction}
          formState={formState}
          setFormState={setFormState}
          isIaCareItem={isIaCareItem}
          formType="preventive_care_central_line_care"
          updateIACareItemRecord={updateIACareItemRecord}
          formItems={
            isIaCareItem ||
            item === undefined ||
            formState?.editingIndex === item?.id ? (
              formItems
            ) : (
              <Descriptions
                bordered
                column={1}
                items={items(item?.data)}
                className="descriptions-custom-column-width"
              />
            )
          }
        />
      ))}
      {(!formData || formData?.isallstopstate) && (
        <>
          {formData?.data !== undefined && !formState?.adding ? (
            <Col span={12} style={{ textAlign: "center" }}>
              <Button
                type="dashed"
                onClick={() =>
                  setFormState((prev) => ({
                    ...prev,
                    adding: "preventive_care_central_line_care",
                  }))
                }
              >
                Add Another
              </Button>
            </Col>
          ) : (
            (formState?.adding === "preventive_care_central_line_care" ||
              !formData) && (
              <CareItemForm
                getData={getData}
                formItems={formItems}
                formState={formState}
                setFormState={setFormState}
                formType="preventive_care_central_line_care"
              />
            )
          )}
        </>
      )}
    </Row>
  );
}

export default forwardRef(CentralLine);
