import React, { useContext, useEffect } from "react";
import { PhysioReviewContext } from "../../Physiotherapist/PhysiotherapistReview/PhysioReviewFormProvider";
import FunctionalDiagnosis from "./FunctionalDiagnosis";

const PhysioFunctionalDiagnosisReview = ({ form }) => {
  const { formData } = useContext(PhysioReviewContext);
  useEffect(() => {
    form.setFieldsValue(formData?.functional_diagnosis);
  }, [formData]);
  return (
    <>
      <FunctionalDiagnosis context={PhysioReviewContext} />
    </>
  );
};

export default PhysioFunctionalDiagnosisReview;
