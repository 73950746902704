import React, { useState, useEffect, useContext } from "react";
import { speechOptions } from "../../../../Options/speechSwallowOptions";
import { Checkbox, Form, Input, Radio } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";
import { SpeechInitialContext } from "../../../../SpeechAndSwallowTherapist/InitialInspection/SpeechSwallowInitialAssessmentProvider";

const PatientCondition = () => {
  const { formData } = useContext(SpeechInitialContext);
  const [showOptions, setShowOptions] = useState({});

  const handleCheckboxChange = (value) => {
    setShowOptions((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  useEffect(() => {
    const initialCheckboxes = speechOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] = formData?.general_examination?.[checkbox.value];

      return acc;
    }, {});
    setShowOptions(initialCheckboxes);
  }, [formData]);

  return (
    <>
      {speechOptions.map((option) => (
        <React.Fragment key={option.value}>
          <Form.Item name={option.value} valuePropName="checked">
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={showOptions[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {showOptions[option.value] && (
            <div className="subsection-card">
              <Form.Item name={`${option.value}_status`}>
                <Radio.Group options={yesNoOptions} />
              </Form.Item>
              <Form.Item name={`${option.value}_comments`}>
                <Input placeholder="Comments" />
              </Form.Item>
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default PatientCondition;
