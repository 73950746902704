import React, { useEffect } from "react";
import {
  Avatar,
  Card,
  List,
  Tag,
  Typography,
  Row,
  Col,
  Space,
  Select,
} from "antd";
import { timeAgo } from "../../../../helpers/utility";
import CustomAvatar from "../../../../blocks/Avatars/CustomAvatar";
import { useState } from "react";
import { getPatientMonitoringAPI } from "../../../../services/patient.services";
import useQuery from "../../../../hooks/useQuery";
import { FilterOutlined } from "@ant-design/icons";

const App = () => {
  const [data, setData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("all");
  const params = useQuery();
  const patient = params?.patient;
  async function getData() {
    const params = { role: selectedRole };
    if (selectedRole === "all") {
      delete params.role;
    }
    try {
      const res = await getPatientMonitoringAPI(patient, params);

      setData(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  const options = [
    { label: "All", value: "all" },
    { label: "Doctor", value: "Doctor" },
    { label: "Nurse", value: "Nurse" },
    { label: "Physiotherapist", value: "Physiotherapist" },
    { label: "Speech Swallow Therapist", value: "Speech_Swallow_Therapist" },
    { label: "Respiratory Therapist", value: "Respiratory_Therapist" },
    { label: "Nutritionist", value: "Nutritionist" },
    { label: "Receptionist", value: "Receptionist" },
  ];
  useEffect(() => {
    if (selectedRole) getData();
  }, [selectedRole]);
  return (
    <Card
      title={
        <Row>
          <Col span={12}>
            <Typography>Progress Notes</Typography>
          </Col>
          <Col span={12} className="flex-end">
            <Space>
              <FilterOutlined style={{ fontSize: "18px" }} />
              <Select
                options={options}
                onChange={setSelectedRole}
                defaultValue={selectedRole}
                style={{ minWidth: "230px" }}
              />
            </Space>
          </Col>
        </Row>
      }
      style={{ marginTop: "10px" }}
    >
      <List
        itemLayout="horizontal"
        size="small"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            key={item.title}
            extra={<Tag>{timeAgo(item?.created_at)}</Tag>}
          >
            <List.Item.Meta
              avatar={
                <CustomAvatar
                  name={`${item?.done_by_first_name} ${item?.done_by_last_name}`}
                  indexId={item?.id}
                  size="medium"
                />
              }
              description={<Typography>{item.notes}</Typography>}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
export default App;
