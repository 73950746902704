import { forwardRef } from "react";
import { Button, Col, Descriptions, Form, Radio, Row } from "antd";

import CareItemForm from "../CareItemForm";
import { yesNoOptions } from "../../../../../constants/defaultKeys";
import { tracheostomyOptions } from "../../../Options/careItemsOptions";

const formItems = (
  <>
    <Form.Item label="Tracheostomy" name="tracheostomy">
      <Radio.Group options={tracheostomyOptions} />
    </Form.Item>

    <Form.Item label="Trial of Decannulation" name="decannulation_trial">
      <Radio.Group options={yesNoOptions} />
    </Form.Item>
  </>
);

const tracheostomyItems = (item) => [
  {
    key: "tracheostomy",
    label: "Tracheostomy",
    children:
      tracheostomyOptions?.find((option) => option.value === item?.tracheostomy)
        ?.label || "-",
  },
  {
    key: "decannulation_trial",
    label: "Trial of Decannulation",
    children: item?.decannulation_trial === "yes" ? "Yes" : "No",
  },
];

function Tracheostomy(props, ref) {
  const {
    getData,
    iaAction,
    formData,
    formState,
    setFormState,
    isIaCareItem,
    updateIACareItemRecord,
  } = props;
  return (
    <Row gutter={[12, 12]}>
      {formData?.data?.map((item, index) => (
        <CareItemForm
          ref={ref}
          key={index}
          formData={item}
          getData={getData}
          iaAction={iaAction}
          formState={formState}
          isIaCareItem={isIaCareItem}
          setFormState={setFormState}
          formType="respiratory_care_tracheostomy_care"
          updateIACareItemRecord={updateIACareItemRecord}
          formItems={
            isIaCareItem ||
            item === undefined ||
            formState?.editingIndex === item?.id ? (
              formItems
            ) : (
              <Descriptions
                className="descriptions-custom-column-width"
                column={1}
                bordered
                items={tracheostomyItems(item?.data)}
              />
            )
          }
        />
      ))}
      {(!formData || formData?.isallstopstate) && (
        <>
          {formData !== undefined && !formState?.adding ? (
            <Col span="12" style={{ textAlign: "center" }}>
              <Button
                type="dashed"
                onClick={() =>
                  setFormState((prev) => ({
                    ...prev,
                    adding: "respiratory_care_tracheostomy_care",
                  }))
                }
              >
                Add Another
              </Button>
            </Col>
          ) : (
            (formState?.adding === "respiratory_care_tracheostomy_care" ||
              !formData) && (
              <CareItemForm
                getData={getData}
                formItems={formItems}
                formState={formState}
                setFormState={setFormState}
                formType="respiratory_care_tracheostomy_care"
              />
            )
          )}
        </>
      )}
    </Row>
  );
}

export default forwardRef(Tracheostomy);
