import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Tag,
  theme,
  Row,
  Col,
  Space,
  Typography,
  Image,
  List,
  Segmented,
  Select,
} from "antd";
import CustomAvatar from "../../blocks/Avatars/CustomAvatar";
import bedImg from "../../assets/images/patientIcons/bed.svg";
import { FilterOutlined } from "@ant-design/icons";
import {
  floorListApi,
  getNurseIpPatientApi,
} from "../../services/nurse.services";
import { getCookie } from "../../helpers/localStorage";
import { FacilityID } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserContext";
const { Option } = Select;
const { useToken } = theme;
const inPatientLabels = [
  { label: "Active", value: "current" },
  { label: "Discharged", value: "older" },
];
const defaultValue = [{ floor_no: "All", id: 0 }];
const NurseCards = () => {
  const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const { token } = useToken();
  const [activeSegment, setActiveSegment] = useState("current");
  const [ipData, setIpData] = useState([]);
  const [floor, setFloor] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState(0);

  useEffect(() => {
    const params =
      selectedFloor === 0
        ? `?facility=${
            Number(getCookie(FacilityID)) || userDetails?.currFacility
          }`
        : `?facility=${
            Number(getCookie(FacilityID)) || userDetails?.currFacility
          }&floor=${selectedFloor}`;
    getNurseIpPatientApi(params)
      .then((res) => setIpData(res?.data?.data))
      .catch((err) => console.log(err));
  }, [getCookie(FacilityID) || userDetails?.currFacility, selectedFloor]);

  useEffect(() => {
    floorListApi(
      `?facility=${Number(getCookie(FacilityID)) || userDetails?.currFacility}`
    )
      .then((res) => setFloor(res?.data))
      .catch((err) => console.log(err));
  }, [getCookie(FacilityID) || userDetails?.currFacility]);

  const onFloorChange = (value) => {
    setSelectedFloor(value);
  };
  return (
    <div className="nurse-cards-container">
      <Row gutter={[12, 12]}>
        <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
          <Space>
            <FilterOutlined />
            <Select
              style={{ width: "300px" }}
              defaultValue={"All"}
              onChange={(value) => onFloorChange(value)}
            >
              {[...defaultValue, ...floor]?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.floor_no} - {item?.building_name}
                </Option>
              ))}
            </Select>
            <Segmented options={inPatientLabels} defaultValue={activeSegment} />
          </Space>
        </Col>
        <Col span={24}>
          <List
            itemLayout="vertical"
            bordered
            dataSource={ipData}
            style={{ background: "#fff" }}
            renderItem={(item) => (
              <List.Item key={item?.admission_id}>
                <Row>
                  <Col xs={24} sm={24} md={8} lg={6}>
                    <Space size="small">
                      <CustomAvatar
                        name={`${item?.patient_name}`}
                        indexId={item?.admission_id}
                        size="medium"
                      />
                      <Typography.Text strong>
                        {item?.patient_name}
                      </Typography.Text>
                      <Tag color="orange">
                        <Image src={bedImg} preview={false} /> {item?.bed}
                      </Tag>
                    </Space>
                    <br />
                    <Tag>IP ID: {item?.ip_id}</Tag>
                    {(item?.age || item?.gender) && (
                      <Tag color="blue">
                        {item?.gender} {item?.age}
                      </Tag>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={18}>
                    <Row>
                      <Col xs={12} sm={12} md={8} lg={4}>
                        <Space direction="vertical">
                          <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() =>
                              navigate(
                                `/nurse/home?patient=${item?.admission_id}&type=ia`
                              )
                            }
                          >
                            Initial Assessment
                          </Typography.Link>

                          <Tag
                            color={
                              item?.nurse_initial_assessment ? "green" : "red"
                            }
                          >
                            {item?.nurse_initial_assessment
                              ? "Completed"
                              : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col xs={12} sm={12} md={8} lg={4}>
                        <Space direction="vertical">
                          <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() =>
                              navigate(
                                `/nurse/home?patient=${item?.admission_id}&type=diet`
                              )
                            }
                          >
                            Diet
                          </Typography.Link>
                          <Tag color={item?.diet?.completed ? "green" : "red"}>
                            {item?.diet?.completed ? "Completed" : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col xs={12} sm={12} md={8} lg={5}>
                        <Space direction="vertical">
                          <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() =>
                              navigate(
                                `/nurse/home?patient=${item?.admission_id}&type=medicine`
                              )
                            }
                          >
                            Medicine
                          </Typography.Link>
                          <Tag
                            color={
                              item?.medication?.completed ? "green" : "red"
                            }
                          >
                            {item?.medication?.completed
                              ? "Completed"
                              : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col xs={12} sm={12} md={8} lg={5}>
                        <Space direction="vertical">
                          <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() =>
                              navigate(
                                `/nurse/home?patient=${item?.admission_id}&type=monitoring`
                              )
                            }
                          >
                            Monitoring
                          </Typography.Link>
                          <Tag
                            color={
                              item?.monitoring?.up_to_date ? "green" : "red"
                            }
                          >
                            {item?.monitoring?.up_to_date
                              ? "Completed"
                              : "Pending"}
                          </Tag>
                        </Space>
                      </Col>
                      <Col xs={12} sm={12} md={8} lg={6}>
                        <Space direction="vertical">
                          <Typography.Link
                            style={{ color: token.colorPrimary }}
                            onClick={() =>
                              navigate(
                                `/nurse/home?patient=${item?.admission_id}&type=order-sheet`
                              )
                            }
                          >
                            Care Items
                          </Typography.Link>
                          <Space>
                            <Tag color="green">
                              Upcoming: {item?.care_items?.upcoming}
                            </Tag>
                            <Tag color="red">
                              Missed: {item?.care_items?.missed}
                            </Tag>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NurseCards;
