import React, { useContext, useEffect } from "react";
import { getPsychologistReviewAPI } from "../../../../services/psychologist.services";
import { Col, message, Row } from "antd";
import PsychologistTherapySummary from "../../InspectionSummaries/Therapy/PsychologistTherapySummary";
import PsychologyDiagnosisSummary from "../../InspectionSummaries/PsychologyDiagnosis/PsychologyDiagnosisSummary";
import { PsychologistReviewContext } from "./PsychologistReviewProvider";
import PsychologistScalesReviewSummary from "../../InspectionSummaries/Scales/PsychologistScalesReviewSummary";

const PsychologistReviewSummary = ({ editId, dailyRefresh }) => {
  const { formData, setFormData } = useContext(PsychologistReviewContext);
  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getPsychologistReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);
  return (
    <Row className="summaries" gutter={[16, 16]}>
      {formData?.scales && (
        <PsychologistScalesReviewSummary formData={formData} />
      )}
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.psychology_diagnosis && (
          <PsychologyDiagnosisSummary
            formData={formData}
            context={PsychologistReviewContext}
          />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {formData?.therapies_suggested && (
          <PsychologistTherapySummary
            formData={formData}
            context={PsychologistReviewContext}
          />
        )}
      </Col>
    </Row>
  );
};

export default PsychologistReviewSummary;
