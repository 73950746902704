import React, { createContext, useState } from "react";

export const DoctorInitialContext = createContext({});
const DoctorHistoryFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <DoctorInitialContext.Provider value={{ formData, setFormData }}>
      {children}
    </DoctorInitialContext.Provider>
  );
};

export default DoctorHistoryFormProvider;
