import React from "react";
import { Table, Tag } from "antd";
import tagStyles from "../../../styles/Custom/Tag.module.css";
import dayjs from "dayjs";
const RecordPayments = ({ paymentData }) => {
  console.log(paymentData);
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => dayjs(value).format("MMM,DD"),
      width: "20%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "20%",
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      width: "20%",
      render: (value) => (
        <Tag color="purple" className={tagStyles.mdRndTag}>
          {value}
        </Tag>
      ),
    },
  ];
  return (
    <>
      <Table dataSource={paymentData} columns={columns} pagination={false} />
    </>
  );
};

export default RecordPayments;
