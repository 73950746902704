import React, { useContext, useEffect, useState } from "react";
import {
  naturalSpeechOptions,
  noFunctionalSpeechOptions,
  noSpeechDisorderOptions,
  obviousSpeechDisorderOptions,
  reductionSpeechOptions,
  speechIntelligibilityOptions,
} from "../../../Options/speechSwallowOptions";
import { Checkbox, Form, Input, Typography } from "antd";
import { SpeechReviewContext } from "../../../SpeechAndSwallowTherapist/Review/SpeechSwallowReviewFormProvider";

const SpeechIntelligibility = () => {
  const { formData } = useContext(SpeechReviewContext);
  const [showSubOptions, setShowSubOptions] = useState({});

  const handleCheckboxChange = (value) => {
    setShowSubOptions((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const getOptions = (value) => {
    switch (value) {
      case "no_detectable_speech_disorder":
        return noSpeechDisorderOptions;
      case "obvious_speech_disorder":
        return obviousSpeechDisorderOptions;
      case "reduction_in_speech":
        return reductionSpeechOptions;
      case "natural_speech_augmentative_techniques":
        return naturalSpeechOptions;
      case "no_functional_speech":
        return noFunctionalSpeechOptions;
      default:
        return [];
    }
  };

  useEffect(() => {
    const initialCheckboxes = speechIntelligibilityOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          formData?.scales?.speech_intelligibility?.[checkbox.value];
        return acc;
      },
      {}
    );
    setShowSubOptions(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <Typography.Title level={5}>
        Speech Intelligibility Scale
      </Typography.Title>
      {speechIntelligibilityOptions.map((option) => (
        <React.Fragment key={option.value}>
          <Form.Item
            name={["speech_intelligibility", option.value]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={showSubOptions[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {showSubOptions[option.value] && (
            <div className="subsection-card">
              <Form.Item
                name={["speech_intelligibility", `${option.value}_details`]}
              >
                <Checkbox.Group options={getOptions(option.value)} />
              </Form.Item>
            </div>
          )}
        </React.Fragment>
      ))}
      <Form.Item name={["speech_intelligibility", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default SpeechIntelligibility;
