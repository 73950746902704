import { Form, Input, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { noYesOptions } from "../../Options/commonOptions";

const PreviousCounselling = ({ form, context }) => {
  const { formData } = useContext(context);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    form.setFieldsValue(formData?.previous_counselling);
    setShowDescription(
      formData?.previous_counselling?.previous_counselling === "yes"
    );
  }, [formData]);
  return (
    <>
      <Form.Item
        label="Previous counselling / Psychotherapy detail"
        name={"previous_counselling"}
      >
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => setShowDescription(e.target.value === "yes")}
        />
      </Form.Item>
      {showDescription && (
        <>
          <Form.Item name={"description"}>
            <Input placeholder="Please enter the details" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default PreviousCounselling;
