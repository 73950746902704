import { Form, Input, Radio } from "antd";
import React from "react";
import { mentalStatusOptions } from "../../Options/RespiratoryTherapistOptions";

const RespiratoryMentalStatus = () => {
  return (
    <>
      <Form.Item label="Mental Status" name={["mental_details", "status"]}>
        <Radio.Group options={mentalStatusOptions} />
      </Form.Item>
      <Form.Item name={["mental_details", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default RespiratoryMentalStatus;
