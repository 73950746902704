import { Form, Input, Radio } from "antd";
import React from "react";
import { chestExpansionOptions } from "../../Options/RespiratoryTherapistOptions";

const ChestExpansion = () => {
  return (
    <>
      <Form.Item label="Chest Expansion" name={["chest_expansion", "status"]}>
        <Radio.Group options={chestExpansionOptions} />
      </Form.Item>
      <Form.Item name={["chest_expansion", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default ChestExpansion;
