import React, { createContext, useState } from "react";

export const NutritionistInitialContext = createContext({});
const NutritionistInitialAssessmentProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <NutritionistInitialContext.Provider value={{ formData, setFormData }}>
      {children}
    </NutritionistInitialContext.Provider>
  );
};

export default NutritionistInitialAssessmentProvider;
