import React from "react";
import { Form, Radio } from "antd";
import { functionalOptions } from "../../../Options/historyOptions";

const FunctionalCapacity = () => {
  return (
    <Form.Item label="Previous Functional Capacity" name="functional_capacity">
      <Radio.Group options={functionalOptions} />
    </Form.Item>
  );
};

export default FunctionalCapacity;
