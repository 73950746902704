import React from "react";
import useQuery from "../../../hooks/useQuery";
import BedManagement from "./BedManagement/BedManagement";
import BillingIp from "./BillingIp/BillingIp";
import PackagesIp from "./PackagesIp/PackagesIp";
import ServicesTabs from "./ServicesIp/ServicesTabs";

const ReceptionistIp = ({ patientData, refreshPage }) => {
  const params = useQuery();

  return (
    <>
      {params?.type === "services" && (
        <ServicesTabs patientData={patientData} />
      )}
      {params?.type === "bed-management" && (
        <BedManagement patientData={patientData} refreshPage={refreshPage} />
      )}
      {params?.type === "packages" && <PackagesIp patientData={patientData} />}
      {params?.type === "billing" && <BillingIp patientData={patientData} />}
    </>
  );
};

export default ReceptionistIp;
