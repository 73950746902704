import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Divider, Image, Space, Typography, Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UploadsView from "./UploadsView";
import GridPdfViewer from "./GridPdfViewer";
import PdfViewer from "./PdfViewer";
import "slick-carousel/slick/slick-theme.css";
const { Meta } = Card;
const ImageMasonry = ({ data, onGroupSelect, setOpenPdf, setPdfData }) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    const groupedItems = {};
    if (data) {
      data.forEach((item) => {
        item?.report_path?.forEach((urlItem) => {
          const name = item?.name || item?.investigation_name;

          if (!groupedItems[name]) {
            groupedItems[name] = [];
          }

          if (urlItem?.type?.includes("image")) {
            groupedItems[name].push({
              type: "image",
              url: urlItem.report_url,
              path: urlItem?.path?.replace("curengo/", ""),
            });
          } else {
            groupedItems[name].push({
              type: "pdf",
              url: urlItem.report_url,
              path: urlItem?.path?.replace("curengo/", ""),
            });
          }
        });
      });
    }

    const groupedArray = Object.keys(groupedItems).map((name) => ({
      name,
      items: groupedItems[name],
    }));

    setItems(groupedArray);
  }, [data]);

  return (
    <div>
      {items?.map((group) => (
        <div key={group?.name}>
          <Typography.Link
            style={{
              cursor: "pointer",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            onClick={() => {
              onGroupSelect(group);
            }}
          >
            {group?.name}
          </Typography.Link>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 300: 3, 500: 4, 700: 3, 900: 5 }}
          >
            <Masonry columnsCount={6} gutter={"10px"}>
              {group?.items?.map((item, index) => (
                <div
                  key={index}
                  className="masonry-item"
                  onClick={() => {
                    if (item.type === "pdf") {
                      setOpenPdf(true);
                      setPdfData(item);
                    }
                  }}
                >
                  {item.type === "image" ? (
                    <Card
                      hoverable
                      style={{ width: 300 }}
                      cover={<Image alt="example" src={item?.url} />}
                    >
                      <Meta title={<Typography>{item?.path}</Typography>} />
                    </Card>
                  ) : (
                    <div className="pdfContainer">
                      <GridPdfViewer
                        data={[item]}
                        pageNumber={1}
                        height="240px"
                        width="20vw"
                      />
                    </div>
                  )}
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
          <Divider />
        </div>
      ))}
    </div>
  );
};

const MasonryImg = ({ data }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfData, setPdfData] = useState([]);

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
  };

  return (
    <div>
      {selectedGroup ? (
        <>
          <Space>
            <ArrowLeftOutlined onClick={() => setSelectedGroup(null)} />
            <Typography>Back</Typography>
          </Space>

          <UploadsView selectedGroup={selectedGroup} />
        </>
      ) : openPdf ? (
        <>
          <Space>
            <ArrowLeftOutlined onClick={() => setOpenPdf(false)} />
            <Typography>Back</Typography>
          </Space>
          <PdfViewer pdfData={[pdfData]} />
        </>
      ) : (
        <ImageMasonry
          data={data}
          onGroupSelect={handleGroupSelect}
          setOpenPdf={setOpenPdf}
          setPdfData={setPdfData}
        />
      )}
    </div>
  );
};

export default MasonryImg;
