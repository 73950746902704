import React, { useState, useEffect } from "react";
import { Button, Row, Col, Segmented, Space } from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import DailyReviewForm from "./DailyReviewForm";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import DailyReviewSummary from "./DailyReviewSummary";

const DailyReview = ({ refreshPage, reviewData, iAssessment, patientData }) => {
  const [visible, setVisible] = useState(false);
  const [activeSegment, setActiveSegment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  // const [newReview, setNewReview] = useState(false);
  const [dailyRefresh, setDailyRefresh] = useState(false);
  const setDailyReviewRefresh = () => {
    setDailyRefresh((prev) => !prev);
  };

  useEffect(() => {
    if (reviewData?.length > 0) {
      setActiveSegment(reviewData[0]?.id);
    }
  }, [reviewData]);

  const segmentData = reviewData?.map((item) => ({
    label: `${dayjs(item?.created_at).format("MMM DD")}, ${dayjs(
      item?.created_at
    ).format("h:mm A")}`,
    value: item?.id,
  }));

  console.log("segment Data", segmentData);

  const handleAddReview = () => {
    setEditMode(false);
    setActiveSegment(null);
    setVisible(true);
    setAddMode(true);
    // setNewReview(reviewData.length === 0);
  };

  const handleEditReview = () => {
    setEditMode(true);
    setVisible(true);
  };

  return (
    <>
      {reviewData?.length > 0 ? (
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right" }}></Col>
          <Space>
            <Button
              type="primary"
              onClick={handleAddReview}
              className={btnStyles.lgBtn}
            >
              <PlusOutlined /> Review
            </Button>
            <Segmented
              className="segment-wrap"
              value={activeSegment}
              options={segmentData}
              onChange={setActiveSegment}
              size="small"
            />
          </Space>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={handleEditReview}
              className={btnStyles.lgBtn}
              disabled={!activeSegment}
            >
              Edit
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <DailyReviewSummary
              editId={activeSegment}
              dailyRefresh={setDailyReviewRefresh}
            />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={handleAddReview}
              className={btnStyles.lgBtn}
            >
              Review Form
            </Button>
          </Col>
        </Row>
      )}
      {visible && (
        // <DailyReviewFormProvider>
        <DailyReviewForm
          visible={visible}
          setVisible={setVisible}
          refreshPage={refreshPage}
          editId={editMode ? activeSegment : null}
          // newReview={reviewData.length === 0}
          iAssessment={iAssessment}
          // reviewId={reviewId}
          addMode={addMode}
          setAddMode={setAddMode}
          setActiveSegment={setActiveSegment}
          reviewData={reviewData}
          setDailyReviewRefresh={setDailyReviewRefresh}
          dailyRefresh={dailyRefresh}
          segmentData={segmentData}
          patientData={patientData}
        />
        // </DailyReviewFormProvider>
      )}
    </>
  );
};

export default DailyReview;
