import React, { useState } from "react";
import { Form, Upload, message, Button } from "antd";
import { getUploadUrlApi } from "../../../../services/common.services";
import { convertToFormData } from "../../../../helpers/utility";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

const { Dragger } = Upload;
const ScanImage = ({ editData, setUploadFile, uploadFile }) => {
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const uploadProps = {
    beforeUpload: (file, fileList) => {
      let file_ext = file.name.split(".").slice(-1)[0];
      const fileType =
        file_ext === "png" ||
        file_ext === "jpg" ||
        file_ext === "jpeg" ||
        file_ext === "svg";

      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await getUploadUrlApi(`path_key=${info.file.name}`);
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setUploadFile((prevState) => [
            ...prevState,
            { path: res?.data?.fields?.key, type: info.file.type },
          ]);
        } catch (err) {
          message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleRemoveFile = (fileToRemove) => {
    const filteredFiles = uploadFile.filter(
      (file) => file.path !== fileToRemove.path
    );
    setUploadFile(filteredFiles);
  };
  return (
    <div>
      <Dragger
        style={{ width: "300px" }}
        accept=".png,.jpg,.jpeg,.svg"
        {...uploadProps}
        multiple
        maxCount={5}
        showUploadList={false}
        customRequest={dummyRequest}
      >
        <p className="ant-upload-drag-icon">
          <CameraOutlined />
        </p>

        <p className="ant-upload-text">
          <span style={{ color: "#406AFF" }}>Click to Capture </span> or upload
          images
        </p>
        <p className="ant-upload-hint">SVG,JPEG,JPG,PNG</p>
      </Dragger>
      {uploadFile.map((item, index) => (
        <div key={index}>
          {item.path.replace("curengo/", "")}
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveFile(item)}
            style={{ marginLeft: "8px" }}
          />
        </div>
      ))}
    </div>
  );
};

export default ScanImage;
