import { useEffect, useState } from "react";
import { Col, Divider, Row, Skeleton } from "antd";

import useQuery from "../../../hooks/useQuery";
import GRBS from "../../IpEMR/Doctor/OrderSheet/GRBS";
import Vitals from "../../IpEMR/Doctor/OrderSheet/Vitals";
import { getOrderSheetApi } from "../../../services/orderSheet.services";

function Monitoring() {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});

  async function getData() {
    try {
      const response = await getOrderSheetApi(query?.patient);

      setFormData(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (query?.patient) getData();
  }, [query?.patient]);

  return loading ? (
    <Skeleton active />
  ) : (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Divider orientation="left">Vitals</Divider>
        <Vitals formData={formData?.vitals} getData={getData} />
        <Divider orientation="left">GRBS Monitoring</Divider>
        <GRBS formData={formData?.grbs_monitoring} getData={getData} />
      </Col>
    </Row>
  );
}

export default Monitoring;
