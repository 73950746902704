import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { Typography } from "antd";
import { heartSoundsOptions } from "../../../../Options/systemicExamOptions";
const { Text } = Typography;

const HeartSounds = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = heartSoundsOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Text>Heart Sounds</Text>
      <CheckListFBYInput
        options={heartSoundsOptions}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    </>
  );
};

export default HeartSounds;
