import { Checkbox, Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  gLSymptomsTimelineOptions,
  gLSymptomsTypeOptions,
} from "../../../Options/historyOptions";

const GLSymptoms = ({ context }) => {
  const { formData } = useContext(context);
  const [showSymptoms, setShowSymptoms] = useState(
    formData?.history?.gl_symptoms_details?.symptoms_timeline ===
      "greater_than_2_weeks"
  );
  useEffect(() => {
    setShowSymptoms(
      formData?.history?.gl_symptoms_details?.symptoms_timeline ===
        "greater_than_2_weeks"
    );
  }, [formData]);
  return (
    <>
      <Form.Item
        label="GL Symptoms"
        name={["gl_symptoms_details", "symptoms_timeline"]}
      >
        <Radio.Group
          options={gLSymptomsTimelineOptions}
          onChange={(e) => {
            setShowSymptoms(e.target.value === "greater_than_2_weeks");
          }}
        />
      </Form.Item>

      {showSymptoms && (
        <div className="subsection-card">
          <Form.Item name={["gl_symptoms_details", "symptoms"]}>
            <Checkbox.Group options={gLSymptomsTypeOptions} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default GLSymptoms;
