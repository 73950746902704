import React, { useEffect, useState } from "react";
import {
  Table,
  Select,
  Button,
  Popconfirm,
  Tag,
  Modal,
  Form,
  Input,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  pressureSoreAreaOptions,
  pressureSoreGradeOptions,
} from "../../../../Options/systemicExamOptions";

const PressureSore = ({ pressureSore, setPressureSore }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleAddPressureSore = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const newData = [
          ...pressureSore,
          { ...values, key: pressureSore.length },
        ];
        setPressureSore(newData);
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const confirmAction = (recordKey) => {
    const newData = pressureSore.map((item) =>
      item.key === recordKey ? { ...item, action: true } : item
    );
    setPressureSore(newData);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...pressureSore,
    });
  }, [pressureSore, form]);

  const columns = [
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      render: (text, record) =>
        pressureSoreAreaOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      render: (text, record) =>
        pressureSoreGradeOptions.find((option) => option.value === text)?.label,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        record.action ? (
          <Tag color="red">×</Tag>
        ) : (
          <Popconfirm
            title="Are you sure to stop?"
            onConfirm={() => confirmAction(record.key)}
            okText="Agree"
            cancelText="Disagree"
          >
            <Button>Stop</Button>
          </Popconfirm>
        ),
    },
  ];

  return (
    <div>
      <Form.Item label="Pressure Sore">
        <Button
          onClick={handleAddPressureSore}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          <PlusOutlined /> Add Row
        </Button>
        <Table columns={columns} dataSource={pressureSore} pagination={false} />
        <Modal
          title="Add Pressure Sore"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Add Row"
          cancelText="Close"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="area"
              label="Area"
              rules={[{ required: true, message: "Please select an area!" }]}
            >
              <Select
                placeholder="Select Area"
                options={pressureSoreAreaOptions}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="grade"
              label="Grade"
              rules={[{ required: true, message: "Please select a grade!" }]}
            >
              <Select
                placeholder="Select Grade"
                options={pressureSoreGradeOptions}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Form.Item>
    </div>
  );
};

export default PressureSore;
