import React, { useContext, useEffect } from "react";
import { Card, Divider } from "antd";
import Hydration from "./GeneralExaminationComponents/Hydration";
import LevelOfConsciousness from "./GeneralExaminationComponents/LevelOfConsciousness";
import Pupils from "./GeneralExaminationComponents/Pupils";
import Pallor from "./GeneralExaminationComponents/Pallor";
import Icterus from "./GeneralExaminationComponents/Icterus";
import Cyanosis from "./GeneralExaminationComponents/Cyanosis";
import Edema from "./GeneralExaminationComponents/Edema";
import PainScale from "./GeneralExaminationComponents/PainScale";
import VitalsAdmission from "./GeneralExaminationComponents/VitalsAdmission";
import Gcs from "./GeneralExaminationComponents/Gcs";
import { DailyReviewContext } from "../../../Doctor/DailyReview/DailyReviewFormProvider";

const DrGeneralExaminationFormDR = ({ form = {} }) => {
  const { formData } = useContext(DailyReviewContext);
  useEffect(() => {
    form.setFieldsValue(formData?.general_examination);
  }, [formData]);
  return (
    <Card>
      {/* <Form
        className="ip-forms"
        id="general-examination-form"
        layout="vertical"
      > */}
      <LevelOfConsciousness context={DailyReviewContext} />
      <Divider />

      <Gcs />
      <Divider />

      <Pupils context={DailyReviewContext} />
      <Divider />

      <Hydration />
      <Divider />
      <Pallor />
      <Divider />

      <Icterus />
      <Divider />

      <Cyanosis />
      <Divider />

      <Edema context={DailyReviewContext} />
      <Divider />

      <VitalsAdmission />
      <Divider />

      <PainScale context={DailyReviewContext} />
      {/* </Form> */}
    </Card>
  );
};

export default DrGeneralExaminationFormDR;
