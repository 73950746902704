import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox, Input } from "antd";
import { respiratoryOptions } from "../../Options/clinicalImpressionOptions";

const Respiratory = ({ context }) => {
  const { formData } = useContext(context);
  const [showRespiratoryOthers, setShowRespiratoryOthers] = useState(
    formData?.clinical_diagnosis?.respiratory_diagnosis?.includes("others")
  );

  const handleRespiratoryChange = (selectedOptions) => {
    setShowRespiratoryOthers(selectedOptions?.includes("others"));
  };

  useEffect(() => {
    setShowRespiratoryOthers(
      formData?.clinical_diagnosis?.respiratory_diagnosis?.includes("others")
    );
  }, [formData]);

  return (
    <>
      <Form.Item name="respiratory_diagnosis" label="Respiratory Diagnosis">
        <Checkbox.Group
          options={respiratoryOptions}
          onChange={handleRespiratoryChange}
        />
      </Form.Item>
      {showRespiratoryOthers && (
        <Form.Item
          name="respiratory_others"
          rules={[{ required: true, message: "Please enter the details" }]}
        >
          <Input type="text" placeholder="Please specify" />
        </Form.Item>
      )}
    </>
  );
};

export default Respiratory;
