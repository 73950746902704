import { CloseOutlined } from "@ant-design/icons";
import { Col, Drawer, Row, Table, Typography, Image, Space, Tag } from "antd";
import React from "react";
import { frequencyDisplay, medicineIcons } from "../../../helpers/medicineKeys";

const MedicationHistoryChart = ({ open, setOpen, data }) => {
  const filteredData = data?.filter((item) => item?.status !== "Stopped");
  const columns = [
    {
      title: "Medication/Dose/Route",
      render(value, record) {
        return {
          //   props: {
          //     style: { background: value ? "#ECFDF3" : "#FEF3F2" },
          //   },
          children: (
            <Space>
              <Image
                src={medicineIcons[record?.form]}
                preview={false}
                width="20px"
              />
              <Typography>{record?.name || record?.medicine_name}</Typography>
              <Typography>
                {record?.dose}
                {record?.unit}
              </Typography>
              <Tag>{record?.route}</Tag>
            </Space>
          ),
        };
      },
      //   render: (record) => (
      //     <Space>
      //       <Image
      //         src={medicineIcons[record?.form]}
      //         preview={false}
      //         width="20px"
      //       />
      //       <Typography>{record?.name || record?.medicine_name}</Typography>
      //       <Typography>
      //         {record?.dose}
      //         {record?.unit}
      //       </Typography>
      //       <Tag>{record?.route}</Tag>
      //     </Space>
      //   ),
      width: 400,
    },
    {
      title: "Jun 28",
      dataIndex: "frequency",
      key: "frequency",
      render: (value) => <Typography>{frequencyDisplay?.[value]}</Typography>,
    },
    {
      title: "Jun 29",
      dataIndex: "frequency",
      key: "frequency",
      render: (value) => <Typography>{frequencyDisplay?.[value]}</Typography>,
    },
    {
      title: "Jun30",
      dataIndex: "frequency",
      key: "frequency",
      render: (value) => <Typography>{frequencyDisplay?.[value]}</Typography>,
    },
    {
      title: "July 1",
      dataIndex: "frequency",
      key: "frequency",
      render: (value) => <Typography>{frequencyDisplay?.[value]}</Typography>,
    },
    {
      title: "July 2",
      dataIndex: "frequency",
      key: "frequency",
      render: (value) => <Typography>{frequencyDisplay?.[value]}</Typography>,
    },
  ];
  return (
    <Drawer
      open={open}
      width={"100%"}
      onClose={() => setOpen(false)}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              Medication History
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined
              onClick={() => {
                setOpen(false);
              }}
            />
          </Col>
        </Row>
      }
      closeIcon={null}
    >
      <Table
        dataSource={filteredData}
        pagination={false}
        columns={columns}
        bordered
      />
    </Drawer>
  );
};

export default MedicationHistoryChart;
