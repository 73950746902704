import React from "react";
import CustomLayout from "../Layout/CustomLayout";
import { Result, Typography } from "antd";
import typoStyles from "../../styles/Custom/Typography.module.css";
import { SmileOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import { capitalizeFirstLetter } from "../../helpers/utility";
import AccountDetails from "./Details";
import Password from "./Password";
import Experience from "./Experience";
import SideMenu from "../Layout/CustomLayout/SideMenu";

const AccountDashboard = () => {
  const query = useParams();
  return (
    <>
      {query?.tab === "details" ? (
        <AccountDetails sideContent={<SideMenu />} />
      ) : query?.tab === "password" ? (
        <Password sideContent={<SideMenu />} />
      ) : (
        <Experience sideContent={<SideMenu />} />
      )}
    </>
  );
};

export default AccountDashboard;
