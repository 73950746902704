export const nutritionalRatingOptions = [
  { label: "Well Nourished", value: "well_nourished" },
  { label: "Moderately Malnourished", value: "moderately_malnourished" },
  { label: "Severely Malnourished", value: "severely_malnourished" },
];

export const nutritionalRiskType = [
  { label: "Minimal", value: "minimal" },
  { label: "Moderate", value: "moderate" },
  { label: "High", value: "high" },
];

export const nutritionRiskFactorsOptions = [
  { label: "Inadequate PO Intake", value: "inadequate_po_intake" },
  { label: "Chewing/ Swallowing Problem", value: "chewing_swallowing_problem" },
  { label: "Mouth Ulcers", value: "mouth_ulcers" },
  { label: "Nausea/ Vomiting", value: "nausea_vomiting" },
  { label: "Disease of GL Tract", value: "disease_of_gl_tract" },
  { label: "Significant Weight Loss", value: "significant_weight_loss" },
  { label: "Poor Skin Integrity", value: "poor_skin_integrity" },
  { label: "Abnormal Lab Values", value: "abnormal_lab_value" },
  { label: "Surgical/ Geriatric Patient", value: "surgical_geriatric_patient" },
  {
    label: "Increased Nutritional Needs",
    value: "increased_nutritional_needs",
  },
  {
    label: "Knowledge/ Compliance Deficit",
    value: "knowledge_compliance_deficit",
  },
  { label: "Others", value: "others" },
];
