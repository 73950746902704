import React, { useState } from "react";
import { Space, Table, Tag, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import BedForm from "./BedForm";
import { formatToINR } from "../../../helpers/utility";

function BedTable({ data, refreshBedPage, facilityId }) {
  const [isVisible, setIsVisible] = useState(false);
  const [editListData, setEditListData] = useState(null);
  const columns = [
    {
      title: "Bed No",
      dataIndex: "bed_no",
      key: "bed_no",
      sorter: (a, b) => a?.bed_no?.localeCompare(b?.bed_no),
      render: (value, record) =>
        record?.is_available ? (
          <Space>
            <Typography>{value}</Typography>
            <Tag color="green">Active</Tag>
          </Space>
        ) : (
          <Space>
            <Typography>{value}</Typography>
            <Tag color="orange">In Active</Tag>
          </Space>
        ),
    },
    {
      title: "Insurance Price (Including tax)",
      dataIndex: "insurance_price",
      key: "insurance_price",
      sorter: (a, b) => a?.insurance_price - b?.insurance_price,
      render: (value) => (value ? formatToINR(value) : "-"),
    },
    {
      title: "Cash Price (Including tax)",
      dataIndex: "cash_price",
      key: "cash_price",
      sorter: (a, b) => a?.cash_price - b?.cash_price,
      render: (value) => (value ? formatToINR(value) : "-"),
    },
    {
      title: "Room No",
      dataIndex: "room_no",
      key: "room_no",
      sorter: (a, b) => a?.room_no?.localeCompare(b?.room_no),
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
      sorter: (a, b) => a?.room_type?.localeCompare(b?.room_type),
    },
    {
      title: "Floor No",
      dataIndex: "floor_no",
      key: "floor_no",
      sorter: (a, b) => a?.floor_no?.localeCompare(b?.floor_no),
    },
    {
      title: "Building Name",
      dataIndex: "building_name",
      key: "building_name",
      sorter: (a, b) => a?.building_name?.localeCompare(b?.building_name),
    },
    {
      title: "Action",
      render: (record) => (
        <EditOutlined
          onClick={() => {
            setIsVisible(true);
            setEditListData(record);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        scroll={{ y: "calc(100vh - 570px)" }}
      />
      {isVisible && (
        <BedForm
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          refreshBedPage={refreshBedPage}
          editListData={editListData}
          facilityId={facilityId}
        />
      )}
    </>
  );
}

export default BedTable;
