import React from "react";
import {
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
} from "../constructTextFromValues";
import {
  barthelIndexOptions,
  genderOptions,
  minsWalkedOptions,
  moreSubjectiveOptions,
  reasonOptions,
  rpeOptions,
  subjectiveOptions,
} from "../../Options/functionalAsessmentOptions";
import {
  normalAbnormalOptions,
  notAssessableAndAssessableOptions,
} from "../../Options/commonOptions";
import { Descriptions, Tag } from "antd";

const PhysioFunctionalStatusSummary = ({ formData }) => {
  const constructFunctionalStatusSummaryItems = () => {
    const functionalStatusSummaryItems = [];
    const status = formData?.functional_status || {};
    const pushFunctionalItem = (key, label, children) => {
      functionalStatusSummaryItems.push({ key, label, children });
    };

    if (status?.subjective_assessment?.vision_status) {
      pushFunctionalItem(
        "1",
        "Vision",
        <>
          {getLabelTag(
            subjectiveOptions,
            status?.subjective_assessment?.vision_status
          )}
          {getLabelTag(
            moreSubjectiveOptions,
            status?.subjective_assessment?.vision_affected_status
          )}
        </>
      );
    }
    if (status?.subjective_assessment?.hearing_status) {
      pushFunctionalItem(
        "2",
        "Hearing",
        <>
          {getLabelTag(
            subjectiveOptions,
            status?.subjective_assessment?.hearing_status
          )}
          {getLabelTag(
            moreSubjectiveOptions,
            status?.subjective_assessment?.hearing_affected_status
          )}
        </>
      );
    }
    if (status?.daily_activities) {
      pushFunctionalItem(
        "3",
        "Daily Activities",
        <>
          {getLabelTag(
            notAssessableAndAssessableOptions,
            status?.daily_activities
          )}
          {getLabelScalesTags(barthelIndexOptions, status?.barthel_index)}
        </>
      );
    }
    if (status?.endurance) {
      pushFunctionalItem(
        "4",
        "Endurance",
        <>
          {getLabelTag(notAssessableAndAssessableOptions, status?.endurance)}
          {getLabelTagInput("Rating of perceived exertion", status?.exertion)}
          {getLabelTagInput("Age", status?.six_min_walk?.age)}
          {getLabelTag(genderOptions, status?.six_min_walk?.gender)}
          {getLabelTagInput("Height", status?.six_min_walk?.height)}
          {getLabelTagInput("Weight", status?.six_min_walk?.weight)}
          {getLabelTagInput(
            "Predicted Distance",
            status?.six_min_walk?.predicted_distance,
            "mtrs"
          )}
          <Tag
            bordered={false}
            color="purple"
            className="break-line-tag"
            key={"pre-vitals"}
          >
            Pre Vitals:{" "}
            {getLabelTagInput(
              "Heart Rate",
              status?.six_min_walk?.heart_rate,
              "beats/min"
            )}
            {getLabelTagInput("SBP", status?.six_min_walk?.sbp, "mmHg")}
            {getLabelTagInput("DBP", status?.six_min_walk?.dbp, "mmHg")}
            {getLabelTagInput("SP02", status?.six_min_walk?.spo2, "%")}
            {getLabelTagInput("RR", status?.six_min_walk?.rr, "breaths/min")}
            {getLabelTag(rpeOptions, status?.six_min_walk?.rpe)}
          </Tag>
          <Tag
            bordered={false}
            color="purple"
            className="break-line-tag"
            key={"pre-vitals"}
          >
            Post Vitals:{" "}
            {getLabelTagInput(
              "Heart Rate",
              status?.six_min_walk?.post_vital_heart_rate,
              "beats/min"
            )}
            {getLabelTagInput(
              "SBP",
              status?.six_min_walk?.post_vital_sbp,
              "mmHg"
            )}
            {getLabelTagInput(
              "DBP",
              status?.six_min_walk?.post_vital_dbp,
              "mmHg"
            )}
            {getLabelTagInput(
              "SP02",
              status?.six_min_walk?.post_vital_spo2,
              "%"
            )}
            {getLabelTagInput(
              "RR",
              status?.six_min_walk?.post_vital_rr,
              "breaths/min"
            )}
            {getLabelTag(rpeOptions, status?.six_min_walk?.post_vital_rpe)}
          </Tag>
          {getLabelTagInput("No of Laps", status?.six_min_walk?.no_of_laps)}
          {getLabelTagInput(
            "Distance covered",
            status?.six_min_walk?.distance_covered,
            "mtrs"
          )}
          {getLabelTagInput(
            "Final Lap",
            status?.six_min_walk?.final_lap,
            "mtrs"
          )}
          {getLabelTagInput(
            "Mins Walked",
            getLabelTag(minsWalkedOptions, status?.six_min_walk?.mins_walked)
          )}
          {getLabelTagInput(
            "Reason for Stopping 6MWT",
            getLabelTag(
              reasonOptions,
              status?.six_min_walk?.reason_for_immediate_stopping
            )
          )}
        </>
      );
    }
    if (status?.speech_and_swallow_assessment) {
      pushFunctionalItem(
        "5",
        "Speech and Swallow Assessment",
        getLabelTag(
          normalAbnormalOptions,
          status?.speech_and_swallow_assessment
        )
      );
    }
    return functionalStatusSummaryItems;
  };
  const functionalStatusSummaryItems = constructFunctionalStatusSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title="Functional Status Assessment"
        items={functionalStatusSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </div>
  );
};

export default PhysioFunctionalStatusSummary;
