import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const Scar = () => {
  const { formData } = useContext(DoctorInitialContext);
  const [showScarDetails, setShowScarDetails] = useState(
    formData?.systemic_examination?.scar === "yes" ? true : false
  );

  const handleScarChange = (e) => {
    if (e.target.value === "yes") {
      setShowScarDetails(true);
    } else {
      setShowScarDetails(false);
    }
  };

  useEffect(() => {
    setShowScarDetails(
      formData?.systemic_examination?.scar === "yes" ? true : false
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Scar" name="scar">
        <Radio.Group options={yesNoOptions} onChange={handleScarChange} />
      </Form.Item>
      {showScarDetails && (
        <Form.Item name="scar_description">
          <Input type="text" placeholder="Description" />
        </Form.Item>
      )}
    </>
  );
};

export default Scar;
