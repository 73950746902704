export const builtOptions = [
  { label: "Lean", value: "lean" },
  { label: "Cachectic", value: "cachectic" },
  { label: "Well Built", value: "well_built" },
  { label: "Muscular", value: "muscular" },
  { label: "Obese", value: "obese" },
  { label: "Morbidly Obese", value: "morbidly_obese" },
];

export const cyanosisOptions = [
  { label: "None", value: "none" },
  { label: "Peripheral Cyanosis", value: "peripheral_cyanosis" },
  { label: "Central Cyanosis", value: "central_cyanosis" },
];

export const edemaOptions = [
  { label: "None", value: "none" },
  { label: "Generalized", value: "generalized" },
  { label: "Sacral", value: "sacral" },
  { label: "Pedal Unilateral", value: "pedal_unilateral" },
  { label: "Pedal Bilateral", value: "pedal_bilateral" },
  { label: "Others", value: "edema_others" },
];
export const feedingTubeOptions = [
  { label: "No", value: "no" },
  { label: "Yes", value: "yes" },
];
export const feedingTubeTypeOptions = [
  { label: "NG Tube", value: "ng_tube" },
  { label: "NJ Tube", value: "nj_tube" },
  { label: "PEG", value: "peg" },
  { label: "OG Tube", value: "og_tube" },
  { label: "Gastronomy Tube", value: "gastronomy_tube" },
  { label: "Jejunostomy Tube", value: "jejunostomy_tube" },
];
export const tubePositionOptions = [
  { label: "Intact", value: "intact" },
  { label: "Displaced", value: "displaced" },
];
export const tubeAspirationOptions = [
  { label: "None", value: "none" },
  { label: "Less than 20ml", value: "less_than_20_ml" },
  { label: "More than 20ml", value: "more_than_20_ml" },
];

export const hydrationOptions = [
  { label: "Good", value: "good" },
  { label: "Fair", value: "fair" },
  { label: "Poor", value: "poor" },
];

export const nmmsOptions = [
  { label: "None", value: "none" },
  { label: "Mild", value: "mild" },
  { label: "Moderate", value: "moderate" },
  { label: "Severe", value: "severe" },
];

export const peripheralCannulaOptions = [
  { label: "No", value: "no" },
  { label: "Yes 1", value: "yes_1" },
  { label: "Yes 2", value: "yes_2" },
];

export const cannulaOptions = [
  { label: "Patent", value: "patent" },
  { label: "Pain", value: "pain" },
  { label: "Swelling", value: "swelling" },
  { label: "Redness", value: "redness" },
];

export const centralLineOptions = [
  { label: "No", value: "no" },
  { label: "Yes", value: "yes" },
];

export const consiousnessOptions = [
  { label: "A- Alert/ Awake", value: "a" },
  { label: "V- Responding to verbal stimulation", value: "v" },
  { label: "P- Responding to painful stimulation", value: "p" },
  { label: "U- Unresponsive", value: "u" },
];

export const moreConsiousnessOptions = [
  { label: "Flex Response", value: "flex_response" },
  { label: "Extensor Response", value: "extensor_response" },
  { label: "Decebrate Response", value: "decebrate_response" },
];
export const ostomiesOptions = [
  { label: "Colostomy", value: "colostomy" },
  { label: "Ileostomy", value: "ileostomy" },
  { label: "Gastrostomy", value: "gastrostomy" },
  { label: "Jejunostomy", value: "jejunostomy" },
];

export const moreOstomiesOptions = [
  {
    name_key: "options",
    label: "",
    options: [
      { label: "Healthy", value: "healthy" },
      { label: "Inflamed", value: "inflamed" },
      { label: "Infected", value: "infected" },
      { label: "Ulcerated", value: "ulcerated" },
    ],
  },
];
export const painScale = {
  0: "No pain",
  1: "",
  2: "Mild",
  3: "",
  4: "Moderate",
  5: "",
  6: "Severe",
  7: "",
  8: "Very Severe",
  9: "",
  10: "Worst Pain Possible",
};

export const pupilOptions = [
  { label: "Bilateral NSRL", value: "bilateral_nsrl" },
  { label: "Abnormal", value: "abnormal" },
];

export const pupilSideOptions = [
  { label: "Left Pupil", value: "left_pupil" },
  { label: "Right Pupil", value: "right_pupil" },
];

export const pupilRadioOptions = [
  {
    label: "Size",
    name_key: "size",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Constricted", value: "constricted" },
      { label: "Dilated", value: "dilated" },
    ],
  },
  {
    label: "Reaction",
    name_key: "reaction",
    options: [
      { label: "Reacting to Light", value: "reacting_to_light" },
      { label: "Not Reacting to Light", value: "not_reacting_to_light" },
    ],
  },
];
export const respiratoryDistressOptions = [
  { label: "Mild", value: "mild" },
  { label: "Moderate", value: "moderate" },
  { label: "Severe", value: "severe" },
  { label: "No", value: "no" },
];

export const respiratorySupportOptions = {
  respiratorySupport: [
    { label: "Oxygen-Nasal Prongs", value: "oxygen_nasal_prongs" },
    { label: "Thermo Ventilator", value: "thermo_ventilator" },
    { label: "Oxygen-Hudson Mask", value: "oxygen_hudson_mask" },
    { label: "Oxygen-Hi Flow Mask", value: "oxygen_hi_flow_mask" },
    { label: "Oxygen-Rebreathing Mask", value: "oxygen_rebreathing_mask" },
    { label: "BiPAP Support", value: "bipap_support" },
    { label: "CPAP Support", value: "cpap_support" },
    { label: "Mechanical Ventilation", value: "mechanical_ventilation" },
  ],
  mechanicalVentilation: [
    { label: "VCV", value: "vcv" },
    { label: "PCV", value: "pcv" },
    { label: "SIMV-VCV", value: "simv_vcv" },
    { label: "SIMV-PCV", value: "simv_pcv" },
  ],
};

export const mechanicalOptions = [
  { label: "TED Stockings", value: "ted_stockings" },
  { label: "Crape Bandages", value: "crape_bandages" },
  { label: "Compression Device", value: "compression_device" },
];
export const pharmalogicalOptions = [
  { label: "Unfractionated Heparin", value: "unfractionated_heparin" },
  { label: "LMW Heparin", value: "lmw_heparin" },
  { label: "Oral Anticoagulants", value: "oral_anticoagulants" },
  { label: "Others", value: "others" },
];

export const tracheostomyTypeOptions = [
  { label: "Non-Fenestrated", value: "non_fenestrated" },
  { label: "Fenestrated", value: "fenestrated" },
];

export const tracheostomySecretionOptions = [
  { label: "Mild", value: "mild" },
  { label: "Moderate", value: "moderate" },
  { label: "Heavy", value: "heavy" },
];

export const urinaryCatheterTypeOptions = [
  { label: "Foleys Catheter", value: "foleys_catheter" },
  { label: "Condom Catheter", value: "condom_catheter" },
];

export const foleysCatheterTypeOptions = [
  { label: "Rubber", value: "rubber" },
  { label: "Silicone", value: "silicone" },
];

export const urineTypeOptions = [
  { label: "Dark Colored Urine", value: "dark_colored_urine" },
  { label: "Hematuria", value: "hematuria" },
  { label: "Pus Cells in Urine", value: "pus_cells_in_urine" },
  { label: "Others", value: "others" },
];

export const depthOfBreathingOptions = [
  { label: "Shallow", value: "shallow" },
  { label: "Adequate", value: "adequate" },
];

export const oxygenSupportOptions = [
  { label: "No", value: "no" },
  { label: "Nasal Prongs", value: "nasal_prongs" },
  { label: "Face Mask", value: "face_mask" },
  { label: "High Flow Mask", value: "high_flow_mask" },
  { label: "NIV/CPAP", value: "niv_cpap" },
  { label: "Ventilation", value: "ventilation" },
];

export const tracheostomyConditionOptions = [
  { label: "Healthy", value: "healthy" },
  { label: "Infected", value: "infected" },
  { label: "Inflamed", value: "inflamed" },
  { label: "Ulcerated", value: "ulcerated" },
  { label: "Secretions", value: "secretions" },
];

export const pulseVolumeOptions = [
  { label: "Good Volume", value: "good_volume" },
  { label: "Feeble", value: "feeble" },
];

export const drainNumberOptions = [
  { value: "drain_1", label: "Drain 1" },
  { value: "drain_2", label: "Drain 2" },
];
