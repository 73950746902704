import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Select,
  message,
  Switch,
  Space,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { getCinApi, getGstApi } from "../../../services/auth.services";
import { getCookie } from "../../../helpers/localStorage";
import { ORGANISATONID } from "../../../constants/defaultKeys";
import {
  createFacilityApi,
  updateFacilityApi,
} from "../../../services/facilities.services";
import { validateConfig } from "../../../config/validate.config";
const { Option } = Select;
const FacilitiesForm = ({
  isVisible,
  setIsVisible,
  editListData,
  refreshPage,
}) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [cinData, setCinData] = useState(null);
  const [gstData, setGstData] = useState(null);
  const orgId = getCookie(ORGANISATONID);
  useEffect(() => {
    getCinApi()
      .then((res) => setCinData(res?.data))
      .catch((err) => console.log(err));
    getGstApi()
      .then((res) => setGstData(res?.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (editListData) {
      form.setFieldsValue({
        ...editListData,
        cin: editListData?.cin?.cin_id,
        gstin: editListData?.gstin?.gstin_id,
      });
    }
  }, [editListData]);

  const onClose = () => {
    setIsVisible(false);
  };
  const onFinish = (values) => {
    setBtnLoading(true);
    if (editListData?.id) {
      updateFacilityApi(editListData?.id, { ...values, organization: orgId })
        .then((res) => {
          message.success(res?.data?.message);
          setIsVisible(false);
          refreshPage();
          setBtnLoading(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    } else {
      createFacilityApi({
        ...values,
        organization: orgId,
        ip_available:
          values?.ip_available === true || values?.ip_available === false
            ? values?.ip_available
            : true,
        op_available:
          values?.op_available === true || values?.op_available === false
            ? values?.op_available
            : true,
      })
        .then((res) => {
          message.success(res?.data?.message);
          setIsVisible(false);
          refreshPage();
          setBtnLoading(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    }
  };
  return (
    <Drawer
      open={isVisible}
      width={"800px"}
      title={
        <Row>
          <Col span={12} className="flex-start">
            <Typography
              style={{
                marginLeft: "12px",
                fontWeight: "400",
                fontSize: "20px",
              }}
            >
              {editListData ? "Edit Facility" : "Add Facility"}
            </Typography>
          </Col>
          <Col span={12} className="flex-end">
            <CloseOutlined onClick={onClose} />
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: "right" }}>
          <Form.Item>
            <Button
              loading={btnLoading}
              className={btnStyles.lgBtn}
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      }
      onClose={onClose}
      closeIcon={null}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        ref={formRef}
        requiredMark={false}
      >
        <Form.Item
          label="Facility Name"
          name="name"
          rules={validateConfig.isRequired("Facility Name")}
        >
          <Input
            className={inputStyles.mdInput}
            style={{ width: "300px" }}
            placeholder="Enter Facility Name"
          />
        </Form.Item>
        <Space>
          <Form.Item name="ip_available" valuePropName="checked" label="IP">
            <Switch
              checkedChildren="Available"
              unCheckedChildren="Not-available"
              defaultChecked
            />
          </Form.Item>
          <Form.Item name="op_available" valuePropName="checked" label="OP">
            <Switch
              checkedChildren="Available"
              unCheckedChildren="Not-available"
              defaultChecked
            />
          </Form.Item>
        </Space>

        <Form.Item
          label="Locality"
          name="address"
          // rules={validateConfig.isRequired("Locality")}
        >
          <Input.TextArea
            placeholder="Enter Locality"
            className={inputStyles.mdInput}
            style={{ width: "300px" }}
            rows={2}
          />
        </Form.Item>
        <Space>
          <Form.Item label="City" name="city">
            <Input
              className={inputStyles.mdInput}
              style={{ width: "250px" }}
              placeholder="Enter City"
            />
          </Form.Item>
          <Form.Item label="State" name="state">
            <Input
              className={inputStyles.mdInput}
              style={{ width: "250px" }}
              placeholder="Enter State"
            />
          </Form.Item>
        </Space>

        <Form.Item
          label="Pincode"
          name="pin_code"
          rules={[
            {
              max: 6,
              message: "Pincode cannot exceed 6 characters",
            },
          ]}
        >
          <Input
            className={inputStyles.mdInput}
            style={{ width: "300px" }}
            placeholder="Enter Pincode"
          />
        </Form.Item>
        <Space>
          <Form.Item label="CIN" name="cin">
            <Select
              style={{ width: "300px" }}
              placeholder="Choose CIN"
              className={inputStyles.mdSelect}
              optionFilterProp="children"
            >
              {cinData?.map((gst) => (
                <Option value={gst?.id} key={gst?.id}>
                  {gst?.cin_no}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="GST" name="gstin">
            <Select
              style={{ width: "300px" }}
              placeholder="Choose GST"
              className={inputStyles.mdSelect}
              optionFilterProp="children"
            >
              {gstData?.map((gst) => (
                <Option value={gst?.id} key={gst?.id}>
                  {gst?.gst_no}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Space>

        <Form.Item
          label="Contact Number"
          name="phone"
          rules={[
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          ]}
        >
          <Input
            style={{ width: "300px" }}
            maxLength="10"
            placeholder="Enter Mobile Number"
            addonBefore={"+91"}
            className={inputStyles.mdAddonInput}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FacilitiesForm;
