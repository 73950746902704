import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import OrgDetails from "./OrgDetails";
import OrgContact from "./OrgContact";
import Additional from "./Additional";
import { orgDetailsApi } from "../../services/org.services";

const OrganizationDashboard = () => {
  const [orgDetails, setOrgDetails] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const query = useParams();

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    orgDetailsApi()
      .then((res) => {
        setOrgDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh, query?.tab]);

  return (
    <>
      {query?.tab === "org-details" ? (
        <OrgDetails details={orgDetails} />
      ) : query?.tab === "contact" ? (
        <OrgContact data={orgDetails} refreshPage={refreshPage} />
      ) : (
        <Additional data={orgDetails} refreshPage={refreshPage} />
      )}
    </>
  );
};

export default OrganizationDashboard;
