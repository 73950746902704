import React, { createContext, useState } from "react";
export const PsychologistInitialContext = createContext({});
const PsychologistInitialAssessmentProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <PsychologistInitialContext.Provider value={{ formData, setFormData }}>
      {children}
    </PsychologistInitialContext.Provider>
  );
};

export default PsychologistInitialAssessmentProvider;
