import React from "react";
import { Form, Radio, InputNumber } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";

const Urine24hr = () => {
  return (
    <>
      <Form.Item label="24 hrs Urine Output" name="urine_output" rules={[]}>
        <InputNumber addonAfter="ml" style={{ width: "20%" }} />
      </Form.Item>
      <Form.Item label="Urinary Leakage" name="urinary_leakage" rules={[]}>
        <Radio.Group options={yesNoOptions} />
      </Form.Item>
    </>
  );
};

export default Urine24hr;
