import React from "react";
import { Form, Radio } from "antd";
import { nmmsOptions } from "../../../../Options/genExamOptions";

const Icterus = () => {
  return (
    <>
      <Form.Item label="Icterus" name="icterus" rules={[]}>
        <Radio.Group options={nmmsOptions} />
      </Form.Item>
    </>
  );
};

export default Icterus;
