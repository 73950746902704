export const TOKEN_KEY = "token";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const USER = "user";
export const ORGANISATONID = "organisation_id";
export const ACTIVEDOMAIN = "active_domain";
export const FacilityID = "facility_id";

export const yesNoOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];
