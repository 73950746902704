import { rolesInfo } from "../utility";

export const redirectToDashboard = (domain, role) => {
  const baseUrl = process.env.REACT_APP_PUBLIC_SUBDOMIAN_BASE_URL;

  if (process.env.REACT_APP_PUBLIC_ENV === "STAGING") {
    window.location.href = `${baseUrl}${rolesInfo[role]?.href}`;
  } else {
    window.location.href = `https://${domain}.${process.env.REACT_APP_PUBLIC_DOMAIN}${rolesInfo[role]?.href}`;
  }
};

export const capitalizeFirstLetter = (word) => {
  if (typeof word !== "string" || word.length === 0) {
    return word; // Return the original word if it's not a string or is empty
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};
