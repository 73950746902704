import React, { useContext, useEffect, useState } from "react";
import { Form, Radio, Select } from "antd";
import {
  ambulanceAssistanceOptions,
  ambulanceBedBoundOptions,
  ambulanceOptions,
} from "../../../Options/funcExamOptions";
import AmbulationDetails from "./AmbulationDetails";

const Ambulation = ({ context, dailyReview = false }) => {
  const { formData } = useContext(context);
  const [state, setState] = useState({
    showAmbulanceAssistance:
      formData?.functional_examination?.ambulation === "assisted" ||
      formData?.functional_status?.ambulation === "assisted",
    showAmbulanceBedBound:
      formData?.functional_examination?.ambulation === "bed_bound" ||
      formData?.functional_status?.ambulation === "assisted",
  });

  const handleAmbulationChange = ({ target: { value } }) => {
    setState({
      showAmbulanceAssistance: value === "assisted",
      showAmbulanceBedBound: value === "bed_bound",
    });
  };

  useEffect(() => {
    setState({
      showAmbulanceAssistance:
        formData?.functional_examination?.ambulation === "assisted" ||
        formData?.functional_status?.ambulation === "assisted",
      showAmbulanceBedBound:
        formData?.functional_examination?.ambulation === "bed_bound" ||
        formData?.functional_status?.ambulation === "bed_bound",
    });
  }, [formData]);

  return (
    <>
      {dailyReview ? (
        <AmbulationDetails />
      ) : (
        <>
          <Form.Item name="ambulation" label="Ambulation">
            <Radio.Group
              options={ambulanceOptions}
              onChange={handleAmbulationChange}
            />
          </Form.Item>
          {state.showAmbulanceAssistance && (
            <Form.Item name="ambulation_assistance">
              <Select
                placeholder="Select"
                options={ambulanceAssistanceOptions}
              />
            </Form.Item>
          )}
          {state.showAmbulanceBedBound && (
            <Form.Item name="ambulation_bed_bound">
              <Select placeholder="Select" options={ambulanceBedBoundOptions} />
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};

export default Ambulation;
