import React from "react";
import { Row, Col } from "antd";
import PatientDetails from "./PatientDetails";
import AssignPackages from "./AssignPackages";

const PackagesIp = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <PatientDetails />
      </Col>
      <Col span={12}>
        <AssignPackages />
      </Col>
    </Row>
  );
};

export default PackagesIp;
