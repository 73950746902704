import React from "react";
import { Form, Radio } from "antd";
import { respiratoryDistressOptions } from "../../../../Options/genExamOptions";

const RespiratoryDistress = () => {
  return (
    <>
      <Form.Item
        label="Respiratory Distress"
        name="respiratory_distress"
        rules={[]}
      >
        <Radio.Group options={respiratoryDistressOptions} />
      </Form.Item>
    </>
  );
};

export default RespiratoryDistress;
