import React, { useContext, useEffect, useState } from "react";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { getAppointmentListApi } from "../../../services/receptionist.services";
import btnStyles from "../../../styles/Custom/Button.module.css";
import AppointmentsTable from "./AppointmentsTable";
import AppointsModal from "./AppointsModal";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Row, Col, Input } from "antd";
import dayjs from "dayjs";
import { debounce } from "../../../helpers/utility";

const dateFormat = "YYYY-MM-DD";
const Appointments = () => {
  const curDate = dayjs().format(dateFormat);
  const { userDetails } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [date, setDate] = useState(curDate);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(1);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    let params = {
      page: page,
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      selected_date: date,
    };
    if (searchQuery) {
      params.search = searchQuery;
    }
    const queryString = new URLSearchParams(params);
    getAppointmentListApi(queryString)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    date,
    refresh,
    searchQuery,
    page,
    Number(getCookie(FacilityID)) || userDetails?.currFacility,
  ]);
  const onContactSearch = (value) => {
    setSearchQuery(value?.target?.value);
  };
  return (
    <Row gutter={[12, 12]}>
      <Col span={24} className="flex-between">
        <Input.Search
          style={{ width: "30%", marginBottom: "10px" }}
          size="large"
          placeholder="Search Appointments (Patient Name,Contact,Practitioner Name)"
          onChange={debounce(onContactSearch, 300)}
        ></Input.Search>
        <Button
          type="primary"
          className={btnStyles.mdBtn}
          onClick={() => setOpen(true)}
        >
          <PlusOutlined /> Appointments
        </Button>
      </Col>
      <Col span={24}>
        <AppointmentsTable
          data={data}
          refresh={refreshPage}
          page={page}
          setPage={setPage}
        />{" "}
      </Col>

      {open && (
        <AppointsModal open={open} setOpen={setOpen} refresh={refreshPage} />
      )}
    </Row>
  );
};

export default Appointments;
