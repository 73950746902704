import React from "react";
import { Form, Checkbox } from "antd";
import { orthopedicOptions } from "../../Options/clinicalImpressionOptions";

const Orthopedic = () => {
  return (
    <>
      <Form.Item name="orthopedic_diagnosis" label="Orthopedic Diagnosis">
        <Checkbox.Group options={orthopedicOptions} />
      </Form.Item>
    </>
  );
};

export default Orthopedic;
