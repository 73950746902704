import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPatientDetailsApi } from "../../services/patient.services";
import { UserContext } from "../../context/UserContext";
import PatientMenu from "./PatientMenu";
import DoctorIP from "./DoctorIP";
import NurseIP from "./NurseIP";
import SpeechAndSwallowTherapistIP from "./SpeechAndSwallowTherapistIP";
import RespiratoryTherapistIP from "./RespiratoryTherapistIP";
import NutritionistIP from "./NutritionistIP";
import PhysiotherapistIP from "./PhysiotherapistIP";
import InvestigationsIp from "./InvestigationsIp/InvestigationsIP";
import useQuery from "../../hooks/useQuery";
import ProgressMonitoring from "./ProgressMonitoring/ProgressMonitoring";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import DietChart from "./Diet/DietChart";
import Gallery from "./PatientGallery/PatientGallery";
import PatientGallery from "./PatientGallery/PatientGallery";
import PsychologistIP from "./PsychologistIP";
import ReceptionistIp from "./ReceptionistIp/ReceptionistIp";

const PatientsIP = () => {
  const query = useParams();
  const navigate = useNavigate();
  const params = useQuery();
  const location = useLocation();
  const { userDetails } = useContext(UserContext);
  const userRole = userDetails?.activeOrg?.default_role;
  const [current, setCurrent] = useState("ia");
  const [patientData, setPatientData] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const refreshPage = () => setRefresh((prev) => !prev);
  console.log(query);
  const role = userDetails?.activeOrg?.default_role
    .toLowerCase()
    ?.replaceAll("_", "-");

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(`/${role}/${query?.tab}?patient=${params?.patient}&type=${e.key}`);
  };

  useEffect(() => {
    getPatientDetailsApi(params?.patient)
      .then((res) => setPatientData(res?.data?.data))
      .catch((err) => console.log(err));
  }, [refresh, params?.patient]);

  const renderComponent = () => {
    switch (userRole) {
      case "Doctor":
        return (
          <DoctorIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Nurse":
        return (
          <NurseIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Speech_Swallow_Therapist":
        return (
          <SpeechAndSwallowTherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Respiratory_Therapist":
        return (
          <RespiratoryTherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Nutritionist":
        return (
          <NutritionistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Physiotherapist":
        return (
          <PhysiotherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Receptionist":
        return (
          <ReceptionistIp
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Psychologist":
        return (
          <PsychologistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      default:
        return null;
    }
  };

  function subComponent() {
    switch (params.type) {
      case "investigations":
        return <InvestigationsIp />;
      case "progress_monitoring":
        return <ProgressMonitoring patient={params?.patient} />;
      case "diet":
        return <DietChart />;
      case "logs":
        return (
          <Result
            icon={<SmileOutlined style={{ color: "#101828" }} />}
            title="Coming soon!!"
          />
        );
      case "gallery":
        return <PatientGallery />;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <PatientMenu
        current={current}
        onClick={onClick}
        userRole={userRole}
        params={params}
        navigate={navigate}
        patientData={patientData}
      />
      <div style={{ padding: "8px" }}>
        {renderComponent()}
        {subComponent()}
      </div>
    </Fragment>
  );
};

export default PatientsIP;
