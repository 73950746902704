import React from "react";
import { Modal, Form, DatePicker, Button, Tag, message } from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import dayjs from "dayjs";
import { stopPackageApi } from "../../../../services/receptionist.services";

const EndPackageModal = ({ item, open, setOpen }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    stopPackageApi(item?.id, {
      end_date: dayjs(values?.end_date).format("YYYY-MM-DD"),
    })
      .then((res) => {
        message.success(res?.data?.message);
        setOpen(false);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      title={item?.package?.name}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={{
          date: dayjs(),
        }}
      >
        <Form.Item label="Start Date">
          <Tag color="blue">
            {dayjs(item?.start_date).format("MMM DD, YYYY")}
          </Tag>
        </Form.Item>
        <Form.Item label="Date" name="end_date">
          <DatePicker
            allowClear={false}
            defaultValue={dayjs()}
            className={inputStyles.mdDateInput}
            style={{ width: "300px" }}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button type="primary" htmlType="submit" className={btnStyles.mdBtn}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EndPackageModal;
