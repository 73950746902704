import React, { useState, useEffect, useContext } from "react";
import CommonLayout from "../Layout/CustomLayout";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import OrgTabs from "./OrgTabs";
import textStyles from "../../styles/Custom/Typography.module.css";
import styles from "../../styles/Custom/Button.module.css";
import { Typography, Card, message, Button } from "antd";
import { TagsInput } from "react-tag-input-component";
import { addCinApi, addGstApi } from "../../services/auth.services";
import { UserContext } from "../../context/UserContext";
import DoctorLayout from "../Layout/DoctorLayout/index";
const Additional = ({ data, refreshPage }) => {
  const { userDetails } = useContext(UserContext);
  const [cin, setCin] = useState(data ? [...data?.cin_nos] : []);
  const [gst, setGst] = useState(data ? [...data?.gstin_nos] : []);

  useEffect(() => {
    if (data) {
      setCin(data?.cin_nos);
      setGst(data?.gstin_nos);
    }
  }, [data]);

  const onCinSubmit = () => {
    if (cin?.length > 0) {
      addCinApi(cin)
        .then((res) => {
          message.success(res?.data?.message);
          // refreshPage();
        })
        .catch((err) => message.error(err?.response?.data?.message));
    } else {
      message.error("Please add cin");
    }
  };

  const onGstSubmit = () => {
    if (gst?.length > 0) {
      addGstApi(gst)
        .then((res) => {
          message.success(res?.data?.message);
          // refreshPage();
        })
        .catch((err) => message.error(err?.response?.data?.message));
    } else {
      message?.error("Please add gst");
    }
  };

  const handleCinTagChange = (newTags) => {
    setCin(newTags);
  };

  const handleGstTagChange = (newTags) => {
    setGst(newTags);
  };

  const handleBeforeCinAddValidate = (tag) => {
    const isCinValid = (cin) => {
      const cinRegex = /^[U,L]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
      return cinRegex.test(cin);
    };

    if (!isCinValid(tag)) {
      message.error("Please enter valid 21 digits alpha-numeric cin number");
      return false;
    }
    return true;
  };

  const handleBeforeGstAddValidate = (tag) => {
    const isGstValid = (gst) => {
      const gstRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      return gstRegex.test(gst);
    };

    if (!isGstValid(tag)) {
      message.error("Please enter valid GST number");
      return false;
    }
    return true;
  };

  const commonOrg = () => {
    return (
      <Card
        style={{ width: "60%", margin: "auto" }}
        className={`${styles.personalDetailsCard} centeredCard`}
      >
        <Typography>CIN</Typography>
        <TagsInput
          separators={[",", "Enter"]}
          value={cin}
          onChange={handleCinTagChange}
          placeHolder="Enter CIN numbers followed by (,)"
          beforeAddValidate={handleBeforeCinAddValidate}
        />
        <div className="flex-end" style={{ gap: "24px", marginTop: "10px" }}>
          <Button
            className={styles.mdBtn}
            type="primary"
            onClick={() => {
              onCinSubmit();
            }}
          >
            Save CIN
          </Button>
        </div>
        <Typography>GST</Typography>
        <TagsInput
          separators={[",", "Enter"]}
          value={gst}
          onChange={handleGstTagChange}
          placeHolder="Enter GST numbers followed by (,)"
          beforeAddValidate={handleBeforeGstAddValidate}
        />
        <div className="flex-end" style={{ gap: "24px", marginTop: "10px" }}>
          <Button
            className={styles.mdBtn}
            type="primary"
            onClick={() => {
              onGstSubmit();
            }}
          >
            Save GST
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <>
      {userDetails?.activeOrg?.default_role === "Doctor" ||
      userDetails?.activeOrg?.default_role === "Nurse" ? (
        <DoctorLayout
          sideContent={<SideMenu />}
          header={
            <div
              style={{
                gap: "5%",
                width: "100%",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>
                Organisation Profile
              </Typography>
              <OrgTabs />
            </div>
          }
        >
          {commonOrg()}
        </DoctorLayout>
      ) : (
        <CommonLayout
          sideContent={<SideMenu />}
          header={
            <div
              style={{
                gap: "5%",
                width: "100%",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>
                Organisation Profile
              </Typography>
              <OrgTabs />
            </div>
          }
        >
          {commonOrg()}
        </CommonLayout>
      )}
    </>
  );
};

export default Additional;
