import React, { useState, useEffect } from "react";
import { Checkbox, Select, Form, Row, Col } from "antd";

const CheckListFBYSelect = ({
  checkBoxOptions = [],
  selectOptions = [],
  checkboxes,
  setCheckboxes,
}) => {
  const handleCheckboxChange = (value) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: !prevCheckboxes[value], // Toggle the checkbox value
    }));
  };

  return (
    <Row>
      {checkBoxOptions.map((checkbox, index) => (
        <Col span={8} key={index}>
          <Form.Item name={checkbox.value} valuePropName="checked">
            <Checkbox
              checked={checkboxes[checkbox.value]}
              onChange={() => handleCheckboxChange(checkbox.value)}
            >
              {checkbox.label}
            </Checkbox>
          </Form.Item>
          {checkboxes[checkbox.value] && (
            <Form.Item
              style={{ marginLeft: "20px", marginRight: "20px" }}
              name={`${checkbox.value}_type`}
            >
              <Select placeholder="Select" options={selectOptions} />
            </Form.Item>
          )}
        </Col>
      ))}
    </Row>
  );
};

export default CheckListFBYSelect;
