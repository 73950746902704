import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { RespiratoryInitialContext } from "./RespiratoryTherapistInitialAssessmentProvider";
import {
  respiratoryInspectionAPI,
  updateRespiratoryInspectionAPI,
} from "../../../../services/respiratory.services";
import { Button, Form, message, Modal, Tabs } from "antd";
import ClinicalImpressionForm from "../../InspectionForms/ClinicalImpressionForm/ClinicalImpressionForm";
import RespiratoryTherapistChartAssessment from "../../InspectionForms/ChartAssessment/RespiratoryTherapistChartAssessment";
import RespiratoryPatientAssessment from "../../InspectionForms/PatientAssessment/RespiratoryPatientAssessment";
import RespiratoryTherapistTreatmentPlan from "../../InspectionForms/TreatmentPlan/RespiratoryTherapistTreatmentPlan";
import RespiratorySpecialInstruction from "../../InspectionForms/SpecialAreas/RespiratorySpecialInstruction";
import btnStyles from "../../../../styles/Custom/Button.module.css";

const RespiratoryTherapistInitialInspectionForm = ({
  setVisible,
  refreshPage,
  initialRefresh,
  editId,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [clinicalForm] = Form.useForm();
  const [chartForm] = Form.useForm();
  const [patientForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const [specialForm] = Form.useForm();

  const [activeTab, setActiveTab] = useState("1");
  const { formData } = useContext(RespiratoryInitialContext);

  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (values) => {
    try {
      if (editId) {
        await updateRespiratoryInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await respiratoryInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success("Form submitted successfully");
      // const nextTab = (parseInt(activeTab) + 1).toString();
      // if (activeTab !== "5") {
      //   setActiveTab(nextTab);
      // } else if (activeTab === "5" && formData?.care_items_generated) {
      //   setVisible(false);
      // } else {
      //   setCurrent(1);
      // }
      // setIsDirty(false);
      if (activeTab === "5") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onClinicalSubmit = (values) => {
    handleSubmit({ clinical_diagnosis: values, admission_id: params?.patient });
  };
  const onChartSubmit = (values) => {
    handleSubmit({
      chart_assessment: { ...values },
      admission_id: params?.patient,
    });
  };
  const onPatientSubmit = (values) => {
    handleSubmit({
      patient_assessment: {
        ...values,
      },
      admission_id: params?.patient,
    });
  };
  const onTreatmentSubmit = (values) => {
    handleSubmit({
      treatment_plan: values,
      admission_id: params?.patient,
    });
  };
  const onSpecialSubmit = (values) => {
    handleSubmit({
      special_instruction: values,
      admission_id: params?.patient,
    });
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return clinicalForm.submit();
      case "2":
        return chartForm.submit();
      case "3":
        return patientForm.submit();
      case "4":
        return treatmentForm.submit();
      case "5":
        return specialForm.submit();

      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      // setNextTab(key);
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              clinicalForm.validateFields().then((values) => {
                setIsDirty(false);
                onClinicalSubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              chartForm.validateFields().then((values) => {
                setIsDirty(false);
                onChartSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              patientForm.validateFields().then((values) => {
                setIsDirty(false);
                onPatientSubmit(values);
                setActiveTab(key);
              });
              break;
            case "4":
              treatmentForm.validateFields().then((values) => {
                setIsDirty(false);
                onTreatmentSubmit(values);
                setActiveTab(key);
              });
              break;
            case "5":
              specialForm.validateFields().then((values) => {
                setIsDirty(false);
                onSpecialSubmit(values);
                setActiveTab(key);
              });
              break;

            default:
              setActiveTab(key);
          }
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Tabs
        activeKey={activeTab}
        items={[
          { key: "1", label: "Clinical Impression " },
          { key: "2", label: "Chart Assessment" },
          { key: "3", label: "Patient Assessment" },
          { key: "4", label: "Treatment Plan" },
          { key: "5", label: "Special Instructions" },
        ]}
        onChange={handleTabChange}
      />
      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div style={{ height: "calc(100% - 38px)", overflowY: "auto" }}>
          {activeTab === "1" && (
            <Form
              id="clinical_diagnosis-form"
              form={clinicalForm}
              layout="vertical"
              onFinish={onClinicalSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <ClinicalImpressionForm
                form={clinicalForm}
                context={RespiratoryInitialContext}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="chart-form"
              form={chartForm}
              layout="vertical"
              onFinish={onChartSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryTherapistChartAssessment form={chartForm} />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="patient-form"
              form={patientForm}
              layout="vertical"
              onFinish={onPatientSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryPatientAssessment
                form={patientForm}
                context={RespiratoryInitialContext}
              />
            </Form>
          )}
          {activeTab === "4" && (
            <Form
              id="treatment-form"
              form={treatmentForm}
              layout="vertical"
              onFinish={onTreatmentSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryTherapistTreatmentPlan
                form={treatmentForm}
                context={RespiratoryInitialContext}
              />
            </Form>
          )}
          {activeTab === "5" && (
            <Form
              id="special-instruction"
              form={specialForm}
              layout="vertical"
              onFinish={onSpecialSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratorySpecialInstruction form={specialForm} />
            </Form>
          )}

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
            >
              {activeTab === "5" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RespiratoryTherapistInitialInspectionForm;
