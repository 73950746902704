import React, { useContext, useEffect, useState } from "react";
import { beckDepressionOptions } from "../../Options/psychologistOptions";
import { Form, Radio, List, Typography } from "antd";

const BeckDepression = ({ context }) => {
  const { formData } = useContext(context);
  const [selectedValues, setSelectedValues] = useState({});

  const getValue = (pseudoValue) => {
    return pseudoValue.startsWith("1")
      ? 1
      : pseudoValue.startsWith("2")
      ? 2
      : 3;
  };

  const handleRadioChange = (name, pseudoValue) => {
    const value =
      typeof pseudoValue === "string" ? getValue(pseudoValue) : pseudoValue;
    setSelectedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateAggregate = () => {
    return Object.values(selectedValues).reduce((sum, value) => sum + value, 0);
  };

  const getDepressionLevelText = (aggregate) => {
    if (aggregate < 11) return "These ups and downs are considered normal";
    if (aggregate < 17) return "Mild mood disturbance";
    if (aggregate < 20) return "Borderline Depression";
    if (aggregate < 31) return "Moderate Depression";
    if (aggregate < 41) return "Severe Depression";
    return "Extreme Depression";
  };

  const aggregate = calculateAggregate();
  const depressionLevelText = getDepressionLevelText(aggregate);

  useEffect(() => {
    if (formData?.scales?.beck_depression) {
      Object.entries(formData?.scales?.beck_depression).forEach(
        ([key, value]) => {
          handleRadioChange(key, value);
        }
      );
    }
  }, [formData]);

  return (
    <>
      <Typography.Title level={5}>Beck Depression Inventory</Typography.Title>
      {beckDepressionOptions.map((option) => (
        <Form.Item
          key={option.value}
          label={option.label}
          name={["beck_depression", option.value]}
        >
          <Radio.Group
            options={option.options}
            onChange={(e) => handleRadioChange(option.value, e.target.value)}
          />
        </Form.Item>
      ))}

      <List bordered>
        <List.Item>
          <Typography.Text strong>
            Aggregate Score: {aggregate}{" "}
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text strong>Depression Level: </Typography.Text>
          {depressionLevelText}
        </List.Item>
      </List>
    </>
  );
};

export default BeckDepression;
