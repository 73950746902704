import React, { useContext, useState } from "react";
import { Button, Divider, Form, Input, message, Typography } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import btnStyles from "../../../styles/Custom/Button.module.css";
import textStyles from "../../../styles/Custom/Typography.module.css";
import {
  emailLoginApi,
  userMetaDataApi,
} from "../../../services/auth.services";
import { setCookie } from "../../../helpers/localStorage";
import {
  ACCESS_TOKEN,
  ACTIVEDOMAIN,
  ORGANISATONID,
  REFRESH_TOKEN,
} from "../../../constants/defaultKeys";

import { redirectToDashboard } from "../../../helpers/auth/helper";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import ChooseOrgModal from "./ChooseOrgModal";
function EmailPassword({ orgID }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setUserDetails } = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const resp = await emailLoginApi(values);
      if (resp) {
        setCookie(
          ACCESS_TOKEN,
          resp?.data?.access,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie(
          REFRESH_TOKEN,
          resp?.data?.refresh,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        getUserDetails();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    try {
      let resp = await userMetaDataApi();
      if (orgID) {
        setCookie(
          ORGANISATONID,
          orgID,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        const activeOrg = orgID
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgID
            )?.[0]
          : {};

        setUserDetails({
          ...resp?.data,
          activeOrg: activeOrg,
          currFacility: activeOrg?.facilities?.[0]?.facility_id,
        });
        if (activeOrg?.domain) {
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          redirectToDashboard(activeOrg?.domain, activeOrg?.default_role);
        }
      } else if (resp?.data?.organizations?.length == 1) {
        let orgId = resp?.data?.organizations?.[0].org_id;
        setCookie(
          ORGANISATONID,
          orgId,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        const activeOrg = orgId
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgId
            )?.[0]
          : {};
        setUserDetails({
          ...resp?.data,
          activeOrg: activeOrg,
          currFacility: activeOrg?.facilities?.[0]?.facility_id,
        });
        if (activeOrg?.domain) {
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          redirectToDashboard(activeOrg?.domain, activeOrg?.default_role);
        }
      } else {
        setOpenModal(true);
        setUserDetails({
          ...resp?.data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading && setLoading(false);
    }
  };

  return (
    <>
      {" "}
      <ChooseOrgModal openModal={openModal} />
      <Typography
        className={`text-center `}
        style={{ paddingTop: "12px", paddingBottom: "32px" }}
      >
        <br />
        <Typography
          className={`text-center ${textStyles.h5}`}
          // style={{ marginTop: "24px" }}
        >
          Login to your account.
        </Typography>
        <br />
        Welcome back! Please enter your details
        <br />
      </Typography>{" "}
      <Form
        autoComplete="off"
        onFinish={onFinish}
        layout="vertical"
        requiredMark={false}
        style={{ width: "100%" }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { type: "email", message: "Please enter a valid email" },
            {
              required: true,
              message: "Enter your email",
            },
          ]}
        >
          <Input
            placeholder="Enter your email"
            className={inputStyles.mdInput}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              max: 500,
              message: "Password cannot exceed 500 characters",
            },
          ]}
        >
          <Input.Password
            placeholder="Enter your password"
            className={inputStyles.mdInput}
            style={{ width: "100%", gap: "0px" }}
          />
        </Form.Item>
        <br />
        <Form.Item style={{ width: "100%", margin: "auto" }}>
          <Button
            loading={loading}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            className={btnStyles.mdBtn}
          >
            Login
          </Button>
        </Form.Item>
        <Divider>Or</Divider>
        <Typography className="text-center" style={{ paddingTop: "12px" }}>
          Login with{" "}
          <span
            style={{ color: "#7F56D9", cursor: "pointer" }}
            onClick={() => navigate("/login")}
          >
            Mobile Password
          </span>{" "}
          /{" "}
          <span
            style={{ color: "#7F56D9", cursor: "pointer" }}
            onClick={() => navigate("/login?type=mobile")}
          >
            Mobile OTP
          </span>
        </Typography>
      </Form>
    </>
  );
}

export default EmailPassword;
