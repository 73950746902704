import React, { useContext, useEffect, useState } from "react";
import {
  emotionalIntelligenceOptions,
  emotionalIntelligenceScaleOptions,
} from "../../Options/psychologistOptions";
import { Form, Radio, List, Typography } from "antd";

const { Title, Paragraph } = Typography;

const EmotionalIntelligence = ({ context }) => {
  const { formData } = useContext(context);
  const [state, setState] = useState({});

  const handleChange = (option, value) => {
    setState((prevState) => ({
      ...prevState,
      [option]: value,
    }));
  };

  const calculateAggregate = (prefix) => {
    return Object.entries(state).reduce((acc, [key, value]) => {
      if (key.startsWith(prefix)) {
        return acc + value;
      }
      return acc;
    }, 0);
  };

  const getSummaryText = () => {
    const threshold = 18;
    const values = [
      calculateAggregate("self_awareness"),
      calculateAggregate("self_management"),
      calculateAggregate("social_awareness"),
      calculateAggregate("relationship_management"),
    ];
    return values.every((value) => value >= threshold)
      ? "All Good"
      : "Could be Improved";
  };

  useEffect(() => {
    if (formData?.scales?.emotional_intelligence) {
      Object.entries(formData?.scales?.emotional_intelligence).forEach(
        ([key, value]) => {
          handleChange(key, value);
        }
      );
    }
  }, [formData]);

  return (
    <>
      <Title level={5}>Emotional Intelligence Scale</Title>
      {emotionalIntelligenceOptions.map((option) => (
        <Form.Item
          key={option.value}
          label={option.label}
          name={["emotional_intelligence", option.value]}
        >
          <Radio.Group
            options={emotionalIntelligenceScaleOptions}
            onChange={(e) => handleChange(option.value, e.target.value)}
            value={state[option.value] || 0}
          />
        </Form.Item>
      ))}

      <List
        bordered
        dataSource={[
          {
            title: "Self Awareness",
            value: calculateAggregate("self_awareness"),
          },
          {
            title: "Self Management",
            value: calculateAggregate("self_management"),
          },
          {
            title: "Social Awareness",
            value: calculateAggregate("social_awareness"),
          },
          {
            title: "Relationship Management",
            value: calculateAggregate("relationship_management"),
          },
        ]}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text strong>{item.title}:</Typography.Text> {item.value}
          </List.Item>
        )}
      />
      <Paragraph>{getSummaryText()}</Paragraph>
    </>
  );
};

export default EmotionalIntelligence;
