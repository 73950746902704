import React from "react";
import { Table } from "antd";
import dayjs from "dayjs";
import { formatToINR } from "../../../../helpers/utility";

const PatientPackageTable = ({ data }) => {
  console.log(data);
  const tableData = data && [
    ...data?.package?.package_services,
    ...data?.package?.package_investigations,
  ];

  const columns = [
    {
      title: "Service / Investigation",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        record?.service?.name || record?.investigation?.name,
    },
    {
      title: "Start Date",
      render: () => dayjs(data?.start_date)?.format("DD MMM"),
    },
    {
      title: "End Date",
      render: () =>
        data?.end_date ? dayjs(data?.end_date)?.format("DD MMM") : "-",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value) => formatToINR(value),
    },
  ];
  return (
    <div>
      <Table dataSource={tableData} columns={columns} pagination={false} />
    </div>
  );
};

export default PatientPackageTable;
