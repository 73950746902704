import { Form, Input } from "antd";
import React, { useContext, useEffect } from "react";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";

const RespiratorySpecialInstruction = ({ form }) => {
  const { formData } = useContext(RespiratoryInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.special_instruction);
  }, [formData]);
  return (
    <>
      <Form.Item name={"special_instruction"}>
        <Input placeholder="Special Instruction if any" />
      </Form.Item>
    </>
  );
};

export default RespiratorySpecialInstruction;
