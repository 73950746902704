import React from "react";
import MaintainsOwn from "./MaintainsOwn";
import { Divider } from "antd";
import ArtificialAirway from "./ArtificialAirway";

const AirWay = () => {
  return (
    <>
      <MaintainsOwn />
      <Divider />
      <ArtificialAirway />
    </>
  );
};

export default AirWay;
