import React, { useContext, useEffect, useState } from "react";
import { Form, Radio } from "antd";
import {
  coordinationOptions,
  coordinationTests,
  intactImpairedOptions,
} from "../../../../Options/systemicExamOptions";

const Coordination = ({ context }) => {
  const { formData } = useContext(context);
  const [showAbnormal, setShowAbnormal] = useState(
    formData?.systemic_examination?.coordination === "abnormal" ? true : false
  );
  const handleCoordinationChange = (e) => {
    setShowAbnormal(e.target.value === "abnormal");
  };
  useEffect(() => {
    setShowAbnormal(
      formData?.systemic_examination?.coordination === "abnormal" ? true : false
    );
  }, [formData]);

  return (
    <>
      <Form.Item label="Co-ordination" name="coordination">
        <Radio.Group
          options={coordinationOptions}
          onChange={handleCoordinationChange}
        />
      </Form.Item>

      {showAbnormal && (
        <div className="subsection-card">
          {coordinationTests.map((test) => (
            <Form.Item label={test.label} name={test.name}>
              <Radio.Group options={intactImpairedOptions} />
            </Form.Item>
          ))}
        </div>
      )}
    </>
  );
};

export default Coordination;
