import React, { useContext, useState, useEffect } from "react";
import { Form, message, Button } from "antd";
import { UserContext } from "../../../../context/UserContext";
import useQuery from "../../../../hooks/useQuery";
import { getCookie } from "../../../../helpers/localStorage";
import { FacilityID } from "../../../../constants/defaultKeys";
import AddInitialMedicine from "../AddMedicine/AddInitialMedicine";
import { medicationPrescribeAPI } from "../../../../services/patient.services";
import dayjs from "dayjs";

const ScanMedicineForm = ({
  item,
  keys,
  handleDelete,
  index,
  refreshPage,
  initial,
}) => {
  const userDetails = useContext(UserContext);
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [form] = Form.useForm();
  const [matched, setMatched] = useState({});

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        ...item,
        name: item?.name,
        // time: item?.schedule?.time,
      });
    }
  }, [item]);

  const onFinish = (values) => {
    setLoading(true);
    const times = values?.schedule?.times.map((time) =>
      dayjs(time)?.format("HH")
    );
    const { schedule, ...restValues } = values;
    const formData = {
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      ip_admission: Number(params?.patient),
      is_initial: true,
      medicine: selectedId || matched?.key,
      schedule: {
        times: times,
      },
      ...restValues,
    };

    medicationPrescribeAPI(formData)
      .then((res) => {
        message.success(res?.data?.message);
        setLoading(false);
        handleDelete(index);
        if (!initial) {
          refreshPage();
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(err?.response?.data?.message);
      });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <AddInitialMedicine
        editData={item}
        form={form}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setMatched={setMatched}
        matched={matched}
      />
      <div className="flex-end">
        {(Object?.keys(matched)?.length > 0 || selectedId) && (
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Done
            </Button>
            {keys?.length > 1 && (
              <Button
                type="primary"
                danger
                ghost
                onClick={() => handleDelete(index)}
                style={{ marginLeft: "10px" }}
              >
                Delete
              </Button>
            )}
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

export default ScanMedicineForm;
