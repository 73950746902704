import React, { useContext, useEffect } from "react";
import Bmi from "../ClinicalEvaluation/Bmi";
import NutritionalRisk from "../ClinicalEvaluation/NutritionalRisk";
import { Divider } from "antd";
import NutritionRiskFactors from "../ClinicalEvaluation/NutritionRiskFactors";
import CaloriesAndProtien from "./CaloriesAndProtien";
import TypeOfDiet from "./TypeOfDiet";
import OtherSpecifications from "./OtherSpecifications";
import PatientFamilyEducation from "./PatientFamilyEducation";
import { RiskAssessmentContext } from "../../Nutritionist/RiskAssessment/RiskAssessmentFormProvider";

const NutritionistRiskAssessmentForm = ({ form }) => {
  const { formData } = useContext(RiskAssessmentContext);
  useEffect(() => {
    const fieldData = formData?.functional_diagnosis || {
      bmi: formData?.clinical_evaluation?.bmi,
      nutritional_risk_details:
        formData?.clinical_evaluation?.nutritional_risk_details,
      ...formData?.diet_advice,
    };
    form.setFieldsValue(fieldData);
    console.log("fieldData", fieldData);
  }, [formData]);
  return (
    <>
      <Bmi context={RiskAssessmentContext} />
      <Divider />
      <NutritionRiskFactors context={RiskAssessmentContext} />
      <Divider />
      <NutritionalRisk context={RiskAssessmentContext} />
      <Divider />
      <CaloriesAndProtien />
      <Divider />
      <TypeOfDiet context={RiskAssessmentContext} />
      <Divider />
      <OtherSpecifications context={RiskAssessmentContext} />
      <Divider />
      <PatientFamilyEducation />
    </>
  );
};

export default NutritionistRiskAssessmentForm;
