import React from "react";
import {
  hourlyOptions,
  onceTwiceOptions,
  twiceThriceOptions,
} from "../../Options/physioOptions";
import { Form, Radio } from "antd";

const TreatmentPlan = () => {
  return (
    <>
      <Form.Item label="Ambulation Schedule" name={"ambulation_schedule"}>
        <Radio.Group options={twiceThriceOptions} />
      </Form.Item>
      <Form.Item label="Recreation Schedule" name={"recreation_schedule"}>
        <Radio.Group options={onceTwiceOptions} />
      </Form.Item>
      <Form.Item
        label="Position Change Schedule"
        name={"position_change_schedule"}
      >
        <Radio.Group options={hourlyOptions} />
      </Form.Item>
      <Form.Item label="Physiotherapy Schedule" name={"physio_schedule"}>
        <Radio.Group options={onceTwiceOptions} />
      </Form.Item>
    </>
  );
};

export default TreatmentPlan;
