import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Typography,
  Select,
  InputNumber,
  Row,
  Space,
  Switch,
} from "antd";
import {
  bloodGroupOptions,
  genderKeys,
  govtIdTypeOptiions,
  maritalStatusOptions,
  occupationOptions,
} from "../receptionKeys";
import inputStyles from "../../../styles/Custom/Input.module.css";
import PatientMobileSearch from "../PatientMobileSearch";
import { validateConfig } from "../../../config/validate.config";

const BasicDetails = ({
  searchedText,
  setSearchedText,
  selectedId,
  setSelectedId,
  editData,
  form,
  ipData,
}) => {
  const [switched, setSwitched] = useState(false);

  useEffect(() => {
    if (switched) {
      const address = form.getFieldValue("address");
      form.setFieldsValue({ permanent_address: address });
    } else {
      form.setFieldsValue({
        permanent_address: editData?.permanent_address || null,
      });
    }
  }, [switched]);

  return (
    <>
      <Col span={8}>
        <PatientMobileSearch
          searchedText={searchedText}
          setSearchedText={setSearchedText}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          id={editData?.id || ipData?.patient_id}
          form={form}
        />
      </Col>
      <Col span={8}>
        <Form.Item
          label="First Name"
          rules={validateConfig.isRequired("Patient Name")}
          name="first_name"
        >
          <Input
            className={inputStyles.mdInput}
            style={{ width: "300px" }}
            placeholder="Enter First name"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Last Name"
          rules={validateConfig.isRequired("Patient Name")}
          name="last_name"
        >
          <Input
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
            placeholder="Enter Last name"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Age" name={"age"}>
          <InputNumber
            placeholder="Enter age"
            className={inputStyles.mdNumberInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Gender" name={"gender"}>
          <Select
            allowClear
            placeholder="Gender"
            className={inputStyles.mdSelect}
            style={{ width: "300px" }}
            options={genderKeys}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[{ type: "email", message: "Please enter a valid email" }]}
        >
          <Input
            placeholder="Enter email"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Address" name={"address"}>
          <Input.TextArea
            placeholder="Address"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Row>
          <Col span={24}>
            <Form.Item label="Permanent Address" name="permanent_address">
              <Input.TextArea
                placeholder="Permanent Address"
                className={inputStyles.mdInput}
                style={{ width: "80%" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Space>
              <Switch onChange={() => setSwitched(!switched)} />
              <Typography>Same as Address</Typography>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Form.Item label="Blood Group" name="blood_group">
          <Select
            allowClear
            placeholder="Blood Broup"
            className={inputStyles.mdSelect}
            style={{ width: "300px" }}
            options={bloodGroupOptions}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Marital Status" name="marital_status">
          <Select
            allowClear
            placeholder="Marital Status"
            className={inputStyles.mdSelect}
            style={{ width: "300px" }}
            options={maritalStatusOptions}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Occupation" name="occupation">
          <Select
            allowClear
            placeholder="Occupation"
            className={inputStyles.mdSelect}
            style={{ width: "300px" }}
            options={occupationOptions}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Govt ID Type" name="govt_id_type">
          <Select
            allowClear
            placeholder="Govt ID Type"
            className={inputStyles.mdSelect}
            style={{ width: "300px" }}
            options={govtIdTypeOptiions}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Govt ID No" name="govt_id_no">
          <Input className={inputStyles.mdInput} style={{ width: "300px" }} />
        </Form.Item>
      </Col>
    </>
  );
};

export default BasicDetails;
