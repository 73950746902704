import React, { useContext, useEffect, useState } from "react";
import { Form, Radio, Select, Input, Space } from "antd";
import { validateConfig } from "../../../../../config/validate.config";
import {
  informantOptions,
  relationOptions,
} from "../../../Options/historyOptions";

const Informant = ({ context }) => {
  const { formData } = useContext(context);
  const [showInformantDetails, setShowInformantDetails] = useState(
    formData?.history?.informant === "relative" ||
      formData?.patient_demographics?.informant === "relative"
  );

  useEffect(() => {
    setShowInformantDetails(
      formData?.history?.informant === "relative" ||
        formData?.patient_demographics?.informant === "relative"
    );
  }, [formData]);

  const handleInformantChange = (e) => {
    setShowInformantDetails(e.target.value === "relative");
  };

  return (
    <>
      <Form.Item
        label="Informant"
        name="informant"
        rules={validateConfig.isRequired("Informant")}
      >
        <Radio.Group
          options={informantOptions}
          onChange={handleInformantChange}
        />
      </Form.Item>
      {showInformantDetails && (
        <div className="subsection-card">
          <Space size="large">
            <Form.Item
              name="relation_with_patient"
              label="Relation with Patient"
              rules={validateConfig.isRequired("Relation with Patient")}
            >
              <Select className="width-200" options={relationOptions} />
            </Form.Item>
            <Form.Item
              name="informant_name"
              label="Name"
              rules={validateConfig.isRequired("Informant Name")}
            >
              <Input type="text" maxLength={50} />
            </Form.Item>
          </Space>
        </div>
      )}
    </>
  );
};

export default Informant;
