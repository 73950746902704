import { Col, Divider, Modal, Row, Timeline } from "antd";
import ReviewForm from "./ReviewForm";

function ReviewData({
  setReviewModalStatus,
  reviewModalStatus,
  reviewFormItems,
  formData,
  formType,
  data,
}) {
  return (
    <Modal
      footer={false}
      title="Review Data"
      open={reviewModalStatus}
      onCancel={() => setReviewModalStatus(false)}
    >
      <Row gutter={[24, 24]}>
        <Col span={24} style={{ marginTop: "20px" }}>
          {formData !== undefined && formData?.action !== "stop" && (
            <ReviewForm
              formData={formData}
              formType={formType}
              formItems={reviewFormItems}
            />
          )}
        </Col>
        <Divider orientation="left">History</Divider>
        <Col span={24}>
          <Timeline items={data} />
        </Col>
      </Row>
    </Modal>
  );
}

export default ReviewData;
