import React, { useContext, useEffect } from "react";
import { getNutritionistRiskAssessmentAPI } from "../../../../services/nutritionist.services";
import { message } from "antd";
import { RiskAssessmentContext } from "./RiskAssessmentFormProvider";
import NutritionistRiskAssessmentSummary from "../../DailyReviewSummaries/NutritionistRiskAssessmentSummary/NutritionistRiskAssessmentSummary";

const RiskAssessmentSummary = ({ editId, riskAssessmentRefresh }) => {
  const { formData, setFormData } = useContext(RiskAssessmentContext);
  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getNutritionistRiskAssessmentAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
        message.error("Unable to Fetch Data");
      }
    }
  };
  useEffect(() => {
    openEdit();
  }, [editId, riskAssessmentRefresh]);
  return (
    <>
      {formData?.functional_diagnosis && (
        <NutritionistRiskAssessmentSummary formData={formData} />
      )}
    </>
  );
};

export default RiskAssessmentSummary;
