import React, { useEffect, useState } from "react";
import { Checkbox, Form } from "antd";

// This block to be used when a list of checkboxes is to be displayed with radio options for each checkbox
// The radio options are displayed only when the checkbox is checked
// Here is the sample data object to be used.
//  const jointPainLocationOptions = [
//   { label: "Elbow", value: "elbow" },
//   { label: "Wrist", value: "wrist" },
//   { label: "Shoulder", value: "shoulder" },
//   { label: "Hip", value: "hip" },
//   { label: "Knee", value: "knee" },
//   { label: "Ankle", value: "ankle" },
//   { label: "Small joints of hand", value: "small_joints_of_hand" },
//   { label: "Small Joints of Foot", value: "small_joints_of_foot" },
//   { label: "Others", value: "others" },
// ];

//  const jointPainSeverityOptions = [
//   {
//     label: "Left",
//     name_key: "left",
//     options: [
//       { label: "Tenderness", value: "tenderness" },
//       { label: "Swelling", value: "swelling" },
//       { label: "Decreased ROM", value: "decreased_rom" },
//     ],
//   },
//   {
//     label: "Right",
//     name_key: "right",
//     options: [
//       { label: "Tenderness", value: "tenderness" },
//       { label: "Swelling", value: "swelling" },
//       { label: "Decreased ROM", value: "decreased_rom" },
//     ],
//   },
// ];

const CheckListFBYCheckGroup = ({
  checkBoxOptions = [],
  checkBoxGroupOptions = [],
  setCheckboxes,
  checkboxes,
}) => {
  const handleCheckboxChange = (value) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: !prevCheckboxes[value], // Toggle the checkbox value
    }));
  };

  return (
    <>
      {checkBoxOptions.map((checkbox, index) => (
        <div key={index}>
          <Form.Item name={checkbox.value} valuePropName="checked">
            <Checkbox
              checked={checkboxes[checkbox.value]}
              onChange={() => handleCheckboxChange(checkbox.value)}
            >
              {checkbox.label}
            </Checkbox>
          </Form.Item>
          {checkboxes[checkbox.value] &&
            checkBoxGroupOptions.map((checkboxGroupItem, groupIndex) => (
              <div key={groupIndex} style={{ marginLeft: "40px" }}>
                <Form.Item
                  label={checkboxGroupItem.label}
                  name={`${checkbox.value}_${checkboxGroupItem.name_key}`}
                >
                  <Checkbox.Group options={checkboxGroupItem.options} />
                </Form.Item>
              </div>
            ))}
        </div>
      ))}
    </>
  );
};

export default CheckListFBYCheckGroup;
