import { Descriptions } from "antd";
import React from "react";
import {
  getLabel,
  getLabelTag,
  getLabelTagInput,
} from "../constructTextFromValues";
import {
  informantOptions,
  relationOptions,
} from "../../Options/historyOptions";

const PsychologistPatientDemographicSummary = ({ formData }) => {
  const constructDemographicSummaryItems = () => {
    const demographicSummaryItems = [];
    const demographic = formData?.patient_demographics || {};
    const pushDemographicItem = (key, label, children) => {
      demographicSummaryItems.push({ key, label, children });
    };
    if (demographic?.informant) {
      pushDemographicItem(
        "1",
        "Informant",
        <>
          {getLabelTag(informantOptions, demographic.informant)}
          {getLabelTag(relationOptions, demographic.relation_with_patient)}
          {getLabelTagInput("Name", demographic.informant_name)}
        </>
      );
    }
    if (demographic?.education) {
      pushDemographicItem("2", "Education", getLabel(demographic?.education));
    }
    if (demographic?.occupation) {
      pushDemographicItem(
        "3",
        "Occupatiion",
        getLabel(demographic?.occupation)
      );
    }
    if (demographic?.marital_status) {
      pushDemographicItem(
        "4",
        "Marital Status",
        getLabel(demographic?.marital_status)
      );
    }
    if (demographic?.type_of_marriage) {
      pushDemographicItem(
        "5",
        "Type of marriage",
        getLabel(demographic?.type_of_marriage)
      );
    }
    return demographicSummaryItems;
  };
  const demographicSummaryItems = constructDemographicSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={"Patient Demographic Descriptions"}
        items={demographicSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PsychologistPatientDemographicSummary;
