import React, { createContext, useState } from "react";
export const SpeechInitialContext = createContext({});
const SpeechSwallowInitialAssessmentProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <SpeechInitialContext.Provider value={{ formData, setFormData }}>
      {children}
    </SpeechInitialContext.Provider>
  );
};

export default SpeechSwallowInitialAssessmentProvider;
