import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";
import { psychologistTherapyOptions } from "../../Options/psychologistOptions";

const PsychologistTherapiesSuggested = ({ form, context }) => {
  const { formData } = useContext(context);
  const [checboxes, setCheckboxes] = useState({});
  useEffect(() => {
    form.setFieldsValue(formData?.therapies_suggested);
    const initialCheckboxes = psychologistTherapyOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] = formData?.therapies_suggested?.[checkbox.value];
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <CheckListFBYInput
        checkboxes={checboxes}
        setCheckboxes={setCheckboxes}
        options={psychologistTherapyOptions}
      />
    </>
  );
};

export default PsychologistTherapiesSuggested;
