import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  InputNumber,
  message,
  Radio,
  Typography,
} from "antd";

import { tcmOptions } from "../../../Options/physioOptions";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import {
  getTcmScaleAPI,
  tcmScaleApi,
  updateTcmScaleAPI,
} from "../../../../../services/physio.services";

const TCMScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});

  const calculateScore = () => {
    return Object.values(states).reduce((acc, cur) => acc + cur, 0);
  };

  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (scalesId?.tcm) {
        await updateTcmScaleAPI(scalesId?.tcm, finalData);
      } else {
        const response = await tcmScaleApi(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, tcm: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.tcm) {
      getTcmScaleAPI(admission, scalesId?.tcm)
        .then((response) => {
          const formData = response.data;
          form.setFieldsValue(formData);
          console.log("tcm response", formData);
          setStates({
            sitting_balance: formData?.sitting_balance,
            selective_movement_control: formData?.selective_movement_control,
            sitting_to_standing: formData?.sitting_to_standing,
          });
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const aggregate = calculateScore() || 0;

  return (
    <>
      <div className="cardless-scale">
        <Typography className="score-counter">
          {aggregate}
          <br />—<br />
          58
          <div className="score-string">
            {aggregate === 58 ? "Normal Trunk Balance" : "Poor Trunk Balance"}
          </div>
        </Typography>
        <Card style={{ width: "600px" }}>
          <Form id="tcm-scale" onFinish={handleSubmit} form={form}>
            {tcmOptions.map((option) => (
              <Form.Item
                key={option.value}
                label={option.label}
                name={option.value}
              >
                <InputNumber
                  placeholder={`${option.min} to ${option.max}`}
                  max={option.max}
                  min={option.min}
                  style={{ width: "100%" }}
                  onChange={(value) => handleChange(value, option.value)}
                />
              </Form.Item>
            ))}
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default TCMScale;
