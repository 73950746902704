import React, { useState, useRef, useEffect, useContext } from "react";
import { Typography, Row, Col, Form, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PatientOpSearch from "../PatientOpSearch";
import OpBillingFormField from "./OpBillingFormField";
import SummaryBillCards from "./SummaryBillCards";
import useQuery from "../../../hooks/useQuery";
import {
  addBillingApi,
  addPaymentApi,
  getBillDetailsApi,
  getPatientDetailsApi,
} from "../../../services/receptionist.services";
import typographyStyles from "../../../styles/Custom/Typography.module.css";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserContext";

const BillingPage = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const params = useQuery();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [searchedText, setSearchedText] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const [finalDiscountedPrice, setFinalDiscountedPrice] = useState(totalPrice);
  const [partialPayment, setPartialPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState(0);
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    if (!params?.id) {
      if (!partialPayment) {
        form.setFieldsValue({ payment: { amount: finalDiscountedPrice } });
        setPartialPaymentAmount(finalDiscountedPrice);
      }
    }
  }, [finalDiscountedPrice, partialPayment]);

  useEffect(() => {
    if (selectedId || Number(params?.patient_id)) {
      getPatientDetailsApi(selectedId || Number(params?.patient_id))
        .then((res) => {
          form?.setFieldsValue({
            ...res?.data,

            mobile: res?.data?.mobile,
          });

          // setFacilityId(res?.data?.facility);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedId || params?.patient_id]);

  useEffect(() => {
    if (params?.id) {
      getBillDetailsApi(params?.id)
        .then((res) => {
          form?.setFieldsValue({
            ...res?.data?.data,
            // patient_id: params?.id,
            payment: { amount: res?.data?.data?.due_amount },
          });
          setPaymentData(res?.data?.data);
          setSelectedId(res?.data?.data?.op_admission);
          setTotalPrice(Number(res?.data?.data?.gross_amount));
          setFinalDiscountedPrice(
            res?.data?.data?.gross_amount - res?.data?.data?.total_discount
          );
          setPartialPaymentAmount(res?.data?.data?.due_amount);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params?.id]);

  const onSubmit = (values) => {
    setLoading(true);
    if (params?.id) {
      const formData = {
        amount: Number(partialPaymentAmount),
        mode: values?.payment?.mode,
        discount: values?.discount,
      };

      addPaymentApi(params?.id, formData)
        .then((res) => {
          message.success(res.data.message);
          navigate(`/receptionist/billing`);
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.response.data.message);
          setLoading(false);
        });
    } else {
      const amountToSubmit = partialPayment
        ? values?.payment?.amount
        : finalDiscountedPrice;

      const formData = {
        gross_amount: totalPrice,
        total_amount: finalDiscountedPrice,
        total_discount: values?.total_discount,
        service_packages: values?.service_packages,
        payment: { ...values?.payment, amount: amountToSubmit, discount: 0 },
        // partial_payment: partialPayment,
        facility: Number(getCookie(FacilityID) || userDetails?.currFacility),
      };

      if (selectedId || params?.patient_id) {
        addBillingApi(selectedId || params?.patient_id, formData)
          .then((res) => {
            message.success(res.data.message);
            navigate(`/receptionist/billing`);
            setLoading(false);
          })
          .catch((err) => {
            message.error(err.response.data.message);
            setLoading(false);
          });
      } else {
        message.error("Please select a patient from the list");
      }
    }
  };

  const calculateTotalPrice = (values) => {
    if (!params?.id) {
      let total = 0;

      if (values?.service_packages) {
        values?.service_packages?.forEach((service) => {
          const price = service?.price || 0;
          const discount = service?.discount || 0;
          total += price - discount;
        });
      }

      setTotalPrice(total);

      if (values?.total_discount) {
        setFinalDiscountedPrice(total - values?.total_discount);
        if (!partialPayment) {
          form.setFieldsValue({
            payment: { amount: total - values?.total_discount },
          });
        }
      } else {
        setFinalDiscountedPrice(total || 0);
        if (!partialPayment) {
          form.setFieldsValue({ payment: { amount: total || 0 } });
        }
      }
      return total;
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    const totalPrice = calculateTotalPrice({ ...changedValues, ...allValues });
    setFormValues({ ...changedValues, ...allValues });
    form.setFieldsValue({ total_amount: totalPrice });

    if (changedValues?.payment?.amount && partialPayment) {
      setPartialPaymentAmount(changedValues?.payment?.amount);
    }
  };

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Typography className={typographyStyles.subtext1}>
            <LeftOutlined onClick={() => navigate(-1)} /> Create Bill
          </Typography>
        </Col>
        {/* <Col span={12} className="flex-end">
          <Segmented
            size="large"
            options={billingKeys?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            value={activeSegment}
            onChange={(e) => setActiveSegment(e)}
          />
        </Col> */}

        <>
          <Col span={24}>
            <Form
              layout="vertical"
              requiredMark={false}
              form={form}
              ref={formRef}
              initialValues={{ service_packages: [{}] }}
              onFinish={onSubmit}
              onValuesChange={onValuesChange}
            >
              <PatientOpSearch
                searchedText={searchedText}
                setSearchedText={setSearchedText}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                form={form}
                id={params?.id || params?.patient_id}
              />
              <OpBillingFormField
                form={form}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                finalDiscountedPrice={finalDiscountedPrice}
                setFinalDiscountedPrice={setFinalDiscountedPrice}
                id={params?.id}
                paymentData={paymentData}
              />
              <SummaryBillCards
                form={form}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                finalDiscountedPrice={finalDiscountedPrice}
                partialPayment={partialPayment}
                setPartialPayment={setPartialPayment}
                id={params?.id}
                loading={loading}
                paymentData={paymentData}
                partialPaymentAmount={partialPaymentAmount}
              />
            </Form>
          </Col>
        </>
      </Row>
    </>
  );
};

export default BillingPage;
