import { Form, Radio, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  depthOfBreathingOptions,
  oxygenSupportOptions,
  tracheostomyConditionOptions,
} from "../../../../Options/genExamOptions";
import RespiratoryDistress from "./RespiratoryDistress";
import { noYesOptions } from "../../../../Options/commonOptions";

const RespiratoryCondition = ({ context }) => {
  const { formData } = useContext(context);
  const [tracheostomyValid, setTracheostomyValid] = useState(
    formData?.examination?.tracheostomy === "yes"
  );
  useEffect(() => {
    setTracheostomyValid(formData?.examination?.tracheostomy === "yes");
  }, [formData]);
  return (
    <>
      <Typography>Respiratory Condition</Typography>
      <div className="subsection-card">
        <Form.Item label="Depth of Breathing" name="depth_of_breathing">
          <Radio.Group options={depthOfBreathingOptions} />
        </Form.Item>
      </div>
      <div className="subsection-card">
        <RespiratoryDistress />
      </div>
      <div className="subsection-card">
        <Form.Item label="Oxygen Support" name="oxygen_support">
          <Radio.Group options={oxygenSupportOptions} />
        </Form.Item>
      </div>
      <div className="subsection-card">
        <Form.Item label="Tracheostomy" name="tracheostomy">
          <Radio.Group
            options={noYesOptions}
            onChange={(e) => {
              setTracheostomyValid(e.target.value === "yes");
            }}
          />
        </Form.Item>
        {tracheostomyValid && (
          <Form.Item name="tracheostomy_condition">
            <Radio.Group options={tracheostomyConditionOptions} />
          </Form.Item>
        )}
      </div>
    </>
  );
};

export default RespiratoryCondition;
