import React, { useEffect, useContext } from "react";
import { Form, Divider, Input } from "antd";

import PresentIllness from "./PresentIllness/PresentIllness";

import "./HistoryForm.css";
import PastMedicalHistory from "./PastMedicalHistory/PastMedicalHistory";
import KnownAllergies from "./KnownAllergies/KnownAllergies";
import { DailyReviewContext } from "../../Doctor/DailyReview/DailyReviewFormProvider";

const DrHistoryFormDR = ({ form = {} }) => {
  const { formData } = useContext(DailyReviewContext);
  // const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  //   const { formData } = useContext(DoctorHistoryContext);

  //   const handleSubmit = async (values) => {
  //     setLoading(true);
  //     if (formData?.id) {
  //       try {
  //         const formSubmitData = { history: values, admission_id: 1 };
  //         console.log(formData);
  //         await updateDoctorInspectionAPI(formData?.id, formSubmitData);
  //         message.success("Form submitted successfully");
  //       } catch (error) {
  //         message.error(error?.response?.data?.message || "Something went wrong");
  //       } finally {
  //         setLoading(false);
  //       }
  //     } else {
  //       try {
  //         const formSubmitData = { history: values, admission_id: 1 };
  //         console.log(formData);
  //         await doctorInspectionAPI(formSubmitData);
  //         message.success("Form submitted successfully");
  //       } catch (error) {
  //         message.error(error?.response?.data?.message || "Something went wrong");
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     form.setFieldsValue(formData?.history);
  //   }, [formData]);
  // const handleSubmit = (values) => {
  //   console.log("Daily review history form:", values);
  // };
  useEffect(() => {
    form.setFieldsValue(formData?.history);
  }, [formData]);
  return (
    <>
      {/* <Form
        id="daily-review-history-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        // initialValues={formData}
        className="ip-forms"
      > */}
      <PresentIllness context={DailyReviewContext} />
      <Divider />
      <PastMedicalHistory context={DailyReviewContext} />
      <Divider />
      <KnownAllergies context={DailyReviewContext} />
      <Divider />
      <Form.Item name="present_complaints">
        <Input placeholder="Enter Present Complaints" />
      </Form.Item>

      {/* <Button type="primary" htmlType="submit" loading={loading}>
          Save & Proceed
        </Button>
      </Form> */}
    </>
  );
};

export default DrHistoryFormDR;
