import { Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import RPEScale from "../../Examination/PhysioScales/RPEScale";
import SixMinuteWalkTest from "../../Examination/PhysioScales/SixMinuteWalkTest";

const Endurance = ({ context }) => {
  const { formData } = useContext(context);
  const [showEndurance, setShowEndurance] = useState(
    formData?.functional_status?.endurance === "assessable"
  );
  useEffect(() => {
    setShowEndurance(formData?.functional_status?.endurance === "assessable");
  }, [formData]);
  return (
    <>
      <Form.Item label="Endurance" name="endurance">
        <Radio.Group
          options={notAssessableAndAssessableOptions}
          onChange={(e) => {
            setShowEndurance(e.target.value === "assessable");
          }}
        />
      </Form.Item>
      {showEndurance && (
        <>
          <RPEScale />
          <SixMinuteWalkTest />
        </>
      )}
    </>
  );
};

export default Endurance;
