import { Descriptions } from "antd";
import React from "react";
import { RespiratoryReviewContext } from "../../RespiratoryTherapist/Review/RespiratoryTherapistReviewProvider";
import {
  getLabel,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithDescription,
} from "../../InspectionSummaries/constructTextFromValues";
import {
  breathSoundOptions,
  coughOptions,
  oxygenRequiredOptions,
  respiratoryPatternOptions,
  trachealConsistencyOptions,
  trachealVolumeOptions,
  tracheostomyTubeOptions,
} from "../../Options/RespiratoryTherapistOptions";

const PatientAssessmentReviewSummary = ({ formData }) => {
  const constructPatientAssessmentSummaryItems = () => {
    const patientAssessmentSummaryItems = [];
    const patient = formData?.patient_assessment;
    const pushPatientItem = (key, label, children) => {
      patientAssessmentSummaryItems.push({ key, label, children });
    };
    if (patient?.respiratory_details?.pattern) {
      pushPatientItem(
        "3",
        "Respiratory Pattern",
        <>
          {getLabelTag(
            respiratoryPatternOptions,
            patient?.respiratory_details?.pattern
          )}
          {getLabelTagInput("Comments", patient?.respiratory_details?.comments)}
        </>
      );
    }
    if (patient?.breath_sounds?.status) {
      pushPatientItem(
        "6",
        "Breath Sounds",
        <>
          {getLabelTag(breathSoundOptions, patient?.breath_sounds?.status)}
          {getLabelTagInput("Comments", patient?.breath_sounds?.comments)}
        </>
      );
    }
    if (patient?.cough?.status) {
      pushPatientItem(
        "10",
        "Cough",
        <>
          {getLabelTag(coughOptions, patient?.cough?.status)}
          {getLabelTagInput("Comments", patient?.cough?.comments)}
        </>
      );
    }
    if (patient?.oxygen_required?.status) {
      pushPatientItem(
        "9",
        "O2 Required for spO2>92%",
        <>
          {getLabelTag(oxygenRequiredOptions, patient?.oxygen_required?.status)}
          {getLabelTagInput("Comments", patient?.oxygen_required?.comments)}
        </>
      );
    }

    if (
      patient?.tracheal_secretions?.volume ||
      patient?.tracheal_secretions?.consistency
    ) {
      pushPatientItem(
        "7",
        "Tracheal Secretions",
        <>
          {patient?.tracheal_secretions?.volume && (
            <>
              {getLabel("Volume")}
              {getLabelTagsWithDescription(
                trachealVolumeOptions,
                patient?.tracheal_secretions_volume
              )}
            </>
          )}
          {patient?.tracheal_secretions?.consistency && (
            <>
              {getLabel("Consistency")}
              {getLabelTagsWithDescription(
                trachealConsistencyOptions,
                patient?.tracheal_secretions_consistency
              )}
            </>
          )}
        </>
      );
    }
    if (patient?.tracheostomy_tube?.status) {
      pushPatientItem(
        "9",
        "Tracheostomy Tube",
        <>
          {getLabelTag(
            tracheostomyTubeOptions,
            patient?.tracheostomy_tube?.status
          )}
          {getLabelTagInput("Comments", patient?.tracheostomy_tube?.comments)}
        </>
      );
    }

    return patientAssessmentSummaryItems;
  };
  const patientAssessmentSummaryItems =
    constructPatientAssessmentSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Patient Assessment"
        items={patientAssessmentSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PatientAssessmentReviewSummary;
