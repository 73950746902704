import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import { Typography } from "antd";
import { softTissueInjuryAreaOptions } from "../../../../Options/systemicExamOptions";
const { Text } = Typography;
const SoftTissueInjury = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    const initialCheckboxes = softTissueInjuryAreaOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          initialValues?.systemic_examination?.soft_tissue_injury?.[
            checkbox.value
          ] ||
          initialValues?.examination?.soft_tissue_injury?.[checkbox.value] ||
          false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Text>Soft Tissue Injury</Text>
      <CheckListFBYInput
        options={softTissueInjuryAreaOptions}
        setCheckboxes={setCheckboxes}
        checkboxes={checkboxes}
        keyChain="soft_tissue_injury"
      />
    </>
  );
};

export default SoftTissueInjury;
