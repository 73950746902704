import React, { useContext, useEffect, useMemo, useState } from "react";
import { Form, Radio } from "antd";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { noYesOptions } from "../../../../Options/commonOptions";
import { limbDeformityOptions } from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
const LimbDeformity = ({ context }) => {
  const { formData } = useContext(context ? context : DoctorInitialContext);
  const initialValues = useMemo(() => formData, [formData]);
  const [checkboxes, setCheckboxes] = useState([]);

  const [showLimbDeformityDetails, setShowLimbDeformityDetails] = useState(
    formData?.systemic_examination?.limb_deformity === "yes"
  );

  const handleDeformityChange = (e) => {
    setShowLimbDeformityDetails(e.target.value === "yes");
  };

  useEffect(() => {
    setShowLimbDeformityDetails(
      formData?.systemic_examination?.limb_deformity === "yes"
    );
    const initialCheckboxes = limbDeformityOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <Form.Item label="Limb Deformity" name="limb_deformity">
        <Radio.Group options={noYesOptions} onChange={handleDeformityChange} />
      </Form.Item>
      {showLimbDeformityDetails && (
        <div className="subsection-card">
          <CheckListFBYInput
            options={limbDeformityOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default LimbDeformity;
