import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RespiratoryReviewContext } from "./RespiratoryTherapistReviewProvider";
import {
  getLatestRespiratoryReviewAPI,
  getRespiratoryInspectionAPI,
  getRespiratoryReviewAPI,
  respiratoryReviewAPI,
  updateRespiratoryReviewAPI,
} from "../../../../services/respiratory.services";
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Modal,
  Row,
  Tabs,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import RespiratoryPatientAssessment from "../../InspectionForms/PatientAssessment/RespiratoryPatientAssessment";
import RespiratoryTherapistTreatmentPlan from "../../InspectionForms/TreatmentPlan/RespiratoryTherapistTreatmentPlan";

const RespiratoryTherapistReviewForm = ({
  editId,
  visible,
  setVisible,
  refreshPage,
  iAssessment,
  addMode,
  setAddMode,
  setActiveSegment,
  reviewData,
  setDailyReviewRefresh,
  dailyRefresh,
  segmentData,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [patientForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const { formData, setFormData } = useContext(RespiratoryReviewContext);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);
  const newReview = reviewData?.length === 0;
  const reviewId = reviewData?.[reviewData.length - 1]?.id;
  const newReviewId = addMode === false && reviewId ? reviewId : null;
  console.log("Segment Data in form", segmentData);
  const reviewDate =
    segmentData?.find(
      (option) => option.value === editId && option.value !== "others"
    )?.label ||
    new Date().toLocaleDateString("en-US", { month: "short", day: "2-digit" });

  const handleSubmit = async (values) => {
    try {
      if (editId || newReviewId) {
        await updateRespiratoryReviewAPI(editId || newReviewId, values);
        setDailyReviewRefresh();
      } else {
        await respiratoryReviewAPI(values);
        refreshPage();
        setAddMode(false);
        setDailyReviewRefresh();
      }

      message.success("Form submitted successfully");

      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onPatientSubmit = (values) => {
    handleSubmit({
      ...formData,
      patient_assessment: values,
      admission_id: params?.patient,
    });
  };

  const onTreatmentSubmit = (values) => {
    handleSubmit({
      ...formData,
      treatment_plan: values,
      admission_id: params?.patient,
    });
  };

  const openEdit = async () => {
    if (editId && newReview === false) {
      try {
        const response = await getRespiratoryReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else if (newReview === false) {
      try {
        const response = await getLatestRespiratoryReviewAPI(params?.patient);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else {
      try {
        const response = await getRespiratoryInspectionAPI(iAssessment);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return patientForm.submit();
      case "2":
        return treatmentForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      switch (activeTab) {
        case "1":
          patientForm.validateFields().then((values) => {
            setIsDirty(false);
            onPatientSubmit(values);
            setActiveTab(key);
          });
          break;
        case "2":
          treatmentForm.validateFields().then((values) => {
            setIsDirty(false);
            onTreatmentSubmit(values);
            setActiveTab(key);
          });
          break;
        default:
          setActiveTab(key);
      }
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <div>
      <Drawer
        open={visible}
        width="100%"
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
                ({reviewDate})
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  Modal.confirm({
                    title: "Confirmation",
                    content: "Are you sure you want to close?",
                    onOk: () => {
                      setVisible(false);
                    },
                  });
                }}
              />
            </Col>
          </Row>
        }
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                getFormKey();
                const nextTab = (parseInt(activeTab) + 1).toString();
                if (activeTab === "2") {
                  setVisible(false);
                } else {
                  setActiveTab(nextTab);
                }
              }}
              type="primary"
            >
              {activeTab === "2" ? "Done" : " Save & Proceed"}
            </Button>
            {"  "}
            {activeTab !== "2" && (
              <Button
                onClick={() => {
                  getFormKey();
                  setVisible(false);
                }}
                type="primary"
              >
                Save & Close
              </Button>
            )}
          </div>
        }
        onClose={() => {
          setVisible(false);
          setActiveSegment(reviewData[0]?.id);
        }}
        closeIcon={null}
        keyboard={false}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Patient Assessment" key="1">
            <Form
              id="review-patient-assessment"
              form={patientForm}
              layout="vertical"
              onFinish={onPatientSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryPatientAssessment
                review={true}
                form={patientForm}
                context={RespiratoryReviewContext}
              />
            </Form>
          </TabPane>
          <TabPane tab="Treatment Plan" key="2">
            <Form
              id="review-treatment-plan"
              form={treatmentForm}
              layout="vertical"
              onFinish={onTreatmentSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryTherapistTreatmentPlan
                form={treatmentForm}
                context={RespiratoryReviewContext}
              />
            </Form>
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

export default RespiratoryTherapistReviewForm;
