import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton } from "antd";
import PatientGalleryUpload from "./PatientGalleryUpload";
import { getPatientUploadsApi } from "../../../services/patient.services";
import useQuery from "../../../hooks/useQuery";
import PatientImageView from "./PatientImageView";

const PatientGallery = () => {
  const [fileList, setFileList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useQuery();

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    getPatientUploadsApi(params?.patient)
      .then((res) => {
        setFileList(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} style={{ textAlign: "right" }}>
        <PatientGalleryUpload refresh={refreshPage} />
      </Col>
      <Col span={24}>
        {loading ? (
          <Skeleton active />
        ) : (
          <PatientImageView data={fileList} loading={loading} />
        )}
      </Col>
    </Row>
  );
};
export default PatientGallery;
