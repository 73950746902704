import React, { useEffect } from "react";
import Goals from "./Goals";
import { NutritionistInitialContext } from "../HistoryForm/NutritionistInitialAssessmentProvider";

const NutritionistGoalsForm = () => {
  return (
    <>
      <Goals context={NutritionistInitialContext} />
    </>
  );
};

export default NutritionistGoalsForm;
