import { Flex, Layout, Typography, Image, Select } from "antd";
import React, { useContext, useState } from "react";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import UserSettings from "./UserSettings";
import { UserContext } from "../../../context/UserContext";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { useLocation } from "react-router-dom";
import { FacilityID } from "../../../constants/defaultKeys";
import "./CustomLayout.css";
import Search from "./Search";

const { Header, Footer, Content, Sider } = Layout;
const { Option } = Select;

const CommonLayout = ({
  children,
  header,
  footer,
  sideContent,
  headerExtra = true,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [collapsed, setCollapsed] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [selectedFacility, setSelectedFacility] = useState(
    Number(getCookie(FacilityID)) || userDetails?.currFacility
  );

  const handleFacilityChange = (value) => {
    setSelectedFacility(value);
    setUserDetails((prev) => ({ ...prev, currFacility: value }));
    setCookie(
      FacilityID,
      value,
      `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
      "/"
    );
  };

  return (
    <Layout className={"layoutContent"}>
      {sideContent && (
        <Sider
          className={collapsed ? `siderCollapse` : `siderNoCollapse`}
          collapsed={collapsed}
          onCollapse={(e) => setCollapsed(e)}
          collapsible
          breakpoint="md"
        >
          <Flex
            style={{
              height: "64px",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingInline: "24px",
            }}
          >
            <Typography
              className={collapsed ? typoStyles.subtitle2 : typoStyles.h6}
            >
              {userDetails?.activeOrg?.org_logo ? (
                <Image
                  preview={false}
                  src={userDetails?.activeOrg?.org_logo}
                  alt={"Logo"}
                  style={{
                    width: "50px",
                    marginTop: "10px",
                  }}
                />
              ) : (
                userDetails?.activeOrg?.org_name
              )}
            </Typography>
          </Flex>
          <div style={{ height: "calc(100vh - 69px)", paddingBlock: "24px" }}>
            {sideContent}
          </div>
        </Sider>
      )}
      <Layout
        className={
          sideContent
            ? collapsed
              ? "contentPushWithSideandCollapse"
              : "contentPushWithSideandNoCollapse"
            : "contentPushWithoutSide"
        }
      >
        <Header
          className={`headerStyle ${
            sideContent
              ? collapsed
                ? "headerWithSideAndCollapse"
                : "headerWithSideAndNoCollapse"
              : "headerStylesWithoutSide"
          }`}
        >
          <div
            className="flex-between"
            style={{ height: "64px", paddingInline: "24px" }}
          >
            <div className="tabWebDisplay" style={{ width: "100%" }}>
              {header}
            </div>
            {headerExtra && (
              <div className="flex-end" style={{ gap: "24px" }}>
                {/* <Search selectedFacility={selectedFacility} /> */}
                {(userDetails?.activeOrg?.default_role === "Facility_Admin" ||
                  userDetails?.activeOrg?.default_role === "Receptionist") && (
                  <Select
                    defaultValue={selectedFacility}
                    onChange={handleFacilityChange}
                    style={{ width: "250px" }}
                  >
                    {userDetails?.activeOrg?.facilities?.map((facility) => (
                      <Option
                        value={facility?.facility_id}
                        key={facility?.facility_id}
                      >
                        {facility?.facility}
                      </Option>
                    ))}
                  </Select>
                )}
                <UserSettings />
              </div>
            )}
          </div>
        </Header>

        <Content
          className={"contentStyle"}
          style={{
            paddingBottom: footer ? "132px" : "32px",
          }}
        >
          {children}
        </Content>
        {footer && (
          <Footer
            className={`footerStyle ${
              sideContent
                ? collapsed
                  ? "footerStyleWithCollapsedSide"
                  : "footerStyleWithNOCollapsedSide"
                : "footerStyleWithOutSide"
            }`}
          >
            {footer}
          </Footer>
        )}
      </Layout>
    </Layout>
  );
};

export default CommonLayout;
