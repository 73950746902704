import React, { useEffect, useState } from "react";
import {
  Form,
  InputNumber,
  Button,
  Collapse,
  Radio,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ServiceSearch from "../../Dashboard/Services/Packages/ServiceSearch";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { getPackages } from "../../../services/service.services";
import ServicePackageTable from "./ServicePackageTable";
import PackageTable from "./PackageTable";

const { Option } = Select;

const OpBillingFormField = ({
  form,
  totalPrice,
  setTotalPrice,
  finalDiscountedPrice,
  setFinalDiscountedPrice,
  id,
  paymentData,
}) => {
  const [packages, setPackages] = useState([]);
  const [typeMap, setTypeMap] = useState({});
  const [previousPrices, setPreviousPrices] = useState({});
  const [selectPackageId, setSelectPackageId] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [packagePrice, setPackagePrice] = useState(0);

  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleTypeChange = (key, value) => {
    const currentRow = form.getFieldValue("service_packages")[key];

    // Subtract the current price from the total before switching type
    if (currentRow.price) {
      setTotalPrice((prevTotal) => prevTotal - currentRow.price);
      setFinalDiscountedPrice((prevTotal) => prevTotal - currentRow.price);
    }

    setTypeMap((prevTypeMap) => ({ ...prevTypeMap, [key]: value }));

    form.setFieldsValue({
      service_packages: form
        .getFieldValue("service_packages")
        .map((item, index) => {
          if (index === key) {
            return {
              ...item,
              service_name: undefined,
              package: undefined,
              price: 0,
              discount: 0,
            };
          }
          return item;
        }),
    });

    // Reset previous price for this row
    setPreviousPrices((prev) => ({ ...prev, [key]: 0 }));
  };

  const updatePrice = (rowIndex, newPrice) => {
    setTotalPrice((prevTotal) => {
      const oldPrice = previousPrices[rowIndex] || 0;
      return prevTotal - oldPrice + newPrice;
    });
    setFinalDiscountedPrice((prevTotal) => {
      const oldPrice = previousPrices[rowIndex] || 0;
      return prevTotal - oldPrice + newPrice;
    });

    // Update previousPrices with the new price
    setPreviousPrices((prev) => ({ ...prev, [rowIndex]: newPrice }));
  };

  const handlePackageChange = (name, value) => {
    setSelectPackageId(value);
    const selectedPackage = packages.find((item) => item.id === value);
    if (selectedPackage) {
      const newPrice = Number(selectedPackage.total_price);
      setPackagePrice(newPrice);
      form.setFieldsValue({
        service_packages: form
          .getFieldValue("service_packages")
          .map((user, index) =>
            index === name ? { ...user, price: newPrice } : user
          ),
      });

      setPreviousPrices((prev) => ({ ...prev, [name]: newPrice }));
      updatePrice(name, newPrice);
    }
  };

  const handleRemove = (name) => {
    // Update previousPrices by removing the entry for the deleted row
    setPreviousPrices((prev) => {
      const updatedPrices = { ...prev };
      delete updatedPrices[name];
      return updatedPrices;
    });

    // Update the total and final prices after row deletion
    setTotalPrice((prevTotal) => prevTotal - (previousPrices[name] || 0));
    setFinalDiscountedPrice(
      (prevTotal) => prevTotal - (previousPrices[name] || 0)
    );
  };
  console.log(packagePrice);
  return (
    <Collapse expandIconPosition="right" bordered={true} defaultActiveKey={"1"}>
      <Collapse.Panel header="Services/Packages" key="1">
        {id ? (
          <ServicePackageTable paymentData={paymentData} />
        ) : (
          <Form.List name="service_packages">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, "type"]}
                      initialValue="service"
                      rules={[
                        {
                          required: true,
                          message: "Please select a service type",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={(e) => handleTypeChange(key, e.target.value)}
                        disabled={id}
                      >
                        <Radio value="service">Services</Radio>
                        <Radio value="package">Packages</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Row>
                      <Col span={8}>
                        {typeMap[key] === "package" ? (
                          <Form.Item
                            {...restField}
                            name={[name, "package"]}
                            label="Select Package"
                            rules={[
                              {
                                required: true,
                                message: "Please select a package",
                              },
                            ]}
                          >
                            <Select
                              disabled={id}
                              className={inputStyles.mdSelect}
                              style={{ width: "300px" }}
                              onChange={(value) =>
                                handlePackageChange(name, value)
                              }
                            >
                              {packages?.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <ServiceSearch
                            id={id}
                            form={form}
                            name={name}
                            totalPrice={totalPrice}
                            setTotalPrice={setTotalPrice}
                            type="billing"
                            finalDiscountedPrice={finalDiscountedPrice}
                            setFinalDiscountedPrice={setFinalDiscountedPrice}
                          />
                        )}
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          label="Price"
                          name={[name, "price"]}
                        >
                          <InputNumber
                            placeholder="Price"
                            prefix="Rs"
                            className={`${inputStyles.mdNumberInput}`}
                            style={{ width: "300px" }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...restField}
                          label="Discount"
                          name={[name, "discount"]}
                          rules={[
                            {
                              validator: (_, value) => {
                                const currentPrice = form.getFieldValue([
                                  "service_packages",
                                  name,
                                  "price",
                                ]);
                                if (value && value > currentPrice) {
                                  return Promise.reject(
                                    new Error(
                                      "Discount cannot be greater than the price"
                                    )
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            disabled={id}
                            placeholder="Enter Discount"
                            className={`${inputStyles.mdNumberInput}`}
                            style={{ width: "300px" }}
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      {fields.length > 1 && (
                        <Col span={2}>
                          <Button
                            onClick={() => {
                              handleRemove(name);
                              remove(name);
                            }}
                            style={{ marginTop: "25px" }}
                          >
                            <DeleteOutlined style={{ color: "red" }} />
                          </Button>
                        </Col>
                      )}
                    </Row>
                    {selectPackageId && (
                      <>
                        {!viewDetails && (
                          <Typography.Link onClick={() => setViewDetails(true)}>
                            View More Details
                          </Typography.Link>
                        )}

                        {viewDetails && (
                          <PackageTable
                            selectPackageId={selectPackageId}
                            setSelectPackageId={setSelectPackageId}
                            setViewDetails={setViewDetails}
                            viewDetails={viewDetails}
                          />
                        )}
                      </>
                    )}
                  </div>
                ))}
                {!id && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add new item
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default OpBillingFormField;
