import { Form, Input, Radio } from "antd";
import React from "react";
import { coughOptions } from "../../Options/RespiratoryTherapistOptions";

const Cough = () => {
  return (
    <>
      <Form.Item label="Cough" name={["cough", "status"]}>
        <Radio.Group options={coughOptions} />
      </Form.Item>
      <Form.Item name={["cough", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default Cough;
