import React, { Fragment, useContext, useEffect } from "react";
import { extractDomainFromUrl } from "../helpers/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";

function AuthProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const test = useContext(UserContext);
  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    const currentDomain = window.location.hostname;
    const mainDomainUrl = process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL;
    const mainDomain = extractDomainFromUrl(mainDomainUrl);
    const path = window.location.pathname;

    const isUnProtectedRoute =
      path.startsWith("/auth") ||
      path.startsWith("/payment") ||
      path === "/" ||
      path === "/unsubscribe" ||
      path === "/verification" ||
      path == "/doctor-forms" ||
      path === "/logout" ||
      path === "/time-formats";

    if (
      !currentDomain.includes(process.env.REACT_APP_PUBLIC_DOMAIN) &&
      process.env.REACT_APP_PUBLIC_ENV !== "STAGING"
    ) {
      // If the current domain doesn't match the public domain and it's not a staging environment, navigate to the home page
      navigate("/");
    } else if (
      (currentDomain === mainDomain && isUnProtectedRoute) ||
      ["/404", "/500", "/domain", "/login", "logout"].includes(path) ||
      /^\/otp\/[a-zA-Z0-9_-]+$/.test(path)
    ) {
      // If it's the main domain and the path is an unprotected route or one of the specified paths, do nothing
    } else if (currentDomain !== mainDomain && isUnProtectedRoute) {
      // If it's not the main domain and it's an unprotected route, navigate to the dashboard home page
      if (path === "/" && Object.keys(userDetails).length === 0) {
        window.location.href = `${mainDomainUrl}/`;
      }
      navigate("/dashboard/home");
    } else {
      if (Object.keys(userDetails).length === 0) {
        window.location.href = `${mainDomainUrl}/`;
      } else if (
        process.env.REACT_APP_PUBLIC_ENV !== "STAGING" &&
        currentDomain?.toLowerCase() !==
          `${userDetails?.activeOrg?.domain?.toLowerCase()}.${
            process.env.REACT_APP_PUBLIC_DOMAIN
          }`
      ) {
        // If it's not any of the above cases, redirect to the main domain's 404 page
        window.location.href = `${mainDomainUrl}/404`;
      }
    }
  }, [location, userDetails]);

  return <Fragment>{children}</Fragment>;
}

export default AuthProvider;
