import React from "react";
import { Form, Radio } from "antd";
import { builtOptions } from "../../../../Options/genExamOptions";

const Built = () => {
  return (
    <>
      <Form.Item label="Built" name="built" rules={[]}>
        <Radio.Group options={builtOptions} />
      </Form.Item>
    </>
  );
};

export default Built;
