import React, { useContext, useEffect, useState } from "react";
import { Form, Radio } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";
import {
  contractureAreas,
  leftRightOptions,
} from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const Contractures = ({ context }) => {
  const { formData } = useContext(context ? context : DoctorInitialContext);
  const [showAreas, setShowAreas] = useState(
    formData?.systemic_examination?.contractures === "yes" ? true : false
  );
  const handleContracturesChange = (e) => {
    setShowAreas(e.target.value === "yes");
  };
  useEffect(() => {
    setShowAreas(
      formData?.systemic_examination?.contractures === "yes" ? true : false
    );
  }, [formData]);

  return (
    <>
      <Form.Item name="contractures" label="Contractures">
        <Radio.Group
          options={noYesOptions}
          onChange={handleContracturesChange}
        />
      </Form.Item>
      {showAreas && (
        <div className="subsection-card">
          {contractureAreas.map((area) => (
            <Form.Item
              key={area}
              name={`contractures_${area.toLowerCase()}`}
              label={area}
            >
              <Radio.Group options={leftRightOptions} />
            </Form.Item>
          ))}
        </div>
      )}
    </>
  );
};
export default Contractures;
