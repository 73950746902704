import { Checkbox, Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { nutritionRiskFactorsOptions } from "../../Options/clinicalEvaluationOptions";

const NutritionRiskFactors = ({ context }) => {
  const { formData } = useContext(context);
  const [showOthers, setShowOthers] = useState(
    formData?.functional_diagnosis?.nutrition_risk_factor_details?.risk_factors?.includes(
      "others"
    )
  );
  const handleChange = (values) => {
    setShowOthers(values?.includes("others"));
  };
  useEffect(() => {
    setShowOthers(
      formData?.functional_diagnosis?.nutrition_risk_factor_details?.risk_factors?.includes(
        "others"
      )
    );
  }, [formData]);
  return (
    <>
      <Form.Item
        label="Nutrition Risk Factors"
        name={["nutrition_risk_factor_details", "risk_factors"]}
      >
        <Checkbox.Group
          options={nutritionRiskFactorsOptions}
          onChange={handleChange}
        />
      </Form.Item>
      {showOthers && (
        <Form.Item
          name={["nutrition_risk_factor_details", "others_description"]}
        >
          <Input
            placeholder="Please enter the description"
            autoComplete="off"
          />
        </Form.Item>
      )}
    </>
  );
};

export default NutritionRiskFactors;
