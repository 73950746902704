import React, { useContext, useEffect, useMemo, useState } from "react";
import { Form, Radio } from "antd";

import CheckListFBYRadioItems from "../../../../../../blocks/FormItems/CheckListFBYRadioItems";
import {
  motorAbnormalRadioItems,
  motorSystemAbnormalOptions,
  motorSystemOptions,
} from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const MotorSystem = ({ context }) => {
  const { formData } = useContext(context);
  const initialValues = useMemo(() => formData, [formData]);
  const [showAbnormal, setShowAbnormal] = useState(
    initialValues?.systemic_examination?.motor_system === "abnormal"
      ? true
      : false
  );
  const [checkboxes, setCheckboxes] = useState([]);

  useEffect(() => {
    setShowAbnormal(
      initialValues?.systemic_examination?.motor_system === "abnormal"
        ? true
        : false
    );
    const initialCheckboxes = motorSystemAbnormalOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          initialValues?.systemic_examination?.[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);
  return (
    <>
      <Form.Item label="Motor System" name="motor_system">
        <Radio.Group
          options={motorSystemOptions}
          onChange={(e) => setShowAbnormal(e.target.value === "abnormal")}
        />
      </Form.Item>

      {showAbnormal && (
        <div className="subsection-card">
          <CheckListFBYRadioItems
            checkBoxOptions={motorSystemAbnormalOptions}
            radioOptions={motorAbnormalRadioItems}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default MotorSystem;
