import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../../../blocks/FormItems/CheckListFBYInput";
import { Form, Radio } from "antd";
import { noYesOptions } from "../../../../Options/commonOptions";
import { limbExtremityOptions } from "../../../../Options/systemicExamOptions";

const Paralysis = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState([]);
  const [showParalysis, setShowParalysis] = useState(
    formData?.examination?.paralysis?.status === "yes"
  );
  useEffect(() => {
    setShowParalysis(formData?.examination?.paralysis?.status === "yes");
    const initialCheckboxes = limbExtremityOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        formData?.examination?.paralysis?.[checkbox.value] || false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);
  return (
    <>
      <Form.Item label="Paralysis" name={["paralysis", "status"]}>
        <Radio.Group
          options={noYesOptions}
          onChange={(e) => {
            setShowParalysis(e.target.value === "yes");
          }}
        />
      </Form.Item>
      {showParalysis && (
        <CheckListFBYInput
          options={limbExtremityOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          keyChain={"paralysis"}
        />
      )}
    </>
  );
};

export default Paralysis;
