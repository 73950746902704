import { Form, Input, Radio } from "antd";
import React from "react";
import { activityLevelOptions } from "../../Options/RespiratoryTherapistOptions";

const ActivityLevel = () => {
  return (
    <>
      <Form.Item label="Activity Level" name={["activity_level", "status"]}>
        <Radio.Group options={activityLevelOptions} />
      </Form.Item>
      <Form.Item name={["activity_level", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default ActivityLevel;
