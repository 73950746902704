import React, { useContext } from "react";
import { psychologistTherapyOptions } from "../../Options/psychologistOptions";
import { Descriptions } from "antd";
import { getLabelTagsWithDescription } from "../constructTextFromValues";

const PsychologistTherapySummary = ({ context }) => {
  const { formData } = useContext(context);
  const constructTherapySummaryItems = () => {
    const therapySummaryItems = [];
    if (formData?.therapies_suggested) {
      therapySummaryItems.push({
        key: "1",
        label: "Therapy",
        children: (
          <>
            {getLabelTagsWithDescription(
              psychologistTherapyOptions,
              formData?.therapies_suggested
            )}
          </>
        ),
      });
    }
    return therapySummaryItems;
  };
  const therapySummaryItems = constructTherapySummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={"Therapies Suggested"}
        items={therapySummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PsychologistTherapySummary;
