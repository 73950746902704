import { Button, Card, Divider, Form, message, Slider } from "antd";
import React, { useEffect, useState } from "react";
import ShoulderJoint from "./ROMScaleComponents/ShoulderJoint";
import ElbowJoint from "./ROMScaleComponents/ElbowJoint";
import ForearmJoint from "./ROMScaleComponents/ForearmJoint";
import WristJoint from "./ROMScaleComponents/WristJoint";
import ThumbJoint from "./ROMScaleComponents/ThumbJoint";
import MetacarpelJoint from "./ROMScaleComponents/MetacarpelJoint";
import ProximalJoint from "./ROMScaleComponents/ProximalJoint";
import DisitalJoint from "./ROMScaleComponents/DisitalJoint";
import HipJoint from "./ROMScaleComponents/HipJoint";
import KneeJoint from "./ROMScaleComponents/KneeJoint";
import AnkleJoint from "./ROMScaleComponents/AnkleJoint";
import FootJoint from "./ROMScaleComponents/FootJoint";
import GreatToeJoint from "./ROMScaleComponents/GreatToeJoint";
import InterPhallengealJoint from "./ROMScaleComponents/InterPhallengealJoint";
import CervicalSpine from "./ROMScaleComponents/CervicalSpine";
import ThoracoLumbarSpine from "./ROMScaleComponents/ThoracoLumbarSpine";
import {
  getRomScaleAPI,
  romScaleApi,
  updateRomScaleAPI,
} from "../../../../../services/physio.services";
import "./PhysioScales.css";

const ROMScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    <ShoulderJoint />,
    <ElbowJoint />,
    <ForearmJoint />,
    <WristJoint />,
    <ThumbJoint />,
    <MetacarpelJoint />,
    <ProximalJoint />,
    <DisitalJoint />,
    <HipJoint />,
    <KneeJoint />,
    <AnkleJoint />,
    <FootJoint />,
    <GreatToeJoint />,
    <InterPhallengealJoint />,
    <CervicalSpine />,
    <ThoracoLumbarSpine />,
  ];

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };
    try {
      if (scalesId?.rom) {
        await updateRomScaleAPI(scalesId?.rom, finalData);
      } else {
        const response = await romScaleApi(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, rom: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      if (currentStep === 15) {
        setScaleTab((prev) => (parseInt(prev) + 1).toString());
      } else {
        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
      }
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.rom) {
      getRomScaleAPI(admission, scalesId?.rom)
        .then((response) => {
          form.setFieldsValue(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const marks = {
    0: (
      <span>
        Shoulder
        <br />
        Joint
      </span>
    ),
    1: (
      <span>
        Elbow
        <br />
        Joint
      </span>
    ),
    2: (
      <span>
        Forearm
        <br />
        Joint
      </span>
    ),
    3: (
      <span>
        Wrist
        <br />
        Joint
      </span>
    ),
    4: (
      <span>
        Thumb
        <br />
        Joint
      </span>
    ),
    5: (
      <span>
        Meta
        <br />
        Carpel
      </span>
    ),
    6: (
      <span>
        Proximal
        <br />
        Interphalangeal
        <br />
        Joints
      </span>
    ),
    7: (
      <span>
        Disital
        <br />
        Inter-
        <br />
        phalangeal
        <br />
        Joints
      </span>
    ),
    8: (
      <span>
        Hip
        <br />
        Joint
      </span>
    ),
    9: (
      <span>
        Knee
        <br />
        Joint
      </span>
    ),
    10: (
      <span>
        Ankle
        <br />
        Joint
      </span>
    ),
    11: (
      <span>
        Foot
        <br />
        Joint
      </span>
    ),
    12: (
      <span>
        Great
        <br />
        Toe
        <br />
        Joint
      </span>
    ),
    13: (
      <span>
        Inter-
        <br />
        phanlangeal
        <br />
        Joint
      </span>
    ),
    14: (
      <span>
        Cervical
        <br />
        Spine
      </span>
    ),
    15: (
      <span>
        Thoraco
        <br />
        Lumbar
        <br />
        Spine
      </span>
    ),
  };

  return (
    <>
      <div className="cardless-scale">
        <Slider
          min={0}
          max={steps.length - 1}
          marks={marks}
          value={currentStep}
          onChange={setCurrentStep}
          disabled={true}
          style={{ width: "90%" }}
        />
        <br />
        <br />
        <br />
        <Form id="rom-scale" form={form} onFinish={handleSubmit}>
          {steps[currentStep]}{" "}
          {/* Display the component based on the current step */}
          {currentStep < steps.length - 1 && <Divider />}
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default ROMScale;
