import React, { useState } from "react";
import { Form, Input, DatePicker, Typography } from "antd";
import dayjs from "dayjs";

const Urobag = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateDifference, setDateDifference] = useState(null);

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
    if (date) {
      const currentDate = new Date();
      const selected = new Date(date);
      const differenceInTime = currentDate.getTime() - selected.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      setDateDifference(differenceInDays);
    } else {
      setDateDifference(null);
    }
  };

  return (
    <>
      <Form.Item label="Urobag Date" name="urobag_datepicker">
        <DatePicker
          disabledDate={(current) => current && current > dayjs().endOf("day")}
          onChange={handleDateChange}
        />
      </Form.Item>
      {selectedDate && (
        <Typography.Text>
          No of cumulative days: {dateDifference} day(s)
        </Typography.Text>
      )}
      <Form.Item label="Comments" name="urobag_comments">
        <Input placeholder="Comments" style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

export default Urobag;
