import React, { useEffect, useState } from "react";
import {
  Form,
  List,
  InputNumber,
  Select,
  Button,
  message,
  Checkbox,
  Typography,
  Modal,
} from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { paymentKeys } from "../../../../components/Receptionist/receptionKeys";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { generateIpBillApi } from "../../../../services/receptionist.services";
import RecordPayments from "../../../Receptionist/Billing/RecordPayments";
import useQuery from "../../../../hooks/useQuery";

const FinalSettlement = ({ billData, refreshData }) => {
  const params = useQuery();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [partialPayment, setPartialPayment] = useState(false);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0); // State for final amount

  const onFinish = (values) => {
    setLoading(true);
    generateIpBillApi(params?.patient, values)
      .then((res) => {
        message.success(res.data.message);
        form.resetFields();
        refreshData();
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  const onValuesChange = (changedValues) => {
    const { discount } = changedValues;

    // Get the due amount from billData
    const dueAmount = billData?.data?.payment?.due_amount;

    // Calculate the final amount based on discount
    const calculatedFinalAmount = dueAmount - (discount || 0);
    setFinalAmount(calculatedFinalAmount);

    // Update the amount field in the form
    form.setFieldsValue({
      amount: calculatedFinalAmount < 0 ? 0 : calculatedFinalAmount, // Ensure it's not negative
    });
  };

  useEffect(() => {
    if (billData?.data?.payment?.due_amount) {
      form.setFieldsValue({
        amount: billData?.data?.payment?.due_amount?.toFixed(2),
      });
      setPartialPaymentAmount(billData?.data?.payment?.due_amount?.toFixed(2));
      setFinalAmount(billData?.data?.payment?.due_amount?.toFixed(2)); // Initialize final amount
    }
  }, [billData]);

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
      >
        <List>
          <List.Item>
            <List.Item.Meta title="Service + Bed Charges" />
            <div>₹ {billData?.data?.payment?.total_amount?.toFixed(2)}/-</div>
          </List.Item>
          <List.Item>
            <List.Item.Meta title="Advance Amount Paid" />
            <div>
              ₹ {billData?.data?.payment?.total_paid_amount?.toFixed(2)}/-
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta title="Due Amount" />
            <div>₹ {billData?.data?.payment?.due_amount?.toFixed(2)}/-</div>
          </List.Item>
          <Typography.Link onClick={() => setOpenModal(true)}>
            View Details
          </Typography.Link>
          <List.Item>
            <List.Item.Meta title="Discount (In rupees)" />
            <Form.Item
              name="discount"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && Number(value) > Number(partialPaymentAmount)) {
                      return Promise.reject(
                        "Discount cannot be greater than amount"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                style={{ width: "300px" }}
                min={0}
                className={inputStyles.mdNumberInput}
              />
            </Form.Item>
          </List.Item>
          <List.Item>
            <List.Item.Meta title="Final Amount" />
            <Form.Item
              name="amount"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      Number(value) >
                        Number(billData?.data?.payment?.due_amount)
                    ) {
                      return Promise.reject(
                        "Amount cannot be greater than total amount"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                prefix="Rs"
                disabled={!partialPayment}
                value={finalAmount} // Set final amount as value
                style={{ width: "300px" }}
                className={inputStyles.mdNumberInput}
              />
            </Form.Item>
          </List.Item>

          <Form.Item name="partial_payment">
            <Checkbox
              checked={partialPayment}
              onChange={(e) => setPartialPayment(e.target.checked)}
            />{" "}
            Partial Payment
          </Form.Item>

          <List.Item>
            <List.Item.Meta title="Payment Mode" />
            <Form.Item
              name="mode"
              rules={[
                { required: true, message: "Please select a payment mode" },
              ]}
            >
              <Select
                style={{ width: "300px" }}
                options={paymentKeys}
                placeholder="Select Payment Mode"
                className={inputStyles.mdSelect}
              />
            </Form.Item>
          </List.Item>
        </List>

        <div style={{ textAlign: "center" }}>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className={btnStyles.lgBtn}
          >
            {partialPayment ? "Generate Receipt" : "Generate Bill"}
          </Button>
        </div>
      </Form>
      {openModal && (
        <Modal
          title="Advance Payment Details"
          open={openModal}
          onCancel={() => setOpenModal(false)}
          footer={null}
          style={{ top: 400 }}
        >
          <RecordPayments paymentData={billData?.data?.payment_details} />
        </Modal>
      )}
    </>
  );
};

export default FinalSettlement;
