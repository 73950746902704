import React from "react";
import { Table, Select, Typography, Form, Row, Col, Card } from "antd";
import { segmentsOptions } from "../../../../Options/physioOptions";

const { Option } = Select;

const upperExtremityOptions = ["c5", "c6", "c7", "c8", "t1"];
const lowerExtremityOptions = ["l2", "l3", "l4", "l5", "s1"];

const motorValues = [1, 2, 3, 4, 5, "NT"];
const sensoryValues = [0, 1, 2, "NT"];

const MotorSensoryScale = ({ scores }) => {
  const data = segmentsOptions.map((segment, index) => ({
    key: index,
    segment: segment.label,
    value: segment.value,
  }));

  const columns = [
    {
      title: "Left Sensory",
      children: [
        {
          title: "Motor",
          dataIndex: "left_motor",
          render: (text, record) =>
            upperExtremityOptions.includes(record.value) ||
            lowerExtremityOptions.includes(record.value) ? (
              <Form.Item
                name={["motor_sensory_scale", "left_motor", record.value]}
              >
                <Select defaultValue={text} style={{ width: 60 }}>
                  {motorValues.map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              "-"
            ),
        },
        {
          title: "LTL",
          dataIndex: "ltl",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ltl", record.value]}>
              <Select defaultValue={text} style={{ width: 60 }}>
                {sensoryValues.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
        {
          title: "PPL",
          dataIndex: "ppl",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ppl", record.value]}>
              <Select defaultValue={text} style={{ width: 60 }}>
                {sensoryValues.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
      ],
    },
    {
      title: "Segment",
      dataIndex: "segment",
      align: "center",
    },
    {
      title: "Right Sensory",
      children: [
        {
          title: "PPR",
          dataIndex: "ppr",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ppr", record.value]}>
              <Select defaultValue={text} style={{ width: 60 }}>
                {sensoryValues.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
        {
          title: "LTR",
          dataIndex: "ltr",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ltr", record.value]}>
              <Select defaultValue={text} style={{ width: 60 }}>
                {sensoryValues.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
        {
          title: "Motor",
          dataIndex: "right_motor",
          render: (text, record) =>
            upperExtremityOptions.includes(record.value) ||
            lowerExtremityOptions.includes(record.value) ? (
              <Form.Item
                name={["motor_sensory_scale", "right_motor", record.value]}
              >
                <Select defaultValue={text} style={{ width: 60 }}>
                  {motorValues.map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              "-"
            ),
        },
      ],
    },
  ];

  return (
    <>
      <Row>
        <Col span={16}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
          />
        </Col>
        {/* <Col span={8}> */}
        {/* <Card> */}
        <Col span={4}>
          <Typography>
            Lower Extremity Motor Score:{" "}
            {(scores.leftLowerMotor || 0) + (scores.rightLowerMotor || 0)}
          </Typography>
          <Typography>
            Left Total Motor Score:{" "}
            {(scores.leftUpperMotor || 0) + (scores.leftLowerMotor || 0)}
          </Typography>
          <Typography>
            Left Total Sensory Score: {(scores.ltl || 0) + (scores.ppl || 0)}
          </Typography>
          <Typography>
            Total LT: {(scores.ltl || 0) + (scores.ltr || 0)}
          </Typography>
        </Col>
        <Col span={4}>
          <Typography>
            Upper Extremity Motor Score:{" "}
            {(scores.leftUpperMotor || 0) + (scores.rightUpperMotor || 0)}
          </Typography>
          <Typography>
            Right Total Motor Score:{" "}
            {(scores.rightLowerMotor || 0) + (scores.rightUpperMotor || 0)}
          </Typography>
          <Typography>
            Right Total Sensory Score: {(scores.ltr || 0) + (scores.ppr || 0)}
          </Typography>
          <Typography>
            Total PP: {(scores.ppl || 0) + (scores.ppr || 0)}
          </Typography>
        </Col>
        {/* </Card> */}
        {/* </Col> */}
      </Row>
    </>
  );
};

export default MotorSensoryScale;
