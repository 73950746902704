import React, { useContext, useEffect, useMemo, useState } from "react";

import { Form, Radio } from "antd";
import CheckListFBYCheckGroup from "../../../../../../blocks/FormItems/CheckListFBYCheckGroup";
import { getInitialValuesForChild } from "../../../../../../helpers/getInitialValues";
import { noneYesOptions } from "../../../../Options/commonOptions";
import {
  jointPainLocationOptions,
  jointPainSeverityOptions,
} from "../../../../Options/systemicExamOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const JointPain = ({ context }) => {
  const { formData } = useContext(context ? context : DoctorInitialContext);
  const initialValues = useMemo(() => formData, [formData]);
  const [showJPOptions, setShowJPOptions] = useState(
    formData?.systemic_examination?.joint_pain === "yes" ? true : false
  );
  const [checkboxes, setCheckboxes] = useState({});

  // useEffect to update state when initialValues change
  useEffect(() => {
    setShowJPOptions(
      initialValues?.systemic_examination?.joint_pain === "yes" ? true : false
    );
    const initialCheckboxes = jointPainLocationOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          getInitialValuesForChild(
            jointPainLocationOptions,
            initialValues?.systemic_examination !== null &&
              initialValues?.systemic_examination
          )[checkbox.value] || false; // Initialize all checkboxes based on initialValues
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [initialValues]);

  return (
    <>
      <Form.Item name="joint_pain" label="Joint Pain">
        <Radio.Group
          options={noneYesOptions}
          onChange={(e) => setShowJPOptions(e.target.value === "yes")}
        />
      </Form.Item>
      {showJPOptions && (
        <div className="subsection-card">
          <CheckListFBYCheckGroup
            checkBoxOptions={jointPainLocationOptions}
            checkBoxGroupOptions={jointPainSeverityOptions}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
    </>
  );
};

export default JointPain;
