import axiosInstance from "../config/axios.config";

export const respiratoryInspectionAPI = (payload) => {
  return axiosInstance.post(
    `inpatient/respiratory-initial-assessment/`,
    payload
  );
};
export const updateRespiratoryInspectionAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/respiratory-initial-assessment/${id}/`,
    payload
  );
};

export const getRespiratoryInspectionAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/respiratory-initial-assessment/${admisssionId}/`
  );
};

export const respiratoryReviewAPI = (payload) => {
  return axiosInstance.post(`inpatient/respiratory-review/`, payload);
};
export const updateRespiratoryReviewAPI = (id, payload) => {
  return axiosInstance.patch(`inpatient/respiratory-review/${id}/`, payload);
};

export const getRespiratoryReviewAPI = (admisssionId) => {
  return axiosInstance.get(`inpatient/respiratory-review/${admisssionId}/`);
};

export const getLatestRespiratoryReviewAPI = (admissionId) => {
  return axiosInstance.get(
    `inpatient/latest-respiratory-review/?admission_id=${admissionId}`
  );
};
