import { Form, Select } from "antd";
import React from "react";
import {
  asiaImpairmentGradeOptions,
  segmentsOptions,
} from "../../../../Options/physioOptions";

const NeurologicalLevels = () => {
  return (
    <>
      <Form.Item label="Sensory Left" name={"sensory_left"}>
        <Select options={segmentsOptions} />
      </Form.Item>
      <Form.Item label="Sensory Right" name={"sensory_right"}>
        <Select options={segmentsOptions} />
      </Form.Item>
      <Form.Item label="Motor Left" name={"motor_left"}>
        <Select options={segmentsOptions} />
      </Form.Item>
      <Form.Item label="Motor Right" name={"motor_right"}>
        <Select options={segmentsOptions} />
      </Form.Item>
      <Form.Item
        label="Neurological Level Injury"
        name={"neurological_level_of_injury"}
      >
        <Select options={segmentsOptions} />
      </Form.Item>
      <Form.Item
        label="ASIA Impairment Scale Grade"
        name={"asia_impairment_scale"}
      >
        <Select options={asiaImpairmentGradeOptions} />
      </Form.Item>
      <Form.Item
        label="Zone of Partial Preservation"
        name={"zone_of_partial_preservation"}
      >
        <Select options={segmentsOptions} />
      </Form.Item>
    </>
  );
};

export default NeurologicalLevels;
