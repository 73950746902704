import axiosInstance from "../config/axios.config";

export const speechInspectionAPI = (payload) => {
  return axiosInstance.post(`inpatient/speech-initial-assessment/`, payload);
};
export const updateSpeechInspectionAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/speech-initial-assessment/${id}/`,
    payload
  );
};

export const getSpeechInspectionAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/speech-initial-assessment/${admisssionId}/`
  );
};

export const speechReviewAPI = (payload) => {
  return axiosInstance.post(`inpatient/speech-and-swallow/`, payload);
};
export const updateSpeechReviewAPI = (id, payload) => {
  return axiosInstance.patch(`inpatient/speech-and-swallow/${id}/`, payload);
};

export const getSpeechReviewAPI = (admisssionId) => {
  return axiosInstance.get(`inpatient/speech-and-swallow/${admisssionId}/`);
};

export const getLatestSpeechReviewAPI = (admissionId) => {
  return axiosInstance.get(
    `inpatient/latest-speech-and-swallow/?admission_id=${admissionId}`
  );
};
