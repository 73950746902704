import { Form, Radio } from "antd";
import React from "react";
import { nutritionalRatingOptions } from "../../Options/clinicalEvaluationOptions";

const NutritionalScreeningRating = () => {
  return (
    <>
      <Form.Item
        label="Nutritional Screen Rating"
        name="nutritional_screen_rating"
      >
        <Radio.Group options={nutritionalRatingOptions} />
      </Form.Item>
    </>
  );
};

export default NutritionalScreeningRating;
