import { Checkbox, Form, Input, Typography } from "antd";
import React, { useContext, useState, useEffect } from "react";
import {
  dossOptions,
  levelFiveOptions,
  levelFourOptions,
  levelOneOptions,
  levelSevenOptions,
  levelSixOptions,
  levelThreeOptions,
  levelTwoOptions,
  modifiedDietOptions,
  nonOralOptions,
  normalDietOptions,
} from "../../../Options/speechSwallowOptions";

const Doss = ({ context }) => {
  const { formData } = useContext(context);
  const [showOptions, setShowOptions] = useState({});
  const [showSubOptions, setShowSubOptions] = useState({});

  useEffect(() => {
    if (formData?.scales?.doss_details) {
      const initialShowOptions = dossOptions.reduce((acc, option) => {
        acc[option.value] =
          formData?.scales?.doss_details[option.value] || false;
        return acc;
      }, {});
      setShowOptions(initialShowOptions);

      const initialShowSubOptions = Object.keys(
        formData?.scales?.doss_details
      ).reduce((acc, key) => {
        if (key.includes("_details")) {
          acc[key.replace("_details", "")] = true;
        }
        return acc;
      }, {});
      setShowSubOptions(initialShowSubOptions);
    }
  }, [formData]);

  const handleCheckboxChange = (value) => {
    setShowOptions((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const handleSubCheckboxChange = (value) => {
    setShowSubOptions((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const getLevelOptions = (value) => {
    switch (value) {
      case "full_po_normal_diet":
        return normalDietOptions;
      case "full_po_modified_diet":
        return modifiedDietOptions;
      case "non_oral_nutrition":
        return nonOralOptions;
      default:
        return [];
    }
  };

  const getSubLevelOptions = (value) => {
    switch (value) {
      case "level_7":
        return levelSevenOptions;
      case "level_6":
        return levelSixOptions;
      case "level_5":
        return levelFiveOptions;
      case "level_4":
        return levelFourOptions;
      case "level_3":
        return levelThreeOptions;
      case "level_2":
        return levelTwoOptions;
      case "level_1":
        return levelOneOptions;
      default:
        return [];
    }
  };

  return (
    <>
      <Typography.Title level={5}>DOSS</Typography.Title>
      {dossOptions.map((option) => (
        <div key={option.value}>
          <Form.Item
            name={["doss_details", option.value]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={showOptions[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {showOptions[option.value] && (
            <div className="subsection-card">
              {getLevelOptions(option.value).map((levelOption) => (
                <div key={levelOption.value}>
                  <Form.Item
                    name={["doss_details", levelOption.value]}
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={() =>
                        handleSubCheckboxChange(levelOption.value)
                      }
                      checked={showSubOptions[levelOption.value]}
                    >
                      {levelOption.label}
                    </Checkbox>
                  </Form.Item>
                  {showSubOptions[levelOption.value] && (
                    <div className="subsection-card">
                      <Form.Item
                        name={["doss_details", `${levelOption.value}_details`]}
                      >
                        <Checkbox.Group
                          options={getSubLevelOptions(levelOption.value)}
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <Form.Item name={["doss_details", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default Doss;
