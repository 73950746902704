import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Typography, Card } from "antd";
import "./PhysioScales.css";
import Gcs from "../GeneralExaminationForm/GeneralExaminationComponents/Gcs";
import {
  gcScaleAPI,
  getGcScaleAPI,
  updateGcScaleApi,
} from "../../../../../services/physio.services";

const GCScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  let formData = {};
  const [states, setStates] = useState({});

  const handleChange = (key, value) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };

    try {
      if (scalesId?.gcs) {
        await updateGcScaleApi(scalesId?.gcs, finalData);
      } else {
        const response = await gcScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, gcs: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.gcs) {
      getGcScaleAPI(admission, scalesId?.gcs)
        .then((response) => {
          formData = response.data;
          form.setFieldsValue(formData);
          setStates(
            Object.fromEntries(
              Object.entries(formData).filter(
                ([key]) =>
                  key !== "comments" && key !== "id" && key !== "ip_admission"
              )
            )
          );
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const aggregate = Object.values(states).reduce((acc, cur) => acc + cur, 0);
  return (
    <>
      <div className="cardless-scale">
        <Typography className="score-counter">
          {aggregate}
          <br />
          —
          <br />
          15
          <span className="score-string">
            {aggregate < 9
              ? "Severe Brain Injury"
              : aggregate < 13
              ? "Moderate Brain Injury"
              : aggregate < 15
              ? "Mild Brain Injury"
              : "Normal"}
          </span>
        </Typography>
        <Card style={{ width: "600px" }}>
          <Form id="gcs-scale" onFinish={handleSubmit} form={form}>
            <Gcs handleChange={handleChange} />
            <Form.Item name="comments">
              <Input placeholder="Comments" style={{ width: "40%" }} />
            </Form.Item>
          </Form>
        </Card>
      </div>

      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => {
            form.submit();
            setActiveTab("3");
          }}
        >
          Save
        </Button>

        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default GCScale;
