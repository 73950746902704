import axiosInstance from "../config/axios.config";

export const emailLoginApi = (payload) => {
  return axiosInstance.post("accounts/email-login/", payload);
};
export const mobileLoginApi = (payload) => {
  return axiosInstance.post("accounts/phone-login/", payload);
};
export const mobileOtpLoginApi = (payload) => {
  return axiosInstance.post("accounts/otp-login/", payload);
};
export const mobileOtpResendApi = (payload) => {
  return axiosInstance.post("accounts/otp-resend/", payload);
};
export const verifyOtpApi = (payload) => {
  return axiosInstance.post("accounts/otp-verify/", payload);
};
export const logoutApi = () => {
  return axiosInstance.post("accounts/logout");
};
export const getGstApi = () => {
  return axiosInstance.get(`/accounts/list-org-gstin/`);
};
export const getCinApi = () => {
  return axiosInstance.get(`/accounts/list-org-cin/`);
};
export const userMetaDataApi = () => {
  return axiosInstance.get("accounts/user-details/");
};
export const orgVerificationApi = (domain) => {
  return axiosInstance.get(`accounts/org-details/?domain=${domain}`);
};
export const addUserApi = (payload) => {
  return axiosInstance.post(`accounts/add-user/`, payload);
};
export const updateUserApi = (payload) => {
  return axiosInstance.patch(`accounts/update-user/`, payload);
};
export const updateUserRoleApi = (payload) => {
  return axiosInstance.patch(`accounts/update-user-role/`, payload);
};
export const usersListApi = (params) => {
  // console.log(params);
  return params
    ? axiosInstance.get(`accounts/org-users-list/`, {
        params: { roles: params },
      })
    : axiosInstance.get(`accounts/org-users-list/`);
};
export const changePasswordApi = (payload) => {
  return axiosInstance.post(`accounts/change-password/`, payload);
};
export const addGstApi = (payload) => {
  return axiosInstance.post(`accounts/add-gstin/`, payload);
};
export const addCinApi = (payload) => {
  return axiosInstance.post(`accounts/add-cin/`, payload);
};
export const deactivateUserApi = (payload) => {
  return axiosInstance.patch(`accounts/deactivate-user/`, payload);
};
