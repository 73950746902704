import React from "react";
import { Form, Radio, Input, Typography } from "antd";
import { periPulsesVolumeOptions } from "../../../../Options/systemicExamOptions";
import { regularIrregularOptions } from "../../../../Options/commonOptions";
const { Text } = Typography;

const PeripheralPulses = () => {
  return (
    <>
      <Text>Peripheral Pulses</Text>
      <Form.Item name="peripheral_volume">
        <Radio.Group options={periPulsesVolumeOptions} />
      </Form.Item>
      <Form.Item name="peripheral_regular">
        <Radio.Group options={regularIrregularOptions} />
      </Form.Item>
      <Form.Item name="peripheral_desc">
        <Input type="text" placeholder="Description" />
      </Form.Item>
    </>
  );
};

export default PeripheralPulses;
