import React, { createContext, useState } from "react";

export const PhysioReviewContext = createContext({});
const PhysioReviewFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <PhysioReviewContext.Provider value={{ formData, setFormData }}>
      {children}
    </PhysioReviewContext.Provider>
  );
};

export default PhysioReviewFormProvider;
