import { Card, Form, Radio, Row, Col, Typography } from "antd";
import React from "react";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Language = () => {
  const languageFields = [
    {
      label: "Repeat Test 1",
      sequence: "I only know that John is the one to help today",
      value: "language_1",
    },
    {
      label: "Repeat Test 2",
      sequence:
        "The cat always hid under the couch when the dogs were in the room",
      value: "language_2",
    },
    {
      label: "Fluency Test",
      sequence:
        "Name the maximum number of words in one minute that begin with the letter F",
      value: "language_3",
    },
  ];

  return (
    <>
      {languageFields.map((field) => (
        <Card key={field.value} style={{ marginBottom: 16 }}>
          <Typography style={{ textAlign: "center" }}>
            <Typography.Text strong>{field.label}</Typography.Text>
            <br />
            <Typography.Text strong>{field.sequence}</Typography.Text>
          </Typography>
          <Form.Item name={["language_data", field.value]}>
            <Row justify="center">
              <Col>
                <Radio.Group style={{ width: "100%" }}>
                  <Row justify="center" gutter={[8, 0]}>
                    <Col>
                      <Radio.Button
                        value={"yes"}
                        style={{
                          backgroundColor: "#E9D7FE",
                          padding: "0 8px",
                        }}
                      >
                        <LikeOutlined />
                      </Radio.Button>
                    </Col>
                    <Col>
                      <Radio.Button
                        value={"no"}
                        style={{
                          backgroundColor: "#FFF5F5",
                          padding: "0 8px",
                        }}
                      >
                        <DislikeOutlined />
                      </Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Col>
            </Row>
          </Form.Item>
        </Card>
      ))}
    </>
  );
};

export default Language;
