export const yesNoOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const noYesOptions = [
  { label: "No", value: "no" },
  { label: "Yes", value: "yes" },
];

export const noneYesOptions = [
  { label: "None", value: "none" },
  { label: "Yes", value: "yes" },
];

export const positiveNegativeOptions = [
  { label: "Positive", value: "positive" },
  { label: "Negative", value: "negative" },
];

export const normalAbsentOptions = [
  { label: "Normal", value: "normal" },
  { label: "Absent", value: "absent" },
];

export const presentAbsentOptions = [
  { label: "Present", value: "present" },
  { label: "Absent", value: "absent" },
];

export const normalAbnormalOptions = [
  { label: "Normal", value: "normal" },
  { label: "Abnormal", value: "abnormal" },
];

export const regularIrregularOptions = [
  { label: "Regular", value: "regular" },
  { label: "Irregular", value: "irregular" },
];

export const notAssessableAndAssessableOptions = [
  { label: "Not Assessable", value: "not_assessable" },
  { label: "Assessable", value: "assessable" },
];
