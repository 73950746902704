import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import DoctorLayout from "../Layout/DoctorLayout";
import PatientsIP from "../PatientsIP/PatientsIP";
import DoctorAppointments from "./Appointments/DoctorAppointments";
import PatientCards from "./PatientCards";
// import PatientMenu from "./PatientMenu";
const DoctorView = () => {
  const location = useLocation();
  const query = useParams();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    if (userDetails?.currFacility) {
      console.log(userDetails?.currFacility === 1 ? "Hello" : "Hai");
    }
  }, [userDetails?.currFacility]);

  return (
    <>
      {query?.tab === "appointments" ? (
        <DoctorAppointments />
      ) : (
        <>
          {params?.patient ? (
            <DoctorLayout>
              <PatientsIP />
            </DoctorLayout>
          ) : (
            <DoctorLayout>
              <PatientCards />
            </DoctorLayout>
          )}
        </>
      )}
    </>
  );
};

export default DoctorView;
