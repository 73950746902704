export const visionOptions = [
  { label: "Adequate", value: "adequate" },
  { label: "Impaired", value: "impaired" },
  { label: "Spectacles", value: "spectacles" },
  { label: "Contact Lenses", value: "contact_lenses" },
  { label: "Blind", value: "blind" },
];

export const leftRightBothOptions = [
  {
    name_key: "side",
    label: "",
    options: [
      { label: "Left", value: "left" },
      { label: "Right", value: "right" },
      { label: "Both", value: "both" },
    ],
  },
];

export const hearingOptions = [
  { label: "Adequate", value: "adequate" },
  { label: "Impaired", value: "impaired" },
  { label: "Deaf", value: "deaf" },
  { label: "Hearing Aids", value: "hearing_aids" },
];

export const speechTypeOptions = [
  { label: "Normal", value: "normal" },
  { label: "Aphasia", value: "aphasia" },
  { label: "Slurred", value: "slurred" },
];

export const dailyActivityOptions = [
  { label: "Bathing", value: "bathing" },
  { label: "Toilet Use", value: "toilet_use" },
  { label: "Dressing", value: "dressing" },
  { label: "Eating", value: "eating" },
  { label: "Walking", value: "walking" },
  { label: "Climbing Stairs", value: "climbing_stairs" },
];

export const dailyActivityDependenceOptions = [
  {
    name_key: "dependence",
    label: "",
    options: [
      { label: "Independent", value: "independent" },
      { label: "With Assistance", value: "with_assistance" },
      { label: "Dependent", value: "dependent" },
    ],
  },
];

export const incontinenceOptions = [
  { label: "None", value: "none" },
  { label: "Occasionally", value: "occasioanlly" },
  { label: "Always", value: "always" },
];

export const catheterOptions = [
  { label: "No", value: "no" },
  { label: "Foleys", value: "foleys" },
  { label: "Condom Catheter", value: "condom_catheter" },
];

export const urineOptions = [
  { label: "Normal", value: "Normal" },
  { label: "High Colored", value: "high_colored" },
];

export const stoolOptions = [
  { label: "Normal", value: "normal" },
  { label: "Diarrhea", value: "diarrhea" },
  { label: "Constipated", value: "constipated" },
];

export const perceptionSelectionOptions = [
  { label: "1 : Completely Limited", value: 1 },
  { label: "2 : Very Limited", value: 2 },
  { label: "3 : Slightly Limited", value: 3 },
  { label: "4 : No Impairment", value: 4 },
];

export const moistureSelectionOptions = [
  { label: "1 : Constantly Moist", value: 1 },
  { label: "2 : Often Moist", value: 2 },
  { label: "3 : Occasionally Moist", value: 3 },
  { label: "4 : Rarely Moist", value: 4 },
];

export const activitySelectionOptions = [
  { label: "1 : Bed Ridden", value: 1 },
  { label: "2 : Chair Mobilize", value: 2 },
  { label: "3 : Walks Occasionally", value: 3 },
  { label: "4 : Walks Frequently", value: 4 },
];

export const mobilitySelectionOptions = [
  { label: "1 : Completely Immobile", value: 1 },
  { label: "2 : Mobilize in limited", value: 2 },
  { label: "3 : Slightly Mobilize", value: 3 },
  { label: "4 : Actively Mobilize", value: 4 },
];

export const nutritionSelectionOptions = [
  { label: "1 : Very Poor", value: 1 },
  { label: "2 : Insufficient", value: 2 },
  { label: "3 : Sufficient", value: 3 },
  { label: "4 : Excellent", value: 4 },
];

export const frictionSelectionOptions = [
  { label: "1 : Happens", value: 1 },
  { label: "2 : May Happens", value: 2 },
  { label: "3 : Never", value: 3 },
];

export const specialCareOptions = [
  { label: "Bed Bound", value: "bed_bound" },
  { label: "Wheel Chair Bound", value: "wheel_chair_bound" },
  { label: "Risk of Fall", value: "risk_of_fall" },
  { label: "Tracheostomy Care", value: "tracheostomy_care" },
  { label: "NIV/CPAP/Ventilation", value: "niv_cpap_ventilation" },
  { label: "Respiratory Physiotherapy", value: "respiratory_physiotherapy" },
  { label: "Oxygen Support", value: "oxygen_support" },
  { label: "NG Feeds", value: "ng_feeds" },
  { label: "IV Fluids", value: "iv_fluids" },
  {
    label: "Pressure Sore Care Dressings",
    value: "pressure_sore_care_dressings",
  },
  { label: "Catheter Care", value: "catheter_care" },
  { label: "Ostomy Care", value: "ostomy_care" },
  { label: "Dressings", value: "dressings" },
  { label: "Others", value: "others" },
];

export const subjectiveOptions = [
  { label: "Normal", value: "normal" },
  { label: "Affected", value: "affected" },
  { label: "Non Assessable", value: "non_assessable" },
];

export const moreSubjectiveOptions = [
  { label: "Right", value: "right" },
  { label: "Left", value: "left" },
  { label: "Bilateral", value: "bilateral" },
];

export const barthelIndexOptions = [
  { label: "Feeding", value: "feeding" },
  { label: "Bathing", value: "bathing" },
  { label: "Grooming", value: "grooming" },
  { label: "Dressing", value: "dressing" },
  { label: "Bowels", value: "bowels" },
  { label: "Bladder", value: "bladder" },
  { label: "Toilet Use", value: "toilet_use" },
  { label: "Transfers", value: "transfers" },
  { label: "Mobility", value: "mobility" },
  { label: "Stairs", value: "stairs" },
];

export const rpeOptions = [
  { label: "1(very light activity)", value: 1 },
  { label: "2(light activity)", value: 2 },
  { label: "3(light activity)", value: 3 },
  { label: "4(moderate activity)", value: 4 },
  { label: "5(moderate activity)", value: 5 },
  { label: "6(moderate activity)", value: 6 },
  { label: "7(vigorous activity)", value: 7 },
  { label: "8(vigorous activity)", value: 8 },
  { label: "9(very hard activity)", value: 9 },
  { label: "10(very hard activity)", value: 10 },
];

export const genderOptions = [
  { label: "Male", value: "male adult" },
  { label: "Female", value: "female adult" },
];

export const minsWalkedOptions = [
  { label: "30sec", value: 30 },
  { label: "1min", value: 60 },
  { label: "1min 30sec", value: 90 },
  { label: "2min", value: 120 },
  { label: "2min 30sec", value: 150 },
  { label: "3min", value: 180 },
  { label: "3min 30sec", value: 210 },
  { label: "4min", value: 240 },
  { label: "4min 30sec", value: 270 },
  { label: "5min", value: 300 },
  { label: "5min 30sec", value: 330 },
  { label: "6min", value: 360 },
];

export const reasonOptions = [
  { label: "Chest pain", value: "chest pain" },
  { label: "Intolerable Dyspnea", value: "intolerable dyspnea" },
  { label: "Leg Cramps", value: "leg cramps" },
  { label: "Staggering", value: "staggering" },
  { label: "Diaphoresis", value: "diaphoresis" },
  { label: "Pale or ashen appearance", value: "pale or ashen appearance" },
  { label: "Others", value: "others" },
];

export const speechCheckboxOptions = [
  { label: "Dysphagia", value: "dysphagia" },
  { label: "Dysarthria", value: "dysarthria" },
  { label: "Aphasia", value: "aphasia" },
  { label: "Voice", value: "voice" },
];

export const dysarthriaOptions = [
  {
    label: "Unilateral Upper Motor Neuron Dysarthria",
    value: "unilateral_upper_motor_neuron_dysarthria",
  },
  { label: "Flaccid Dysarthria", value: "flaccid_dysarthria" },
  { label: "Spastic Dysarthria", value: "spastic_dysarthria" },
  { label: "Hypokinetic Dysarthria", value: "hypokinetic_dysarthria" },
  { label: "Hyperkinetic Dysarthria", value: "hyperkinetic_dysarthria" },
  { label: "Ataxic Dysarthria", value: "ataxic_dysarthria" },
  { label: "Mixed Dysarthria", value: "mixed_dysarthria" },
];

export const aphasiaOptions = [
  { label: "Broca's Aphasia", value: "brocas_aphasia" },
  { label: "Wernicke's Aphasia", value: "wernickes_aphasia" },
  { label: "Conduction Aphasia", value: "conduction_aphasia" },
  {
    label: "Transcortical Motor Aphasia",
    value: "transcortical_motor_aphasia",
  },
  {
    label: "Transcortical Sensory Aphasia",
    value: "transcortical_sensory_aphasia",
  },
  { label: "Anomic Aphasia", value: "anomic_aphasia" },
  { label: "Global Aphasia", value: "global_aphasia" },
  {
    label: "Primary Progressive Aphasia",
    value: "primary_progressive_aphasia",
  },
  { label: "Dementia", value: "dementia" },
];

export const voiceOptions = [
  { label: "Left Vocal Fold Palsy", value: "left_vocal_fold_palsy" },
  { label: "Right Vocal Fold Palsy", value: "right_vocal_fold_palsy" },
];
