import { Descriptions } from "antd";
import React, { useContext } from "react";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";
import { getLabel } from "../constructTextFromValues";

const RespiratorySpecialInstructionSummary = () => {
  const { formData } = useContext(RespiratoryInitialContext);
  const constructSpecialSummaryItems = () => {
    const specialSummaryItems = [];
    if (formData?.special_instruction?.special_instruction) {
      specialSummaryItems.push({
        key: "1",
        label: "Instructions",
        children: (
          <>{getLabel(formData?.special_instruction?.special_instruction)}</>
        ),
      });
    }
    return specialSummaryItems;
  };
  const specialSummaryItems = constructSpecialSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Special Instructions"
        items={specialSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default RespiratorySpecialInstructionSummary;
