import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal, Button, Space, Typography, Form, message } from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import styles from "../../../../styles/Custom/Dashboard/Dashboard.module.css";
import {
  medicationPrescribeAPI,
  updateMedicationPrescribeAPI,
} from "../../../../services/patient.services";
import { UserContext } from "../../../../context/UserContext";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../../../helpers/localStorage";
import { FacilityID } from "../../../../constants/defaultKeys";
import AddInitialMedicine from "./AddInitialMedicine";
import dayjs from "dayjs";

const AddInitialMedicineModal = ({
  open,
  setOpen,
  editData,
  refreshPage,
  setDrawerOpen,
  initial,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const { userDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [add, setAdd] = useState(true);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({
        ...editData,
        name: editData?.medicine_name || editData?.name,
        schedule: {
          times: editData?.schedule?.times?.map((time) => dayjs(time, "HH")),
        },
      });
      setSelectedId(editData?.medicine);
    }
  }, [editData?.id]);

  const onFinish = (values) => {
    const times = values?.schedule?.times.map((time) =>
      dayjs(time)?.format("HH")
    );

    const { schedule, ...restValues } = values;

    const formData = {
      facility: Number(getCookie(FacilityID)) || userDetails?.currFacility,
      ip_admission: Number(params?.patient),
      medicine: selectedId,
      is_initial: true,
      schedule: {
        times: times,
      },
      ...restValues,
    };
    if (selectedId) {
      if (editData?.id) {
        updateMedicationPrescribeAPI(editData?.id, formData)
          .then((res) => {
            message.success(res?.data?.message);
            setOpen(false);
            setDrawerOpen && setDrawerOpen(false);
            refreshPage();
          })
          .catch((err) => message.error(err?.response?.data?.message));
      } else {
        medicationPrescribeAPI(formData)
          .then((res) => {
            message.success(res?.data?.message);
            refreshPage();
            if (add) {
              setOpen(true);

              form.resetFields();
              setSelectedId(null);
            } else {
              setOpen(false);
              setDrawerOpen && setDrawerOpen(false);
              // setDrawerOpen(false);
            }
          })
          .catch((err) => message.error(err?.response?.data?.message));
      }
    } else {
      message.error("Please choose valid medicine");
    }
  };

  return (
    <Modal
      keyboard={false}
      style={{ top: "10px" }}
      className={styles.test}
      width={"100%"}
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      footer={
        <div className="flex-end" style={{ gap: "24px" }}>
          {!editData?.id && (
            <Form.Item>
              <Button
                type="primary"
                ghost
                className={btnStyles.mdBtn}
                onClick={() => {
                  setAdd(true);
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                }}
              >
                Submit and Add Another
              </Button>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => {
                setAdd(false);

                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit and Close
            </Button>
          </Form.Item>
        </div>
      }
      title={
        <Space>
          <Typography>
            {editData ? "Edit Initial Medicine" : "Add Medicine"}
          </Typography>
        </Space>
      }
    >
      <>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          ref={formRef}
          style={{ marginTop: "20px" }}
        >
          <AddInitialMedicine
            setSelectedId={setSelectedId}
            form={form}
            selectedId={selectedId}
            editData={editData}
            initial={initial}
          />
        </Form>
      </>
    </Modal>
  );
};

export default AddInitialMedicineModal;
