import React from "react";
import { useLocation } from "react-router-dom";
import DoctorLayout from "../Layout/DoctorLayout";
import PatientsIP from "../PatientsIP/PatientsIP";
import NutritionistCards from "./NutritionistCards";
import PatientCards from "../DoctorView/PatientCards";
// import NurseCards from "./NurseCards";

const NutritionistView = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));

  return (
    <DoctorLayout>
      {params?.patient ? <PatientsIP /> : <PatientCards />}
    </DoctorLayout>
  );
};

export default NutritionistView;
