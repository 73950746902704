export const symptomsOptions = [
  { label: "Rapid Mood Swings", value: "rapid_mood_swings" },
  { label: "Withdrawal Behaviour", value: "withdrawal_behaviour" },
  { label: "Helplessness/Hopelessness", value: "helplessness_hopelessness" },
  { label: "Increased Energy", value: "increased_energy" },
  { label: "Decreased Energy", value: "decreased_energy" },
  { label: "Fear/Anxious", value: "fear_anxious" },
  { label: "Repetitive Thoughts", value: "repetitive_thoughts" },
  { label: "Repetitive Behaviour", value: "repetitive_behaviour" },
  { label: "Nightmares", value: "nightmares" },
  { label: "Loss of Interest", value: "loss_of_interest" },
  { label: "Irritability", value: "irritability" },
  { label: "Intrusive Memories", value: "intrusive_memories" },
  { label: "Distress", value: "distress" },
  { label: "Body Image Problems", value: "body_image_problems" },
  {
    label: "Sleep Disturbances (Non-Physical)",
    value: "sleep_disturbances_non_physical",
  },
  { label: "Substance Use", value: "substance_use" },
  { label: "Unexplained Losses of Time", value: "unexplained_losses_of_time" },
  { label: "Attention Deficit", value: "attention_deficit" },
  { label: "Hallucination", value: "hallucination" },
  { label: "Delusions", value: "delusions" },
  { label: "Irrelevant Speech", value: "irrelevant_speech" },
  { label: "Loss of Memory", value: "loss_of_memory" },
  { label: "Others", value: "others" },
];

export const psychologistDiagnosisOptions = [
  { label: "Anxiety Disorder", value: "anxiety_disorder" },
  { label: "Schizophrenia", value: "schizophrenia" },
  { label: "Bipolar Disorder", value: "bipolar_disorder" },
  { label: "Schizoaffective Disorder", value: "schizoaffective_disorder" },
  { label: "Brief Psychotic Disorder", value: "brief_psychotic_disorder" },
  { label: "Delusional Disorder", value: "delusional_disorder" },
  { label: "Panic Attacks", value: "panic_attacks" },
  { label: "Empty Nest Syndrome", value: "empty_nest_syndrome" },
  { label: "Delirium Disorder", value: "delirium_disorder" },
  { label: "Dementia", value: "dementia" },
  {
    label: "Avoidant Personality Disorder",
    value: "avoidant_personality_disorder",
  },
  { label: "Pseudo-bulbar Affect", value: "pseudo_bulbar_affect" },
  { label: "Mood Disorder", value: "mood_disorder" },
  { label: "Apathy", value: "apathy" },
  { label: "Eating Disorder", value: "eating_disorder" },
  { label: "Depression", value: "depression" },
  { label: "OCD", value: "ocd" },
  { label: "Phobia", value: "phobia" },
  { label: "Personality Disorder", value: "personality_disorder" },
  { label: "PTSD", value: "ptsd" },
  { label: "Insomnia", value: "insomnia" },
  { label: "Distress", value: "distress" },
  { label: "Mania", value: "mania" },
  { label: "Substance Abuse Disorder", value: "substance_abuse_disorder" },
  { label: "Others", value: "others" },
];

export const psychologistTherapyOptions = [
  {
    label: "Cognitive Behaviour Therapy",
    value: "cognitive_behaviour_therapy",
  },
  {
    label: "Cognitive Stimulation Therapy",
    value: "cognitive_stimulation_therapy",
  },
  { label: "Rational Emotive Therapy", value: "rational_emotive_therapy" },
  { label: "Behaviour Training", value: "behaviour_training" },
  { label: "Mindfulness Practices", value: "mindfulness_practices" },
  { label: "Relaxation Techniques", value: "relaxation_techniques" },
  { label: "Psychoeducation", value: "psychoeducation" },
  {
    label: "Psycho Supportive Counselling",
    value: "psycho_supportive_counselling",
  },
  { label: "Motivational Counselling", value: "motivational_counselling" },
  { label: "Grief Therapy", value: "grief_therapy" },
  { label: "Solution Focused Therapy", value: "solution_focused_therapy" },
  { label: "Cognitive Retraining", value: "cognitive_retraining" },
  { label: "Others", value: "others" },
];

export const emotionalIntelligenceOptions = [
  {
    label:
      "1. I am aware of the physical reactions (Twinges, Aches, Sudden Changes) that signal a `Gut Reaction`",
    value: "self_awareness_gut_reaction",
  },
  {
    label: "2. I readily admit mistakes and apologize",
    value: "relationship_management_admit_mistakes",
  },
  {
    label: "3. When I feel angry I can still stay composed",
    value: "self_management_stay_composed",
  },
  {
    label:
      "4. I generally have an accurate idea of how another person perceives me during a particular interaction",
    value: "social_awareness_accurate_idea",
  },
  {
    label:
      "5. In assessing a situation, I look at my biases and adjust my assessment accordingly",
    value: "self_awareness_assess_biases",
  },
  {
    label: "6. I can keep going on a project, despite obstacles",
    value: "self_management_keep_going",
  },
  {
    label:
      "7. I can engage in an interaction with another and pretty well size-up that person's mood based on non-verbal signals",
    value: "social_awareness_size_up_mood",
  },
  {
    label: "8. Others feel encouraged after talking to me",
    value: "relationship_management_encourage_others",
  },
  {
    label:
      "9. I consider my 'emotional temperature' before I make important decisions",
    value: "self_awareness_emotional_temperature",
  },
  {
    label:
      "10. When I feel a strong impulse to do something, I usually pause to reflect and decide whether I really want to act on it",
    value: "self_management_pause_reflect",
  },
  {
    label:
      "11. I can deal calmly, sensitively, and proactively with the emotional displays of others",
    value: "relationship_management_deal_emotions",
  },
  {
    label: "12. I can identify the emotion I am feeling at any given moment",
    value: "self_awareness_identify_emotion",
  },
  {
    label:
      "13. I am able to honestly say how I feel without getting others upset",
    value: "self_management_honest_feelings",
  },
  {
    label:
      "14. I can show empathy and match my feelings with those of another person in an interaction",
    value: "social_awareness_show_empathy",
  },
  {
    label: "15. I think about the emotions behind my actions",
    value: "self_awareness_emotions_behind_actions",
  },
  {
    label:
      "16. I am respected and liked by others, even when they don't agree with me",
    value: "relationship_management_respected_liked",
  },
  {
    label:
      "17. I watch how others react to me to understand which of my own behaviors are effective and which are not",
    value: "social_awareness_watch_reactions",
  },
  {
    label:
      "18. I am good at managing my moods, and I refrain from bringing negative emotions to work",
    value: "self_management_manage_moods",
  },
  {
    label: "19. It is easy to understand why other people feel the way they do",
    value: "social_awareness_understand_others",
  },
  {
    label:
      "20. I can effectively persuade others to adopt my point of view without coercing them",
    value: "relationship_management_persuade_effectively",
  },
];

export const emotionalIntelligenceScaleOptions = [
  { label: "Never", value: 1 },
  { label: "Rarely", value: 2 },
  { label: "Sometimes", value: 3 },
  { label: "Usually", value: 4 },
  { label: "Always", value: 5 },
];

export const beckAnxietyOptions = [
  { label: "1. Numbness or tingling", value: "numbness_tingling" },
  { label: "2. Feeling hot", value: "feeling_hot" },
  { label: "3. Wobbliness in legs", value: "wobbliness_in_legs" },
  { label: "4. Unable to relax", value: "unable_to_relax" },
  { label: "5. Fear of worst happening", value: "fear_of_worst_happening" },
  { label: "6. Dizzy or lightheaded", value: "dizzy_lightheaded" },
  { label: "7. Heart pounding / racing", value: "heart_pounding_racing" },
  { label: "8. Unsteady", value: "unsteady" },
  { label: "9. Terrified or afraid", value: "terrified_afraid" },
  { label: "10. Nervous", value: "nervous" },
  { label: "11. Feeling of choking", value: "feeling_of_choking" },
  { label: "12. Hands trembling", value: "hands_trembling" },
  { label: "13. Shaky / unsteady", value: "shaky_unsteady" },
  { label: "14. Fear of losing control", value: "fear_of_losing_control" },
  { label: "15. Difficulty in breathing", value: "difficulty_in_breathing" },
  { label: "16. Fear of dying", value: "fear_of_dying" },
  { label: "17. Scared", value: "scared" },
  { label: "18. Indigestion", value: "indigestion" },
  { label: "19. Faint / lightheaded", value: "faint_lightheaded" },
  { label: "20. Face flushed", value: "face_flushed" },
  { label: "21. Hot / cold sweats", value: "hot_cold_sweats" },
];

export const beckAnxietyScaleOptions = [
  { label: "Not at all", value: 0 },
  { label: "Mildly, but it didn't bother me much", value: 1 },
  { label: "Moderately - it wasn't pleasant at times", value: 2 },
  { label: "Severely - it bothered me a lot", value: 3 },
];

export const beckDepressionOptions = [
  {
    label: "1. Sadness",
    value: "sadness",
    options: [
      { label: "I do not feel sad", value: 0 },
      { label: "I feel sad much of the time", value: 1 },
      { label: "I am sad all the time", value: 2 },
      {
        label: "I am so sad or unhappy that I can`t stand it",
        value: 3,
      },
    ],
  },
  {
    label: "2. Pessimism",
    value: "pessimism",
    options: [
      {
        label: "I am not discouraged about my future",
        value: 0,
      },
      {
        label: "I feel more discouraged about my future than I used to",
        value: 1,
      },
      {
        label: "I do not expect things to work out for me",
        value: 2,
      },
      {
        label: "I feel my future is hopeless and will only get worse",
        value: 3,
      },
    ],
  },
  {
    label: "3. Past Failure",
    value: "past_failure",
    options: [
      { label: "I do not feel like a failure", value: 0 },
      {
        label: "I have failed more than I should have",
        value: 1,
      },
      {
        label: "As I look back, I see a lot of failures",
        value: 2,
      },
      {
        label: "I feel I am a total failure as a person",
        value: 3,
      },
    ],
  },
  {
    label: "4. Loss of Pleasure",
    value: "loss_of_pleasure",
    options: [
      {
        label: "I get as much pleasure as I ever did from the things I enjoy",
        value: 0,
      },
      {
        label: "I don`t enjoy things as much as I used to",
        value: 1,
      },
      {
        label: "I get very little pleasure from the things I used to enjoy",
        value: 2,
      },
      {
        label: "I can`t get any pleasure from the things I used to enjoy",
        value: 3,
      },
    ],
  },
  {
    label: "5. Guilty Feelings",
    value: "guilty_feelings",
    options: [
      { label: "I don`t feel particularly guilty", value: 0 },
      {
        label: "I feel guilty over many things I have done or should have done",
        value: 1,
      },
      {
        label: "I feel quite guilty most of the time",
        value: 2,
      },
      { label: "I feel guilty all of the time", value: 3 },
    ],
  },
  {
    label: "6. Punishment Feelings",
    value: "punishment_feelings",
    options: [
      {
        label: "I don`t feel I am being punished",
        value: 0,
      },
      { label: "I feel I may be punished", value: 1 },
      { label: "I expect to be punished", value: 2 },
      { label: "I feel I am being punished", value: 3 },
    ],
  },
  {
    label: "7. Self-Dislike",
    value: "self_dislike",
    options: [
      { label: "I feel the same about myself as ever", value: 0 },
      { label: "I have lost confidence in myself", value: 1 },
      { label: "I am disappointed in myself", value: 2 },
      { label: "I dislike myself", value: 3 },
    ],
  },
  {
    label: "8. Self-Criticalness",
    value: "self_criticalness",
    options: [
      {
        label: "I don`t criticize or blame myself more than usual",
        value: 0,
      },
      {
        label: "I am more critical of myself than I used to be",
        value: 1,
      },
      {
        label: "I criticize myself for all of my faults",
        value: 2,
      },
      {
        label: "I blame myself for everything bad that happens",
        value: 3,
      },
    ],
  },
  {
    label: "9. Suicidal Thoughts or Wishes",
    value: "suicidal_thoughts",
    options: [
      {
        label: "I don`t have any thoughts of killing myself",
        value: 0,
      },
      {
        label:
          "I have thoughts of killing myself, but I would not carry them out",
        value: 1,
      },
      { label: "I would like to kill myself", value: 2 },
      {
        label: "I would kill myself if I had the chance",
        value: 3,
      },
    ],
  },
  {
    label: "10. Crying",
    value: "crying",
    options: [
      {
        label: "I don`t cry anymore than I used to",
        value: 0,
      },
      { label: "I cry more than I used to", value: 1 },
      {
        label: "I cry over every little thing",
        value: 2,
      },
      {
        label: "I feel like crying, but I cant",
        value: 3,
      },
    ],
  },
  {
    label: "11. Agitation",
    value: "agitation",
    options: [
      {
        label: "I am no more restless or wound up than usual",
        value: 0,
      },
      {
        label: "I feel more restless or wound up than usual",
        value: 1,
      },
      {
        label: "I am so restless or agitated, its hard to stay still",
        value: 2,
      },
      {
        label:
          "I am so restless or agitated that I have to keep moving or doing something",
        value: 3,
      },
    ],
  },
  {
    label: "12. Loss of Interest",
    value: "loss_of_interest",
    options: [
      {
        label: "I have not lost interest in other people or activities",
        value: 0,
      },
      {
        label: "I am less interested in other people or things than before",
        value: 1,
      },
      {
        label: "I have lost most of my interest in other people or things",
        value: 2,
      },
      {
        label: "Its hard to get interested in anything",
        value: 3,
      },
    ],
  },
  {
    label: "13. Indecisiveness",
    value: "indecisiveness",
    options: [
      {
        label: "I make decisions about as well as ever",
        value: 0,
      },
      {
        label: "I find it more difficult to make decisions than usual",
        value: 1,
      },
      {
        label:
          "I have much greater difficulty in making decisions than I used to",
        value: 2,
      },
      {
        label: "I have trouble making any decisions",
        value: 3,
      },
    ],
  },
  {
    label: "14. Worthlessness",
    value: "worthlessness",
    options: [
      {
        label: "I do not feel I am worthless",
        value: 0,
      },
      {
        label: "I don`t consider myself as worthwhile and useful as I used to",
        value: 1,
      },
      {
        label: "I feel more worthless as compared to others",
        value: 2,
      },
      { label: "I feel utterly worthless", value: 3 },
    ],
  },
  {
    label: "15. Loss of Energy",
    value: "loss_of_energy",
    options: [
      { label: "I have as much energy as ever", value: 0 },
      { label: "I have less energy than I used to have", value: 1 },
      {
        label: "I don`t have enough energy to do very much",
        value: 2,
      },
      {
        label: "I don`t have enough energy to do anything",
        value: 3,
      },
    ],
  },
  {
    label: "16. Changes in Sleeping Pattern",
    value: "sleeping_pattern",
    options: [
      { label: "I have not experienced any change in my sleeping", value: 0 },
      {
        label: "I sleep somewhat more than usual",
        value: "1_1",
      },
      {
        label: "I sleep somewhat less than usual",
        value: "1_2",
      },
      { label: "I sleep a lot more than usual", value: "2_1" },
      { label: "I sleep a lot less than usual", value: "2_2" },
      { label: "I sleep most of the day", value: "3_1" },
      {
        label: "I wake up 1-2 hours early and can't get back to sleep",
        value: "3_2",
      },
    ],
  },
  {
    label: "17. Irritability",
    value: "irritability",
    options: [
      {
        label: "I am not more irritable than usual",
        value: 0,
      },
      { label: "I am more irritable than usual", value: 1 },
      {
        label: "I am much more irritable than usual",
        value: 2,
      },
      { label: "I am irritable all the time", value: 3 },
    ],
  },
  {
    label: "18. Changes in Appetite",
    value: "changes_in_appetite",
    options: [
      { label: "I have not experienced any change in my appetite", value: 0 },
      {
        label: "My appetite is somewhat less than usual",
        value: "1_1",
      },
      {
        label: "My appetite is somewhat greater than usual",
        value: "1_2",
      },
      {
        label: "My appetite is much less than before",
        value: "2_1",
      },
      {
        label: "My appetite is much greater than usual",
        value: "2_2",
      },
      { label: "I have no appetite at all", value: "3_1" },
      { label: "I crave food all the time", value: "3_2" },
    ],
  },
  {
    label: "19. Concentration Difficulty",
    value: "concentration_difficulty",
    options: [
      {
        label: "I can concentrate as well as ever",
        value: 0,
      },
      {
        label: "I can`t concentrate as well as usual",
        value: 1,
      },
      {
        label: "Its hard to keep my mind on anything for very long",
        value: 2,
      },
      {
        label: "I find I can`t concentrate on anything",
        value: 3,
      },
    ],
  },
  {
    label: "20. Tiredness or Fatigue",
    value: "tiredness_fatigue",
    options: [
      {
        label: "I am no more tired or fatigued than usual",
        value: 0,
      },
      {
        label: "I get more tired or fatigued more easily than usual",
        value: 1,
      },
      {
        label:
          "Iam too tired or fatigued to do a lot of the things I used to do",
        value: 2,
      },
      {
        label:
          "I am too tired or fatigued to do most of the things I used to do",
        value: 3,
      },
    ],
  },
  {
    label: "21. Loss of Interest in Sex",
    value: "loss_of_interest_in_sex",
    options: [
      {
        label: "I have not noticed any recent change in my interest in sex",
        value: 0,
      },
      {
        label: "I am less interested in sex than I used to be",
        value: 1,
      },
      {
        label: "I am much less interested in sex now",
        value: 2,
      },
      {
        label: "I have lost interest in sex completely",
        value: 3,
      },
    ],
  },
];
