import React, { useState } from "react";
import { Table, Tag, Col, Pagination } from "antd";
import { statusColors } from "../../../helpers/medicineKeys";
import { EditOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import InvestigationModal from "../../PatientsIP/InvestigationsIp/InvestigationModal";

const InvestigationTable = ({ data, refreshPage, page, setPage }) => {
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);

  const columns = [
    {
      title: "Investigation Name",
      dataIndex: "investigation_name",
      key: "investigation_name",
      render: (value, record) => (value ? value : record?.name),
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      render: (value) => capitalizeFirstLetter(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (value, record) => {
        return (
          <>
            <Tag color={statusColors?.[value]}>{value?.replace("_", " ")}</Tag>
            {/* {value === "Report_Delivered" ? (
              <ExportOutlined style={{ color: "green", fontSize: "18px" }} />
            ) : null} */}
          </>
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            setOpen(true);
            setEditData(record);
          }}
        />
      ),
    },
  ];
  return (
    <>
      <Table
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)", x: 0 }}
      />
      <Col span={24} className="text-center" style={{ paddingTop: "10px" }}>
        <Pagination
          current={page}
          pageSize={50}
          total={data?.total_pages}
          onChange={(e) => setPage(e)}
          showSizeChanger={false}
        />
      </Col>
      {open && (
        <InvestigationModal
          refreshPage={refreshPage}
          open={open}
          setOpen={setOpen}
          editData={editData}
        />
      )}
    </>
  );
};

export default InvestigationTable;
