import React, { useContext, useEffect } from "react";

import { PhysioInitialContext } from "../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
import FunctionalDiagnosis from "./FunctionalDiagnosis";

const PhysioFunctionalDiagnosis = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.functional_diagnosis);
  }, [formData]);

  return (
    <>
      <FunctionalDiagnosis context={PhysioInitialContext} />
    </>
  );
};

export default PhysioFunctionalDiagnosis;
