import React, { useEffect, useState } from "react";
import { Card, DatePicker } from "antd";
import { getNurseCommonData } from "../../../services/nurse.services";
import useQuery from "../../../hooks/useQuery";
import inputStyles from "../../../styles/Custom/Input.module.css";
import StatMedicines from "./StatMedicines";
import dayjs from "dayjs";
import ScheduledMedicine from "./ScheduledMedicine";

const dateFormat = "YYYY-MM-DD";

const NurseMedicine = () => {
  const curDate = dayjs().format(dateFormat);
  const params = useQuery();
  const [medData, setMedData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [date, setDate] = useState(curDate);
  const [statData, setStatData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getNurseCommonData(
          params?.patient,
          "medication",
          date
        );
        setMedData(
          res?.data?.data?.filter(
            (data) =>
              data?.frequency !== "If_Required" &&
              data?.frequency !== "Immediately"
          )
        );
        setStatData(
          res?.data?.data?.filter(
            (data) =>
              data?.frequency === "If_Required" ||
              data?.frequency === "Immediately"
          )
        );
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [refresh, date]);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  const disabledDate = (current) => current && current > dayjs().endOf("day");

  const onDateChange = (date, dateString) => {
    setDate(date ? dateString : null);
  };

  const extractTimeSlots = (data = []) => [
    ...new Set(
      data?.flatMap((medicine) => medicine.actions.map((action) => action.hour))
    ),
  ];

  const timeSlots = medData?.length > 0 ? extractTimeSlots(medData) : [];
  const sortedTimeSlots = timeSlots.sort((a, b) => a - b);

  return (
    <div>
      <div className="flex-end" style={{ marginBottom: "10px" }}>
        <DatePicker
          disabledDate={disabledDate}
          className={inputStyles.mdDateInput}
          onChange={onDateChange}
          defaultValue={dayjs(curDate)}
          allowClear={false}
        />
      </div>
      {statData?.length > 0 && (
        <StatMedicines statData={statData} refreshPage={refreshPage} />
      )}
      <Card title="Scheduled Medicines">
        <ScheduledMedicine
          medData={medData}
          sortedTimeSlots={sortedTimeSlots}
          refreshPage={refreshPage}
          date={date}
          curDate={curDate}
        />
      </Card>
    </div>
  );
};

export default NurseMedicine;
