import React, { useEffect, useState } from "react";
import Rooms from "./Rooms";
import { Button, Card, Collapse, message, Input, Space } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import {
  createFloorApi,
  updateFloorApi,
} from "../../../../services/beds.services";

const { Panel } = Collapse;
const Floors = ({ floors, id, refreshPage, buildingName, refreshRoom }) => {
  const [editFloorId, setEditFloorId] = useState(null);
  const [editFloorNo, setEditFloorNo] = useState("");
  const [newFloorNo, setNewFloorNo] = useState("");
  const [addingFloor, setAddingFloor] = useState(false);

  const handleEditFloorNo = (floorId, floorName) => {
    setEditFloorId(floorId);
    setEditFloorNo(floorName);
    setAddingFloor(false);
  };
  const handleEditFloorSubmit = (floorId) => {
    updateFloorApi(floorId, { floor_no: editFloorNo })
      .then((res) => {
        message.success(res?.data?.message);
        setEditFloorId(null);
        refreshPage();
      })
      .catch((err) => {
        message.error(err?.res?.data?.message);
      });
  };

  const handleAddFloor = () => {
    if (newFloorNo.trim() === "") {
      message.error("Floor number cannot be empty");
      return;
    }

    createFloorApi({ building: id, floor_no: newFloorNo })
      .then((res) => {
        message.success(res?.data?.message);
        setAddingFloor(false);
        setNewFloorNo("");
        refreshPage();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };
  const handleNewFloorNoChange = (e) => {
    setNewFloorNo(e.target.value);
  };

  const handleAddButtonClick = () => {
    setAddingFloor(true); // Show the input field for adding a new building
  };

  const customAddFloor = () => {
    return (
      <>
        {addingFloor ? (
          <Space>
            <Input
              className={inputStyles.mdInput}
              placeholder="Enter Floor Number"
              value={newFloorNo}
              onChange={handleNewFloorNoChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleAddFloor();
                }
              }}
            />
            <Button
              onClick={() => handleAddFloor()}
              type="primary"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={() => setAddingFloor(null)}
              icon={<CloseOutlined />}
            />
          </Space>
        ) : (
          <Button onClick={handleAddButtonClick} type="primary">
            <PlusOutlined /> Floor
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Collapse>
        {floors?.length > 0 &&
          floors?.map((floor, floorIndex) => (
            <Panel
              header={
                editFloorId === floor.floor_id ? (
                  <Space>
                    {" "}
                    <Input
                      className={inputStyles.mdInput}
                      value={editFloorNo}
                      onChange={(e) => setEditFloorNo(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleEditFloorSubmit(floor?.floor_id);
                        }
                      }}
                    />
                    <Button
                      onClick={() => handleEditFloorSubmit(floor?.floor_id)}
                      type="primary"
                      icon={<CheckOutlined />}
                    />
                    <Button
                      onClick={() => setEditFloorId(null)}
                      icon={<CloseOutlined />}
                    />
                  </Space>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {floor?.floor_no}
                    <EditOutlined
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        handleEditFloorNo(floor?.floor_id, floor?.floor_no)
                      }
                    />
                  </div>
                )
              }
              key={`floor-${floorIndex}`}
            >
              <Rooms
                rooms={floor.rooms}
                buildingId={id}
                floorId={floor?.floor_id}
                refreshPage={refreshPage}
                buildingName={buildingName}
                floorNo={floor?.floor_no}
                refreshRoom={refreshRoom}
              />
            </Panel>
          ))}
      </Collapse>
      <>{customAddFloor()}</>
    </>
  );
};

export default Floors;
