import React, { useContext, useEffect } from "react";
import Built from "./GeneralExaminationComponents/Built";
import VitalsAdmission from "./GeneralExaminationComponents/VitalsAdmission";
import { Divider } from "antd";
import Gcs from "./GeneralExaminationComponents/Gcs";
import PainScale from "./GeneralExaminationComponents/PainScale";
import { PhysioInitialContext } from "../../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";

const PhysioGeneralExaminationForm = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.general_examination);
  }, [formData]);
  return (
    <>
      <Built />
      <Divider />
      <VitalsAdmission />
      <Divider />
      <Gcs />
      <Divider />
      <PainScale context={PhysioInitialContext} />
    </>
  );
};

export default PhysioGeneralExaminationForm;
