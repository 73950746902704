import React, { useContext, useEffect } from "react";
import PatientCondition from "./GeneralExaminationComponents/PatientCondition";
import { SpeechInitialContext } from "../../../SpeechAndSwallowTherapist/InitialInspection/SpeechSwallowInitialAssessmentProvider";

const SpeechSwallowGeneralExamination = ({ form }) => {
  const { formData } = useContext(SpeechInitialContext);
  useEffect(() => {
    form.setFieldsValue(formData?.general_examination);
  }, [formData]);
  return (
    <>
      <PatientCondition />
    </>
  );
};

export default SpeechSwallowGeneralExamination;
