import { Form, Radio } from "antd";
import React from "react";
import { appetiteOptions } from "../../../Options/historyOptions";

const AppetitePriorAdmission = () => {
  return (
    <>
      <Form.Item
        label="Appetite Prior to Admission"
        name="appetite_prior_to_admission"
      >
        <Radio.Group options={appetiteOptions} />
      </Form.Item>
    </>
  );
};

export default AppetitePriorAdmission;
