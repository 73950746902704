import React, { useState } from "react";
import { Card, Radio, Typography, Space } from "antd";
import AdvancePayment from "./AdvancePayment";
import FinalSettlement from "./FinalSettlement";

const RecordPayment = ({ billData, refreshData }) => {
  const [value, setValue] = useState("advance");

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <Card
      title={
        <Space>
          <Typography>Record Payment</Typography>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="advance">Advance Payment</Radio>
            <Radio value="final">Final Settlement</Radio>
          </Radio.Group>
        </Space>
      }
    >
      {value === "advance" ? (
        <AdvancePayment billData={billData} refreshData={refreshData} />
      ) : (
        <FinalSettlement billData={billData} refreshData={refreshData} />
      )}
    </Card>
  );
};

export default RecordPayment;
