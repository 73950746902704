export const genderKeys = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Other",
    label: "Others",
  },
];
export const bloodGroupOptions = [
  {
    value: "A+",
    label: "A+",
  },
  {
    value: "A-",
    label: "A-",
  },
  {
    value: "B+",
    label: "B+",
  },
  {
    value: "B-",
    label: "B-",
  },
  {
    value: "AB+",
    label: "AB+",
  },
  {
    value: "AB-",
    label: "AB-",
  },
  {
    value: "O+",
    label: "O+",
  },
  {
    value: "O-",
    label: "O-",
  },
  {
    value: "bombay_blood_group",
    label: "Bombay Blood Group",
  },
];
export const payerOptions = [
  { label: "Self", value: "Self" },
  { label: "Insurance", value: "Insurance" },
  { label: "Corporate", value: "Corporate" },
  { label: "Relative", value: "Relative" },
  { label: "Others", value: "Others" },
];
export const occupationOptions = [
  { label: "Student", value: "Student" },
  { label: "Employed", value: "Employed" },
  { label: "Self Employed", value: "Self Employed" },
  { label: "Retired", value: "Retired" },
  { label: "Housewife", value: "Housewife" },
  { label: "Unemployed", value: "Unemployed" },
  { label: "Other", value: "Other" },
];
export const maritalStatusOptions = [
  { label: "Single", value: "Single" },
  { label: "Married", value: "Married" },
  { label: "Divorced", value: "Divorced" },
  { label: "Widowed", value: "Widowed" },
  { label: "Separated", value: "Separated" },
];
export const contactRelationOptions = [
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Spouse", value: "Spouse" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Relative", value: "Relative" },
  { label: "Friend", value: "Friend" },
  { label: "Other", value: "Other" },
];
export const steps = [
  {
    title: "Basic Details",
  },
  {
    title: "IP Details and Payer",
  },
];
export const appointmentSteps = [
  {
    title: "Patient Registration",
  },
  {
    title: "Appointment",
  },
];
export const billingKeys = [
  {
    name: "OP Billing",
    id: "op_admission",
  },
  {
    name: "IP Billing",
    id: "ip_admission",
  },
];
export const paymentKeys = [
  { label: "Cash", value: "cash" },
  { label: "Card", value: "card" },
  // { label: "Cheque", value: "cheque" },
  // { label: "DD", value: "dd" },
  // { label: "NEFT", value: "neft" },
  // { label: "RTGS", value: "rtgs" },
  // { label: "IMPS", value: "imps" },
  { label: "UPI", value: "upi" },
  { label: "Others", value: "others" },
];
export const servicesKeys = [
  {
    name: "All Services",
    id: "all_services",
  },
  {
    name: "Assigned Services",
    id: "assigned_services",
  },
];
export const govtIdTypeOptiions = [
  { label: "Aadhar", value: "aadhar" },
  { label: "PanCard", value: "pancard" },
  { label: "Voter ID", value: "voter_id" },
  { label: "Passport", value: "passport" },
  { label: "Driving Licence", value: "licence" },
];
