import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  genderOptions,
  minsWalkedOptions,
  reasonOptions,
  rpeOptions,
} from "../../../Options/functionalAsessmentOptions";
import {
  getSixMinuteWalkAPI,
  sixMinuteWalkAPI,
  updateSixMinuteWalkAPI,
} from "../../../../../services/physio.services";

const SixMinuteWalkTest = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});

  const calculateDistance = () => {
    const { gender, age, height, weight } = states;
    return gender === "male adult"
      ? 7.57 * height - 5.02 * age - 1.76 * weight - 309
      : 2.11 * height - 2.29 * weight - 5.78 * age + 667;
  };

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      predicted_distance: calculateDistance().toFixed(0),
      ip_admission: admission,
    };
    try {
      if (scalesId?.smwt) {
        await updateSixMinuteWalkAPI(scalesId?.smwt, finalData);
      } else {
        const response = await sixMinuteWalkAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, smwt: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error :${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.smwt) {
      getSixMinuteWalkAPI(admission, scalesId?.smwt)
        .then((response) => {
          const formData = response.data;
          form.setFieldsValue(formData);
          setStates({
            gender: formData?.gender,
            age: formData?.age,
            height: formData?.height,
            weight: formData?.weight,
          });
        })
        .catch((error) => {
          console.log(`Error :${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  const predictedDistance = calculateDistance().toFixed(0);

  return (
    <>
      <div className="cardless-scale" style={{ height: "180vh" }}>
        <Card style={{ width: "600px" }}>
          <Form
            id="six-min-walk"
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Typography>Six Minute Walk Test</Typography>
            <Form.Item label="Gender" name={"gender"}>
              <Radio.Group
                options={genderOptions}
                onChange={(e) => handleChange("gender", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Age" name={"age"}>
              <InputNumber
                min={0}
                max={150}
                addonAfter="years"
                onChange={(value) => handleChange("age", value)}
              />
            </Form.Item>
            <Form.Item label="Height" name={"height"}>
              <InputNumber
                min={0}
                addonAfter="cms"
                onChange={(value) => {
                  handleChange("height", value);
                }}
              />
            </Form.Item>
            <Form.Item label="Weight" name={"weight"}>
              <InputNumber
                min={0}
                max={500}
                addonAfter="kgs"
                onChange={(value) => {
                  handleChange("weight", value);
                }}
              />
            </Form.Item>
            {states.age && states.age && states.height && states.weight && (
              <>
                <Typography>
                  Predicted Distance: {predictedDistance} mtrs
                </Typography>
              </>
            )}
            <Divider />
            <Typography.Title level={5}>Pre Vitals</Typography.Title>
            <Form.Item label="Heart Rate" name={"heart_rate"}>
              <InputNumber
                placeholder="Range-40 to 130"
                max={130}
                min={40}
                addonAfter="beats/min"
              />
            </Form.Item>
            <Form.Item label="SBP" name={"sbp"}>
              <InputNumber
                placeholder="Range-100 to 180"
                max={180}
                min={100}
                addonAfter="mmHg"
              />
            </Form.Item>
            <Form.Item label="DBP" name={"dbp"}>
              <InputNumber
                placeholder="Range-60 to 110"
                max={110}
                min={60}
                addonAfter="mmHg"
              />
            </Form.Item>
            <Form.Item label="SPO2" name={"spo2"}>
              <InputNumber
                placeholder="Range-90 to 100"
                max={100}
                min={90}
                addonAfter="%"
              />
            </Form.Item>
            <Form.Item label="RR" name={"rr"}>
              <InputNumber
                placeholder="Range-12 to 30"
                max={30}
                min={12}
                addonAfter="breaths/min"
              />
            </Form.Item>
            <Form.Item label="RPE" name={"rpe"}>
              <Select options={rpeOptions} />
            </Form.Item>
            <Divider />
            <Typography.Title level={5}>Post Vitals</Typography.Title>
            <Form.Item label="Heart Rate" name={"post_vital_heart_rate"}>
              <InputNumber
                placeholder="Range-40 to 200"
                max={200}
                min={40}
                addonAfter="beats/min"
              />
            </Form.Item>
            <Form.Item label="SBP" name={"post_vital_sbp"}>
              <InputNumber
                placeholder="Range-100 to 240"
                max={240}
                min={100}
                addonAfter="mmHg"
              />
            </Form.Item>
            <Form.Item label="DBP" name={"post_vital_dbp"}>
              <InputNumber
                placeholder="Range-60 to 140"
                max={140}
                min={60}
                addonAfter="mmHg"
              />
            </Form.Item>
            <Form.Item label="SPO2" name={"post_vital_spo2"}>
              <InputNumber
                placeholder="Range-80 to 100"
                max={100}
                min={80}
                addonAfter="%"
              />
            </Form.Item>
            <Form.Item label="RR" name={"post_vital_rr"}>
              <InputNumber
                placeholder="Range-12 to 60"
                max={60}
                min={12}
                addonAfter="breaths/min"
              />
            </Form.Item>
            <Form.Item label="RPE" name={"post_vital_rpe"}>
              <Select options={rpeOptions} />
            </Form.Item>
            <Form.Item label="No of Laps" name={"no_of_laps"}>
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Distance walked in meters"
              name={"distance_covered"}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Final partial lap in meters"
              name={"final_lap_in_meters"}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item label="Mins walked" name={"mins_walked"}>
              <Select options={minsWalkedOptions} />
            </Form.Item>
            <Form.Item
              label="Reasons for immediately stopping a 6MWT"
              name={"reason_for_immediate_stopping"}
            >
              <Select options={reasonOptions} />
            </Form.Item>
            <Form.Item name={"comments"}>
              <Input placeholder="Comments" />
            </Form.Item>
          </Form>
        </Card>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default SixMinuteWalkTest;
