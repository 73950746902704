import React, { useEffect, useState } from "react";
import { Button, Form, Radio, message, Input, Typography } from "antd";

import {
  getRasScaleAPI,
  rasScaleAPI,
  updateRasScaleAPI,
} from "../../../../../services/physio.services";

import unarousableSvg from "../../../../../assets/images/scalesImages/unarousable.svg";
import deepSedationSvg from "../../../../../assets/images/scalesImages/deepSedation.svg";
import moderateSedationSvg from "../../../../../assets/images/scalesImages/moderateSedation.svg";
import lightSedationSvg from "../../../../../assets/images/scalesImages/lightSedation.svg";
import drowsySvg from "../../../../../assets/images/scalesImages/drowsy.svg";
import alertAndCalmSvg from "../../../../../assets/images/scalesImages/alertAndCalm.svg";
import restlessSvg from "../../../../../assets/images/scalesImages/restless.svg";
import agitatedSvg from "../../../../../assets/images/scalesImages/agitated.svg";
import veryAgitatedSvg from "../../../../../assets/images/scalesImages/veryAgitated.svg";
import combativeSvg from "../../../../../assets/images/scalesImages/combative.svg";

import "./PhysioScales.css";

const RASScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const rassOptions = [
    {
      value: -5,
      label: "Unarousable",
      image: unarousableSvg,
    },
    { value: -4, label: "Deep Sedation", image: deepSedationSvg },
    { value: -3, label: "Moderate Sedation", image: moderateSedationSvg },
    { value: -2, label: "Light Sedation", image: lightSedationSvg },
    { value: -1, label: "Drowsy", image: drowsySvg },
    { value: 0, label: "Alert and Calm", image: alertAndCalmSvg },
    { value: 1, label: "Restless", image: restlessSvg },
    { value: 2, label: "Agitated", image: agitatedSvg },
    { value: 3, label: "Very Agitated", image: veryAgitatedSvg },
    { value: 4, label: "Combative", image: combativeSvg },
  ];

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };

    try {
      if (Object.keys(formData).length !== 0) {
        await updateRasScaleAPI(scalesId?.rass, finalData);
      } else {
        const response = await rasScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, rass: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error: ${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.rass) {
      try {
        const response = await getRasScaleAPI(admission, scalesId?.rass);
        setFormData(response.data);
        form.setFieldsValue(response.data);
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale">
        <Form
          id="rass"
          onFinish={handleSubmit}
          form={form}
          initialValues={formData}
        >
          <Form.Item name="rass_scale">
            {/* <Typography>Richmond Agitation - Sedation Scale</Typography> */}
            <Radio.Group className="radio-group">
              {rassOptions.map((option) => (
                <Radio.Button
                  key={option.value}
                  value={option.value}
                  style={{
                    height: "100px",
                    width: "100px",
                    padding: 0,
                    margin: "0 10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="visual-option">
                    {option.image && (
                      <img
                        src={option.image}
                        alt={option.label}
                        className="visual-image"
                      />
                    )}
                    <div
                      className="visual-label"
                      style={{ fontSize: "10px", lineHeight: "1.2" }}
                    >
                      {option.value}
                      <br />
                      {option.label}
                    </div>
                  </div>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item name="comments">
            <Input placeholder="Comments" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default RASScale;
