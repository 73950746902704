import axiosInstance from "../config/axios.config";

export const getCategoryApi = () => {
  return axiosInstance.get(`/careservices/services/service-categories/`);
};
export const createServiceApi = (payload) => {
  return axiosInstance.post(`/careservices/services/`, payload);
};
export const updateServiceApi = (service_id, payload) => {
  return axiosInstance.patch(`/careservices/services/${service_id}/`, payload);
};
export const getServicesApi = () => {
  return axiosInstance.get(`/careservices/services/`);
};
export const getFacilityServicesApi = (facility_id, params) => {
  return params
    ? axiosInstance.get(
        `/inpatient/services/facility/${facility_id}/?${params}`
      )
    : axiosInstance.get(`/careservices/services/facility/${facility_id}/`);
};
export const createCategoryApi = (payload) => {
  return axiosInstance.post(
    `/careservices/services/service-categories/`,
    payload
  );
};
export const updateCategoryApi = (category_id, payload) => {
  return axiosInstance.patch(
    `/careservices/services/service-categories/${category_id}/`,
    payload
  );
};
export const createPackage = (payload) => {
  return axiosInstance.post(`/careservices/packages/`, payload);
};
export const getPackages = () => {
  return axiosInstance.get(`/careservices/packages/`);
};
export const updatePackage = (id, payload) => {
  return axiosInstance.patch(`/careservices/packages/${id}/`, payload);
};
