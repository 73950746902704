import React, { useState, useEffect } from "react";
import { Form, Select, DatePicker } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import PratitionerSearch from "./PractitionerSearch";
import { getFacilityListApi } from "../../../services/facilities.services";
import Slot from "./Slot";
import dayjs from "dayjs";
import { validateConfig } from "../../../config/validate.config";
const { Option } = Select;

const AppointmentFormList = ({
  facilityId,
  setFacilityId,
  formValues,
  selectedPractitioner,
  setSelectedPractitioner,
  selectedSlot,
  setSelectedSlot,
  selectedEndSlot,
  setSelectedEndSlot,
  selectedId,
  editData,
}) => {
  const [practitionerText, setPractitionerText] = useState("");
  const [facilities, setFacilities] = useState(null);
  console.log(selectedId);
  useEffect(() => {
    getFacilityListApi()
      .then((res) => {
        setFacilities(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function disableDate(current) {
    return current && current < dayjs().startOf("day");
  }
  return (
    <>
      <Form.Item
        label="Facility"
        name="facility"
        rules={validateConfig.isRequired("Facility")}
      >
        <Select
          allowClear
          style={{ width: "300px" }}
          placeholder="Choose facility"
          className={inputStyles.mdSelect}
          optionFilterProp="children"
          onChange={(value) => setFacilityId(value)}
        >
          {facilities?.map((facility) => (
            <Option value={facility?.id} key={facility?.id}>
              {facility?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {facilityId && (
        <PratitionerSearch
          formValues={formValues}
          selectedPractitioner={selectedPractitioner}
          setSelectedPractitioner={setSelectedPractitioner}
          practitionerText={practitionerText}
          setPractitionerText={setPractitionerText}
          facilityId={facilityId}
        />
      )}
      {selectedPractitioner && (
        <Form.Item
          label="Date "
          name="appointment_date"
          rules={validateConfig.isRequired("Date")}
        >
          <DatePicker
            className={inputStyles.mdDateInput}
            style={{ width: "300px" }}
            disabledDate={disableDate}
          />
        </Form.Item>
      )}
      {(formValues?.appointment_date || editData?.appointment_date) && (
        <Slot
          formValues={formValues}
          selectedPractitioner={selectedPractitioner}
          facilityId={facilityId}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          selectedEndSlot={selectedEndSlot}
          setSelectedEndSlot={setSelectedEndSlot}
          editData={editData}
        />
      )}
      <Form.Item label="Status" name="status" initialValue="scheduled">
        <Select
          className={inputStyles.mdSelect}
          style={{ width: "300px" }}
          defaultValue="scheduled"
        >
          <Option value="scheduled">Scheduled</Option>
          {selectedId && <Option value="reported">Reported</Option>}
        </Select>
      </Form.Item>
    </>
  );
};

export default AppointmentFormList;
