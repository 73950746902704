import { Form, Input, Radio } from "antd";
import React from "react";
import { tracheostomyTubeOptions } from "../../Options/RespiratoryTherapistOptions";

const TracheostomyTube = () => {
  return (
    <>
      <Form.Item
        label="Tracheostomy Tube"
        name={["tracheostomy_tube", "status"]}
      >
        <Radio.Group options={tracheostomyTubeOptions} />
      </Form.Item>
      <Form.Item name={["tracheostomy_tube", "comments"]}>
        <Input placeholder="Comments" />
      </Form.Item>
    </>
  );
};

export default TracheostomyTube;
