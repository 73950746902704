import React, { forwardRef, useState } from "react";
import { Form, Radio, Input, Row, Col, Descriptions, Button } from "antd";

import CareItemForm from "./CareItemForm";
import {
  urinaryCatheterTypeOptions,
  urinaryFoleysCatheterOptions,
} from "../../Options/careItemsOptions";

const { TextArea } = Input;

const formItems = (
  <>
    <Form.Item label="Catheter Type" name="urinary_catheter_type">
      <Radio.Group options={urinaryCatheterTypeOptions} />
    </Form.Item>

    <Form.Item label="Foleys Catheter Type" name="foleys_catheter_type">
      <Radio.Group options={urinaryFoleysCatheterOptions} />
    </Form.Item>

    <Form.Item name="urinary_catheter_care_notes" label="Comments">
      <TextArea type="text" placeholder="Enter Notes" />
    </Form.Item>
  </>
);

const items = (item) => [
  {
    key: "urinary_catheter_type",
    label: "Catheter Type",
    children:
      urinaryCatheterTypeOptions?.find(
        (option) => option.value === item?.urinary_catheter_type
      )?.label || "-",
  },
  {
    key: "foleys_catheter_type",
    label: "Foleys Catheter Type",
    children:
      urinaryFoleysCatheterOptions?.find(
        (option) => option.value === item?.foleys_catheter_type
      )?.label || "-",
  },
  {
    key: "urinary_catheter_care_notes",
    label: "Notes",
    children: item?.urinary_catheter_care_notes || "-",
  },
];

const UrinaryCatheter = (props, ref) => {
  const { formData, getData, isIaCareItem, updateIACareItemRecord, iaAction } =
    props;
  const [formState, setFormState] = useState({
    editingIndex: null,
    adding: false,
  });

  return (
    <Row gutter={[12, 12]}>
      {formData?.data?.map((item, index) => (
        <CareItemForm
          ref={ref}
          key={index}
          formData={item}
          getData={getData}
          iaAction={iaAction}
          formState={formState}
          setFormState={setFormState}
          isIaCareItem={isIaCareItem}
          formType="urinary_catheter_care"
          updateIACareItemRecord={updateIACareItemRecord}
          formItems={
            isIaCareItem ||
            item === undefined ||
            formState?.editingIndex === item?.id ? (
              formItems
            ) : (
              <Descriptions
                className="descriptions-custom-column-width"
                bordered
                column={1}
                items={items(item?.data)}
              />
            )
          }
        />
      ))}
      {(formData === undefined || formData?.isallstopstate) && (
        <>
          {formData !== undefined && !formState?.adding ? (
            <Col span={12} style={{ textAlign: "center" }}>
              <Button
                type="dashed"
                onClick={() =>
                  setFormState((prev) => ({ ...prev, adding: true }))
                }
              >
                Add Another
              </Button>
            </Col>
          ) : (
            <CareItemForm
              getData={getData}
              formItems={formItems}
              formState={formState}
              setFormState={setFormState}
              formType="urinary_catheter_care"
            />
          )}
        </>
      )}
    </Row>
  );
};

export default forwardRef(UrinaryCatheter);
