import React from "react";
import { Form, Input, Col, Select, DatePicker } from "antd";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { contactRelationOptions } from "../receptionKeys";
import dayjs from "dayjs";

const MoreDetails = () => {
  return (
    <>
      <Col span={8}>
        <Form.Item
          label="IP Admission Date"
          name="admission_date"
          rules={[
            { required: true, message: "Please select an admission date" },
          ]}
        >
          <DatePicker
            defaultValue={dayjs()}
            className={inputStyles?.mdDateInput}
            style={{ width: "300px" }}
            allowClear={false}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Emergency Contact Name" name="emergency_contact_name">
          <Input className={inputStyles.mdInput} style={{ width: "80%" }} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Emergency Contact No" name="emergency_contact_no">
          <Input
            className={inputStyles.mdAddonInput}
            style={{ width: "80%" }}
            maxLength="10"
            placeholder="Mobile Number"
            addonBefore={"+91"}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Emergency Contact Relation"
          name="emergency_contact_relation"
        >
          <Select
            options={contactRelationOptions}
            style={{ width: "300px" }}
            className={inputStyles.mdSelect}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Primary Hospital Name" name="primary_hospital_name">
          <Input className={inputStyles.mdInput} style={{ width: "80%" }} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Primary Consultant Name"
          name="primary_consultant_name"
        >
          <Input
            placeholder="Primary Consultant Name"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Primary Consultant Mobile"
          name="primary_consultant_contact"
        >
          <Input
            className={inputStyles.mdAddonInput}
            style={{ width: "80%" }}
            maxLength="10"
            placeholder="Mobile Number"
            addonBefore={"+91"}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Primary Hospital Address"
          name="primary_hospital_address"
        >
          <Input.TextArea
            placeholder="Primary Hospital Address"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Designation" name="primary_consultant_designation">
          <Input
            placeholder="Designation"
            className={inputStyles.mdInput}
            style={{ width: "80%" }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default MoreDetails;
