import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Radio, Divider } from "antd";
import { yesNoOptions } from "../../../../Options/commonOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";

const SurgicalIncision = () => {
  const { formData } = useContext(DoctorInitialContext);
  const [showSurgicalDetails, setShowSurgicalDetails] = useState(
    formData?.systemic_examination?.surgical_incision === "yes" ? true : false
  );

  const handleSurgicalChange = (e) => {
    if (e.target.value === "yes") {
      setShowSurgicalDetails(true);
    } else {
      setShowSurgicalDetails(false);
    }
  };

  useEffect(() => {
    setShowSurgicalDetails(
      formData?.systemic_examination?.surgical_incision === "yes" ? true : false
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Surgical Incision" name="surgical_incision">
        <Radio.Group options={yesNoOptions} onChange={handleSurgicalChange} />
      </Form.Item>
      {showSurgicalDetails && (
        <Form.Item name="surgical_incision_description">
          <Input type="text" placeholder="Description" />
        </Form.Item>
      )}
    </>
  );
};

export default SurgicalIncision;
