import React, { useContext, useState, useEffect } from "react";
import { Form, Checkbox, Radio } from "antd";
import { urineTypeOptions } from "../../../../Options/genExamOptions";
import { normalAbnormalOptions } from "../../../../Options/commonOptions";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
const UrineExamination = () => {
  const { formData } = useContext(DoctorInitialContext);
  const [urineState, setUrineState] = useState(
    formData?.general_examination?.urine_examination === "abnormal"
      ? "abnormal"
      : null
  );
  useEffect(() => {
    setUrineState(
      formData?.general_examination?.urine_examination === "abnormal"
        ? "abnormal"
        : null
    );
  }, [formData]);
  return (
    <>
      <Form.Item label="Urine Examination" name="urine_examination" rules={[]}>
        <Radio.Group
          options={normalAbnormalOptions}
          onChange={(e) => setUrineState(e.target.value)}
        />
      </Form.Item>
      {urineState === "abnormal" && (
        <>
          <Form.Item name="urine_type">
            <Checkbox.Group options={urineTypeOptions} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default UrineExamination;
