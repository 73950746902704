import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Drawer, Typography, Steps } from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import InitialInspectionForm from "./InitialInspectionForm";
import InitialInspectionSummary from "./InitialInspectionSummary";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";
import { CloseOutlined } from "@ant-design/icons";
import CareItems from "../CareItems/CareItems";
import InvestigationsInitial from "../../Investigations/InvestigationsInitial";
import MedicinesInitial from "../MedicinesInitial.js/MedicinesInitial";
import ClosePopup from "./ClosePopup";
const items = [
  {
    title: "Initial Assessment",
  },
  {
    title: "Care Items",
  },
  {
    title: "Initial Medicine",
  },
  {
    title: "Initial Investigation",
  },
];
const InitialInspection = ({ refreshPage, editId, patientData }) => {
  const [visible, setVisible] = useState(false);
  const { formData, setFormData } = useContext(DoctorInitialContext);
  const [initialRefresh, setInitialRefresh] = useState(false);
  const [current, setCurrent] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const setInitialAssessmentRefresh = () => {
    setInitialRefresh((prev) => !prev);
  };

  const openEdit = async () => {
    if (editId) {
      try {
        const response = await getDoctorInspectionAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, initialRefresh]);

  // const onChange = (value) => {
  //   setCurrent(value);
  // };
  // console.log(patientData);
  return (
    <>
      {editId ? (
        <Row gutter={[12, 12]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(false);
              }}
              className={btnStyles.lgBtn}
            >
              Edit
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <InitialInspectionSummary editId={editId} />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
                setAddModal(true);
              }}
              className={btnStyles.lgBtn}
            >
              Initial Assessment Form
            </Button>
          </Col>
        </Row>
      )}
      {visible && (
        <Drawer
          open={visible}
          width="100%"
          title={
            <Row>
              <Col span={4} className="flex-start">
                <Typography
                  style={{
                    marginLeft: "12px",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}
                </Typography>
              </Col>
              {(!formData?.care_items_generated || addModal) && (
                <Col span={18}>
                  <Steps
                    current={current}
                    items={items}
                    //  onChange={onChange}
                  />
                </Col>
              )}

              <Col
                span={!formData?.care_items_generated || addModal ? 2 : 20}
                className="flex-end"
              >
                <CloseOutlined onClick={() => setConfirmModal(true)} />
              </Col>
            </Row>
          }
          footer={null}
          onClose={() => setVisible(false)}
          closeIcon={null}
          keyboard={false}
        >
          {!formData?.care_items_generated || addModal ? (
            <>
              {current === 0 && (
                <InitialInspectionForm
                  visible={visible}
                  setVisible={setVisible}
                  refreshPage={refreshPage}
                  initialRefresh={setInitialAssessmentRefresh}
                  editId={editId}
                  setCurrent={setCurrent}
                />
              )}
              {current === 1 && (
                <CareItems
                  setCurrent={setCurrent}
                  initialRefresh={setInitialAssessmentRefresh}
                />
              )}
              {current === 2 && <MedicinesInitial setCurrent={setCurrent} />}
              {current === 3 && (
                <InvestigationsInitial
                  setCurrent={setCurrent}
                  setVisible={setVisible}
                />
              )}
            </>
          ) : (
            <InitialInspectionForm
              visible={visible}
              setVisible={setVisible}
              refreshPage={refreshPage}
              initialRefresh={setInitialAssessmentRefresh}
              editId={editId}
              setCurrent={setCurrent}
            />
          )}

          {confirmModal && (
            <ClosePopup
              open={confirmModal}
              setOpen={setConfirmModal}
              setVisible={setVisible}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

export default InitialInspection;
