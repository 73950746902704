import React, { useContext, useEffect, useState } from "react";
import CheckListFBYInput from "../../../../blocks/FormItems/CheckListFBYInput";
import { Typography } from "antd";
import { otherSpecificationsOptions } from "../../Options/dietAdviceOptions";

const OtherSpecifications = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState([]);
  useEffect(() => {
    const initialCheckboxes = otherSpecificationsOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          formData?.diet_advice?.other_specifications?.[checkbox.value] ||
          formData?.functional_diagnosis?.other_specifications?.[
            checkbox.value
          ];
        return acc;
      },
      {}
    );
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <Typography>Other Specifications</Typography>
      <div className="subsection-card">
        <CheckListFBYInput
          options={otherSpecificationsOptions}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          keyChain={"other_specifications"}
        />
      </div>
    </>
  );
};

export default OtherSpecifications;
