import { Button, Space, Empty, Typography } from "antd";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PdfViewer({
  selectedPdfGroup,
  width = null,
  height = null,
  pageNumber,
  pdfData,
}) {
  const data = selectedPdfGroup?.pdf;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const [pageNumbers, setPageNumbers] = useState([]);
  const [numPages, setNumPages] = useState([]);
  const [loadErrors, setLoadErrors] = useState([]);
  console.log(pdfData, "gggggg");
  const settings = {
    dots: data?.length > 1,
    infinite: data?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: data?.length > 1,
  };

  const onDocumentLoadSuccess = (pdf, index) => {
    const newNumPages = [...numPages];
    newNumPages[index] = pdf.numPages;
    setNumPages(newNumPages);

    const newPageNumbers = [...pageNumbers];
    newPageNumbers[index] = 1; // Reset to the first page when a new PDF is loaded
    setPageNumbers(newPageNumbers);
  };

  const goToPrevPage = (index) => {
    const newPageNumbers = [...pageNumbers];
    newPageNumbers[index] =
      pageNumbers[index] - 1 <= 1 ? 1 : pageNumbers[index] - 1;
    setPageNumbers(newPageNumbers);
  };

  const goToNextPage = (index) => {
    const newPageNumbers = [...pageNumbers];
    newPageNumbers[index] =
      pageNumbers[index] + 1 >= numPages[index]
        ? numPages[index]
        : pageNumbers[index] + 1;
    setPageNumbers(newPageNumbers);
  };

  return (
    <>
      {pdfData?.length > 0 ? (
        <>
          {pdfData?.map((item, index) => (
            <div key={index}>
              <Typography style={{ fontWeight: 500, fontSize: "18px" }}>
                {item.path}
              </Typography>
              <div
                style={{
                  overflow: "hidden",
                  height: height || "650px",
                  overflowY: "auto",
                }}
              >
                <Document
                  file={item?.pdf || item?.url}
                  onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf, index)}
                  onLoadError={() => {
                    const newLoadErrors = [...loadErrors];
                    newLoadErrors[index] = true;
                    setLoadErrors(newLoadErrors);
                  }}
                  error={
                    loadErrors[index] ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Empty description="Cannot load pdf file" />
                      </div>
                    ) : (
                      "Failed to load PDF"
                    )
                  }
                >
                  <Page
                    noData="Unable to read file"
                    pageNumber={pageNumber ? pageNumber : pageNumbers[index]}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    size="A4"
                    scale={1.5}
                  />
                </Document>
              </div>
              {!loadErrors[index] && numPages[index] > 1 && !pageNumber && (
                <Space
                  align="end"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end !important",
                  }}
                >
                  <Button
                    onClick={() => goToPrevPage(index)}
                    disabled={pageNumbers[index] <= 1}
                  >
                    Prev
                  </Button>
                  <Button
                    onClick={() => goToNextPage(index)}
                    disabled={numPages[index] === pageNumbers[index]}
                  >
                    Next
                  </Button>
                </Space>
              )}
            </div>
          ))}
        </>
      ) : (
        <Slider {...settings}>
          {data?.map((item, index) => (
            <div key={index}>
              <Typography style={{ fontWeight: 500, fontSize: "18px" }}>
                {item.path}
              </Typography>
              <div
                style={{
                  overflow: "hidden",
                  height: height || "600px",
                  overflowY: "auto",
                }}
              >
                <Document
                  file={item?.pdf}
                  onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf, index)}
                  onLoadError={() => {
                    const newLoadErrors = [...loadErrors];
                    newLoadErrors[index] = true;
                    setLoadErrors(newLoadErrors);
                  }}
                  error={
                    loadErrors[index] ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Empty description="Cannot load pdf file" />
                      </div>
                    ) : (
                      "Failed to load PDF"
                    )
                  }
                >
                  <Page
                    noData="Unable to read file"
                    pageNumber={pageNumber ? pageNumber : pageNumbers[index]}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    size="A4"
                    scale={1.5}
                  />
                </Document>
              </div>
              {!loadErrors[index] && numPages[index] > 1 && !pageNumber && (
                <Space
                  align="end"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end !important",
                  }}
                >
                  <Button
                    onClick={() => goToPrevPage(index)}
                    disabled={pageNumbers[index] <= 1}
                  >
                    Prev
                  </Button>
                  <Button
                    onClick={() => goToNextPage(index)}
                    disabled={numPages[index] === pageNumbers[index]}
                  >
                    Next
                  </Button>
                </Space>
              )}
            </div>
          ))}
        </Slider>
      )}
    </>
  );
}

export default PdfViewer;
