import React, { useState, useEffect } from "react";
import { Row, Col, Card, Statistic, Typography } from "antd";
import { getIPBillDetailsApi } from "../../../../services/receptionist.services";
import useQuery from "../../../../hooks/useQuery";
import ServicesBilling from "./ServicesBilling";
import BedsIp from "./BedsIp";
import RecordPayment from "./RecordPayment";
import { formatToINR } from "../../../../helpers/utility";

const BillingIp = () => {
  const params = useQuery();

  const [billData, setBillData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const refreshData = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    // if (params?.patient) {
    getIPBillDetailsApi(params?.patient)
      .then((res) => {
        setBillData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }, [params?.patient, refresh]);

  const customCards = (title, value) => {
    return (
      <Card style={{ border: "1px solid #D9D9D9" }}>
        <Row>
          <Col span={12}>
            <Typography>{title}</Typography>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            {title === "Admitted Since" ||
            title === "No of Services Availed" ? (
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                {value} {title === "Admitted Since" && "days"}
              </Typography>
            ) : (
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                {value < 0 ? (
                  <Statistic
                    value={formatToINR(value?.toFixed(2))}
                    suffix={"/-"}
                    valueStyle={{
                      color: title === "Balance Amount" && "#FD6050",
                      fontSize: "18px",
                    }}
                  />
                ) : (
                  <Statistic
                    value={formatToINR(value?.toFixed(2))}
                    suffix={"/-"}
                    valueStyle={{
                      color: title === "Balance Amount" && "#40C164",
                      fontSize: "18px",
                    }}
                  />
                )}
              </Typography>
            )}
          </Col>
        </Row>
      </Card>
    );
  };
  const cardData = billData?.data;
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          <Col span={6}>
            {customCards("Admitted Since", cardData?.admission?.no_of_days)}
          </Col>
          <Col span={6}>
            {customCards(
              "Tentative Bill Amount",
              cardData?.payment?.total_amount
            )}
          </Col>
          <Col span={6}>
            {customCards("Balance Amount", cardData?.payment?.due_amount)}
          </Col>
          <Col span={6}>
            {customCards(
              "No of Services Availed",
              cardData?.admission?.no_of_services_availed
            )}
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <ServicesBilling billData={billData} />
      </Col>
      <Col span={12}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <BedsIp billData={billData} />
          </Col>
          <Col span={24}>
            <RecordPayment billData={billData} refreshData={refreshData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BillingIp;
