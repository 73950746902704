import React, { useContext, useState } from "react";
import CommonLayout from "../Layout/CustomLayout";
import SideMenu from "../Layout/CustomLayout/SideMenu";
import { Typography, Card, Button, Input, Form, message } from "antd";
import textStyles from "../../styles/Custom/Typography.module.css";
import inputStyles from "../../styles/Custom/Input.module.css";
import btnStyles from "../../styles/Custom/Button.module.css";
import styles from "../../styles/Custom/Dashboard/Dashboard.module.css";
import AccountTabs from "./AccountTabs";
import DoctorLayout from "../Layout/DoctorLayout/index";
import {
  changePasswordApi,
  userMetaDataApi,
} from "../../services/auth.services";
import { getCookie } from "../../helpers/localStorage";
import { UserContext } from "../../context/UserContext";
const Password = () => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const resp = await changePasswordApi({
        current_password: values?.current_password,
        new_password: values?.new_password,
      });
      if (resp) {
        setUpdatePassword(false);
        message.success(resp?.data?.message);
        getUserData();
        form.resetFields();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  const getUserData = async () => {
    try {
      let resp = await userMetaDataApi();
      const orgId = getCookie("organisation_id");
      setUserDetails({
        ...resp?.data,
        activeOrg: orgId
          ? resp?.data?.organizations?.filter(
              (org) => org?.org_id == orgId
            )?.[0]
          : {},
      });
    } catch (error) {
      console.log(error);
    }
  };
  const commonProfile = () => {
    return (
      <Card
        style={{ width: "50%", margin: "auto" }}
        className={`${styles.personalDetailsCard} centeredCard`}
      >
        <Form
          onFinish={onFinish}
          onFinishFailed={(values) => console.log(values)}
          onValuesChange={() => setUpdatePassword(true)}
          style={{ marginTop: "24px" }}
          layout="vertical"
          form={form}
          requiredMark={false}
        >
          <Form.Item label="Current password" name="current_password">
            <Input.Password
              placeholder="Enter current password"
              className={inputStyles.mdInput}
            />
          </Form.Item>
          <Form.Item
            label="New password"
            name="new_password"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                message:
                  "Password must have at least 8 characters, including uppercase, lowercase, digit, and special character.",
                pattern: new RegExp(
                  "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                ),
              },
            ]}
          >
            <Input.Password
              placeholder="Enter new password"
              className={inputStyles.mdInput}
            />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirm_new_password"
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The new passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm new password"
              className={inputStyles.mdInput}
            />
          </Form.Item>
        </Form>
      </Card>
    );
  };
  return (
    <>
      {userDetails?.activeOrg?.default_role === "Doctor" ||
      userDetails?.activeOrg?.default_role === "Nurse" ? (
        <DoctorLayout
          header={
            <div
              style={{
                gap: "5%",
                width: "550px",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>Account</Typography>
              <AccountTabs />
            </div>
          }
          footer={
            updatePassword && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdatePassword(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonProfile()}
        </DoctorLayout>
      ) : (
        <CommonLayout
          sideContent={<SideMenu />}
          header={
            <div
              style={{
                gap: "5%",
                width: "100%",
              }}
              className="flex-start"
            >
              <Typography className={textStyles.h6}>Account</Typography>
              <AccountTabs />
            </div>
          }
          footer={
            updatePassword && (
              <div className="flex-end" style={{ gap: "24px" }}>
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setUpdatePassword(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                  className={btnStyles.mdBtn}
                >
                  Save
                </Button>
              </div>
            )
          }
        >
          {commonProfile()}
        </CommonLayout>
      )}
    </>
  );
};

export default Password;
