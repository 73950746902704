export const goalsOptions = [
  { label: "Weight Loss", value: "weight_loss" },
  { label: "Weight Gain", value: "weight_gain" },
  {
    label: "Maintain Present Nutritional Status",
    value: "maintain_present_nutritional_status",
  },
  {
    label: "Improve Nutritional Status",
    value: "improve_nutritional_status",
  },
  { label: "Maintain Blood Pressure", value: "maintain_blood_pressure" },
  { label: "Maintain Blood Sugar", value: "maintain_blood_sugar" },
  {
    label: "Reduce Cholesterol / Triglycerides",
    value: "reduce_cholesterol",
  },
  { label: "Others", value: "others" },
];
