import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

// Define custom relative time formats
const customRelativeTime = {
  future: "in %s",
  past: "%s ago",
  s: "a few secs",
  m: "a min",
  mm: "%d mins",
  h: "an hr",
  hh: "%d hrs",
  d: "a day",
  dd: "%d days",
  M: "a month",
  MM: "%d months",
  y: "a year",
  yy: "%d years",
};

// Extend dayjs with the custom relative time configuration
dayjs.updateLocale("en", { relativeTime: customRelativeTime });

function useTwitterTimestamp(date) {
  return dayjs(date).fromNow();
}

export default useTwitterTimestamp;
