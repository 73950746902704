import { Card, Checkbox, Form, Radio, Row, Col, Typography } from "antd";
import React from "react";
import { attentionCheckboxOptions } from "../../../../Options/physioOptions";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Attention = () => {
  const attentionFields = [
    {
      label: "Repeat the following in forward order",
      sequence: "2 1 8 5 4",
      value: "attention_1",
    },
    {
      label: "Repeat the following in backward order",
      sequence: "7 4 2",
      value: "attention_2",
    },
    {
      label: "Repeat the list and tap hand on occurrence of A",
      sequence: "F B A C M N A A J K L B A F A K D E A A A J A M O F A A B",
      value: "attention_3",
    },
  ];

  return (
    <>
      {attentionFields.map((field) => (
        <Card key={field.value} style={{ marginBottom: 16 }}>
          <Typography style={{ textAlign: "center" }}>
            <Typography.Text strong>{field.label}</Typography.Text>
            <br />
            <Typography.Text strong>{field.sequence}</Typography.Text>
          </Typography>
          <Form.Item name={["attention_data", field.value]}>
            <Row justify="center">
              <Col>
                <Radio.Group style={{ width: "100%" }}>
                  <Row justify="center" gutter={[8, 0]}>
                    <Col>
                      <Radio.Button
                        value={"yes"}
                        style={{
                          backgroundColor: "#E9D7FE",
                          padding: "0 8px",
                        }}
                      >
                        <LikeOutlined />
                      </Radio.Button>
                    </Col>
                    <Col>
                      <Radio.Button
                        value={"no"}
                        style={{
                          backgroundColor: "#FFF5F5",
                          padding: "0 8px",
                        }}
                      >
                        <DislikeOutlined />
                      </Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              </Col>
            </Row>
          </Form.Item>
        </Card>
      ))}
      <Card key="attention_4" style={{ marginBottom: 16 }}>
        <Typography style={{ textAlign: "center" }}>
          <Typography.Text strong>
            Serial 7 subtraction starting from 100
          </Typography.Text>
          <br />
          <Typography.Text strong></Typography.Text>
        </Typography>
        <Form.Item name={["attention_data", "attention_4"]}>
          <Row justify="center">
            <Col>
              <Checkbox.Group options={attentionCheckboxOptions} />
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </>
  );
};

export default Attention;
