import { Descriptions } from "antd";
import React, { useContext } from "react";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";
import {
  getLabel,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsArray,
} from "../constructTextFromValues";
import {
  activityLevelOptions,
  airwaySizeOptions,
  airwayTubeTypeOptions,
  breathSoundOptions,
  chestExpansionOptions,
  coughEffectivenessOptions,
  coughOptions,
  oxygenRequiredOptions,
  respiratoryPatternOptions,
  trachealSecretionConsistencyOptions,
  trachealStomaOptions,
  ventilationStatusOptions,
  mentalStatusOptions,
} from "../../Options/RespiratoryTherapistOptions";

const PatientAssessmentSummary = () => {
  const { formData } = useContext(RespiratoryInitialContext);
  const constructPatientAssessmentSummaryItems = () => {
    const patientAssessmentSummaryItems = [];
    const patient = formData?.patient_assessment;
    const pushPatientItem = (key, label, children) => {
      patientAssessmentSummaryItems.push({ key, label, children });
    };
    if (
      patient?.air_way?.maintains_own ||
      patient?.air_way?.tracheostomy ||
      patient?.air_way?.laryngeal_mask ||
      patient?.air_way?.oropharyngeal ||
      patient?.air_way?.nasopharyngeal
    ) {
      pushPatientItem(
        "1",
        "Air Way",
        <>
          {getLabelTagInput("Maintains Own", patient?.air_way?.maintains_own)}
          {patient?.air_way?.tracheostomy && (
            <>
              {getLabelTag(
                airwaySizeOptions,
                patient?.air_way?.tracheostomy_details?.size
              )}
              {getLabelTagsArray(
                airwayTubeTypeOptions,
                patient?.air_way?.tracheostomy_details?.tube_type
              )}
              {getLabelTag(
                trachealStomaOptions,
                patient?.air_way?.tracheostomy_details?.tracheal_stoma
              )}
              {getLabelTagsArray(
                trachealSecretionConsistencyOptions,
                patient?.air_way?.tracheostomy_details?.secretion_consistency
              )}
              {getLabelTagInput(
                "Comments",
                patient?.air_way?.tracheostomy_details?.comments
              )}
            </>
          )}
          {patient?.air_way?.laryngeal_mask && getLabel("Laryngeal Mask")}
          {patient?.air_way?.oropharyngeal && getLabel("Oropharyngeal")}
          {patient?.air_way?.nasopharyngeal && getLabel("Nasopharyngeal")}
        </>
      );
    }

    if (patient?.ventilation_details?.status) {
      pushPatientItem(
        "2",
        "Ventilation Status",
        <>
          {getLabelTag(
            ventilationStatusOptions,
            patient?.ventilation_details?.status
          )}
          {getLabelTagInput("Coments", patient?.ventilation_details?.comments)}
        </>
      );
    }

    if (patient?.respiratory_details?.pattern) {
      pushPatientItem(
        "3",
        "Respiratory Pattern",
        <>
          {getLabelTag(
            respiratoryPatternOptions,
            patient?.respiratory_details?.pattern
          )}
          {getLabelTagInput("Comments", patient?.respiratory_details?.comments)}
        </>
      );
    }

    if (patient?.mental_details?.status) {
      pushPatientItem(
        "4",
        "Mental Details",
        <>
          {getLabelTag(mentalStatusOptions, patient?.mental_details?.status)}
          {getLabelTagInput("Comments", patient?.mental_details?.comments)}
        </>
      );
    }

    if (patient?.chest_expansion?.status) {
      pushPatientItem(
        "5",
        "Chest Expansion",
        <>
          {getLabelTag(chestExpansionOptions, patient?.chest_expansion?.status)}
          {getLabelTagInput("Comments", patient?.chest_expansion?.comments)}
        </>
      );
    }

    if (patient?.breath_sounds?.status) {
      pushPatientItem(
        "6",
        "Breath Sounds",
        <>
          {getLabelTag(breathSoundOptions, patient?.breath_sounds?.status)}
          {getLabelTagInput("Comments", patient?.breath_sounds?.comments)}
        </>
      );
    }

    if (patient?.cough_effectiveness?.status) {
      pushPatientItem(
        "7",
        "Cough Effectiveness",
        <>
          {getLabelTag(
            coughEffectivenessOptions,
            patient?.cough_effectiveness?.status
          )}
          {getLabelTagInput("Comments", patient?.cough_effectiveness?.comments)}
        </>
      );
    }

    if (patient?.activity_level?.status) {
      pushPatientItem(
        "8",
        "Activity Level",
        <>
          {getLabelTag(activityLevelOptions, patient?.activity_level?.status)}
          {getLabelTagInput("Comments", patient?.activity_level?.comments)}
        </>
      );
    }

    if (patient?.oxygen_required?.status) {
      pushPatientItem(
        "9",
        "O2 Required for spO2>92%",
        <>
          {getLabelTag(oxygenRequiredOptions, patient?.oxygen_required?.status)}
          {getLabelTagInput("Comments", patient?.oxygen_required?.comments)}
        </>
      );
    }

    if (patient?.cough?.status) {
      pushPatientItem(
        "10",
        "Cough",
        <>
          {getLabelTag(coughOptions, patient?.cough?.status)}
          {getLabelTagInput("Comments", patient?.cough?.comments)}
        </>
      );
    }
    return patientAssessmentSummaryItems;
  };

  const patientAssessmentSummaryItems =
    constructPatientAssessmentSummaryItems();
  return (
    <>
      <Descriptions
        bordered
        column={1}
        title="Patient Assessment"
        items={patientAssessmentSummaryItems}
        style={{ margin: "0", border: "none" }}
      />
    </>
  );
};

export default PatientAssessmentSummary;
