import React from "react";
import { Form, InputNumber, Typography } from "antd";
const { Text } = Typography;

const VitalsAdmission = () => {
  return (
    <>
      <Text>Vitals at the time of Admission</Text>
      <Form.Item name="temperature" label="Temperature">
        <InputNumber
          min={94}
          max={106}
          placeholder="Range:94-106 "
          addonAfter="Degrees F"
          style={{ width: "20%" }}
        />
      </Form.Item>
      <Form.Item name="pulse_rate" label="Pulse Rate">
        <InputNumber
          min={40}
          max={160}
          placeholder="Range:40-160 "
          addonAfter="beats/min"
          style={{ width: "20%" }}
        />
      </Form.Item>
      <Form.Item name="respiratory_rate" label="Respiratory Rate">
        <InputNumber
          min={6}
          max={60}
          placeholder="Range:6-60 "
          addonAfter="breaths/min"
          style={{ width: "20%" }}
        />
      </Form.Item>
      <Form.Item name="systolic_bp" label="Systolic BP">
        <InputNumber
          min={60}
          max={200}
          placeholder="Range:60-200 "
          addonAfter="mmHg"
          style={{ width: "20%" }}
        />
      </Form.Item>
      <Form.Item name="diastolic_bp" label="Diastolic BP">
        <InputNumber
          min={40}
          max={140}
          placeholder="Range:40-140 "
          addonAfter="mmHg"
          style={{ width: "20%" }}
        />
      </Form.Item>
      <Form.Item name="spo2" label="SpO2">
        <InputNumber
          min={75}
          max={100}
          placeholder="Range:75-100 "
          addonAfter="%"
          style={{ width: "20%" }}
        />
      </Form.Item>
    </>
  );
};

export default VitalsAdmission;
