import React, { useContext, useEffect, useState } from "react";
import NurseHistoryForm from "../../InspectionForms/HistoryForm/NurseHistoryForm";
import NurseExaminationForm from "../../InspectionForms/Examination/NurseExaminationForm";
import NurseFunctionalAssessment from "../../InspectionForms/FunctionalStatusAssessment/NurseFunctionalAssessment";
import NurseSpecialCareRequirement from "../../InspectionForms/NurseSpecialCareRequirement/NurseSpecialCareRequirement";
import { Button, Form, Modal, Tabs, message } from "antd";
import { NurseInitialContext } from "../../InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import { useLocation } from "react-router-dom";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import {
  nurseInspectionAPI,
  updateNurseInspectionAPI,
} from "../../../../services/nurse.services";

const NurseInitialInspectionForm = ({
  setVisible,
  refreshPage,
  initialRefresh,
  editId,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [historyForm] = Form.useForm();
  const [examinationForm] = Form.useForm();

  const [functionalForm] = Form.useForm();
  const [specialCareForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const { formData } = useContext(NurseInitialContext);
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (values) => {
    try {
      if (editId) {
        await updateNurseInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await nurseInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success("Form submitted successfully");
      if (activeTab === "4") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      console.log(error, "Error while submitting");
      message.error(error?.response?.data?.message || "Something went wrong");
    }
    console.log("Nurse initial assessment form data", values);
  };

  const onHistorySubmit = (values) => {
    handleSubmit({ history: values, admission_id: params?.patient });
  };
  const onExaminationSubmit = (values) => {
    handleSubmit({
      examination: values,
      admission_id: params?.patient,
    });
  };

  const onFunctionalSubmit = (values) => {
    handleSubmit({
      functional_status: values,
      admission_id: params?.patient,
    });
  };
  const onSpecialCareSubmit = (values) => {
    handleSubmit({
      special_care: values,
      admission_id: params?.patient,
      completed: true,
      // intial_assessment_done: true,
    });
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return historyForm.submit();
      case "2":
        return examinationForm.submit();
      case "3":
        return functionalForm.submit();
      case "4":
        return specialCareForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      // setActiveTab(key);
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              historyForm.validateFields().then((values) => {
                setIsDirty(false);
                onHistorySubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              examinationForm.validateFields().then((values) => {
                setIsDirty(false);
                onExaminationSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              functionalForm.validateFields().then((values) => {
                setIsDirty(false);
                onFunctionalSubmit(values);
                setActiveTab(key);
              });
              break;
            case "4":
              specialCareForm.validateFields().then((values) => {
                setIsDirty(false);
                onSpecialCareSubmit(values);
                setActiveTab(key);
              });
            default:
              setActiveTab(key);
          }
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Tabs
        activeKey={activeTab}
        items={[
          { key: "1", label: "History" },
          { key: "2", label: "Examination" },
          { key: "3", label: "Functional Status Assessment" },
          { key: "4", label: "Area of Special Care Requirement" },
        ]}
        onChange={handleTabChange}
      />
      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div style={{ height: "calc(100% - 38px)", overflowY: "auto" }}>
          {activeTab === "1" && (
            <Form
              id="history-form"
              form={historyForm}
              layout="vertical"
              onFinish={onHistorySubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseHistoryForm
                form={historyForm}
                formData={formData}
                patientData={patientData}
                editId={editId}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="examination-form"
              form={examinationForm}
              layout="vertical"
              onFinish={onExaminationSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseExaminationForm
                form={examinationForm}
                formData={formData}
              />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="functional-status-form"
              form={functionalForm}
              layout="vertical"
              onFinish={onFunctionalSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseFunctionalAssessment
                form={functionalForm}
                formData={formData}
              />
            </Form>
          )}
          {activeTab === "4" && (
            <Form
              id="special-care-form"
              form={specialCareForm}
              layout="vertical"
              onFinish={onSpecialCareSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseSpecialCareRequirement
                form={specialCareForm}
                formData={formData}
              />
            </Form>
          )}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
            >
              {activeTab === "4" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NurseInitialInspectionForm;
