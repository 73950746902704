import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Radio, Slider, Typography } from "antd";
import {
  getVaScaleApi,
  updateVaScaleApi,
  vaScaleAPI,
} from "../../../../../services/physio.services";
import noPainSvg from "../../../../../assets/images/scalesImages/noPain.svg";
import lightPainSvg from "../../../../../assets/images/scalesImages/lightPain.svg";
import moderatePainSvg from "../../../../../assets/images/scalesImages/moderatePain.svg";
import severePainSvg from "../../../../../assets/images/scalesImages/severePain.svg";
import verySeverePainSvg from "../../../../../assets/images/scalesImages/verySeverePain.svg";
import worstPainSvg from "../../../../../assets/images/scalesImages/worstPain.svg";

const VAScale = ({
  admission,
  onScalesSubmit,
  scalesId,
  setScalesId,
  setScaleTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const vasOptions = [
    {
      value: 0,
      label: "No Pain",
      image: noPainSvg,
    },
    {
      value: 1,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 2,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 3,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 4,
      label: "Moderate",
      image: moderatePainSvg,
    },
    {
      value: 5,
      label: "Moderate",
      image: moderatePainSvg,
    },
    {
      value: 6,
      label: "Severe",
      image: severePainSvg,
    },
    {
      value: 7,
      label: "Severe",
      image: severePainSvg,
    },
    {
      value: 8,
      label: "Very Severe",
      image: verySeverePainSvg,
    },
    {
      value: 9,
      label: "Very Severe",
      image: verySeverePainSvg,
    },
    {
      value: 10,
      label: "Worst Pain",
      image: worstPainSvg,
    },
  ];

  const handleSubmit = async (values) => {
    const finalData = {
      ...values,
      ip_admission: admission,
    };

    try {
      if (Object.keys(formData)?.length !== 0) {
        updateVaScaleApi(scalesId?.vas, finalData);
      } else {
        const response = await vaScaleAPI(finalData);
        setScalesId((prev) => {
          const newScalesId = { ...prev, vas: response?.data?.id };
          onScalesSubmit(newScalesId);
          return newScalesId;
        });
      }
      setScaleTab((prev) => (parseInt(prev) + 1).toString());
      message.success("Form filled successfully");
    } catch (error) {
      console.log(`Error:${error}`);
      message.error("Failed to load data");
    }
  };

  const handleView = async () => {
    if (scalesId?.vas) {
      getVaScaleApi(admission, scalesId?.vas)
        .then((response) => {
          form.setFieldsValue(response.data);
          setFormData(response.data);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  useEffect(() => {
    handleView();
  }, [admission]);

  return (
    <>
      <div className="cardless-scale" style={{ height: "70vh" }}>
        {/* <Typography>Visual Analog Scale</Typography> */}
        <Form id="vas" onFinish={handleSubmit} form={form}>
          <Form.Item name="pain">
            <Radio.Group className="radio-group">
              {vasOptions.map((option) => (
                <Radio.Button
                  key={option.value}
                  value={option.value}
                  style={{
                    height: "100px",
                    width: "80px",
                    padding: 0,
                    margin: "0 10px",
                  }}
                >
                  <div className="visual-option">
                    {option.image && (
                      <img
                        src={option.image}
                        alt={option.label}
                        className="visual-image"
                      />
                    )}
                    <div
                      className="visual-label"
                      style={{ fontSize: "10px", lineHeight: "1.2" }}
                    >
                      {option.value}
                      <br />
                      {option.label}
                    </div>
                  </div>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item name="comments">
            <Input placeholder="Comments" />
          </Form.Item>
        </Form>
      </div>
      <div className="scale-footer">
        <Button
          style={{ marginRight: "8px" }}
          onClick={() => setActiveTab("3")}
        >
          Save
        </Button>

        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default VAScale;
