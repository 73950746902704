import { Col, Form, List, Radio, Row, Typography } from "antd";
import React from "react";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { memoryFieldsOptions } from "../../../../Options/physioOptions";

const Memory = () => {
  return (
    <>
      <List
        bordered
        header={
          <Row style={{ width: "100%", textAlign: "center" }} align="middle">
            <Col span={12}></Col>
            <Col span={6}>
              <Typography.Text strong>First Test</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text strong>Second Test</Typography.Text>
            </Col>
          </Row>
        }
        style={{ background: "#fff", width: "600px" }}
        dataSource={memoryFieldsOptions}
        renderItem={(field) => (
          <List.Item>
            <Row style={{ width: "100%" }} align="middle">
              <Col span={12}>
                <Typography>{field.label}</Typography>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["memory_data", `${field.value}_1`]}
                  key={`${field.value}_1`}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="center" gutter={[8, 0]} align={"middle"}>
                      <Col>
                        <Radio.Button
                          value={"yes"}
                          style={{
                            backgroundColor: "#E9D7FE",
                            padding: "0 8px",
                          }}
                        >
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button
                          value={"no"}
                          style={{
                            backgroundColor: "#FFF5F5",
                            padding: "0 8px",
                          }}
                        >
                          <DislikeOutlined />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={["memory_data", `${field.value}_2`]}
                  key={`${field.value}_2`}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row justify="center" gutter={[8, 0]}>
                      <Col>
                        <Radio.Button
                          value={"yes"}
                          style={{
                            backgroundColor: "#E9D7FE",
                            padding: "0 8px",
                          }}
                        >
                          <LikeOutlined />
                        </Radio.Button>
                      </Col>
                      <Col>
                        <Radio.Button
                          value={"no"}
                          style={{
                            backgroundColor: "#FFF5F5",
                            padding: "0 8px",
                          }}
                        >
                          <DislikeOutlined />
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default Memory;
