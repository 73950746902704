import React from "react";
import { Form, Radio } from "antd";
import { bladderIncontinenceOptions } from "../../../Options/funcExamOptions";

const BladderIncontinence = () => {
  return (
    <Form.Item name="bladder_incontinence" label="Bladder Incontinence">
      <Radio.Group options={bladderIncontinenceOptions} />
    </Form.Item>
  );
};

export default BladderIncontinence;
