import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Skeleton, Result, Empty } from "antd";
import ServiceForm from "./ServiceForm";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { PlusOutlined } from "@ant-design/icons";
import ServiceTable from "./ServiceTable";
import {
  getFacilityServicesApi,
  getServicesApi,
} from "../../../../services/service.services";
import { UserContext } from "../../../../context/UserContext";
import { useLocation } from "react-router-dom";
import { FacilityID } from "../../../../constants/defaultKeys";
import { getCookie } from "../../../../helpers/localStorage";

const Service = () => {
  const { userDetails } = useContext(UserContext);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [isVisible, setIsVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    if (userDetails?.activeOrg?.default_role !== "Facility_Admin") {
      getServicesApi()
        .then((res) => {
          setLoading(false);
          setData(res?.data?.message);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [refresh]);
  useEffect(() => {
    setLoading(true);
    if (userDetails?.activeOrg?.default_role === "Facility_Admin") {
      getFacilityServicesApi(
        Number(getCookie(FacilityID)) || userDetails?.currFacility
      )
        .then((res) => {
          setLoading(false);
          setData(res?.data);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [params?.facility, userDetails]);

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {data && data?.length > 0 ? (
            <>
              <Row gutter={[12, 12]}>
                {userDetails?.activeOrg?.default_role !== "Facility_Admin" && (
                  <Col span={24} style={{ textAlign: "right" }}>
                    <Button
                      className={btnStyles.lgBtn}
                      type="primary"
                      onClick={() => setIsVisible(true)}
                    >
                      <PlusOutlined />
                      Service
                    </Button>
                  </Col>
                )}
                <Col span={24}>
                  <ServiceTable
                    data={data}
                    refreshPage={refreshPage}
                    userDetails={userDetails}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              {userDetails?.activeOrg?.default_role !== "Facility_Admin" ? (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Col span={8} style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      onClick={() => setIsVisible(true)}
                      className={btnStyles.lgBtn}
                    >
                      <PlusOutlined /> Each Service
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Result icon={<Empty />} />
              )}
            </>
          )}
          {isVisible && (
            <ServiceForm
              open={isVisible}
              setOpen={setIsVisible}
              refreshPage={refreshPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default Service;
