import React, { useEffect } from "react";
import Vision from "./Vision/Vision";
import { Divider, Typography } from "antd";
import Hearing from "./Hearing/Hearing";
import Speech from "./Speech/Speech";
import Ambulation from "../Examination/FunctionalExaminationForm/Ambulation";
import DailyActivities from "./DailyActivities/DailyActivities";
import BladderNeeds from "./BladderNeeds/BladderNeeds";
import BowelNeeds from "./BowelNeeds/BowelNeeds";
import Braden from "./Braden/Braden";
import RiskOfFall from "./RiskOfFall/RiskOfFall";
import { NurseInitialContext } from "../HistoryForm/NurseInitialAssessmentFormProvider";

const NurseFunctionalAssessment = ({ form, formData }) => {
  useEffect(() => {
    form.setFieldsValue(formData?.functional_status);
  }, [formData]);
  return (
    <>
      <Vision context={NurseInitialContext} />
      <Divider />
      <Hearing context={NurseInitialContext} />
      <Divider />
      <Speech context={NurseInitialContext} />
      <Divider />
      <Ambulation context={NurseInitialContext} />
      <Divider />
      <DailyActivities context={NurseInitialContext} />
      <Divider />
      <BladderNeeds />
      <Divider />
      <BowelNeeds />
      <Divider />
      <Braden context={NurseInitialContext} />
      <Divider />
      <RiskOfFall context={NurseInitialContext} />
    </>
  );
};

export default NurseFunctionalAssessment;
