import React from "react";
import { Card, Table, Tag } from "antd";
import dayjs from "dayjs";
import { formatToINR } from "../../../../helpers/utility";

const BedsIp = ({ billData }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) => dayjs(value).format("MMM DD, YYYY"),
    },
    {
      title: "Room",
      dataIndex: "room_no",
      key: "room_no",
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) =>
        value === "active" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="orange">Inactive</Tag>
        ),
    },
    {
      title: "Total",
      dataIndex: "total_price",
      key: "total_price",
      render: (value) => formatToINR(value),
    },
  ];
  return (
    <Card title="Beds">
      <Table
        columns={columns}
        dataSource={billData?.data?.beds}
        pagination={false}
      />
    </Card>
  );
};

export default BedsIp;
