import React, { useContext, useEffect, useState } from "react";
import {
  airwaySizeOptions,
  airwayTubeTypeOptions,
  artificialAirwayOptions,
  trachealSecretionConsistencyOptions,
  trachealStomaOptions,
} from "../../Options/RespiratoryTherapistOptions";
import { Checkbox, Form, Input, Radio, Typography } from "antd";
import { RespiratoryInitialContext } from "../../RespiratoryTherapist/InitialInspection/RespiratoryTherapistInitialAssessmentProvider";

const ArtificialAirway = () => {
  const { formData } = useContext(RespiratoryInitialContext);
  const [states, setStates] = useState({});

  const handleCheckboxChange = (value) => {
    setStates((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };
  useEffect(() => {
    const initialCheckboxes = artificialAirwayOptions.reduce(
      (acc, checkbox) => {
        acc[checkbox.value] =
          formData?.patient_assessment?.air_way?.[checkbox.value];
        return acc;
      },
      {}
    );
    setStates(initialCheckboxes);
  }, [formData]);

  return (
    <>
      <Typography>Artificial Airway</Typography>
      {artificialAirwayOptions.map((option) => (
        <React.Fragment key={option.value}>
          <Form.Item name={["air_way", option.value]} valuePropName="checked">
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={states[option.value] || false}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {option.value === "tracheostomy" && states[option.value] && (
            <>
              <Form.Item
                label="Size"
                name={["air_way", "tracheostomy_details", "size"]}
              >
                <Radio.Group options={airwaySizeOptions} />
              </Form.Item>
              <Form.Item
                label="Tube Type"
                name={["air_way", "tracheostomy_details", "tube_type"]}
              >
                <Checkbox.Group options={airwayTubeTypeOptions} />
              </Form.Item>
              <Form.Item
                label="Tracheal Stoma"
                name={["air_way", "tracheostomy_details", "tracheal_stoma"]}
              >
                <Radio.Group options={trachealStomaOptions} />
              </Form.Item>
              <Form.Item
                label="Tracheal Secretion Consistency"
                name={[
                  "air_way",
                  "tracheostomy_details",
                  "secretion_consistency",
                ]}
              >
                <Checkbox.Group options={trachealSecretionConsistencyOptions} />
              </Form.Item>
              <Form.Item name={["air_way", "tracheostomy_details", "comments"]}>
                <Input placeholder="Comments" />
              </Form.Item>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default ArtificialAirway;
