import React, { useEffect } from "react";
import CaloriesAndProtien from "./CaloriesAndProtien";
import { Divider } from "antd";
import TypeOfDiet from "./TypeOfDiet";
import OtherSpecifications from "./OtherSpecifications";
import PatientFamilyEducation from "./PatientFamilyEducation";
import { NutritionistInitialContext } from "../HistoryForm/NutritionistInitialAssessmentProvider";

const NutritionistDietAdvice = ({ form, formData }) => {
  useEffect(() => {
    form.setFieldsValue(formData?.diet_advice);
  }, [formData]);
  return (
    <>
      <CaloriesAndProtien />
      <Divider />
      <TypeOfDiet context={NutritionistInitialContext} />
      <Divider />
      <OtherSpecifications context={NutritionistInitialContext} />
      <Divider />
      <PatientFamilyEducation />
    </>
  );
};

export default NutritionistDietAdvice;
